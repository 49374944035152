// import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import Avatar from 'react-avatar-edit';
// import { Button } from '@mui/material';
// import styles from './profile.module.scss';

// export default class ProfileCropper extends Component {
//   constructor(props) {
//     super(props);

//     console.log(this.props);

//     const { currentImage, handleUpload } = this.props;


//     const src = '';
//     this.state = {
//       preview: currentImage || null,
//       src,
//       file: '',
//     };
//     this.onCrop = this.onCrop.bind(this);
//     this.onClose = this.onClose.bind(this);
//     this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
//   }

//   onClose() {
//     this.setState({ preview: null });
//     this.state.src = '';
//   }

//   onCrop(preview) {
//     this.setState({ preview });
//     function dataURLtoFile(dataurl, filename) {
//       var arr = dataurl.split(','),
//         mime = arr[0].match(/:(.*?);/)[1],
//         bstr = atob(arr[1]),
//         n = bstr.length,
//         u8arr = new Uint8Array(n);

//       while (n--) {
//         u8arr[n] = bstr.charCodeAt(n);
//       }

//       return new File([u8arr], filename, { type: mime });
//     }

//     const file = dataURLtoFile(preview, 'filename');

//     this.setState({
//       file,
//       src: preview,
//     });
//   }

//   onBeforeFileLoad(elem) {

//     if (elem.target.files[0].size > 5000000) {
//       alert('File is too big!');
//       elem.target.value = '';
//     }

//     // this.setState({
//     //   file: elem,
//     //   src: elem,
//     // });
//   }

//   render() {
//     return (
//       <div
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//         }}
//       >
//         <Avatar
//           width={250}
//           height={200}
//           onCrop={this.onCrop}
//           onClose={this.onClose}
//           onBeforeFileLoad={this.onBeforeFileLoad}
//           src={''}
//           //crossOrigin="anonymous"
//         />

//         {this.state.preview && (
//           <div
//             style={{
//               marginBlock: '2rem',
//             }}
//           >
//             <h2>Current Image / Preview</h2>
//             <img
//               style={{
//                 width: '150px',
//                 height: '150px',
//                 borderRadius: '50%',
//               }}
//               src={this.state.preview}
//               alt='Preview'
//             />
//           </div>
//         )}

//         <Button
//           onClick={() => {
//             this.props.handleUpload(this.state.file);
//           }}
//           disabled={!this.state.src || !this.state.preview}
//           className={styles.cropBtn}
//         >
//           Save
//         </Button>
//       </div>
//     );
//   }
// }

// import { Button } from '@mui/material';
// import React from 'react';
// import AvatarEditor from 'react-avatar-editor';
// export default class Main extends React.Component {
//   state = {
//     image: '',
//     allowZoomOut: false,
//     position: { x: 0.5, y: 0.5 },
//     scale: 1,
//     rotate: 0,
//     borderRadius: 100,
//     preview: null,
//     width: 200,
//     height: 200,
//   };

//   handleNewImage = async e => {
//     await this.setState({ image: e.target.files[0] });
//   };

//   handleSave = data => {
//     const img = this.editor.getImageScaledToCanvas().toDataURL();
//     const rect = this.editor.getCroppingRect();

//     this.setState({
//       preview: {
//         img,
//         rect,
//         scale: this.state.scale,
//         width: this.state.width,
//         height: this.state.height,
//         borderRadius: this.state.borderRadius,
//       },
//     });
//   };

//   handleScale = e => {
//     const scale = parseFloat(e.target.value);
//     this.setState({ scale });
//   };

//   rotateLeft = e => {
//     e.preventDefault();
//     this.setState({
//       rotate: this.state.rotate - 90,
//     });
//   };

//   rotateRight = e => {
//     e.preventDefault();
//     this.setState({
//       rotate: this.state.rotate + 90,
//     });
//   };

//   handleXPosition = e => {
//     const x = parseFloat(e.target.value);
//     this.setState({ position: { ...this.state.position, x } });
//   };

//   handleYPosition = e => {
//     const y = parseFloat(e.target.value);
//     this.setState({ position: { ...this.state.position, y } });
//   };

//   logCallback(e) {
//     // eslint-disable-next-line
//     console.log('callback', e);
//   }

//   setEditorRef = editor => {
//     if (editor) {
//       this.editor = editor;
//     }
//   };

//   handlePositionChange = position => {
//     this.setState({ position });
//   };

//   render() {
//     return (
//       <div>
//         <div>
//           <AvatarEditor
//             ref={this.setEditorRef}
//             scale={parseFloat(this.state.scale)}
//             width={this.state.width}
//             height={this.state.height}
//             position={this.state.position}
//             onPositionChange={this.handlePositionChange}
//             rotate={parseFloat(this.state.rotate)}
//             borderRadius={this.state.width / (100 / this.state.borderRadius)}
//             onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
//             onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
//             onImageReady={this.logCallback.bind(this, 'onImageReady')}
//             image={this.state.image}
//           />
//         </div>
//         <br />
//         New File:
//         <input type='file' onChange={this.handleNewImage} />
//         <br />
//         Zoom:
//         <input
//           name='scale'
//           type='range'
//           onChange={this.handleScale}
//           min={this.state.allowZoomOut ? '0.1' : '1'}
//           max='2'
//           step='0.01'
//           defaultValue='1'
//         />
//         Rotate:
//         <button onClick={this.rotateLeft}>Left</button>
//         <button onClick={this.rotateRight}>Right</button>
//         <br />
//         <br />
//         <input type='button' onClick={this.handleSave} value='Preview' />
//         <br />
//         {!!this.state.preview && (
//           <img
//             src={this.state.preview.img}
//             style={{
//               borderRadius: `${
//                 (Math.min(this.state.preview.height, this.state.preview.width) +
//                   10) *
//                 (this.state.preview.borderRadius / 2 / 100)
//               }px`,
//             }}
//             alt='img'
//           />
//         )}
//         <Button></Button>
//       </div>
//     );
//   }

// }

// import './styles.css';
import { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Avatar, Button, Slider } from '@mui/material';
import firebase from '../../firebase/firebase';
import styles from './profile.module.scss';
// import Avatar from '@material-ui/core/Avatar';
// import Slider from '@material-ui/core/Slider';

export default function ProfileCropper(props) {
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  const user = firebase.auth().currentUser;

  const today = new Date();
  const { currentImage, handleUpload, toggleModal } = props;

  const [state, setState] = useState({
    editor: '',
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: currentImage,
  });

  const handleZoomSlider = (event, value) => {
    const canvasScaled = state.editor.getImageScaledToCanvas();

    const croppedImg = canvasScaled.toDataURL();

    setState({ ...state, croppedImg, zoom: value });
  };

  const handleFileChange = e => {
    console.log('change');
    let url = window.URL.createObjectURL(e.target.files[0]);
    setState({
      img: url,
      cropperOpen: true,
    });
  };

  const handleSave = e => {
    if (state.editor) {
      const canvasScaled = state.editor.getImageScaledToCanvas();

      const croppedImg = canvasScaled.toDataURL();

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }

      const file = dataURLtoFile(
        croppedImg,
        user.displayName + today.getTime()
      );

      handleUpload(file);

      setState({
        ...state,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
      });
    }
  };

  const handleCancel = () => {
    setState({ cropperOpen: false });
  };

  const setEditorRef = editor => {
    state.editor = editor;
  };

  const handlePhoto = e => {
    e.preventDefault();
    inputRef.current.click();
    console.log('click');
  };

  return (
    <div className='App'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            margin: '1rem 0',
          }}
        >
          <input
            ref={inputRef}
            type='file'
            accept='image/*'
            onChange={handleFileChange}
            // style={{ display: 'none' }}
          />
        </div>
        <Avatar
          onClick={handlePhoto}
          src={state.croppedImg}
          style={{ height: 200, width: 200 }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AvatarEditor
          ref={setEditorRef}
          image={state.img}
          width={200}
          height={200}
          border={50}
          borderRadius={100}
          color={[255, 255, 255, 0.6]}
          scale={state.zoom}
          style={{ marginBottom: 10, paddingBottom: 0 }}
        />
        {state.img && (
          <>
            <Slider
              min={1}
              max={10}
              step={0.1}
              value={state.zoom}
              onChange={handleZoomSlider}
              style={{ width: 150, marginTop: 0, paddingTop: 0 }}
            />
            <div
              style={{
                margin: '0.5rem 0',
                display: 'flex',
                gap: '1rem',
              }}
            >
              <Button className={styles.cropBtn} onClick={toggleModal}>
                Cancel
              </Button>
              <Button className={styles.cropBtn} onClick={handleSave}>
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
