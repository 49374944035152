import React, { useEffect } from 'react';
import styles from './top-categories.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getVendor } from '../../../redux/slices/vendor';

import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image4 from './images/4.png';
import { useMediaQuery } from '@mui/material';
import {
  LAP_WIDTH,
  MOBILE_WIDTH,
  TAB_WIDTH,
} from '../../../constants/breakPoints';
import { Link } from 'react-router-dom';

const categories = [
  {
    id: 1,
    name: 'Sustainability',
    value: 'suits',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2FSustainability.png?alt=media&token=a9fbeae3-6b4a-4ffe-94d2-ce417a620336',
    height: {
      sm: '102px',
      md: '200px',
      lg: '300px',
      xl: '350px',
    },
    href: '/collection/women/sustainability',
  },
  {
    id: 2,
    name: 'Modern Classics',
    value: 'weeding',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fmodern-classic.png?alt=media&token=422374e5-0bf8-47b8-865b-29faf842eded',
    height: {
      sm: '102px',
      md: '200px',
      lg: '300px',
      xl: '350px',
    },
    href: '/collection/women/modern-classics',
  },
  {
    id: 3,
    name: 'Seasonal Wear',
    value: 'casual',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fseasonal.png?alt=media&token=654d127b-df9c-4fd8-ae59-0ce41c57ffa3',
    height: {
      sm: '200px',
      md: '300px',
      lg: '420px',
      xl: '500px',
    },
    href: '/collection/women/seasonal-wear',
  },
  {
    id: 4,
    name: 'Plus size',
    value: 'dresses',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fplus-size.png?alt=media&token=2d4e8142-d56b-40ad-baf2-df009b073609',
    height: {
      sm: '200px',
      md: '300px',
      lg: '420px',
      xl: '500px',
    },
    href: '/collection/women/plus-size',
  },
];

const TopCategories = ({ title, topCategories = categories, style }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendor());
  }, []);

  const isMobile = useMediaQuery(MOBILE_WIDTH);
  const isTab = useMediaQuery(TAB_WIDTH);
  const isLap = useMediaQuery('(max-width:1264px)');
  const isDesktop = useMediaQuery('(max-width:1464px)');

  return (
    <section
      className={styles.section}
      style={{
        ...style,
      }}
    >
      <div className='container'>
        <h1 className={styles.title}>{title}</h1>

        <div className={styles.categories}>
          {topCategories.map(category => {
            const { id, image, name, height, href } = category;
            return (
              <div
                key={id}
                style={{
                  gridArea: category.value,
                  position: 'relative',
                }}
              >
                <Link to={href}>
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    <img
                      src={image}
                      alt=''
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'top center',
                        height:
                          height &&
                          (isMobile
                            ? '150px'
                            : isTab
                            ? height.md
                            : isLap
                            ? height.lg
                            : height.xl),
                      }}
                    />
                    <div
                      className={styles.shadow}
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        zIndex: 1,
                        height: '30%',
                        width: '100%',
                        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%)`,
                      }}
                    ></div>
                  </div>
                  <h2
                    style={{
                      zIndex: 2,
                    }}
                  >
                    {name}
                  </h2>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TopCategories;
