import React from 'react';
import bg from './images/bg.png';
import styles from './for-kids.module.scss';
import { Button, useMediaQuery } from '@mui/material';
import {
  LAP_WIDTH,
  MOBILE_WIDTH,
  TAB_WIDTH,
} from '../../../constants/breakPoints';
import { Link } from 'react-router-dom';

const ForKids = () => {
  const isMobile = useMediaQuery(MOBILE_WIDTH);
  const isTab = useMediaQuery(TAB_WIDTH);
  const isLap = useMediaQuery(LAP_WIDTH);
  return (
    <section className={styles.section}>
      <div
        style={{
          widows: '100%',
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <img
            src={bg}
            alt=''
            style={{
              width: '100%',
            }}
          />

          <div className={styles.item}>
            <h1>Shop for kids</h1>
            <p>
              Explore trendy outfits designed just for little fashion stars.
              From playful tees to twirl-worthy dresses, discover fashion that
              sparks joy. Let the little ones shine in style. Dive in now!
            </p>
            <Button className={styles.btn}>
              <Link to='/kids'>Shop Now</Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForKids;
