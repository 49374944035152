import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import { getLynksDetails } from '../../../redux/slices/lynks';
import firebase from '../../../firebase/firebase'
import {useNavigate} from 'react-router-dom'
const NotificationCard = ({ styles,data }) => {
const dispatch = useDispatch();
  const navigate = useNavigate();
  const user=useSelector(state => state.user);
const clicknoti=async ()=>{
if(data.status===1 || data.status=== 2 ){

  await firebase.firestore().collection('lynks').doc(data?.Lynkid).get().then((res)=>{
    if(res.data().status>2){
      navigate(`/orders/${data?.Lynkid}`)
    }
    else {
      navigate(`/${data.bussinessName}/${data?.Lynkid}`)
    }
  })
 
}
if(data.status===3){
  navigate(`/orders/${data?.Lynkid}`)
}
if(data.status===4){
  await firebase.firestore().collection('requestorder').doc(data?.rid).get()
  .then((res)=>{
    if(res.status===0){
      navigate(`/requested-orders/${data?.rid}`)
    } else {
      navigate(`/${data.bussinessName || "unnamed"}/${data?.clynk}`)
    }
  })
 

}
if(data.status===5){

  navigate(`/${data.bussinessName || "unnamed"}/${data?.clynk}`)
}

}
  return (
     
    <div className={styles.notification__card} onClick={clicknoti} styele={{cursor: 'pointer'}}>
    
      <h2>
        {user?.profiledetials?.username || "U2lynk"}: <span>{data?.message} ...</span>
      </h2>
      {(data?.reason!=="" && data?.reason!==undefined) && <p>Reason of Rejection :{data?.reason}</p>}
      <p>{moment(data?.timestamp?.toDate()).format("Do MMMM YYYY , h:mm:ss a")}</p>
    
    
    </div>
    
  );
};

export default NotificationCard;
