import React, { useEffect, useState } from 'react';
import styles from './productCard.module.scss';
import example from './images/example.svg';
import { imageurl } from '../../config';
import Zoom from 'react-medium-image-zoom';
import { ReactComponent as Tick } from './images/tick.svg';
import Cross from './images/cross.svg';
import moment from 'moment';
import {
  Button,
  Checkbox,
  FormControl,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { style, styled } from '@mui/system';
import Countdown from 'react-countdown';
import MyImage from '../Common/ImageLoader';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';

import { useDispatch } from 'react-redux';
import {
  isAlterationDaysAvaliable,
  isExchangeDaysAvaliable,
  isReturnDaysAvaliable,
} from '../../utils/isAvaliableFor';

const ProductCard = ({
  index,
  data,
  vendor,
  status,
  from,
  productType,
  setselectedproduct,
  selectedproduct,
  ini,
  setsize,
  readymadesize,
  renderplace,
  sizeerror,
  canReturn,
  returnDays,
  exchangeDays,
  steps,
  alterationDays,
  onAlterationCancel,
  ddate,
}) => {
  const [personName, setPersonName] = React.useState([]);

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {},
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid rgba(0, 0, 0, 0.4)',
      background: '#fff',
      fontSize: '16px',
      lineHeight: '1.5',
      borderRadius: '5px',
      padding: '12px 12px',
      color: '#0A0A0A',

      // transition: theme.transitions.create([
      //   'border-color',
      //   'background-color',
      //   'box-shadow',
      // ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'DM Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      },
    },
  }));

  const [activeSize, setActiveSize] = useState(readymadesize || null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (renderplace === 'lynk') setsize(activeSize, index);
  }, [activeSize]);

  const checkclicked = e => {
    console.log(e.target.checxk);
    if (e.target.checked) {
      setselectedproduct([...selectedproduct, data]);
    } else {
      const old = [...selectedproduct];
      old.splice(old.indexOf(ini), 1);
      setselectedproduct([...old]);
    }
  };
  return (
    <>
      {from === 'alterModal' ? (
        <AlterationModal data={data} checkclicked={checkclicked} />
      ) : (
        <div className={styles.itemContainer}>
          <div
            className={`${
              from !== 'orderDetails' ? styles.item : styles.itemBorder
            }  `}
          >
            {status && (
              <div className={`${styles.status} `}>
                {!status?.includes('Rejected') ? (
                  <div
                    className={`${
                      styles.deliverd
                    } ${status.toLowerCase()}-color`}
                  >
                    {status}
                    {/* <img src={Tick} alt='tick' /> */}
                    <Tick />
                  </div>
                ) : (
                  <div className={styles.deliverd} style={{ color: '#DA1E28' }}>
                    {status}
                    <img src={Cross} alt='cross' />
                  </div>
                )}
              </div>
            )}

            <div
              className={styles.itemBody}
              style={{
                marginTop: '1rem',
              }}
            >
              {/* <div className={styles.itemImage}>
            <Zoom>
              <img
                src={imageurl(data?.images[0]) || example}
                alt='item'
                style={{
                  width: '150px',
                  height: '120px',
                  objectFit: 'contain',
                }}
              />
            </Zoom>
            <svg
              width='11'
              height='12'
              viewBox='0 0 11 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className={styles.image_zoom}
            >
              <path
                d='M0.000920822 6.89141L0 11.0176H4.12659L2.79314 9.68429L5.54202 6.93552L4.08241 5.47588L1.33353 8.22465L0.000920822 6.89141ZM6.87482 0.0177877L8.20693 1.34974L5.45674 4.09982L6.91636 5.55928L9.66655 2.8092L11 4.1427V0.0175781L6.87482 0.0177877Z'
                fill='#6A5B40'
              />
              <path
                d='M4.12617 0.0184986L0 0.0175776L-1.80379e-07 4.14417L1.33329 2.81072L4.08206 5.5596L5.5417 4.09998L2.79293 1.3511L4.12617 0.0184986ZM10.9998 6.8924L9.66784 8.22451L6.91776 5.47432L5.4583 6.93394L8.20838 9.68413L6.87488 11.0176L11 11.0176L10.9998 6.8924Z'
                fill='#6A5B40'
              />
            </svg>
          </div> */}
              <div className={styles.itemImage}>
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  style={{
                    width: '100%',
                  }}
                  spaceBetween={10}
                >
                  {data?.images.length === 0 ? (
                    <SwiperSlide>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {/* <Zoom closeText='Close'> */}
                        <MyImage
                          src={'http://placehold.jp/300x250.png'}
                          alt=''
                        />
                        {/* </Zoom> */}
                      </div>
                    </SwiperSlide>
                  ) : (
                    data?.images?.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          {/* <Zoom> */}
                          <MyImage
                            src={imageurl(item) || example}
                            alt='item'
                            style={{
                              width: '150px',
                              height: '120px',
                              objectFit: 'contain',
                            }}
                            images={data?.images.map(item => {
                              return imageurl(item) || example;
                            })}
                            index={index}
                          />
                          {/* </Zoom> */}
                          <svg
                            width='11'
                            height='12'
                            viewBox='0 0 11 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className={styles.image_zoom}
                          >
                            <path
                              d='M0.000920822 6.89141L0 11.0176H4.12659L2.79314 9.68429L5.54202 6.93552L4.08241 5.47588L1.33353 8.22465L0.000920822 6.89141ZM6.87482 0.0177877L8.20693 1.34974L5.45674 4.09982L6.91636 5.55928L9.66655 2.8092L11 4.1427V0.0175781L6.87482 0.0177877Z'
                              fill='#6A5B40'
                            />
                            <path
                              d='M4.12617 0.0184986L0 0.0175776L-1.80379e-07 4.14417L1.33329 2.81072L4.08206 5.5596L5.5417 4.09998L2.79293 1.3511L4.12617 0.0184986ZM10.9998 6.8924L9.66784 8.22451L6.91776 5.47432L5.4583 6.93394L8.20838 9.68413L6.87488 11.0176L11 11.0176L10.9998 6.8924Z'
                              fill='#6A5B40'
                            />
                          </svg>
                        </SwiperSlide>
                      );
                    })
                  )}
                </Swiper>
              </div>

              <div
                className={styles.itemDetails}
                style={{
                  textAlign: 'left',
                  width: '100%',
                  marginTop: '10px',
                }}
              >
                <div className={styles.itemName}>
                  {vendor?.businessName && (
                    <h4>
                      By <em>{vendor?.businessName}</em>
                    </h4>
                  )}
                  <h4>{data?.name}</h4>
                </div>
                <div className={styles.itemPrice}>
                  <h2>₹{parseFloat(data?.price).toFixed(2)}</h2>
                </div>
                {productType === 'readymade' && (
                  <div className={styles.itemPrice}>
                    <h2>Size : {activeSize}</h2>
                  </div>
                )}

                <div className={styles.itemLink}>
                  <a href={data?.lynk} target='_blank' rel='noreferrer'>
                    {data?.lynk?.substring(0, 15) + '...'}
                  </a>
                </div>
              </div>

              {from === 'alterModal' &&
                (data.isModification !== undefined
                  ? data.isModification
                  : true) && (
                  <div>
                    {/* {data?.status === 2 && ( */}
                    <Checkbox
                      sx={{
                        color: '#6A5B40',
                        '&.Mui-checked': {
                          color: '#6A5B40',
                          '&.Mui-checked': {
                            color: '#6A5B40',
                          },
                          '& .MuiSvgIcon-root': { fontSize: 24 },
                        },
                      }}
                      onChange={checkclicked}
                    />
                    {/* )} */}
                  </div>
                )}
            </div>
            {(productType === 'readymade'
              ? data?.exchange?.rejection_reason
              : data?.alteration?.rejection_reason) && (
              <div>
                Reason:
                {productType === 'readymade'
                  ? data?.exchange?.rejection_reason
                  : data?.alteration?.rejection_reason}
              </div>
            )}
            {status === 'Alteration Requested' && (
              <div
                style={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '2px',
                  alignItems: 'center',
                  backgroundColor: '#EEECE7',
                  borderRadius: '0px 0px 15px 15px',
                }}
              >
                <Button
                  style={{
                    fontSize: '1.2rem',
                    color: '#6A5B40',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    onAlterationCancel(data?.id);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            {/* {(renderplace === 'lynk' || from === 'orderDetails') &&
              (data.isModification !== undefined
                ? !data.isModification
                : false && productType === 'readymade') && (
                <div
                  style={{
                    width: '100%',
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '2px',
                    alignItems: 'center',
                    backgroundColor: '#EEECE7',
                    borderRadius: '0px 0px 15px 15px',
                  }}
                >

                </div>
              )} */}
          </div>

          {productType === 'readymade' &&
            from === 'lynkdetails' &&
            steps === 0 && (
              <div className={styles.input}>
                <label
                  htmlFor=''
                  style={{
                    color: sizeerror?.includes(index) ? '#DA1E28' : 'black',
                  }}
                >
                  Select your prefered size
                </label>
                <div className={styles.sizes}>
                  {data?.sizes.map((size, index) => (
                    <div
                      role='button'
                      key={index}
                      onClick={() => setActiveSize(size)}
                      className={`  ${
                        activeSize === size ? styles.activeSize : styles.size
                      }`}
                    >
                      <p>{size}</p>
                      {activeSize === size && (
                        <svg
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M20 10.0176C20 15.5404 15.5228 20.0176 10 20.0176C4.47715 20.0176 0 15.5404 0 10.0176C0 4.49473 4.47715 0.0175781 10 0.0175781C15.5228 0.0175781 20 4.49473 20 10.0176Z'
                            fill='white'
                          />
                          <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M7.4987 13.5174L3.9987 10.0174L2.83203 11.1841L7.4987 15.8507L17.4987 5.85075L16.332 4.68408L7.4987 13.5174Z'
                            fill='#6A5B40'
                          />
                        </svg>
                      )}
                    </div>
                  ))}
                </div>
                {sizeerror?.includes(index) && (
                  <div style={{ fontSize: '10px', color: '#DA1E28' }}>
                    *required
                  </div>
                )}
              </div>
            )}

          {/* <div className={styles.input}>
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <Select
            fullWidth
            displayEmpty
            value={personName}
            onChange={handleChange}
            input={<BootstrapInput />}
            renderValue={selected => {
              if (selected.length === 0) {
                return 'Available Sizes';
              }

              return selected.join(', ');
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem
              disabled
              value=''
              sx={{
                fontSize: '1.4rem',
                color: '#0A0A0A',
              }}
            >
              Available Sizes
            </MenuItem>
            {sizes.map(name => (
              <MenuItem
                sx={{
                  fontSize: '1.4rem',
                  color: '#0A0A0A',
                }}
                key={name}
                value={name}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div> */}

          {/* RETURN */}
          {data.status > 1 ? (
            <div
              style={{
                margin: '10px 0',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                textAlign: 'start',
                columnGap: '20px',
                fontSize: '12px',
                fontWeight: '700',
                color: '#3B3B3dB',
                padding: '0 10px',
              }}
            >
              {productType === 'readymade' ? (
                <p>
                  <span>
                    {isExchangeDaysAvaliable(
                      exchangeDays,
                      ddate,
                      productType,
                      data
                    ) ? (
                      <>
                        <span> Exchange within</span>&nbsp;
                        <span
                          style={{
                            color: '#DA1E28',
                          }}
                        >
                          {moment(
                            moment(ddate).add(parseInt(exchangeDays), 'd')
                          ).diff(moment(), 'day')}{' '}
                          days
                        </span>
                      </>
                    ) : !data.exchange ? (
                      <span
                        style={{
                          color: '#DA1E28',
                        }}
                      >
                        Exchange Not available
                      </span>
                    ) : (
                      <></>
                    )}
                  </span>
                </p>
              ) : (
                <></>
              )}
              {productType !== 'readymade' ? (
                <p>
                  <span>
                    {isAlterationDaysAvaliable(
                      alterationDays,
                      ddate,
                      productType,
                      data
                    ) ? (
                      <>
                        <span> Alteration within</span>&nbsp;
                        <span
                          style={{
                            color: '#DA1E28',
                          }}
                        >
                          {moment(
                            moment(ddate).add(parseInt(alterationDays), 'd')
                          ).diff(moment(), 'day')}{' '}
                          days
                        </span>
                      </>
                    ) : !data.alteration ? (
                      <span
                        style={{
                          color: '#DA1E28',
                        }}
                      >
                        Alteration Not available
                      </span>
                    ) : (
                      <></>
                    )}
                  </span>
                </p>
              ) : (
                <></>
              )}
              {productType === 'readymade' ? (
                <p>
                  <span>
                    {isReturnDaysAvaliable(
                      returnDays,
                      ddate,
                      productType,
                      data
                    ) ? (
                      <>
                        <span> Return within </span>&nbsp;
                        <span
                          style={{
                            color: '#DA1E28',
                          }}
                        >
                          {moment(
                            moment(ddate).add(parseInt(returnDays), 'd')
                          ).diff(moment(), 'day')}{' '}
                          days
                        </span>
                      </>
                    ) : !data.return && !data.exchange ? (
                      <span
                        style={{
                          color: '#DA1E28',
                        }}
                      >
                        Return Not available
                      </span>
                    ) : (
                      <></>
                    )}
                  </span>
                </p>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div
              style={{
                margin: '10px 0',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                textAlign: 'start',
                columnGap: '20px',
                fontSize: '12px',
                fontWeight: '700',
                color: '#3B3B3dB',
                padding: '0 10px',
              }}
            >
              {productType === 'readymade' ? (
                <p>
                  <span>
                    {parseInt(exchangeDays) > 0 && !data.exchange ? (
                      <>
                        <span> Exchange within</span>&nbsp;
                        <span
                          style={{
                            color: '#DA1E28',
                          }}
                        >
                          {parseInt(exchangeDays)} days
                        </span>
                      </>
                    ) : !data.exchange ? (
                      <span
                        style={{
                          color: '#DA1E28',
                        }}
                      >
                        Exchange Not available
                      </span>
                    ) : (
                      <></>
                    )}
                  </span>
                </p>
              ) : (
                <></>
              )}
              {productType !== 'readymade' ? (
                <p>
                  <span
                    style={{
                      color: '#DA1E28',
                    }}
                  >
                    {parseInt(alterationDays) > 0 && !data.alteration ? (
                      <>
                        <span> Alteration within</span>&nbsp;
                        {parseInt(alterationDays)} days
                      </>
                    ) : !data.alteration ? (
                      <>Alteration Not available</>
                    ) : (
                      <></>
                    )}
                  </span>
                </p>
              ) : (
                <></>
              )}
              {productType === 'readymade' ? (
                <p>
                  <span>
                    {parseInt(returnDays) > 0 && !data.return ? (
                      <>
                        <span> Return within </span>{' '}
                        <span
                          style={{
                            color: '#DA1E28',
                          }}
                        >
                          {parseInt(returnDays)} days
                        </span>
                      </>
                    ) : !data.return && !data.exchange ? (
                      <span
                        style={{
                          color: '#DA1E28',
                        }}
                      >
                        Return Not available
                      </span>
                    ) : (
                      <></>
                    )}
                  </span>
                </p>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProductCard;

const AlterationModal = ({ data, checkclicked }) => {
  return (
    <div className={styles.alterationProduct__item}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <div className={styles.itemImage}>
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            style={{
              width: '100%',
            }}
            spaceBetween={10}
          >
            {data?.images.length === 0 ? (
              <SwiperSlide>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Zoom closeText='Close'>
                    <MyImage src={'http://placehold.jp/300x250.png'} alt='' />
                  </Zoom>
                </div>
              </SwiperSlide>
            ) : (
              data?.images?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Zoom>
                      <img
                        src={imageurl(item)}
                        alt='item'
                        style={{
                          width: '50px',
                          height: '50px',
                          objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                    <svg
                      width='11'
                      height='12'
                      viewBox='0 0 11 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className={styles.image_zoom}
                    >
                      <path
                        d='M0.000920822 6.89141L0 11.0176H4.12659L2.79314 9.68429L5.54202 6.93552L4.08241 5.47588L1.33353 8.22465L0.000920822 6.89141ZM6.87482 0.0177877L8.20693 1.34974L5.45674 4.09982L6.91636 5.55928L9.66655 2.8092L11 4.1427V0.0175781L6.87482 0.0177877Z'
                        fill='#6A5B40'
                      />
                      <path
                        d='M4.12617 0.0184986L0 0.0175776L-1.80379e-07 4.14417L1.33329 2.81072L4.08206 5.5596L5.5417 4.09998L2.79293 1.3511L4.12617 0.0184986ZM10.9998 6.8924L9.66784 8.22451L6.91776 5.47432L5.4583 6.93394L8.20838 9.68413L6.87488 11.0176L11 11.0176L10.9998 6.8924Z'
                        fill='#6A5B40'
                      />
                    </svg>
                  </SwiperSlide>
                );
              })
            )}
          </Swiper>
        </div>
        <div className={styles.itemInfo}>
          <h1>{data.name}</h1>
          {data?.price && <p>₹{parseFloat(data?.price).toFixed(2)}</p>}
        </div>
      </div>
      <div>
        {/* {data?.status === 2 && ( */}
        <Checkbox
          sx={{
            color: '#6A5B40',
            height: '4rem',
            '&.Mui-checked': {
              color: '#6A5B40',
              '&.Mui-checked': {
                color: '#6A5B40',
              },
            },
            '& .MuiSvgIcon-root': { fontSize: '2.1rem', height: '2.1rem' },
          }}
          onChange={checkclicked}
        />
        {/* )} */}
      </div>
    </div>
  );
};
