export const TermNConditionsData = [
  {
    id: 1,
    title: 'Data and Privacy',
    term: (
      <>
        We are committed to protecting your privacy and data. Your personal
        information and data will be handled as described in our Privacy Policy.
        By signing up for the App, you consent to the collection, use, and
        disclosure of your personal information as outlined in the Privacy
        Policy.
      </>
    ),
  },
  {
    id: 2,
    title: 'Account Registration',
    term: (
      <>
        2.1 By signing up for LynkTown, you represent that you are at least 18
        years old and have the legal capacity to enter into a binding agreement.
        <br />
        <br />
        2.2 You agree to provide accurate and complete information during the
        registration process and to update this information as necessary to
        ensure it remains accurate and current
      </>
    ),
  },
  {
    id: 3,
    title: 'Account Security',
    term: (
      <>
        3.1 You are responsible for maintaining the confidentiality of your
        account credentials, including your username and password. You agree not
        to share your account credentials with any third party.
        <br />
        <br />
        3.2 You are responsible for all activities that occur under your
        account. If you believe that your account has been compromised, please
        notify us immediately.
      </>
    ),
  },
  {
    id: 4,
    title: 'Direct Communication with Sellers',
    term: (
      <>
        4.1 LynkTown provides an option for direct communication between buyers
        and sellers.
        <br />
        <br />
        4.2 Sellers can communicate with customers through communication
        channels, including call and WhatsApp, for inquiries, product details,
        and support.
      </>
    ),
  },
  {
    id: 5,
    title: 'Modifications and Updates',
    term: (
      <>
        5.1 We reserve the right to modify, suspend, or discontinue the App, or
        any part thereof, with or without notice.
        <br />
        <br />
        5.2 We may update the App to enhance its features and functionality.
        Your continued use of the App after updates signifies your acceptance of
        such changes.
      </>
    ),
  },
  {
    id: 6,
    title: 'Cancellation',
    term: (
      <>
        6.1 Customers have the right to cancel their orders within 5 hours of
        placing them. In the event of a cancellation request, you will have to
        contact our customer support team, and the same shall be informed to the
        seller
      </>
    ),
  },
  {
    id: 7,
    title: 'Governing Law and Jurisdiction',
    term: (
      <>
        7.1 These Terms shall be governed by and construed in accordance with
        the laws of India.
        <br />
        <br />
        These Terms constitute the entire understanding between you and LynkTown
        with regard to orders placed on the App, prevailing over any previous or
        concurrent agreements, interactions, or understandings.
        <br />
        <br />
        By signing up for LynkTown, you acknowledge that you have read,
        understood, and agree to be bound by these Terms. If you have any
        questions or concerns about these Terms, please contact us at&nbsp;
        <a href='mailto: support@lynktown.com'>support@lynktown.com</a>
      </>
    ),
  },
];
export const TermNConditionsReturnData = [
  {
    id: 1,
    title: 'Return and Exchange',
    term: (
      <>
        1.1 We are committed to ensuring your satisfaction with your products
        purchased from sellers on LynkTown. If you wish to return or exchange a
        readymade product, please review the seller's return or exchange policy,
        which will be clearly outlined on the product listing.
        <br />
        <br />
        1.2 Each seller has the flexibility to define their own return or
        exchange policy, giving them the freedom to decide how they want to
        manage returns or exchanges. Please carefully review the policy on the
        product listing before making a purchase.
        <br />
        <br />
        1.3 While the seller has the flexibility to define their return or
        exchange policy, there is a timeline within which customers can initiate
        a return or exchange request. This timeline will also be specified in
        the seller's policy and will be based on industry best practices and
        product specifications.
      </>
    ),
  },
  {
    id: 2,
    title: 'Alterations',
    term: (
      <>
        2.1 Should you require alterations to ensure a perfect fit for your
        chosen product, kindly adhere to the provided measurement guidelines
        accurately during the order placement.
        <br />
        <br />
        2.2 Alterations will be carried out by the seller in accordance with
        their specified alteration policies, which will be outlined on the
        product listing. The timeline for completing alterations will also be
        indicated in the policy.
        <br />
        <br />
        2.3 Please note that any alterations necessitated due to inaccurate
        measurements provided by the customer may result in additional charges.
      </>
    ),
  },
  {
    id: 3,
    title: 'Communication and Support',
    term: (
      <>
        3.1 You have the option to directly communicate with the seller via
        WhatsApp or call for any inquiries, concerns, or issues related to your
        order.
      </>
    ),
  },
  {
    id: 4,
    title: 'Cancellation',
    term: (
      <>
        4.1 You may cancel your order within 24 hours of placing it. Please
        contact our customer support team to initiate the cancellation process
      </>
    ),
  },
  {
    id: 5,
    title: 'Governing Law and Jurisdiction',
    term: (
      <>
        5.1 These Terms shall be governed by and construed in accordance with
        the laws of India.
        <br />
        <br />
        These Terms constitute the entire understanding between you and LynkTown
        with regard to orders placed on the App, prevailing over any previous or
        concurrent agreements, interactions, or understandings.
        <br />
        <br />
        By placing an order on LynkTown, you acknowledge having comprehended,
        read, and accepted these Terms. Should you possess any inquiries or
        apprehensions about these Terms, kindly contact us at&nbsp;
        <a href='mailto: support@lynktown.com'>support@lynktown.com</a>
      </>
    ),
  },
];
