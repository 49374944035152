import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';
export default function SimpleBackdrop({ open, handleClose, children }) {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '90%',
          }}
        >
          {children}
          <Button onClick={handleClose}>
            <AiFillCloseCircle
              style={{
                fontSize: 50,
                color: 'white',
                marginTop: '20px',
                cursor: 'pointer',
              }}
            />
          </Button>
        </Box>
      </Backdrop>
    </div>
  );
}
