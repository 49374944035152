import React, { useEffect, useState } from 'react';
import styles from './top-designers.module.scss';
import { imageurl } from '../../../config';
import { Button } from '@mui/material';
import firebase from '../../../firebase/firebase';
import { Link, useLocation } from 'react-router-dom';

const defaultDesigners = [
  {
    id: 1,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_e24f216a-f1ea-413a-93f8-e9f3791d8e2b.jpg?alt=media',
    href: 'akruthi_designer_wear',
    name: 'Akruthi Creations',
  },
  {
    id: 2,

    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_2e7b98de-f93e-4590-bae0-c0c04c7f1712.jpg?alt=media',
    href: 'Chajed_neha',
    name: 'Neha',
  },
  {
    id: 3,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_f937d857-b8a5-46d8-99fd-44ef7c6d0019.jpg?alt=media',
    href: 'surbhis-designer-label',
    name: `Surbhi's designer label`,
  },
  {
    id: 4,

    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_985eaca3-c66e-44bb-b306-3950d03c245a.jpg?alt=media',
    href: 'mom.nd.daughter',
    name: 'Mom & Daughter',
  },
];
const TopDesigners = ({ topDesigners = defaultDesigners, showMore }) => {
  return (
    <section className={styles.section}>
      <div className='container'>
        <h1 className={styles.title}>Our top Designers</h1>
        {/* {loading && (
          <p
            style={{
              textAlign: 'center',
              margin: '2rem 0',
            }}
          >
            Loading....
          </p>
        )} */}
        <div className={styles.designers}>
          {topDesigners?.map((item, idx) => {
            const { href, id, image, name } = item;
            return (
              <Link key={id} to={`/designers/${href}`}>
                <img src={image} alt='' />
                <h1>{name}</h1>
              </Link>
            );
          })}
        </div>
        {showMore && (
          <Link to='/designers'>
            <Button className={styles.btn}>Show all</Button>
          </Link>
        )}
      </div>
    </section>
  );
};

export default TopDesigners;
