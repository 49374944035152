import React from 'react';
import AccordionComp from './AccordionComp/AccordionComp';

const LowerBody = ({ mea }) => {
  const l = mea?.mesurement?.lowerbody;
  const gender = mea?.gender;
  const measurementData = [
    {
      heading: 'Waist',
      id: '1',
      items:
        gender === 'He' || gender === 'Boy'
          ? [
              {
                name: 'Waist',
                value: l?.Waist,
                id: '1',
              },
            ]
          : [
              {
                name: 'High Waist',
                value: l?.Waist?.High_Waist,
                id: '1',
              },

              {
                name: 'low Waist',
                value: l?.Waist?.Low_Waist,
                id: '2',
              },
              {
                name: 'Mid Waist',
                value: l?.Waist?.Mid_Waist,
                id: '3',
              },
            ],
    },
    {
      heading: 'Legs',
      id: '2',
      items: [
        {
          name: 'Full Length',
          value: l?.Full_length,
          id: '1',
        },
        {
          name: 'InSeam',
          value: l?.InSeam,
          id: '1',
        },
        {
          name: 'Thigh',
          value: l?.Thigh,
          id: '1',
        },
      ],
    },
    {
      heading: 'Hip',
      id: '3',
      items: [
        {
          name: 'Hip Round',
          value: l?.Hip_Round,
          id: '1',
        },
      ],
    },
    // {
    //   heading: 'InSeam',
    //   id: '5',
    //   items: [
    //     {
    //       name: 'InSeam',
    //       value: l?.InSeam,
    //       id: '1',
    //     },
    //   ],
    // },
    // {
    //   heading: 'Thigh',
    //   id: '6',
    //   items: [
    //     {
    //       name: 'Thigh',
    //       value: l?.Thigh,
    //       id: '1',
    //     },
    //   ],
    // },
    {
      heading: 'Calf',
      id: '7',
      items: [
        {
          name: 'Calf',
          value: l?.Calf,
          id: '1',
        },
      ],
    },
    {
      heading: 'Ankle',
      id: '8',
      items: [
        {
          name: 'Ankle',
          value: l?.Ankle,
          id: '1',
        },
      ],
    },
  ];
  return (
    <div>
      {measurementData.map(measurement => {
        const { heading, items, id } = measurement;

        return <AccordionComp key={id} heading={heading} items={items} />;
      })}
    </div>
  );
};

export default LowerBody;
