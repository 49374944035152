import React, { useEffect } from 'react';
import styles from './for-festival.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getVendor } from '../../../redux/slices/vendor';
import { imageurl } from '../../../config';
import { Button } from '@mui/material';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image4 from './images/4.png';
import { Link } from 'react-router-dom';

const images = [
  {
    id: 1,
    name: 'Haldi - Hues of sunset',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fhaldi.png?alt=media&token=0f38cb8c-651e-4d09-87a2-6ec8d1c5a503',
    href: '/collection/women/haldi-hues-of-sunset',
  },
  {
    id: 2,
    name: 'Mehendi',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fmehandi.png?alt=media&token=62e8b58e-b85b-47ce-b643-0bb66dd3e062',
    href: '/collection/women/mehendi',
  },
  {
    id: 3,
    name: 'Sangeet Cocktail',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fsangeet.png?alt=media&token=7bbd5d59-49b6-4270-9354-1554ad0f6ae3',
    href: '/collection/women/sangeet',
  },
  {
    id: 4,
    name: 'Reception',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Freception.png?alt=media&token=d7deec76-2e8e-4d64-baeb-7363450cec3d',
    href: '/collection/women/reception',
  },
];
const BridalTrousseau = () => {
  return (
    <section className={styles.section}>
      <div className='container'>
        <h1 className={styles.title}>Bridal Trousseau</h1>
        <div className={styles.designers}>
          {images?.map(designer => {
            const { id, image, name, href } = designer;
            return (
              <div key={id}>
                <Link to={href}>
                  <div
                    className={styles.image}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <img src={image} alt='' />
                    <div className={styles.shadow}></div>
                    <h1>{name}</h1>
                  </div>
                </Link>
                {/* <DesignerCard designer={designer} /> */}
              </div>
            );
          })}
        </div>
        {/* <Link to='/women'>
          <Button className={styles.btn}>Show all</Button>
        </Link> */}
      </div>
    </section>
  );
};

export default BridalTrousseau;
