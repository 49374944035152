import React, { useEffect, useState } from 'react';
import styles from './userCard.module.scss';
import userImg from './images/user.svg';
import { Avatar, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import firebase from '../../../../../../firebase/firebase';
import { useNavigate } from 'react-router';
import { getProfiledata } from '../../../../../../redux/slices/userslice';
import { imageurl } from '../../../../../../config';
import { Link } from 'react-router-dom';
import ModalComponent from '../../../../../Modal/ModalComponent';
import SnackBarModal from '../../../../../Modal/snackbar/SnackBarModal';
import { showSnackBar } from '../../../../../../redux/slices/ui';
import { ReactComponent as Lynks } from './images/lynks.svg';
import { ReactComponent as ExploreDesigner } from './images/explore-designer.svg';
import { ReactComponent as Orders } from './images/orders.svg';

const UserCard = ({ userCardRef, navItems, setShowUserCard }) => {
  const { user } = useSelector(state => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const {
    profiledetials,
  } = useSelector(state => state.user);

  return (
    <div>
      <SnackBarModal open={isLogout} text='Logging out' />
      <ModalComponent open={showLogoutModal} height={'220px'} width='400px'>
        <div className={styles.logoutModal}>
          <h1>Are you sure you want to logout?</h1>
          <p>We hope to see you soon</p>
          <div className={styles.logoutModal__btns}>
            <Button
              onClick={() => {
                setShowUserCard(false);
                setShowLogoutModal(false);
              }}
              style={{
                backgroundColor: '#6D5C41',
                color: '#fff',
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                color: '#DA1E28',
                border: '1px solid #DA1E28',
              }}
              onClick={() => {
                setShowLogoutModal(false);
                setIsLogout(true);

                setTimeout(() => {
                  setIsLogout(false);
                  sessionStorage.clear();
                  firebase.auth().signOut();
                  dispatch(
                    showSnackBar({
                      message: 'Logged out Successfully!',
                      position: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    })
                  );
                }, 2000);
              }}
              className={styles.logoutModal__btns_red}
            >
              Logout
            </Button>
          </div>
        </div>
      </ModalComponent>
      <div className={styles.userCard}>
        <div
          className={styles.userCardContent}
          role='button'
          onClick={() => {
            navigate('/profile');
            setShowUserCard(false);
          }}
          style={{ cursor: 'pointer' }}
        >
          <div className={styles.userCard__avatar}>
            <Avatar
              src={
                imageurl(profiledetials?.avatar) || './images/dummy-profile.jpg'
              }
              alt=''
            />
          </div>
          <div className={styles.userCard__info}>
            <h2>{profiledetials?.username}</h2>
          </div>
        </div>
        <div
          className={styles.userCardList}
          onClick={() => {
            setShowUserCard(false);
          }}
        >
          {navItems.map(item => {
            const { icon, id, title, to } = item;
            return (
              <Link key={id} to={to}>
                {icon}

                <h2>{title}</h2>
              </Link>
            );
          })}
          {/* <Link to='/orders'>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.5 1.5L2.25 4.5V15C2.25 15.3978 2.40804 15.7794 2.68934 16.0607C2.97064 16.342 3.35218 16.5 3.75 16.5H14.25C14.6478 16.5 15.0294 16.342 15.3107 16.0607C15.592 15.7794 15.75 15.3978 15.75 15V4.5L13.5 1.5H4.5Z'
                stroke='#857250'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M2.25 4.5H15.75'
                stroke='#857250'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M12 7.5C12 8.29565 11.6839 9.05871 11.1213 9.62132C10.5587 10.1839 9.79565 10.5 9 10.5C8.20435 10.5 7.44129 10.1839 6.87868 9.62132C6.31607 9.05871 6 8.29565 6 7.5'
                stroke='#857250'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <h2>Orders</h2>
          </Link> */}
        </div>
        <div className={styles.cardFooter}>
          <Button
            onClick={() => {
              // setShowUserCard(false);
              setShowLogoutModal(true);
              // sessionStorage.clear();
              // firebase.auth().signOut();
            }}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
