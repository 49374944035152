import {
  Button,
  FormControlLabel,
  InputBase,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  withStyles,
} from '@mui/material';
import { styled } from '@mui/system';
import styles from './MeasurementForm.module.scss';
import { useNavigate } from 'react-router';
import React, { useState } from 'react';

const MeasurementForm = ({ id, vendorInfo }) => {
  const genders = ['He', 'She', 'Boy', 'Girl'];
  const sizes = ['S', 'M', 'L', 'XL', 'XXL'];
  const fittings = ['Regular', 'Slim', 'Loose'];

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {},
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: '1.6rem',
      borderRadius: '5px',
      padding: '8px 12px',
      // transition: theme.transitions.create([
      //   'border-color',
      //   'background-color',
      //   'box-shadow',
      // ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'DM Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      },
    },
  }));

  const [gender, setGender] = useState('He');
  const [size, setSize] = useState('S');
  const [fitting, setFitting] = useState('Regular');
  const navigate = useNavigate();
  return (
    <div>
      <div className={styles.form}>
        <div className={styles.form__control}>
          <h4>Select gender</h4>
          <Select
            input={<BootstrapInput className={styles.selectInput} />}
            value={gender}
            onChange={e => setGender(e.target.value)}
          >
            {genders.map(name => (
              <MenuItem value={name}>
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue='female'
                  name='radio-buttons-group'
                >
                  <FormControlLabel
                    checked={name === gender}
                    value={name}
                    control={
                      <Radio
                        sx={{
                          color: 'var(--primary-dark)',
                          '&.Mui-checked': {
                            color: 'var(--primary-dark)',
                          },
                        }}
                        size='large'
                      />
                    }
                    label={
                      <>
                        <p
                          style={{
                            fontSize: '1.6rem',
                          }}
                          className={styles.selectLabel}
                        >
                          {name}
                        </p>
                      </>
                    }
                  />
                </RadioGroup>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.form__control}>
          <h4>Select your standard size</h4>
          <Select
            input={<BootstrapInput className={styles.selectInput} />}
            sx={{
              '&:focus': {
                borderRadius: 4,
                borderColor: 'red',
              },
            }}
            value={size}
            onChange={e => setSize(e.target.value)}
          >
            {sizes.map(name => (
              <MenuItem value={name}>
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue='female'
                  name='radio-buttons-group'
                >
                  <FormControlLabel
                    checked={name === size}
                    value={name}
                    control={
                      <Radio
                        sx={{
                          color: 'var(--primary-dark)',
                          '&.Mui-checked': {
                            color: 'var(--primary-dark)',
                          },
                        }}
                        size='large'
                      />
                    }
                    label={
                      <>
                        <p
                          style={{
                            fontSize: '1.6rem',
                          }}
                          className={styles.selectLabel}
                        >
                          {name}
                        </p>
                      </>
                    }
                  />
                </RadioGroup>
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className={styles.form__control}>
        <h4>Preferred fittings</h4>
        <Select
          input={<BootstrapInput className={styles.selectInputBig} />}
          value={fitting}
          onChange={e => setFitting(e.target.value)}
        >
          {fittings.map(name => (
            <MenuItem value={name}>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='female'
                name='radio-buttons-group'
              >
                <FormControlLabel
                  checked={name === fitting}
                  value={name}
                  control={
                    <Radio
                      sx={{
                        color: 'var(--primary-dark)',
                        '&.Mui-checked': {
                          color: 'var(--primary-dark)',
                        },
                      }}
                      size='large'
                    />
                  }
                  label={
                    <>
                      <p
                        style={{
                          fontSize: '1.6rem',
                        }}
                        className={styles.selectLabel}
                      >
                        {name}
                      </p>
                    </>
                  }
                />
              </RadioGroup>
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.btnContainer}>
        <Button
          onClick={() => {
            navigate(`/add-measurement/${gender}/${size}/${fitting}/${id}`, {
              state: vendorInfo,
            });
          }}
        >
          Add measurement
        </Button>
      </div>
    </div>
  );
};

export default MeasurementForm;
