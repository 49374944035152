import React from 'react';
import Header from './Header/Header';
import styles from './container.module.scss';
import SEO from '../SEO';
const Container = ({ children, name, back }) => {
  return (
    <>
      {/* <SEO /> */}
      <Header name={name} back={back} />
      <main className={styles.mainContainer}>{children}</main>
    </>
  );
};

export default Container;
