import React from "react";
import styles from "./designerCard.module.scss";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";
import { imageurl } from "../../config";
import designerImage from "./images/designer.png";
import { FaRegHeart } from "react-icons/fa";
import { IconButton } from "@mui/material";

const DesignerCard = ({ designer }) => {
  const { id, businessName, shippingDetails, profilePic, profileInfo } =
    designer;
  return (
    <div key={id} className={styles.designer_card}>
      {/* <IconButton className={styles.heart}>
        <FaRegHeart size={25} />
      </IconButton> */}
      <img src={imageurl(profilePic) || designerImage} alt="" />
      <h1>{businessName}</h1>
      <p>
        <HiOutlineLocationMarker />
        <span>
          {" "}
          {[shippingDetails?.city, shippingDetails?.state].join(" ")}
        </span>
      </p>
      <div>
        <Link
          to={`/designers/${encodeURIComponent(
            profileInfo?.insta_username && profileInfo?.insta_username
          )}`}
        >
          Explore lynks
        </Link>
      </div>
    </div>
  );
};

export default DesignerCard;
