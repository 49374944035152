/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import styles from './profile.module.scss';
import { TiPencil } from 'react-icons/ti';
import { FaWhatsappSquare } from 'react-icons/fa';
import { Form, Formik } from 'formik';
import Input from '../../Components/Common/Form/Input';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import firebase from '../../firebase/firebase';
import { getProfiledata, setProfiledata } from '../../redux/slices/userslice';
import { imageurl } from '../../config';
import { notify, showSnackBar } from '../../redux/slices/ui';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import ModalComponent from '../../Components/Modal/ModalComponent';
import AvatarEditor from 'react-avatar-editor';
import Avatar from 'react-avatar-edit';
import ProfileCropper from './ProfileCropper';
import SnackBarModal from '../../Components/Modal/snackbar/SnackBarModal';
import { useRef } from 'react';

const Profile = () => {
  const {
    user: { phoneNumber, uid },
    profiledetials,
  } = useSelector(state => state.user);

  const { loading } = useSelector(state => state.ui);
  const mobile = useMediaQuery(MOBILE_WIDTH);

  const [editing, setEditing] = useState(false);
  const initialValues = {
    name: profiledetials?.username || '',
    email: profiledetials?.email || '',
    phoneno: profiledetials?.phoneno || '',
    gender: profiledetials?.gender || '',
  };

  const [loadingSaveProfile, setLoadingSaveProfile] = useState(false);

  const handleSubmit = values => {
    setLoadingSaveProfile(true);
    dispatch(setProfiledata(values));

    setTimeout(() => {
      setLoadingSaveProfile(false);
    }, 1000);
    setEditing(false);
  };

  const validationSchema = Yup.object({
    name: Yup.string(),
    gender: Yup.string().oneOf(['male', 'female']),
  });

  const viewimage = async file => {
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    return await toBase64(file);
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getProfiledata());
  // }, []);

  useEffect(() => {
    if (profiledetials?.avatar) {
      setimage(imageurl(`${profiledetials?.avatar}`));
    }
    if (profiledetials?.bgimage) {
      setbgimage(imageurl(`${profiledetials?.bgimage}`));
    }
  }, []);

  const [image, setimage] = useState(null);
  const [bgimage, setbgimage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  const toggleModal = (dd, d) => {
    setShowImageModal(prev => !prev);
  };

  const tab = useMediaQuery('(max-width:760px)');
  const OnfileUpload = async (e, index) => {
    const papai = [e];

    const user = firebase.auth().currentUser;
    const heap = papai.map(filei => viewimage(filei));

    await Promise.all(heap).then(result => {
      if (image?.includes('firebasestorage.googleapis.com')) {
        firebase
          .storage()
          .ref(`userprofile/${papai[0].name}`)
          .put(papai[0])
          .then(() => {
            let ref = firebase.storage().refFromURL(image);

            firebase
              .storage()
              .ref(ref.fullPath)
              .delete()
              .then(() => { })
              .catch(err => {
                console.log(err);
              });

            firebase
              .firestore()
              .collection('users')
              .doc(uid)
              .update({
                avatar: encodeURIComponent(`userprofile/${papai[0].name}`),
              });
            user.updateProfile({
              photoURL: encodeURIComponent(`userprofile/${papai[0].name}`),
            });
          });
      } else {
        firebase
          .storage()
          .ref(`/userprofile/${papai[0].name}`)
          .put(papai[0])
          .then(() => {
            firebase
              .firestore()
              .collection('users')
              .doc(uid)
              .update({
                avatar: encodeURIComponent(`userprofile/${papai[0].name}`),
              });
          });
        user.updateProfile({
          photoURL: encodeURIComponent(`userprofile/${papai[0].name}`),
        });
      }
      setimage(result);
      setShowImageModal(false);
      dispatch(
        showSnackBar({
          message: 'Profile Picture updated successfully',
        })
      );
      // dispatch(
      //   notify({
      //     type: 'success',
      //     message: 'profile Pic updated successfully',
      //     status: true,
      //   })
      // );
    });
  };
  const bgOnfileUpload = async (e, index) => {
    const papai = [...e.target.files];
    const user = firebase.auth().currentUser;
    const heap = papai.map(filei => viewimage(filei));

    await Promise.all(heap).then(result => {
      if (bgimage?.includes('firebasestorage.googleapis.com')) {
        firebase
          .storage()
          .ref(`bguserprofile/${papai[0].name}`)
          .put(papai[0])
          .then(() => {
            let ref = firebase.storage().refFromURL(bgimage);

            firebase
              .storage()
              .ref(ref.fullPath)
              .delete()
              .then(() => { })
              .catch(err => {
                console.log(err);
              });

            firebase
              .firestore()
              .collection('users')
              .doc(uid)
              .update({
                bgimage: encodeURIComponent(`bguserprofile/${papai[0].name}`),
              });
          });
      } else {
        firebase
          .storage()
          .ref(`/bguserprofile/${papai[0].name}`)
          .put(papai[0])
          .then(() => {
            firebase
              .firestore()
              .collection('users')
              .doc(uid)
              .update({
                bgimage: encodeURIComponent(`userprofile/${papai[0].name}`),
              });
          });
      }
      setbgimage(result);
      setShowImageModal(false);
      dispatch(
        showSnackBar({
          message: 'Your Cover image updated successfully',
        })
      );
      // dispatch(
      //   notify({
      //     type: 'success',
      //     message: 'profile Pic updated successfully',
      //     status: true,
      //   })
      // );
    });
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {},
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid rgba(0, 0, 0, 0.4)',
      fontSize: '1.4rem',
      borderRadius: '5px',
      padding: '12px 12px',
      fontFamily: [
        'DM Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {},
    },
  }));
  const bgref = useRef();

  const onbgchnage = () => {
    bgref.current.click();
  };
  return (
    <div
      className={styles.profilePage}
      style={{
        position: 'relative',
      }}
    >
      <SnackBarModal open={loadingSaveProfile} text='Saving Changes' />
      <ModalComponent
        open={showImageModal}
        handleClose={toggleModal}
        height={tab ? 'fit-content' : 'fit-content'}
        width={'650px'}
      >
        <ProfileCropper
          currentImage={image ? image : './images/dummy-profile.jpg'}
          handleUpload={OnfileUpload}
          toggleModal={toggleModal}
        />
      </ModalComponent>
      <SectionNavigation back='/' />
      <div className={styles.profileContent}>
        <div
          className={styles.profileContent__header}
          style={{ backgroundImage: `url(${bgimage})` }}
        >
          <IconButton
            style={{
              position: 'absolute',
              bottom: '5px',
              left: '10px',
            }}
            onClick={onbgchnage}
          >
            <svg
              width='36'
              height='35'
              viewBox='0 0 36 35'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle
                cx='18.1816'
                cy='17.5'
                r='17.5'
                fill='black'
                fillOpacity='0.5'
              />
              <path
                d='M28.7046 10.6159C28.2602 10.0845 27.6464 9.77053 26.9481 9.77053H23.435V9.72222C23.435 9.11836 23.2233 8.53865 22.8636 8.15217C22.5038 7.74155 22.017 7.5 21.488 7.5H15.139C14.5888 7.5 14.102 7.74155 13.7422 8.15217C13.3825 8.5628 13.1708 9.11836 13.1708 9.72222V9.77053H9.6789C8.98051 9.77053 8.36678 10.0845 7.92235 10.6159C7.47793 11.1232 7.18164 11.8478 7.18164 12.6208V24.6498C7.18164 25.4469 7.45676 26.1473 7.92235 26.6546C8.36678 27.1618 9.00167 27.5 9.6789 27.5H26.9481C27.6464 27.5 28.2602 27.186 28.7046 26.6546C29.149 26.1473 29.4453 25.4227 29.4453 24.6498V12.6208C29.4453 11.8237 29.1702 11.1232 28.7046 10.6159ZM28.3448 24.6498H28.3237C28.3237 25.0845 28.1755 25.471 27.9216 25.7609C27.6676 26.0507 27.329 26.2198 26.9481 26.2198H9.6789C9.29796 26.2198 8.95935 26.0507 8.70539 25.7609C8.45143 25.471 8.30329 25.0845 8.30329 24.6498V12.6208C8.30329 12.186 8.45143 11.7995 8.70539 11.5097C8.95935 11.2198 9.29796 11.0507 9.6789 11.0507H13.7634C14.0808 11.0507 14.3348 10.7609 14.3348 10.3986V9.69807C14.3348 9.43237 14.4195 9.19082 14.5676 9.02174C14.7157 8.85266 14.9274 8.75604 15.1602 8.75604H21.488C21.7207 8.75604 21.9324 8.85266 22.0805 9.02174C22.2287 9.19082 22.3133 9.43237 22.3133 9.69807V10.3986C22.3133 10.7609 22.5673 11.0507 22.8847 11.0507H26.9692C27.3502 11.0507 27.6888 11.2198 27.9427 11.5097C28.1967 11.7995 28.3448 12.186 28.3448 12.6208V24.6498Z'
                fill='white'
              />
              <path
                d='M18.3136 12.6934C16.8745 12.6934 15.5624 13.3697 14.6312 14.4325C13.6788 15.5194 13.1074 16.9929 13.1074 18.6354C13.1074 20.2779 13.7 21.7755 14.6312 22.8383C15.5835 23.9252 16.8745 24.5774 18.3136 24.5774C19.7527 24.5774 21.0648 23.9011 21.996 22.8383C22.9483 21.7513 23.5197 20.2779 23.5197 18.6354C23.5197 16.9929 22.9271 15.4953 21.996 14.4325C21.0648 13.3697 19.7527 12.6934 18.3136 12.6934ZM21.1918 21.9446C20.451 22.7658 19.4352 23.2972 18.3136 23.2972C17.1919 23.2972 16.1761 22.7658 15.4354 21.9446C14.6947 21.0992 14.2502 19.9397 14.2502 18.6595C14.2502 17.3793 14.7158 16.2199 15.4354 15.3745C16.1761 14.5291 17.1919 14.0219 18.3136 14.0219C19.4352 14.0219 20.451 14.5533 21.1918 15.3745C21.9325 16.2199 22.3769 17.3793 22.3769 18.6595C22.3981 19.9397 21.9325 21.0992 21.1918 21.9446Z'
                fill='white'
              />
              <path
                d='M25.8475 15.0351C26.4203 15.0351 26.8845 14.5052 26.8845 13.8515C26.8845 13.1979 26.4203 12.668 25.8475 12.668C25.2748 12.668 24.8105 13.1979 24.8105 13.8515C24.8105 14.5052 25.2748 15.0351 25.8475 15.0351Z'
                fill='white'
              />
            </svg>
          </IconButton>
          <label>
            <input
              ref={bgref}
              onChange={e => {
                bgOnfileUpload(e);
              }}
              style={{ display: 'none' }}
              accept='image/*'
              type='file'
            />
          </label>
          <h1>Profile</h1>
          <div
            className={styles.profileContent__header__image}
            onClick={() => {
              setShowImageModal(true);
            }}
          >
            <label htmlFor={`inputavatar`}>
              {/* <input
                onChange={e => {
                  console.log(e);
                  showImageModal(true);
                  // OnfileUpload(e);
                }}
                style={{ display: 'none' }}
                accept='image/*'
                id={`inputavatar`}
                type='file'
              /> */}
              <Button
                variant='contained'
                component='span'
                style={{
                  borderRadius: '100%',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
              >
                <img
                  src={image ? image : './images/dummy-profile.jpg'}
                  alt='profile'
                />
                <svg
                  style={{
                    position: 'absolute',
                    right: '0',
                    bottom: '60px',
                  }}
                  width='36'
                  height='35'
                  viewBox='0 0 36 35'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='18.1816'
                    cy='17.5'
                    r='17.5'
                    fill='black'
                    fillOpacity='0.5'
                  />
                  <path
                    d='M28.7046 10.6159C28.2602 10.0845 27.6464 9.77053 26.9481 9.77053H23.435V9.72222C23.435 9.11836 23.2233 8.53865 22.8636 8.15217C22.5038 7.74155 22.017 7.5 21.488 7.5H15.139C14.5888 7.5 14.102 7.74155 13.7422 8.15217C13.3825 8.5628 13.1708 9.11836 13.1708 9.72222V9.77053H9.6789C8.98051 9.77053 8.36678 10.0845 7.92235 10.6159C7.47793 11.1232 7.18164 11.8478 7.18164 12.6208V24.6498C7.18164 25.4469 7.45676 26.1473 7.92235 26.6546C8.36678 27.1618 9.00167 27.5 9.6789 27.5H26.9481C27.6464 27.5 28.2602 27.186 28.7046 26.6546C29.149 26.1473 29.4453 25.4227 29.4453 24.6498V12.6208C29.4453 11.8237 29.1702 11.1232 28.7046 10.6159ZM28.3448 24.6498H28.3237C28.3237 25.0845 28.1755 25.471 27.9216 25.7609C27.6676 26.0507 27.329 26.2198 26.9481 26.2198H9.6789C9.29796 26.2198 8.95935 26.0507 8.70539 25.7609C8.45143 25.471 8.30329 25.0845 8.30329 24.6498V12.6208C8.30329 12.186 8.45143 11.7995 8.70539 11.5097C8.95935 11.2198 9.29796 11.0507 9.6789 11.0507H13.7634C14.0808 11.0507 14.3348 10.7609 14.3348 10.3986V9.69807C14.3348 9.43237 14.4195 9.19082 14.5676 9.02174C14.7157 8.85266 14.9274 8.75604 15.1602 8.75604H21.488C21.7207 8.75604 21.9324 8.85266 22.0805 9.02174C22.2287 9.19082 22.3133 9.43237 22.3133 9.69807V10.3986C22.3133 10.7609 22.5673 11.0507 22.8847 11.0507H26.9692C27.3502 11.0507 27.6888 11.2198 27.9427 11.5097C28.1967 11.7995 28.3448 12.186 28.3448 12.6208V24.6498Z'
                    fill='white'
                  />
                  <path
                    d='M18.3136 12.6934C16.8745 12.6934 15.5624 13.3697 14.6312 14.4325C13.6788 15.5194 13.1074 16.9929 13.1074 18.6354C13.1074 20.2779 13.7 21.7755 14.6312 22.8383C15.5835 23.9252 16.8745 24.5774 18.3136 24.5774C19.7527 24.5774 21.0648 23.9011 21.996 22.8383C22.9483 21.7513 23.5197 20.2779 23.5197 18.6354C23.5197 16.9929 22.9271 15.4953 21.996 14.4325C21.0648 13.3697 19.7527 12.6934 18.3136 12.6934ZM21.1918 21.9446C20.451 22.7658 19.4352 23.2972 18.3136 23.2972C17.1919 23.2972 16.1761 22.7658 15.4354 21.9446C14.6947 21.0992 14.2502 19.9397 14.2502 18.6595C14.2502 17.3793 14.7158 16.2199 15.4354 15.3745C16.1761 14.5291 17.1919 14.0219 18.3136 14.0219C19.4352 14.0219 20.451 14.5533 21.1918 15.3745C21.9325 16.2199 22.3769 17.3793 22.3769 18.6595C22.3981 19.9397 21.9325 21.0992 21.1918 21.9446Z'
                    fill='white'
                  />
                  <path
                    d='M25.8475 15.0351C26.4203 15.0351 26.8845 14.5052 26.8845 13.8515C26.8845 13.1979 26.4203 12.668 25.8475 12.668C25.2748 12.668 24.8105 13.1979 24.8105 13.8515C24.8105 14.5052 25.2748 15.0351 25.8475 15.0351Z'
                    fill='white'
                  />
                </svg>
              </Button>
            </label>
          </div>
        </div>
        <div className={styles.profileContent__body}>
          <IconButton
            className={styles.editBtn}
            onClick={() => setEditing(prev => !prev)}
          >
            <TiPencil color={'black'} size={25} />
          </IconButton>
          <div className={styles.profileContent__body__info}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnMount
            >
              {formik => {
                const { dirty, values } = formik;

                return (
                  <Form>
                    <div className={styles.profileContent__form}>
                      <Input
                        disabled={!editing}
                        label='Phone Number'
                        name='phoneno'
                        id='phoneno'
                      />
                      <Input
                        disabled={!editing}
                        label='User Name'
                        name='name'
                        id='name'
                      />
                      <Input
                        disabled={!editing}
                        readOnly
                        label='Email Id'
                        name='email'
                        id='email'
                      />
                      {/*                       
                      <Input
                        disabled={!editing}
                        label='Gender'
                        name='gender'
                        id='gender'
                      /> */}
                      <FormControl
                        fullWidth
                        className={styles.profileContent__select}
                      >
                        <label htmlFor='gender'>Gender</label>
                        <Select
                          id='gender'
                          input={
                            <BootstrapInput className={styles.selectInput} />
                          }
                          onChange={formik.handleChange}
                          value={values.gender}
                          name='gender'
                          disabled={!editing}
                        >
                          <MenuItem
                            value={'male'}
                            sx={{
                              fontSize: '1.4rem',
                            }}
                          >
                            Male
                          </MenuItem>
                          <MenuItem
                            value={'female'}
                            sx={{
                              fontSize: '1.4rem',
                            }}
                          >
                            Female
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <div className={styles.profileContent__helpTxt}>
                        <h4>Need help?</h4>
                        <IconButton
                          onClick={() =>
                            window.open(
                              'https://api.whatsapp.com/send?phone=9999999999'
                            )
                          }
                        >
                          <FaWhatsappSquare color={'#25D366'} size={20} />
                        </IconButton>
                      </div>
                    </div>
                    {editing && (
                      <div className={styles.profileContent__submitButton}>
                        <Button disabled={!dirty} type='submit'>
                          Save
                        </Button>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              <Link
                to='/terms-and-conditions'
                style={{
                  color: '#418DFF',
                  fontSize: '1.6rem',
                  textDecoration: 'underline',
                }}
              >
                Terms and conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
