import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  List,
  ListItemButton,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import React from 'react';
import { FiMinus } from 'react-icons/fi';
import { MdAdd, MdOutlineClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { convertToSlug } from '../../../../../../utils/helper';

const sidebarItems = [
  {
    id: 1,
    title: 'Mens',
    href: '/men',

    subCategory: [
      'Diwali',
      'Dushera',
      'Holi',
      'Onam',
      'Sangeet',
      'Shaadi',
      'Reception',
    ],
  },
  {
    id: 2,
    title: 'Women',
    href: '/women',

    subtitles: [
      'Diwali',
      'Dushera',
      'Holi',
      'Onam',
      'Sangeet',
      'Shaadi',
      'Reception',
    ],
  },
  {
    id: 8,
    title: 'Kids',
    href: '/kids',

    subtitles: [
      'Diwali',
      'Dushera',
      'Holi',
      'Onam',
      'Sangeet',
      'Shaadi',
      'Reception',
    ],
  },
  {
    id: 3,
    title: 'Designers',
    href: '/designers',
  },
];

const Sidebar = ({ anchor, isOpen, onClose, navItems }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [expandedSub, setExpandedSub] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeSub = panel => (event, isExpanded) => {
    setExpandedSub(isExpanded ? panel : false);
  };
  // const handleChange = title => {
  //   setExpanded(expanded === title ? false : title);
  // };
  return (
    <SwipeableDrawer anchor={'left'} open={isOpen} onClose={onClose}>
      <div
        style={{
          padding: '12px 16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        }}
      >
        <IconButton
          onClick={onClose}
          style={{
            fontSize: '4rem',
            color: '#000',
            width: 'fit-content',
          }}
        >
          <MdOutlineClose size={20} />
        </IconButton>
      </div>
      <div
        style={{
          width: '100vw',
        }}
      >
        {navItems.map(item => {
          const { id, name, value, data } = item;

          return (
            <Accordion
              key={id}
              expanded={expanded === name}
              onChange={handleChange(name)}
              sx={{
                background: expanded === name ? '#f3f3f3' : '',
                '&::before': {
                  display: 'none',
                },
                '&.Mui-expanded': {
                  margin: '0',
                },
              }}
            >
              <AccordionSummary
                sx={{
                  padding: '10px 20px',
                  borderBottom:
                    expanded === name ? '' : '0.5px solid rgba(0, 0, 0, 0.1)',

                  '&.Mui-expanded': {
                    minHeight: '10px',
                  },
                }}
                expandIcon={
                  data?.length && (
                    <>
                      {expanded === name ? (
                        <IconButton>
                          <FiMinus
                            style={{
                              fontSize: '22px',
                            }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <MdAdd
                            style={{
                              fontSize: '22px',
                            }}
                          />
                        </IconButton>
                      )}
                    </>
                  )
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <h1>{name}</h1>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: 0,
                }}
              >
                <List
                  sx={{
                    padding: 0,
                  }}
                >
                  {data?.map(item => {
                    const { id, title, subCategory } = item;
                    return (
                      <Accordion
                        key={id}
                        expanded={expandedSub === title}
                        onChange={handleChangeSub(title)}
                        sx={{
                          background: expandedSub === title ? '#f3f3f3' : '',
                          '&::before': {
                            display: 'none',
                          },
                          '&.Mui-expandedSub': {
                            margin: '0',
                          },
                        }}
                      >
                        <AccordionSummary
                          sx={{
                            padding: '10px 20px',
                            borderBottom:
                              expandedSub === title
                                ? ''
                                : '0.5px solid rgba(0, 0, 0, 0.1)',

                            '&.Mui-expanded': {
                              minHeight: '10px',
                            },
                          }}
                          expandIcon={
                            subCategory?.length && (
                              <>
                                {expandedSub === title ? (
                                  <IconButton>
                                    <FiMinus
                                      style={{
                                        fontSize: '22px',
                                      }}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton>
                                    <MdAdd
                                      style={{
                                        fontSize: '22px',
                                      }}
                                    />
                                  </IconButton>
                                )}
                              </>
                            )
                          }
                          aria-controls='panel1bh-content'
                          id='panel1bh-header'
                        >
                          <h1
                            style={{
                              fontSize: '1.8rem',
                              fontWeight: '500',
                              marginLeft: '1rem',
                            }}
                          >
                            {title}
                          </h1>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: 0,
                          }}
                        >
                          {subCategory.map(sub => {
                            return (
                              <ListItemButton
                                sx={{
                                  padding: '10px 40px',
                                }}
                                onClick={() => {
                                  onClose();
                                }}
                              >
                                <Link
                                  style={{
                                    fontSize: '1.4rem',
                                  }}
                                  to={`/collection/${value}/${convertToSlug(
                                    sub
                                  )}`}
                                >
                                  {sub}
                                </Link>
                              </ListItemButton>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <ListItemButton
          sx={{
            padding: '13px 20px',
          }}
          onClick={onClose}
        >
          <Link
            style={{
              fontSize: '2rem',
              color: '#000000DE',
              fontWeight: 700,
            }}
            to='/designers'
          >
            Designers
          </Link>
        </ListItemButton>
      </div>
    </SwipeableDrawer>
  );
};

export default Sidebar;
