import React, { Fragment } from 'react';
import styles from './menu.module.scss';
import { Link } from 'react-router-dom';
import { convertToSlug } from '../../../../../../utils/helper';

const MegaMenu = ({
  selectedNavOption,
  setSelectedNavOption,
  selectedNavOptionPosition,
  data,
}) => {
  const finaldata = data.find(i => i.value === selectedNavOption);

  return (
    <div
      style={{
        left: selectedNavOptionPosition.x,
        top: 96,
        background: '#fff',
        position: 'absolute',
        opacity: selectedNavOption ? 1000000 : -10000,
        zIndex: selectedNavOption ? 1000000 : -10000,
        padding: '30px 50px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
        width: 'fit-content',
      }}
      className={styles.megaMenu}
    >
      <div
        className={styles.items}
        style={{
          ...finaldata?.style,
        }}
      >
        {/* {finaldata?.data?.sub_categories
          ?.filter(i => i.active)
          .map(sub => {
            return (
              <li key={sub.id}>
                <Link
                  onClick={() => {
                    setSelectedNavOption(null);
                  }}
                  to={`/collection/${selectedNavOption}/${sub.id}`}
                >
                  {sub.name}
                </Link>
              </li>
            );
          })} */}
        {finaldata?.data?.map(data => {
          const { id, title, subCategory } = data;
          return (
            <div key={id}>
              <h1>{title}</h1>
              <ul>
                {subCategory.map(category => {
                  return (
                    <li
                      style={{
                        marginBottom: '0.4rem',
                      }}
                      onClick={() => {
                        setSelectedNavOption(false);
                      }}
                    >
                      <Link
                        to={`/collection/${finaldata.value}/${convertToSlug(
                          category
                        )}`}
                      >
                        {' '}
                        {category}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MegaMenu;
