import React, { useEffect, useState } from 'react';
import styles from './Styles/AddMeasurmentMan.module.scss';

import AccordionS from './Components/Accordion';
import FloatingTag from './Components/FloatingTag';
import ModalComponent from '../../Components/Modal/ModalComponent';
import ReactPlayer from 'react-player';
import Image from './Images/women/Woman.svg';
import neck_image from './Images/women/upper/neck.svg';
import shoulder_image from './Images/women/upper/shoulder.svg';
import chest_image from './Images/women/upper/chest.svg';
import wrist_image from './Images/women/upper/wrist.svg';
import arm_image from './Images/women/upper/Armhhole.svg';
import sleeve_image from './Images/women/upper/sleveelength.svg';
import waist_image from './Images/women/lower/waist.svg';
import fullLength_image from './Images/women/lower/full length.svg';
import hip_image from './Images/women/lower/hip round.svg';
import inseam_image from './Images/women/lower/inseam.svg';
import calf_image from './Images/women/lower/Calf.svg';
import ankle_image from './Images/women/lower/ankle.svg';
import thigh_image from './Images/women/lower/thigh.svg';
import MeasurementFloating from './Components/MeasurementFloating';
// import Container from '../../utils/Container/container';
import { useSelector, useDispatch } from 'react-redux';
import help_img from './Images/help.svg';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import {
  getMesurementDetails,
  setlocalmeasure,
} from '../../redux/slices/mesurement';
import Videoplayer from '../../Components/Modal/videoplayer';
// import {
//   getAllMeasurements,
//   saveMeasurement,
//   set_lower_body,
//   set_upper_body,
// } from '../../Redux/actions/measurement';

// import {
//   // Measuremant Parameters
//   NeckData,
//   ShoulderData,
//   ChestData,
//   ArmHoleData,
//   SleeveLengthData,
//   WristData,
//   WaistData,
//   FullLengthData,
//   HipRoundData,
//   InSeamData,
//   ThighData,
//   CalfData,
//   AnkleData,

//   // Measurement Functions
//   NeckVALUE,
//   ShoulderVALUE,
//   ChestVALUE,
//   ArmHoleVALUE,
//   SleeveLengthVALUE,
//   WristVALUE,
//   WaistVALUE,
//   FullLengthVALUE,
//   HipRoundVALUE,
//   InSeamVALUE,
//   ThighVALUE,
//   CalfVALUE,
//   AnkleVALUE,
// } from '../../Redux/MeasuremantData';
import {
  useMediaQuery,
  Button,
  IconButton,
  Backdrop,
  Snackbar,
  Fade,
  Slide,
} from '@mui/material';
import { submitmesurement } from '../../redux/slices/lynks';

function AddWomenMeasurement() {
  const measaure = useSelector(state => state.mesurements);
  const history = useLocation();
  const mobileView = useMediaQuery('(max-width:500px)');
  const tabView = useMediaQuery('(max-width:769px)');
  const dispatch = useDispatch();
  const { id, size, fitting } = useParams();

  const location = useLocation();
  const mesurementdetail = useSelector(state => state.mesurements);
  useEffect(() => {
    if (location.pathname[1] === 'v') {
      dispatch(getMesurementDetails(id));
    }
  }, []);

  const vendorInfo = location.state.profileInfo;

  const [snackState, setSnackState] = React.useState({
    open: false,
    Transition: Fade,
    message: '',
    count: 0,
  });

  useEffect(() => {
    if (location.pathname[1] === 'v') {
      setAllDone('View');
      setmesurement(mesurementdetail?.mesurements?.mesurement);
    }
    if (measaure?.localmesure?.mesurement) {
      setmesurement(measaure?.localmesure?.mesurement);
    }
  }, [mesurementdetail]);

  const upper_body = {
    neck: '',
    shoulder: '',
    chest: '',
    arm_hole: '',
    sleeve: '',
    wrist: '',
  };
  const lower_body = {
    waist: '',
    hip_round: '',
    full_length: '',
    inseam: '',
    thigh: '',
    calf: '',
    ankle: '',
  };
  function SlideTransition(props) {
    return <Slide {...props} direction='up' />;
  }

  console.log('body');
  const { neck, chest, wrist, shoulder, arm_hole, sleeve } = upper_body;
  const { waist, hip_round, full_length, inseam, thigh, calf, ankle } =
    lower_body;

  const [mesurement, setmesurement] = useState({
    upperbody: {
      Neck: {
        Neck_Around: '',
        Neck_Back: '',
        Neck_Front: '',
      },
      Shoulder: '',
      Chest: '',
      Arm_Hole: '',
      Sleeve_length: '',
      Wrist: '',
    },
    lowerbody: {
      Waist: {
        High_Waist: '',
        Mid_Waist: '',
        Low_Waist: '',
      },
      Full_length: '',
      Hip_Round: '',

      InSeam: '',
      Thigh: '',
      Calf: '',
      Ankle: '',
    },
  });

  const Form = (values, name, bodytype) => {
    if (error === Open) {
      if (!mesurement[button === 'upper' ? 'upperbody' : 'lowerbody'][Open]) {
        setError(Open);
      } else {
        setError('');
      }
      // if(measaure[button][Open])
    } else {
      setError('');
    }
    if (location.pathname[1] !== 'v') {
      let m;
      let value;

      value = +Math.max(0, parseFloat(values)).toString().slice(0, 8);
      if (isNaN(value)) value = '';

      if (
        name === 'Shoulder' ||
        name === 'Chest' ||
        name === 'Arm_Hole' ||
        name === 'Sleeve_length' ||
        name === 'Wrist'
      )
        m = {
          ...mesurement,
          upperbody: { ...mesurement.upperbody, [name]: value },
        };
      else if (
        name === 'Neck_Around' ||
        name === 'Neck_Front' ||
        name === 'Neck_Back'
      ) {
        m = {
          ...mesurement,
          upperbody: {
            ...mesurement.upperbody,
            Neck: { ...mesurement.upperbody.Neck, [name]: value },
          },
        };
      } else if (
        name === 'High_Waist' ||
        name === 'Mid_Waist' ||
        name === 'Low_Waist'
      ) {
        m = {
          ...mesurement,
          lowerbody: {
            ...mesurement.lowerbody,
            Waist: { ...mesurement.lowerbody.Waist, [name]: value },
          },
        };
      } else {
        m = {
          ...mesurement,
          lowerbody: { ...mesurement.lowerbody, [name]: value },
        };
      }
      setmesurement({ ...m });
      console.log(m);
    }
  };

  useEffect(() => {
    let temp = 0;
    const uppertempi = { ...mesurement?.upperbody };
    const lowertempi = { ...mesurement?.lowerbody };
    delete uppertempi?.Neck;
    delete uppertempi?.Waist;
    const waist = { ...mesurement?.lowerbody?.Waist };
    const neck = { ...mesurement?.upperbody?.Neck };
    Object.values(neck).map(item => {
      if (item === '') temp = 1;
      return null;
    });

    if (uppertempi) {
      Object.values(uppertempi).map(item => {
        if (item === '') temp = 1;
        return null;
      });
    }
    if (lowertempi) {
      Object.values(lowertempi).map(item => {
        if (item === '') temp = 1;
        return null;
      });
    }

    Object.values(waist).map(item => {
      if (item === '') temp = 1;
    });

    if (temp === 0 && uppertempi && lowertempi) {
      if (location.pathname[1] !== 'v') setAllDone('Save');
      else setAllDone('Back');
    }

    if (temp === 1) {
      setAllDone('Next');
    }
  }, [mesurement]);

  // const [AllValues, SetAllValues] = useState(LowerValues && UpperValues);
  const [AllValues, SetAllValues] = useState('');

  // useEffect(() => {
  //   SetAllValues(LowerValues && UpperValues);
  // }, [LowerValues, UpperValues]);

  const [AllDone, setAllDone] = useState('Start');

  const [button, setButton] = useState('upper');

  const navigate = useNavigate();
  const [Open, SetOpen] = useState('upper');

  const FocusIt = value => {
    if (Open === value) {
      if (
        value === 'Neck' ||
        value === 'Arm_Hole' ||
        value === 'Shoulder' ||
        value === 'Chest' ||
        value === 'Wrist' ||
        value === 'Sleeve_length'
      ) {
        SetOpen('upper');
      } else {
        SetOpen('lower');
      }
    } else {
      SetOpen(value);
    }
  };
  const SetIt = () => {
    if (AllDone !== 'Save') {
      setError(Open);
    }
    if (AllDone === 'Done') {
      setButton('lower');
      SetOpen('lower');
    }

    if (AllDone === 'Start' || AllDone === 'View') {
      SetOpen('Neck');
      setButton('upper');
      setAllDone('Next');
    }
    if (AllDone === 'Next') {
      let temp = '',
        ty = '';
      const uppertempi = { ...mesurement.upperbody };
      const lowertempi = { ...mesurement.lowerbody };
      delete uppertempi.Neck;
      delete uppertempi.Waist;
      const waist = { ...mesurement.lowerbody.Waist };
      const neck = { ...mesurement.upperbody.Neck };
      Object.values(neck).map(item => {
        if (item === '' && temp === '') {
          temp = 'Neck';
          ty = 'upper';
        }
        return null;
      });
      Object.values(uppertempi).map((item, index) => {
        if (item === '' && temp === '') {
          temp = Object.keys(uppertempi)[index];
          ty = 'upper';
        }
        return null;
      });

      if (button === 'upper' && temp === '') {
        setSnackState({
          open: true,
          SlideTransition,
          message: 'Added Upper Body measurement',
        });
      }
      setTimeout(() => {
        setSnackState({
          open: false,
          SlideTransition,
          message: 'Added Upper Body measurement',
        });
      }, 1000);
      Object.values(waist).map(item => {
        if (item === '' && temp === '') {
          temp = 'Waist';
          ty = 'lower';
        }
        return null;
      });
      Object.values(lowertempi).map((item, index) => {
        if (item === '' && temp === '') {
          temp = Object.keys(lowertempi)[index];
          ty = 'lower';
        }
        return null;
      });

      if (temp !== '') {
        SetOpen(temp);
        setButton(ty);
      }
    }
    if (AllDone === 'Save') {
      dispatch(
        setlocalmeasure({
          id,
          fitting,
          size,
          gender: location.pathname.split('/')[2],
          mesurement,
        })
      );

      setSnackState({
        open: true,
        SlideTransition,
        message: 'Measurement added successfully',
      });

      setTimeout(() => {
        setSnackState({
          open: false,
          SlideTransition,
          message: 'Measurement added successfully!',
        });
        navigate(`/upload-image`);
      }, 1000);
    }
    if (AllDone === 'Back') {
      SetOpen('upper');
    }
  };

  const UploadMeasurement = () => {
    setAllDone('Done');
    // const upperBodyData = {
    //   type: 'upper',
    //   measurements_basic_id: basic_id,
    //   neck: parseFloat(NeckData),
    //   shoulder: parseFloat(ShoulderData),
    //   chest: parseFloat(ChestData),
    //   arm_hole: parseFloat(ArmHoleData),
    //   wrist: parseFloat(WristData),
    //   sleeve: parseFloat(SleeveLengthData),
    // };

    // const lowerBodyData = {
    //   type: 'Lower',
    //   measurements_basic_id: basic_id,
    //   full_length: parseFloat(FullLengthData),
    //   hip_round: parseFloat(HipRoundData),
    //   inseam: parseFloat(InSeamData),
    //   thigh: parseFloat(ThighData),
    //   waist: parseFloat(WaistData),
    //   calf: parseFloat(CalfData),
    //   ankle: parseFloat(AnkleData),
    // };
  };
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(false);
  };

  const enterpressed = () => {
    if (AllDone !== 'Save') {
      setError(Open);
    }

    if (AllDone === 'Save') {
      dispatch(
        setlocalmeasure({
          id,
          fitting,
          size,
          gender: location.pathname.split('/')[2],
          mesurement,
        })
      );

      setSnackState({
        open: true,
        SlideTransition,
        message: 'Measurement added successfully',
      });

      setTimeout(() => {
        setSnackState({
          open: false,
          SlideTransition,
          message: 'Measurement added successfully!',
        });
        navigate(`/upload-image`);
      }, 1000);
      return;
    }

    // if (error === Open) {
    //   if (!mesurement[button === 'upper' ? 'upperbody' : 'lowerbody'][Open]) {
    //     setError(Open);
    //   } else {
    //     setError('');
    //   }
    //   // if(measaure[button][Open])
    // } else {
    //   setError('');
    // }
    let temp = '',
      ty = '';
    const uppertempi = { ...mesurement.upperbody };
    const lowertempi = { ...mesurement.lowerbody };
    delete uppertempi.Neck;
    delete uppertempi.Waist;
    const waist = { ...mesurement.lowerbody.Waist };
    const neck = { ...mesurement.upperbody.Neck };
    Object.values(neck).map(item => {
      if (item === '' && temp === '') {
        temp = 'Neck';
        ty = 'upper';
      }
      return null;
    });
    Object.values(uppertempi).map((item, index) => {
      if (item === '' && temp === '') {
        temp = Object.keys(uppertempi)[index];
        ty = 'upper';
      }
      return null;
    });

    if (button === 'upper' && temp === '') {
      setSnackState({
        open: true,
        SlideTransition,
        message: 'Added Upper Body measurement',
      });
    }

    Object.values(waist).map(item => {
      if (item === '' && temp === '') {
        temp = 'Waist';
        ty = 'lower';
      }
      return null;
    });
    Object.values(lowertempi).map((item, index) => {
      if (item === '' && temp === '') {
        temp = Object.keys(lowertempi)[index];
        ty = 'lower';
      }
      return null;
    });

    if (temp !== '') {
      SetOpen(temp);
      setButton(ty);
    }
  };

  const handleClose = () => {
    setSnackState({
      ...snackState,
      open: false,
    });
  };

  const [error, setError] = useState('');

  useEffect(() => {
    if (error === Open) {
      if (!mesurement[button === 'upper' ? 'upperbody' : 'lowerbody'][Open]) {
        setError(Open);
      } else {
        setError('');
      }
      // if(measaure[button][Open])
    } else {
      setError('');
    }
  }, [Open, measaure]);
  const urls = {
    Neck: 'https://youtu.be/4h4qsgvAvJ8',
    Shoulder: 'https://youtu.be/4x0nH4THrVY',
    Chest: 'https://youtu.be/1EKPFoxX0rs',
    Arm_Hole: 'https://youtu.be/5JTRABp8VPc',
    Sleeve_length: 'https://youtu.be/rErN0QL7TI0',
    Wrist: 'https://youtu.be/Yfos7bthoVs',
    Waist: 'https://youtu.be/y8wcSF7XRiY',
    Full_length: 'https://youtu.be/iLnQskNpRYE',
    Hip_Round: 'https://youtu.be/8xc6wD0q9s0',
    InSeam: 'https://youtu.be/6dM8qDuvvu0',
    Thigh: 'https://youtu.be/hLXNMOVKjFw',
    Calf: 'https://youtu.be/iIbnVsaNxN4',
    Ankle: 'https://youtu.be/91i0Z6uBp7E',
  };
  const [youtubeUrl, setYouTubeUrl] = useState();

  useEffect(() => {
    if (Open === 'upper' || Open === 'lower') {
      setYouTubeUrl('');
    } else {
      setYouTubeUrl(
        urls[Open] ||
          'https://youtube.com/playlist?list=PLAYGpjI2SxDKBX7tY0qAkP4qkoiofunq4'
      );
    }
  }, [Open]);

  return (
    // <Container>
    <div
      className={styles.container}
      style={
        tabView && !mobileView ? { marginTop: '1em' } : { marginTop: '0em' }
      }
    >
      {vendorInfo?.whatsappNumber && mobileView && (
        <IconButton
          style={{
            width: '50px',
            height: '50px',
            position: 'fixed',
            top: '15px',
            right: '10px',
          }}
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=91${
                vendorInfo?.whatsappNumber || ''
              }`
            )
          }
        >
          <img
            src='https://img.icons8.com/color/144/000000/whatsapp--v1.png'
            alt={'whats'}
          />
        </IconButton>
      )}
      {youtubeUrl && (
        <div className={styles.videoGuide} onClick={() => setOpenModal(true)}>
          <IconButton>
            <svg
              width='51'
              height='50'
              viewBox='0 0 51 50'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g filter='url(#filter0_b_3205_6334)'>
                <path
                  d='M50.5596 25C50.5596 38.8071 39.3667 50 25.5596 50C11.7525 50 0.55957 38.8071 0.55957 25C0.55957 11.1929 11.7525 0 25.5596 0C26.8414 0 28.1006 0.0964651 29.3305 0.282532C29.8893 0.367078 30.4421 0.470124 30.9881 0.591026C42.1851 3.07016 50.5596 13.0571 50.5596 25Z'
                  fill='white'
                  fillOpacity='0.2'
                />
                <path
                  d='M50.3096 25C50.3096 38.669 39.2286 49.75 25.5596 49.75C11.8905 49.75 0.80957 38.669 0.80957 25C0.80957 11.331 11.8905 0.25 25.5596 0.25C26.8288 0.25 28.0756 0.345519 29.2931 0.529719C29.8463 0.613418 30.3935 0.715429 30.9341 0.835114C42.0191 3.28944 50.3096 13.1768 50.3096 25Z'
                  stroke='#9D8E73'
                  strokeWidth='0.5'
                />
              </g>
              <path
                d='M21.1665 17.5L32.8332 25L21.1665 32.5V17.5Z'
                fill='#6A5B40'
                stroke='#6A5B40'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <defs>
                <filter
                  id='filter0_b_3205_6334'
                  x='-53.4404'
                  y='-54'
                  width='158'
                  height='158'
                  filterUnits='userSpaceOnUse'
                  colorInterpolationFilters='sRGB'
                >
                  <feFlood floodOpacity='0' result='BackgroundImageFix' />
                  <feGaussianBlur in='BackgroundImage' stdDeviation='27' />
                  <feComposite
                    in2='SourceAlpha'
                    operator='in'
                    result='effect1_backgroundBlur_3205_6334'
                  />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_backgroundBlur_3205_6334'
                    result='shape'
                  />
                </filter>
              </defs>
            </svg>
          </IconButton>
          <p>Video Guide</p>
        </div>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={snackState.open}
        // open={true}
      >
        <Snackbar
          open={snackState.open}
          // open={true}
          onClose={handleClose}
          TransitionComponent={snackState.Transition}
          autoHideDuration={2000}
          message={
            <>
              <div className={styles.snackbarContent}>
                <svg
                  width='25'
                  height='25'
                  viewBox='0 0 25 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    x='0.429688'
                    y='0.294922'
                    width='24'
                    height='24'
                    rx='12'
                    fill='#27AE60'
                  />
                  <path
                    d='M5.83008 12.2949L10.0301 16.4949L18.7301 7.79492'
                    stroke='white'
                    strokeWidth='3'
                  />
                </svg>
                {snackState.message}
              </div>
            </>
          }
          // key={state.Transition.name}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{
            '& > div': {
              backgroundColor: '#010101',
              padding: '1rem 2rem',
              maxWidth: '341.86px',
              width: '100%',
            },
            maxWidth: '341.86px',
            width: '100%',
            margin: '0 auto',
          }}
        />
      </Backdrop>

      {openModal && (
        <Videoplayer open={openModal} handleClose={toggleModal}>
          <ReactPlayer
            url={youtubeUrl}
            playing={openModal}
            style={{ maxWidth: '90%' }}
            controls
            autoPlay={true}
            config={{
              youtube: {
                playerVars: { showinfo: 1, autoplay: 0 },
              },
              facebook: {
                appId: '12345',
              },
            }}
          />
        </Videoplayer>
      )}
      <div className={styles.TabsButtonDivTop}>
        <Button
          onClick={() => {
            setButton('upper');
            SetOpen('upper');
          }}
          className={
            button === 'upper' ? styles.TabsButtonActive1 : styles.TabsButton1
          }
        >
          Upper Body
        </Button>
        <Button
          onClick={() => {
            setButton('lower');
            SetOpen('lower');
          }}
          className={
            button === 'lower' ? styles.TabsButtonActive2 : styles.TabsButton2
          }
        >
          Lower Body
        </Button>
      </div>
      <div className={`${styles.SVGDiv} ${Open}-SVGDiv`}>
        {/* <img src={Image} className={styles.upperImage} /> */}

        <img
          src={
            Open === 'upper'
              ? Image
              : Open === 'lower'
              ? Image
              : Open === 'Neck'
              ? neck_image
              : Open === 'Shoulder'
              ? shoulder_image
              : Open === 'Chest'
              ? chest_image
              : Open === 'Arm_Hole'
              ? arm_image
              : Open === 'Waist'
              ? waist_image
              : Open === 'Wrist'
              ? wrist_image
              : Open === 'Hip_Round'
              ? hip_image
              : Open === 'Thigh'
              ? thigh_image
              : Open === 'Ankle'
              ? ankle_image
              : Open === 'Sleeve_length'
              ? sleeve_image
              : Open === 'Calf'
              ? calf_image
              : Open === 'InSeam'
              ? inseam_image
              : Open === 'Full_length'
              ? fullLength_image
              : Image
          }
          alt='body'
          className={
            Open === 'upper'
              ? styles.upperImage
              : Open === 'lower'
              ? styles.lowerImage
              : Open === 'Neck'
              ? styles.Neck
              : Open === 'Shoulder'
              ? styles.Shoulder
              : Open === 'Chest'
              ? styles.Chest
              : Open === 'Arm_Hole'
              ? styles.Arm
              : Open === 'Waist'
              ? styles.Waist
              : Open === 'Wrist'
              ? styles.Wrist
              : Open === 'Hip_Round'
              ? styles.Hip
              : Open === 'Thigh'
              ? styles.Thigh
              : Open === 'Ankle'
              ? styles.Ankle
              : Open === 'Sleeve_length'
              ? styles.Sleeve
              : Open === 'Calf'
              ? styles.Calf
              : Open === 'InSeam'
              ? styles.Inseam
              : Open === 'Full_length'
              ? styles.FullLength
              : styles.upperImage
          }
        />
        {Open === 'upper' || Open === 'lower' ? (
          <FloatingTag
            position={button}
            FocusIt={FocusIt}
            NECK={mesurement.upperbody.Neck}
            CHEST={mesurement.upperbody.Chest}
            SHOULDER={mesurement.upperbody.Shoulder}
            ARMHOLE={mesurement.upperbody.Arm_Hole}
            WAIST={mesurement.lowerbody.Waist}
            WRIST={mesurement.upperbody.Wrist}
            HIPROUND={mesurement.lowerbody.Hip_Round}
            THIGH={mesurement.lowerbody.Thigh}
            ANKLE={mesurement.lowerbody.Ankle}
            SLEEVE={mesurement.upperbody.Sleeve_length}
            CALF={mesurement.lowerbody.Calf}
            INSEAM={mesurement.lowerbody.InSeam}
            FULLLENGTH={mesurement.lowerbody.Full_length}
            body='women'
          />
        ) : (
          <></>
        )}
      </div>
      <div className={`${styles.FloatingMeasure} textField-${Open}-women`}>
        <MeasurementFloating
          name={Open}
          focused={true}
          Form={Form}
          video={setOpenModal}
          enterpressed={enterpressed}
          body='women'
          value={
            Open === 'Neck'
              ? mesurement?.upperbody?.Neck
              : Open === 'Shoulder'
              ? mesurement?.upperbody?.Shoulder
              : Open === 'Arm_Hole'
              ? mesurement?.upperbody?.Arm_Hole
              : Open === 'Sleeve_length'
              ? mesurement?.upperbody?.Sleeve_length
              : Open === 'Waist'
              ? mesurement?.lowerbody?.Waist
              : Open === 'Full_length'
              ? mesurement?.lowerbody?.Full_length
              : Open === 'Chest'
              ? mesurement?.upperbody?.Chest
              : Open === 'Ankle'
              ? mesurement?.lowerbody?.Ankle
              : Open === 'InSeam'
              ? mesurement?.lowerbody?.InSeam
              : Open === 'Thigh'
              ? mesurement?.lowerbody?.Thigh
              : Open === 'Calf'
              ? mesurement?.lowerbody?.Calf
              : Open === 'Wrist'
              ? mesurement?.upperbody?.Wrist
              : Open === 'Hip_Round'
              ? mesurement?.lowerbody?.Hip_Round
              : 0
          }
          AllDone={AllDone}
          AllValues={AllValues}
          UploadMeasurement={UploadMeasurement}
          SetIt={SetIt}
          setYouTubeUrl={setYouTubeUrl}
        />
      </div>
      {/* <Button className={styles.submitBtn} >{AllDone === 'Start' ? 'Save' : AllDone === false ? 'Next' : "Save"}</Button> */}
      {/* <Button className={styles.submitBtn} onClick={SetIt} >{AllDone}</Button> */}
      {/* <div
        style={{
          marginTop: (Open === 'Neck' || Open === 'Waist') && '350px',
        }}
      >
        {AllDone === true ? (
          <Link
            // to={`/add-measurement-image/${basic_id}`}
            //  to={`/viewmeasurement/${basic_id}`}
            style={{ color: '#fff' }}
          >
            <Button
              className={styles.submitBtn}
              onClick={
                AllDone === true && AllValues ? UploadMeasurement : SetIt
              }
            >
              {AllDone === true ? 'Save' : 'Please Fill all the fields'}
              Save 
            </Button>
          </Link>
        ) : (
          <Button
            className={styles.submitBtn}
            onClick={AllDone === true ? UploadMeasurement : SetIt}
          >
            {AllDone}
          </Button>
        )}
      </div> */}

      <div className={styles.AccordionDiv}>
        <div className={styles.TabsButtonDiv}>
          <Button
            onClick={() => {
              setButton('upper');
              SetOpen('upper');
            }}
            className={
              button === 'upper' ? styles.TabsButtonActive1 : styles.TabsButton1
            }
          >
            Upper Body
          </Button>
          <Button
            onClick={() => {
              setButton('lower');
              SetOpen('lower');
            }}
            className={
              button === 'lower' ? styles.TabsButtonActive2 : styles.TabsButton2
            }
          >
            Lower Body
          </Button>
        </div>
        <h1 className={styles.AddMeadurementsH1}>Add measurements</h1>
        <AccordionS
          error={error}
          position={button}
          FocusIt={FocusIt}
          Open={Open}
          Form={Form}
          AllDone={AllDone}
          SetIt={SetIt}
          setAllDone={setAllDone}
          enterpressed={enterpressed}
          mes={mesurement}
          video={setOpenModal}
          setYouTubeUrl={setYouTubeUrl}
          // UploadMeasurement={UploadMeasurement}
          // basicId={basic_id}
        />
        {vendorInfo?.whatsappNumber && (
          <div className={styles.help}>
            <h1>Help</h1>
            <IconButton
              className={styles.help_btn}
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=91${
                    vendorInfo?.whatsappNumber || ''
                  }`
                )
              }
            >
              <img
                src='https://img.icons8.com/color/144/000000/whatsapp--v1.png'
                alt={'whats'}
              />
            </IconButton>
          </div>
        )}
      </div>
    </div>
    // </Container>
  );
}

export default AddWomenMeasurement;
