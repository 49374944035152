import { useSelector } from 'react-redux';
import ModalComponent from './ModalComponent';
import styles from './otpverifer.scss';
const OtpModal = () => {
  const { otpveryingstate } = useSelector(state => state.user);

  return (
    <ModalComponent
      open={
        otpveryingstate?.state === 'true' ||
        otpveryingstate?.state === 'successfull'
      }
      width='296px'
      height='152px'
      padding='2rem'
    >
      <div classNameName={styles.verfiyModalContainer}>
        {otpveryingstate?.state === 'true' && (
          <div className='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}

        {otpveryingstate?.state === 'successfull' && (
          <svg
            style={{
              marginTop: '10px',
            }}
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='40' height='40' rx='20' fill='#3AC267' />
            <path d='M9 20L16 27L30.5 12.5' stroke='white' strokeWidth='5' />
          </svg>
        )}
        <h5
          style={{
            paddingTop:
              otpveryingstate?.state === 'successfull' ? '10px' : '40px',
            margin: 0,
            fontSize: '16px',
            fontWeight: '500',
            fontFamily: 'DM Sans',
          }}
        >
          {otpveryingstate?.state === 'successfull'
            ? 'Verified OTP'
            : 'OTP Verifying '}
        </h5>
      </div>
    </ModalComponent>
  );
};
export default OtpModal;
