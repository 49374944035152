import React, { useEffect, useState } from "react";
import styles from "./requestOrder.module.scss";
import BreadCrumbs from "../../Components/Common/BreadCrumbs/BreadCrumbs";
import SectionNavigation from "../../Components/Common/SectionNavigation/SectionNavigation";
import DetailsPageHeader from "../../Components/DetailsPageHeader/DetailsPageHeader";
import { ReactComponent as Tick } from "./images/tick.svg";
import Advantages from "./components/Advantages/Advantages";
import Works from "./components/Works/Works";
import { Button, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";
import {
  createOrderRequest,
  getRequestOrderVendor,
} from "../../redux/slices/requestOrder";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Compressor from "compressorjs";
import Formelement from "./components/FormBox/form";
import Input from "../../Components/Common/Form/normalinput";
import firebase from "../../firebase/firebase";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router";
import ModalComponent from "../../Components/Modal/ModalComponent";
import { startmodal } from "../../redux/slices/ui";
const RequestOrder = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const { id } = useParams();
  const dispatch = useDispatch();
  const requestOrder = useSelector((state) => state.requestOrder);
  const [userdetail, setuserdetail] = useState({
    name: "",
    phone: "",
    pro: "",
    img: "",
  });
  const [product, setproduct] = useState([
    { images: [], lynk: "", localimage: [] },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRequestOrderVendor(id));
  }, []);

  // const [uploadedfiles,setuploadedfiles]=useState([])
  // const [localimage,setlocalimage]=useState([])
  // const [compressedFile, setCompressedFile] = useState();

  const OnfileUpload = async (e, index) => {
    const papai = [...e.target.files];
    const pro = [...product];
    pro[index].images = [...pro[index].images, ...papai];

    const heap = pro[index].images.map((filei) => viewimage(filei));
    await Promise.all(heap).then((result) => {
      pro[index].localimage = result;
      setproduct([...pro]);
    });
  };

  // const handleCompressedUpload = (file,index) => {

  //     new Compressor(file, {
  //       quality: 0.8,
  //       success: (compressedResult) => {

  //       },
  //     });

  //   };

  const viewimage = async (file) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    return await toBase64(file);
  };

  const imageRemove = (eleindex, imgindex) => {
    const finalimnage = [...product];

    finalimnage[eleindex].images.splice(imgindex, 1);
    finalimnage[eleindex].localimage.splice(imgindex, 1);

    setproduct([...finalimnage]);
  };

  const uploadSingleImage = async (file) => {
    const task = firebase
      .storage()
      .ref(`/requestedorders/${file.name}`)
      .put(file);

    await task
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const Addproduct = () => {
    setproduct([...product, { images: [], lynk: "" }]);
  };
  const delproduct = (index) => {
    const data = [...product];
    data.splice(index, 1);
    setproduct([...data]);
  };

  const onLinkChange = (index, link) => {
    const pro = [...product];
    pro[index].lynk = link;
    setproduct([...pro]);
  };

  const [iserror, setiserror] = useState({ phone: "", name: "", snap: "" });

  const submitrequest = () => {
    let sanp = false;
    let phone;
    let pro;
    let name;
    if (userdetail.phone.length !== 10 || userdetail.phone === "") {
      phone = "enter valid phone number";
      sanp = true;
    }
    if (userdetail.name === "") {
      name = "enter valid name";
      sanp = true;
    }

    product.map((proo, index) => {
      if (proo.lynk === "") {
        sanp = true;
        pro = { error: "Please Enter the link ", index: index };
      }
    });

    let img;
    product.map((proii, index) => {
      if (proii.images.length === 0) {
        sanp = true;
        img = { error: "Please Upload the image", index: index };
      }
    });

    setiserror({ name, pro, phone, img });

    if (!sanp) {
      const newrewuest = [];
      const prrr = product.map((item) => {
        let temp = { lynk: "", images: [] };
        temp["lynk"] = item.lynk;
        temp["images"] = item.images.map((file) => {
          return `requestedorders%2F${file.name}`;
        });
        newrewuest.push(temp);
        return item.images.map((file) => {
          return uploadSingleImage(file);
        });
      });
      Promise.all(prrr).then((result) => {});

      dispatch(
        createOrderRequest(
          newrewuest,
          id,
          userdetail,
          requestOrder?.requestOrderVendor?.businessName
        )
      );
    }
  };

  useEffect(() => {
    console.log(iserror);
  }, [iserror]);

  useEffect(() => {
    if (iserror.pro || iserror.img) {
      setiserror({ ...iserror, pro: null, img: null });
    }
  }, [product]);

  return (
    <div>
      <BreadCrumbs
        currentPath="Request Orders"
        paths={[
          { name: "Home", to: "/" },
          { name: "Lynks", to: "/lynks" },
        ]}
      />
      <SectionNavigation back={-1} />
      <div className={styles.requestOrderContent}>
        <DetailsPageHeader
          name={requestOrder?.requestOrderVendor?.businessName}
          image={requestOrder?.requestOrderVendor?.profilePic}
          verified={requestOrder?.requestOrderVendor?.isVerified}
          location={`${requestOrder?.requestOrderVendor?.shippingDetails?.city} 
        , ${requestOrder?.requestOrderVendor?.shippingDetails?.state}`}
          coverpic={requestOrder?.requestOrderVendor?.coverPic}
          insta_username={
            requestOrder?.requestOrderVendor?.profileInfo?.insta_username
          }
          requestPage
        />
        {currentStep === 1 ? (
          <>
            <div className={styles.requestOrderInfo}>
              <Works />
              <Advantages styles={styles} />
              <div className={styles.requestOrderBtn}>
                <Button onClick={() => setCurrentStep(2)}>Request order</Button>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.requestOrderForm}>
            <div className={styles.requestOrderFormContainer}>
              <div className={styles.requestOrderFormGroup}>
                <Input
                  label="Enter full name"
                  name="name"
                  id="name"
                  error={iserror?.name}
                  onChange={(e) => {
                    if (iserror?.name) setiserror({ ...iserror, name: null });
                    setuserdetail({ ...userdetail, name: e.target.value });
                  }}
                />
              </div>
              <div className={styles.requestOrderFormGroup}>
                <Input
                  label="Phone number"
                  name="phoneNumber"
                  id="phoneNumber"
                  type="number"
                  error={iserror?.phone}
                  onChange={(e) => {
                    if (iserror?.phone) setiserror({ ...iserror, phone: null });
                    setuserdetail({ ...userdetail, phone: e.target.value });
                  }}
                />
              </div>
            </div>

            {product.map((item, index) => {
              return (
                <Formelement
                  key={index}
                  ind={index}
                  onLinkChange={onLinkChange}
                  fileupload={(e) => {
                    OnfileUpload(e, index);
                  }}
                  product={product}
                  index={index}
                  delete={delproduct}
                  imageRemove={imageRemove}
                  proerror={iserror.pro}
                  imgerror={iserror.img}
                />
              );
            })}

            <div className={styles.requestOrderAddBtn}>
              <div role="button" onClick={Addproduct}>
                <MdAddCircle />
                <h4>Add Product</h4>
              </div>
            </div>
            <div className={styles.requestOrderBtn}>
              <Button onClick={submitrequest}>Request order</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestOrder;
