import { createSlice } from '@reduxjs/toolkit';
import firebase from '../../firebase/firebase';
import { message } from '../../firebase/firebase';
import { notify, showSnackBar, startLoading } from './ui';

const initialState = {
  user: null,
  otpverify: null,
  profiledetials: null,
  otpveryingstate: { state: 'false' },
  resetphone: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userdetails(state, action) {
      state.user = action.payload;
    },
    otptoken(state, action) {
      state.otpverify = action.payload;
    },
    profiledetials(state, action) {
      state.profiledetials = action.payload;
    },
    otpveryingstate(state, action) {
      state.otpveryingstate = action.payload;
    },
    resetphone(state, action) {
      state.resetphone = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const setuser = user => {
  return dispatch => {
    dispatch(slice.actions.userdetails(user));
  };
};

export const Signinwithphone = phoneno => {
  return dispatch => {
    firebase.auth().useDeviceLanguage();

    try {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
          callback: response => {
            console.log('response============>', response);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
    dispatch(startLoading(true));

    firebase
      .auth()
      .signInWithPhoneNumber(
        `+91${phoneno}`,
        new firebase.auth.RecaptchaVerifier('sign-in-button', {
          size: 'invisible',
          callback: response => {
            console.log(response);
          },
        })
      )
      .then(confirmationResult => {
        dispatch(slice.actions.otptoken(confirmationResult));
        dispatch(startLoading(false));
      })
      .catch(error => {
        dispatch(startLoading(false));
        dispatch(
          notify({ type: 'error', message: error.message, status: true })
        );
        console.log(error);
      });
  };
};
export const ChangePhoneNumber = phonenum => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));
    const state = getstate();
    const userphonenumber = state?.user?.user?.phoneNumber;
    firebase.auth().useDeviceLanguage();
    if (`+91${phonenum}` === userphonenumber) {
      dispatch(
        notify({
          type: 'error',
          message: 'New Phone number cannot be same as previous number',
          status: true,
        })
      );
      dispatch(startLoading(false));
      return false;
    }
    var applicationVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: response => {
          console.log(response);
        },
      }
    );
    var provider = new firebase.auth.PhoneAuthProvider();
    return provider
      .verifyPhoneNumber(`+91${phonenum}`, applicationVerifier)
      .then(verificationId => {
        dispatch(startLoading(false));
        dispatch(slice.actions.resetphone(verificationId));
        return true;
      })
      .catch(error => {
        dispatch(startLoading(false));
        dispatch(
          notify({ type: 'error', message: error.message, status: true })
        );
        return false;
      });
  };
};

export const verifynewphone = code => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(startLoading(true));

    const cred = firebase.auth.PhoneAuthProvider.credential(
      state?.user?.resetphone,
      code
    );
    return firebase
      .auth()
      .currentUser.updatePhoneNumber(cred)
      .then(pc => {
        dispatch(startLoading(false));

        return pc;
      })
      .catch(error => {
        dispatch(startLoading(false));
        dispatch(
          notify({ type: 'error', message: error.message, status: true })
        );
        return null;
      });
  };
};

export const verifyotp = otptoken => {
  return (dispatch, getState) => {
    // dispatch(startLoading(true));
    dispatch(slice.actions.otpveryingstate({ state: 'true' }));
    const state = getState();
    state?.user?.otpverify
      .confirm(otptoken)
      .then(result => {
        if (result.user) {
          dispatch(slice.actions.otpveryingstate({ state: 'successfull' }));
          setTimeout(() => {
            dispatch(slice.actions.otpveryingstate({ state: 'false' }));
          }, 1500);
          dispatch(slice.actions.otptoken(null));
          firebase
            .firestore()
            .collection('users')
            .doc(result.user.uid)
            .get()
            .then(async res => {
              let token = '';
              await message
                .getToken({
                  vapidKey:
                    'BM6HQ4jKYMqYUgpbdUiAlsn1CRDlJwarBhKja4SaGifzd5YyzYeCPCmz3PEmR_2C_CAqDCK380T0VTVODl7WY_k',
                })
                .then(res => {
                  token = res;
                })
                .catch(error => {
                  dispatch(slice.actions.otpveryingstate({ state: 'false' }));
                  dispatch(startLoading(false));
                  console.log(error);
                  dispatch(
                    notify({
                      type: 'info',
                      message: 'your Push notfication is off',
                      status: true,
                    })
                  );
                });

              if (!res.exists) {
                const h = {
                  phoneno: result.user.phoneNumber,

                  uid: result.user.uid,
                };
                if (token !== '') {
                  h['token'] = token;
                }
                firebase
                  .firestore()
                  .collection('users')
                  .doc(result.user.uid)
                  .set({
                    ...h,
                  })
                  .then(res => {
                    token = res;
                  })
                  .catch(error => {
                    console.log(error);
                  });

                if (!res.exists) {
                  const h = {
                    phoneno: result.user.phoneNumber,

                    uid: result.user.uid,
                  };
                  if (token !== '') {
                    h['token'] = token;
                  }
                  firebase
                    .firestore()
                    .collection('users')
                    .doc(result.user.uid)
                    .set({
                      ...h,
                    });
                } else {
                  if (token !== '')
                    firebase
                      .firestore()
                      .collection('users')
                      .doc(result.user.uid)
                      .update({
                        token: token,
                      });
                }
              }
            });
          console.log(result);
          dispatch(startLoading(false));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(slice.actions.otpveryingstate({ state: 'false' }));
        dispatch(startLoading(false));
        dispatch(
          notify({
            type: 'error',
            message: 'Otp Verification Failed! Try again',
            status: true,
          })
        );
      });
    return true;
  };
};

export const getProfiledata = () => {
  return async (dispatch, getState) => {
    const useri = firebase.auth().currentUser;
    if (useri) {
      dispatch(startLoading(false));
      firebase
        .firestore()
        .collection('users')
        .doc(useri.uid)
        .onSnapshot(res => {
          dispatch(slice.actions.profiledetials(res.data()));

          dispatch(startLoading(false));
        });
    }
  };
};

export const setProfiledata = values => {
  return async (dispatch, getState) => {
    const user = firebase.auth().currentUser;
    dispatch(startLoading(true));
    await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .update({
        username: values.name,
        email: values.email,
        gender: values.gender,
      })
      .then(res => {
        dispatch(startLoading(false));

        dispatch(
          showSnackBar({
            message: 'Profile Details Updated Successfully!',
          })
        );
      })
      .catch(() => {
        dispatch(startLoading(false));
      });
  };
};

export const resendOtp = phoneno => {
  return dispatch => {
    firebase.auth().useDeviceLanguage();

    try {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
          callback: response => {
            console.log(response);
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
    dispatch(startLoading(true));

    firebase
      .auth()
      .signInWithPhoneNumber(`+91${phoneno}`, window.recaptchaVerifier)
      .then(confirmationResult => {
        dispatch(slice.actions.otptoken(confirmationResult));
        dispatch(startLoading(false));
      })
      .catch(error => {
        dispatch(startLoading(false));
        console.log(error);
      });
  };
};

export const iftoken = () => {
  return async (dispatch, getState) => {
    const user = firebase.auth().currentUser;
    dispatch(startLoading(false));
    let token;
    await message
      .getToken({
        vapidKey:
          'BM6HQ4jKYMqYUgpbdUiAlsn1CRDlJwarBhKja4SaGifzd5YyzYeCPCmz3PEmR_2C_CAqDCK380T0VTVODl7WY_k',
      })
      .then(res => {
        token = res;
      })
      .catch(error => {
        console.log(error);
      });
    if (token) {
      //firebase.firestore().collection("users").doc(user.uid).update({ token });
    }
  };
};

// sign in with email address

export const RegisterWithEmail = ({ email, password, ...rest }) => {
  return dispatch => {
    dispatch(startLoading(true));
    const info = { ...rest };
    info['email'] = email;
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(result => {
        dispatch(startLoading(false));

        if (result.user) {
          let token = '';
          let h = { uid: result.user.uid };
          message
            .getToken({
              vapidKey:
                'BM6HQ4jKYMqYUgpbdUiAlsn1CRDlJwarBhKja4SaGifzd5YyzYeCPCmz3PEmR_2C_CAqDCK380T0VTVODl7WY_k',
            })
            .then(restoken => {
              token = restoken;
            });
          if (token !== '') {
            h['token'] = token;
          }
          h = { ...h, ...info };
          firebase
            .firestore()
            .collection('users')
            .doc(result.user.uid)
            .set({
              ...h,
            });
        }
        console.log(result);
        dispatch(startLoading(false));
      })
      .catch(error => {
        dispatch(startLoading(false));
        dispatch(
          notify({ type: 'error', message: error.message, status: true })
        );
        console.log(error);
      });
  };
};

export const SigninwithEmail = (email, password) => {
  return dispatch => {
    dispatch(startLoading(true));
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        dispatch(startLoading(false));
      })
      .catch(error => {
        dispatch(startLoading(false));
        dispatch(
          notify({ type: 'error', message: error.message, status: true })
        );
        console.log(error);
      });
  };
};

// google sign in

export const signInWithGoogle = (email, password) => {
  return dispatch => {
    dispatch(startLoading(true));

    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        const {
          additionalUserInfo: { profile, isNewUser },
        } = result;

        const data = {
          email: profile.email,
          username: profile.name,
          avatar: profile.picture || '',
          phoneno: profile?.phoneNumber || result?.user?.phoneNumber || '',
        };

        dispatch(startLoading(false));
        if (result.user) {
          let token = '';

          let h = { uid: result.user.uid };
          message
            .getToken({
              vapidKey:
                'BM6HQ4jKYMqYUgpbdUiAlsn1CRDlJwarBhKja4SaGifzd5YyzYeCPCmz3PEmR_2C_CAqDCK380T0VTVODl7WY_k',
            })
            .then(restoken => {
              token = restoken;
            });
          if (token !== '') {
            h['token'] = token;
          }

          h = { ...h, ...data };

          firebase
            .firestore()
            .collection('users')
            .doc(result.user.uid)
            .get({
              ...h,
            })
            .then(result2 => {
              if (!result2.exists) {
                firebase
                  .firestore()
                  .collection('users')
                  .doc(result.user.uid)
                  .set({
                    ...h,
                  });
              }
            });
        }
      })
      .catch(error => {
        dispatch(
          notify({ type: 'error', message: error.message, status: true })
        );
        console.log(error);
      });
  };
};
