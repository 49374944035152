import React, { useEffect, useRef, useState } from 'react';
import styles from './UploadImage.module.scss';
// Images
import Main from './images/main.svg';
import Back from './images/back.svg';
import Front from './images/front.svg';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as Edit } from './images/edit.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { localimages, localimagesfile } from '../../redux/slices/mesurement';
import SnackBarModal from '../../Components/Modal/snackbar/SnackBarModal';
const UploadImage = () => {
  const [front, setfront] = useState();
  const [back, setback] = useState();
  const [fronti, setfronti] = useState();
  const [backi, setbacki] = useState();
  const navigate = useNavigate();
  const mesure = useSelector(state => state.mesurements);

  const mobileView = useMediaQuery('(max-width:550px)');
  const viewimage = async file => {
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    return await toBase64(file);
  };
  useEffect(() => {
    if (mesure?.localimages?.front && mesure?.localimages?.back) {
      setfront(mesure?.localimages?.front);
      setfronti(mesure?.localimagesfiles?.front);
      setback(mesure?.localimages?.back);
      setbacki(mesure?.localimagesfiles?.back);
    }
  }, []);

  const dispatch = useDispatch();

  const OnfileUpload = async (e, type) => {
    const papai = [...e.target.files];
    if (type === 'front') {
      setfronti(papai[0]);
    }
    if (type === 'back') {
      setbacki(papai[0]);
    }
    const heap = papai.map(filei => viewimage(filei));
    await Promise.all(heap).then(result => {
      if (type === 'front') setfront(result[0]);
      else if (type === 'back') setback(result[0]);
      console.log(result);
    });
  };

  const [loadingImageSave, setLoadingImageSave] = useState(false);
  return (
    <section className={styles.section}>
      <SnackBarModal open={loadingImageSave} text='Upload in progress' />
      <div style={{ padding: mobileView && '0 1rem', marginTop: '2rem' }}></div>
      <div className={styles.main}>
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={Main} alt='' className={styles.mainImg} />
          <h1>Upload Images</h1>
        </div>
        <p>
          Upload your images for better understanding to designer & for a right
          fit product.
        </p>
        <div className={styles.content}>
          <div className={styles.front}>
            {front && (
              <div className={styles.edit} htmlFor={`input-front`}>
                <Edit />
              </div>
            )}
            <label htmlFor={`input-front`}>
              <input
                onChange={e => {
                  OnfileUpload(e, 'front');
                }}
                style={{ display: 'none' }}
                accept='image/*'
                id={`input-front`}
                type='file'
              />
              <img src={front ? front : Back} alt='' />
              <p style={{ textAlign: 'center' }}>Front</p>
            </label>
          </div>
          <div className={styles.back}>
            {back && (
              <div className={styles.edit}>
                <Edit />
              </div>
            )}
            <label htmlFor={`input-back`}>
              <input
                onChange={e => {
                  OnfileUpload(e, 'back');
                }}
                style={{ display: 'none' }}
                accept='image/*'
                id={`input-back`}
                type='file'
              />
              <img src={back ? back : Back} alt='' />
              <p style={{ textAlign: 'center' }}>Back</p>
            </label>
          </div>
        </div>
        {
          <button
            className={styles.btn}
            disabled={!(front && back)}
            onClick={() => {
              setLoadingImageSave(true);
              dispatch(localimages({ front, back }));
              dispatch(localimagesfile({ fronti, backi }));

              setTimeout(() => {
                setLoadingImageSave(false);
                navigate('/view-measurement');
              }, 1500);
            }}
          >
            Add
          </button>
        }
        <h3
          style={{
            margin: '1rem 0',
            color: '#3B3B3B',
            fontSize: '1.2rem',
          }}
        >
          {' '}
          Supports JPG & PNG
        </h3>
      </div>
    </section>
  );
};

export default UploadImage;
