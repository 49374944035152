import React, { useEffect, useState } from 'react';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import NotificationCard from './components/NotificationCard';
import styles from './notification.module.scss';
import firebase from '../../firebase/firebase';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateread } from '../../redux/slices/notification';
import EmptyData from '../../Components/EmptyData/EmptyData';
const Notifications = () => {
  const dispatch = useDispatch();
  const noti = useSelector(state => state.noti);

  useEffect(() => {
    if (noti?.unreadnoti > 0) dispatch(updateread());
  }, [noti]);
  return (
    <div>
      <BreadCrumbs
        currentPath='Notifications'
        paths={[{ name: 'Home', to: '/' }]}
      />
      <SectionNavigation back={-1} heading='Notifications' />
      {!noti?.noti?.length && (
        <EmptyData
          label='notifications'
          svg={
            <svg
              width='101'
              height='101'
              viewBox='0 0 101 101'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M75.1875 33.583C75.1875 26.9526 72.5536 20.5937 67.8652 15.9053C63.1768 11.2169 56.8179 8.58301 50.1875 8.58301C43.5571 8.58301 37.1982 11.2169 32.5098 15.9053C27.8214 20.5937 25.1875 26.9526 25.1875 33.583C25.1875 62.7497 12.6875 71.083 12.6875 71.083H87.6875C87.6875 71.083 75.1875 62.7497 75.1875 33.583Z'
                stroke='#E6E3DC'
                stroke-width='8'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M57.394 87.75C56.6614 89.0128 55.61 90.061 54.3449 90.7897C53.0798 91.5184 51.6455 91.9019 50.1856 91.9019C48.7257 91.9019 47.2914 91.5184 46.0264 90.7897C44.7613 90.061 43.7098 89.0128 42.9773 87.75'
                stroke='#E6E3DC'
                stroke-width='8'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          }
        />
      )}
      <div className={styles.main}>
        {noti?.noti
          ?.slice()
          .sort(
            (a, b) =>
              moment(b?.timestamp?.toDate()) - moment(a?.timestamp?.toDate())
          )
          ?.map((item, idx) => {
            return <NotificationCard key={idx} styles={styles} data={item} />;
          })}
      </div>
    </div>
  );
};

export default Notifications;
