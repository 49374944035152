import React, { useState } from 'react';
import styles from './cart.module.scss';
import cartImg from './image/cart.png';
import { Button, IconButton } from '@mui/material';
import { HiMinus, HiMinusSm, HiPlusSm } from 'react-icons/hi';

const items = [
  {
    id: 1,
    name: 'Piece Name',
    size: 'M',
    designer: 'Piere Cardone',
    quantity: 1,
    price: 2000,
    image: cartImg,
  },
  {
    id: 2,
    name: 'Piece Name 2',
    size: 'L',
    designer: 'Piere Cardone',
    quantity: 2,
    price: 2000,
    image: cartImg,
  },
];

const Cart = () => {
  const [cartItems, setCartItems] = useState(items);
  return (
    <section className={styles.section}>
      <div className={styles.cartItems}>
        {cartItems.map((item, idx) => {
          const { designer, id, image, name, price, quantity, size } = item;
          return (
            <div
              key={id}
              className={styles.cartItem}
              style={{
                borderBottom:
                  cartItems.length - 1 !== idx &&
                  `1px solid rgba(218, 218, 218, 1)`,
              }}
            >
              <div>
                <img src={image} alt='' />
              </div>
              <div>
                <h1>{name}</h1>
                <h3>by {designer}</h3>
                <div className={styles.size_quantity}>
                  <div className={styles.size}>{size}</div>
                  <div className={styles.quantity}>
                    <IconButton
                      onClick={() => {
                        const updatedCart = cartItems?.map(pro => {
                          if (pro.id === id && quantity !== 1) {
                            return { ...pro, quantity: item.quantity - 1 }; //gets everything that was
                          }
                          return pro;
                        });
                        setCartItems(updatedCart);
                      }}
                    >
                      <HiMinusSm size={15} />
                    </IconButton>
                    <p>{quantity}</p>
                    <IconButton
                      onClick={() => {
                        const updatedCart = cartItems?.map(pro => {
                          if (pro.id === id) {
                            return { ...pro, quantity: item.quantity + 1 }; //gets everything that was
                          }
                          return pro;
                        });
                        setCartItems(updatedCart);
                      }}
                    >
                      <HiPlusSm size={15} />
                    </IconButton>
                  </div>
                </div>
                <h2>₹{quantity * price}</h2>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.total}>
        <h3>
          Subtotal: ₹
          <span>
            {cartItems.reduce((acc, item) => {
              return item.price * item.quantity + acc;
            }, 0)}
          </span>
        </h3>
        <Button>Checkout</Button>
      </div>
    </section>
  );
};

export default Cart;
