import React, { Fragment, useEffect } from 'react';
import Header from '../../Components/Common/Container/Header/Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/zoom';
import 'swiper/css/navigation';

import image1 from './images/carousel/1.png';
import image1sm from './images/carousel/1sm.png';
import image2 from './images/carousel/2.png';
import image2sm from './images/carousel/2sm.png';
import image3 from './images/carousel/3.png';
import image3sm from './images/carousel/3sm.png';
import image4 from './images/carousel/4.jpg';
import image4sm from './images/carousel/4sm.png';
import { useMediaQuery } from '@mui/material';

import Footer from '../../Components/Common/Container/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrders } from '../../redux/slices/orders';
import { getAlllinks } from '../../redux/slices/lynks';
import { getAllrequestedOrder } from '../../redux/slices/requestOrder';
import { getnotification } from '../../redux/slices/notification';
import { Link } from 'react-router-dom';
import ForOccasions from '../../Components/Home/for-occasions';
import TopDesigners from '../../Components/Home/top-designers';
import TopCategories from '../../Components/Home/top-categories';
import DifferentMaterials from '../../Components/Home/different-materials';
import NewArrivals from '../../Components/Home/new-arrivals';
import CelebrityFashion from '../../Components/Home/celebrity-fashion';
import PickOfTheDay from '../../Components/Home/pick-of-day';
import ForMens from '../../Components/Home/for-mens';
import ForKids from '../../Components/Home/for-kids';
import { LAP_WIDTH } from '../../constants/breakPoints';
import TwinningCollection from '../../Components/Home/twinning-collection';
import { getCategoryDetails } from '../../redux/slices/category';
import BridalTrousseau from '../../Components/Home/for-festival';
import SEO from '../../Components/Common/SEO';

const landingCarousel = [
  {
    id: 1,
    href: '/products/new-arrivals',
    image: image2,
    imageSM: image2sm,
  },
  {
    id: 2,
    href: '/products/custom-made',
    image: image1,
    imageSM: image1sm,
  },
  {
    id: 3,
    href: '/designers',
    image: image3,
    imageSM: image3sm,
  },
  {
    id: 4,
    href: '/products/designers',
    image: image4,
    imageSM: image4sm,
  },
];

const Products = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    dispatch(getCategoryDetails());
    if (user?.user) {
      dispatch(getAllOrders());
      dispatch(getAlllinks());
      dispatch(getAllrequestedOrder());
      dispatch(getnotification());
    }
  }, [user.user]);

  return (
    <>
      <SEO
        title='Shop Latest Designer Collection - Online Shopping for Men, Women & Kids '
        description='Discover the latest designer collection of Indian clothing for women, men, and kids online in India. Shop from a variety of sizes and enjoy the best prices.'
      />

      <Header name={'Products'} back={'/'} />

      <section className='container'>
        <Swiper
          pagination={{
            clickable: true,
          }}
          // modules={[Pagination]}
          spaceBetween={25}
        >
          {landingCarousel.map(item => {
            const { href, id, image, imageSM } = item;
            return (
              <SwiperSlide key={id}>
                <Link to={href}>
                  <img
                    src={image}
                    alt='Banner'
                    style={{
                      minHeight: '200px',
                      display: isMobile ? 'none' : 'block',
                      borderRadius: '8px',
                    }}
                  />
                  <img
                    alt='Banner'
                    src={imageSM}
                    style={{
                      minHeight: '200px',
                      maxHeight: '500px',
                      display: isMobile ? 'block' : 'none',
                    }}
                  />
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>

      <ForOccasions />
      <TopDesigners showMore={true} />
      <NewArrivals />
      <TopCategories title='Top Categories' />
      <DifferentMaterials />
      <BridalTrousseau />
      {/* <PickOfTheDay /> */}
      <CelebrityFashion />
      <ForMens />
      <TwinningCollection />
      <ForKids />

      <Footer />
    </>
  );
};

export default Products;
