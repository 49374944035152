import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

export default function Loading({ isLoading }) {
  const state = useSelector(state => state.ui);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={state.loading || isLoading}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
}
