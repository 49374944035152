import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingVerifyOTP: false,
  modal: {
    message: '',
    description: '',
    btn: '',
    type: 'loading',
    status: false,
    navigate: '',
    onClose: null,
  },
  notification: { type: 'success', message: 'new', state: false },
  snackbar: {
    position: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    message: '',
    show: false,
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loading(state, action) {
      state.loading = action.payload;
    },
    modal(state, action) {
      state.modal = action.payload;
    },
    notification(state, action) {
      state.notification = action.payload;
    },
    loadingVerifyOTP(state, action) {
      state.loadingVerifyOTP = action.payload;
    },
    handleSnackBar(state, action) {
      state.snackbar = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const startLoading = state => {
  return dispatch => {
    dispatch(slice.actions.loading(state));
  };
};
export const startLoadingVerifyOTP = state => {
  return dispatch => {
    dispatch(slice.actions.loadingVerifyOTP(state));
  };
};

export const startmodal = state => {
  return dispatch => {
    dispatch(slice.actions.modal(state));
    // setTimeout(() => {
    //   dispatch(slice.actions.modal({ ...state, status: false }));
    // }, 2500);
  };
};

export const closeModal = () => {
  return dispatch => {
    dispatch(slice.actions.modal({ ...initialState.modal, status: false }));
  };
};

export const notify = data => {
  return dispatch => {
    dispatch(slice.actions.notification(data));
  };
};

export const showSnackBar = data => {
  return dispatch => {
    dispatch(
      slice.actions.handleSnackBar({
        ...initialState.snackbar,
        show: true,
        message: data.message,
        position: {
          ...initialState.snackbar.position,
          ...data.position,
        },
      })
    );
  };
};
export const closeSnackBar = data => {
  return dispatch => {
    dispatch(
      slice.actions.handleSnackBar({
        ...initialState.snackbar,
        show: false,
        message: '',
      })
    );
  };
};
