import React, { useEffect } from 'react';
import Header from '../../../Components/Common/Container/Header/Header';
import SectionNavigation from '../../../Components/Common/SectionNavigation/SectionNavigation';
import ItemCard from '../../../Components/ItemsCard/ItemCard';
import { products } from '../../../constants/dummyData';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllroductlinks } from '../../../redux/slices/lynks';
import BreadCrumbs from '../../../Components/Common/BreadCrumbs/BreadCrumbs';
import CategoryCard from '../../../Components/Card/CategoryCard';

const DesignerProducts = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllroductlinks());
  }, []);
  const { allCommonlynks } = useSelector(state => state.lynks);
  return (
    <>
      <Header name={' All Designers Products'} back={'/products'} />
      <div className='container'>
        <BreadCrumbs
          currentPath='All Designers Products'
          paths={[{ name: 'Home', to: '/' }]}
        />
        <SectionNavigation
          noLine={true}
          heading={
            <>
              <p
                style={{
                  color: '#0A0A0A',
                }}
              >
                <span
                  style={{
                    color: '#6A5B40',
                    fontStyle: 'italic',
                  }}
                >
                  All Designers
                </span>{' '}
                Products{' '}
              </p>
            </>
          }
          back='/'
        />
        <div className='gridContainer'>
          {allCommonlynks
            ?.filter(i => i.createdDate)
            .sort((a, b) => b?.createdDate - a?.createdDate)
            ?.map(lynk => {
              // return lynk?.items?.map(product => {
              return <CategoryCard product={lynk?.items[0]} lynk={lynk} />;
              // });
            })}
        </div>
      </div>
    </>
  );
};

export default DesignerProducts;
