import { Slide, Snackbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackBar } from '../../../redux/slices/ui';

const SnackBar = () => {
  const { snackbar } = useSelector(state => state.ui);
  const dispatch = useDispatch();


  const { show, position, message } = snackbar;
  return (
    <Snackbar
      anchorOrigin={position}
      open={show}
      TransitionComponent={
        position.vertical === 'bottom' ? TransitionUp : TransitionDown
      }
      autoHideDuration={3500}
      onClose={e => {
        dispatch(closeSnackBar());
      }}
      sx={{
        backgroundColor: '#010101',
      }}
      message={
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <svg
              width='25'
              height='25'
              viewBox='0 0 25 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                x='0.820312'
                y='0.5'
                width='24'
                height='24'
                rx='12'
                fill='#27AE60'
              />
              <path
                d='M6.2207 12.5L10.4207 16.7L19.1207 8'
                stroke='white'
                strokeWidth='3'
              />
            </svg>
            <h3
              style={{
                fontSize: '1.4rem',
              }}
            >
              {message}
            </h3>
          </div>
        </>
      }
      key={message}
    />
  );
};

export default SnackBar;

function TransitionUp(props) {
  return <Slide {...props} direction='up' />;
}
function TransitionDown(props) {
  return <Slide {...props} direction='down' />;
}
