import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import Container from './Components/Common/Container/Container';

const PrivateRoute = ({ children, name, back }) => {
  const user = useSelector(state => state.user);
  const location = useLocation();

  if (!user.user) {
    return <Navigate to={'/login?step=2'} replace={true} />;
  } else
    return (
      <Container name={name} back={back}>
        {children}
      </Container>
    );
};
export default PrivateRoute;
