import { createSlice } from "@reduxjs/toolkit";
import firebase from "../../firebase/firebase";
import { startLoading } from "./ui";
const initialState = {
  category: [],
  productlist: [],
  zeroViews: [],
  maxViews: [],
  discountProduct: [],
  vendorsCategory: [],
};

const slice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setcategory(state, action) {
      state.category = action.payload;
    },
    setProduct(state, action) {
      state.productlist = action.payload;
    },
    setZeroView(state, action) {
      state.zeroViews = action.payload;
    },
    setMaxView(state, action) {
      state.maxViews = action.payload;
    },
    setDiscoutPercentView(state, action) {
      state.discountProduct = action.payload;
    },
    setVendorsCategory(state, action) {
      state.vendorsCategory = action.payload;
    },
  },
});

export const getCategoryDetails = (id) => {
  return (dispatch, getstate) => {
    firebase
      .firestore()
      .collection("categories")
      .get()
      .then((res) => {
        const finaldata = res.docs.map((doc) => doc.data());
        dispatch(slice.actions.setcategory(finaldata));
      })
      .catch((err) => {});
  };
};

export const getLynksAccToCategory = (category, subcate) => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));
    firebase
      .firestore()
      .collection("commonlynks")
      .where("category", "==", category)
      .where("isDeleted", "==", 0)
      .where("acceptingOrders", "==", true)
      .where("sub_category", "array-contains", subcate)
      .where("inCompleteProfile", "==", false)
      .limit(4)
      .get()
      .then((res) => {
        const finaldata = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(slice.actions.setProduct(finaldata));
        dispatch(startLoading(false));
      })
      .catch((err) => {
        dispatch(startLoading(false));
      });
  };
};

export const getLynksAccToOnlyCategory = (category) => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));
    firebase
      .firestore()
      .collection("commonlynks")
      .where("isDeleted", "==", 0)
      .where("category", "==", category)
      .where("acceptingOrders", "==", true)
      .where("inCompleteProfile", "==", false)
      .get()
      .then((res) => {
        const finaldata = res.docs.map((doc) => doc.data());
        dispatch(slice.actions.setcategory(finaldata));
        dispatch(startLoading(false));
      })
      .catch((err) => {
        dispatch(startLoading(false));
      });
  };
};

export const getLynkWithMostView = (category) => {
  return (dispatch, getstate) => {
    // firebase
    //   .firestore()
    //   .collection("commonlynks")
    //   .get()
    //   .then((docs) => {
    //     alert("called");
    //     docs.docs.forEach(async (item) => {
    //       await firebase
    //         .firestore()
    //         .collection("commonlynks")
    //         .doc(item.id)
    //         .update({
    //           createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    //         });
    //     });
    //   });
    firebase
      .firestore()
      .collection("commonlynks")
      .where("isDeleted", "==", 0)
      .where("category", "==", category)
      .where("acceptingOrders", "==", true)
      .where("inCompleteProfile", "==", false)
      .limit(4)
      .get()
      .then((res) => {
        const finaldata = res.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .sort((a, b) => b?.views - a.views);
        dispatch(slice.actions.setMaxView(finaldata));
        dispatch(startLoading(false));
      })
      .catch((err) => {
        dispatch(startLoading(false));
      });
  };
};

export const getLatestLynksAccToCategory = (category) => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));
    firebase
      .firestore()
      .collection("commonlynks")
      .where("isDeleted", "==", 0)
      .where("category", "==", category)
      .where("acceptingOrders", "==", true)
      .where("inCompleteProfile", "==", false)
      .orderBy("createdDate", "desc")
      .limit(4)
      .get()
      .then((res) => {
        const finaldata = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(slice.actions.setZeroView(finaldata));
        dispatch(startLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(startLoading(false));
      });
  };
};

export const getLynksAccToOnlyCategoryWithDiscount = (category, percent) => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));
    firebase
      .firestore()
      .collection("commonlynks")
      .where("isDeleted", "==", 0)
      .where("category", "==", category)
      .where("acceptingOrders", "==", true)
      .where("inCompleteProfile", "==", false)
      .where("totalDiscountPercent", ">=", percent)
      .get()
      .then((res) => {
        const finaldata = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(slice.actions.setDiscoutPercentView(finaldata));
        dispatch(startLoading(false));
      })
      .catch((err) => {
        dispatch(startLoading(false));
      });
  };
};

export const getVendoAccToCategory = (category) => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));
    firebase
      .firestore()
      .collection("vendors")
      .where("targetAudience", "array-contains", category)
      .get()
      .then((res) => {
        const finaldata = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(slice.actions.setVendorsCategory(finaldata));
        dispatch(startLoading(false));
      })
      .catch((err) => {
        dispatch(startLoading(false));
      });
  };
};

// Reducer
export default slice.reducer;
