import React from 'react';
import styles from './accordion.module.scss';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { MdExpandMore } from 'react-icons/md';

const AccordionComp = ({ heading, items }) => {
  return (
    <div>
      <Accordion className={styles.accordion}>
        <AccordionSummary
          expandIcon={<MdExpandMore size={'2.5rem'} />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{
            padding: '0 5px',
          }}
        >
          <h1>{heading}</h1>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0 5px',
          }}
        >
          {items.map(item => {
            const { name, value, id } = item;
            return (
              <div key={id} className={styles.accordionDetails}>
                <h4>{name}</h4>
                <h2>{value} inches</h2>
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionComp;
