import {
  Backdrop,
  Fade,
  Grow,
  Modal,
  Snackbar,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { MOBILE_WIDTH } from '../../../constants/breakPoints';

const SnackBarModal = ({
  width,
  height,
  padding,
  top,
  loading = true,
  open,
  text,
}) => {
  const mobile = useMediaQuery(MOBILE_WIDTH);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: width || '289.87px',
    width: '100%',
    height: height || 'fit-content',
    backgroundColor: `var(--white)`,
    padding: padding || '2rem 4rem',
    boxShadow: 24,
    textAlign: 'center',
    zIndex: 10000000000000,
    borderRadius: '10px',
    outline: 'none',
  };

  const styleMobile = {
    position: 'absolute',

    bottom: top || '20px',
    left: '50%',
    marginTop: '0 2rem',
    maxWidth: width || '90vw',
    transform: 'translateX(-50%)',
    width: '90vw',
    height: height || 'fit-content',
    backgroundColor: `var(--white)`,
    padding: padding || '2rem 4rem',
    boxShadow: 24,
    textAlign: 'center',
    zIndex: 10000000000000,
    borderRadius: '10px',
    outline: 'none',
  };
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      // onClose={handleClose}
      closeAfterTransition
      disableBackdropClick={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div style={mobile ? styleMobile : style}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2rem',
              justifyContent: 'center',
            }}
          >
            {loading && (
              <svg
                id='loading-spinner'
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='25'
                viewBox='0 0 48 48'
              >
                <g fill='none'>
                  <path
                    id='track'
                    fill='#418dff27'
                    d='M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z'
                  />
                  <path
                    id='section'
                    fill='#418dff'
                    d='M24,0 C37.254834,0 48,10.745166 48,24 L44,24 C44,12.954305 35.045695,4 24,4 L24,0 Z'
                  />
                </g>
              </svg>
            )}
            <h3
              style={{
                fontSize: '1.4rem',
              }}
            >
              {text}
            </h3>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default SnackBarModal;

function GrowTransition(props) {
  return <Grow {...props} />;
}
