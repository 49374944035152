import { useMediaQuery } from '@mui/material';
import React from 'react';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import 'swiper/css';
import styles from './categoryCard.module.scss';
import { imageurl } from '../../config';
import ItemTag from '../ItemTag';

const CategoryCard = ({ product, lynk }) => {
  console.log(
    '🚀 ~ file: CategoryCard.js:13 ~ CategoryCard ~ product, lynk :',
    product,
    lynk
  );
  const { id, images, name, description, price, productDiscount } = product;

  const allImage = lynk.items.map(item => item.images).flat();

  const priceInfo = lynk.items.reduce((acc, item) => {
    return +item.price + acc;
  }, 0);
  const discountInfo = lynk.items.reduce((acc, item) => {
    return +item.productDiscount + acc;
  }, 0);

  const isMobile = useMediaQuery(MOBILE_WIDTH);
  const isTab = useMediaQuery('(max-width:726px)');
  const isDesktop = useMediaQuery('(max-width:1350px)');

  const productpriceData = parseFloat(priceInfo) - parseFloat(discountInfo);

  return (
    <div
      key={id}
      style={{
        maxWidth: isTab ? '45vw' : '293.46px',

        width: '100%',
      }}
    >
      <Link to={`/products/${lynk.businessname}/${lynk.id}`}>
        <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          centeredSlides={true}
        >
          {allImage?.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{
                  width: '100%',
                }}
              >
                <img
                  src={imageurl(item)}
                  alt=''
                  style={{
                    width: '100%',
                    height: isMobile ? '240px' : isTab ? '350px' : '404px',
                    objectFit: 'cover',
                    objectPosition: 'top center',
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className={styles.info}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <ItemTag
              lynk={lynk}
              style={{
                margin: '1rem 0',
              }}
            />
            {/* <h4 className={styles.date}>
              {product?.productDispatchTimeLine && (
                <>Dispatch In : {product?.productDispatchTimeLine} days</>
              )}
            </h4> */}
          </div>

          <h3>{lynk.businessname}</h3>
          <h2>{name} </h2>
          <span>
            {lynk?.items?.length > 1 && <>Total no of products (2)</>}
          </span>
          <h4>{description}</h4>
          <div className={styles.itemPrice}>
            {discountInfo ? (
              <>
                <h2>₹{productpriceData.toFixed(2)}</h2>
                <p>₹{parseFloat(priceInfo).toFixed(2)}</p>
                <span>
                  {parseFloat(
                    (parseFloat(discountInfo) /
                      parseFloat(priceInfo).toFixed(2)) *
                      100
                  ).toFixed(2)}
                  % OFF
                </span>
              </>
            ) : (
              <>
                <h2>₹{parseFloat(priceInfo).toFixed(2)}</h2>
              </>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CategoryCard;
