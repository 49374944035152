import React, { useState } from 'react';

import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

// import { motion } from 'framer-motion';

import CustomTextField from '../Components/CustomTextField';
import { MdExpandMore } from 'react-icons/md';

import styles from './Accordion.module.scss';
import { Link } from 'react-router-dom';

function AccordionS({
  position,
  FocusIt,
  Open,
  Form,
  AllDone,
  SetIt,
  setAllDone,
  UploadMeasurement,
  video,
  basicId,
  match,
  mes,
  enterpressed,
  body,
  error,
  setYouTubeUrl,
}) {
  return (
    <div className={styles.accordionDiv}>
      <div
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
      // transition={{ duration: 0.5 }}
      >
        {position === 'upper' ? (
          <>
            <AccordionSingle
              error={error}
              name={'Neck'}
              Open={Open}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.upperbody?.Neck}
              bt='upper'
              video={video}
              enterpressed={enterpressed}
              body={body}
              setYouTubeUrl={setYouTubeUrl}
              description={`Place the measuring tape at the base of your neck (where the collar would normally rest), and pull it around your neck, meeting at the starting point. Hold one finger under the tape measure to ensure some room for comfort. `}
            />

            <AccordionSingle
              error={error}
              name={'Shoulder'}
              SetOpen={FocusIt}
              Form={Form}
              video={video}
              Open={Open}
              value={mes?.upperbody?.Shoulder}
              bt='upper'
              enterpressed={enterpressed}
              setYouTubeUrl={setYouTubeUrl}
              description={`Place the measuring tape on your left or right shoulder (the point where you desire the sleeve of your piece of clothing to begin) and pull it all the way across to your other shoulder, from behind your neck. `}
            />
            <AccordionSingle
              error={error}
              name={'Chest'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.upperbody?.Chest}
              Open={Open}
              bt='upper'
              enterpressed={enterpressed}
              video={video}
              setYouTubeUrl={setYouTubeUrl}
              description={`Place the measuring tape 2-inches below your armpit (left or right) and pull it around your chest, back to the starting point.  `}
            />
            <AccordionSingle
              error={error}
              name={'Arm_Hole'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.upperbody?.Arm_Hole}
              Open={Open}
              bt='upper'
              video={video}
              enterpressed={enterpressed}
              setYouTubeUrl={setYouTubeUrl}
              description={`Raise your (left or right) arm, and extend it so that it’s perpendicular to your body. Wrap the measuring tape from the shoulder, to your armpit, and back to the starting point. The tape should not be tight to restrict the movement of your arm in any way. `}
            />
            <AccordionSingle
              error={error}
              name={'Sleeve_length'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.upperbody?.Sleeve_length}
              Open={Open}
              bt='upper'
              enterpressed={enterpressed}
              video={video}
              setYouTubeUrl={setYouTubeUrl}
              description={`Straighten your arm. Place the measuring tape on your left or right shoulder (the point where you desire the sleeve of your piece of clothing to begin) and pull it across the length of your arm, till the wrist.  `}
            />
            <AccordionSingle
              error={error}
              name={'Wrist'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.upperbody?.Wrist}
              Open={Open}
              bt='upper'
              video={video}
              enterpressed={enterpressed}
              setYouTubeUrl={setYouTubeUrl}
              description={`Make sure the palm of your hand is facing up. Open up your wrist completely to get the real size of your wrist. Wrap the measuring tape around your wrist, snuggly.`}
            />
          </>
        ) : position === 'lower' ? (
          <>
            <AccordionSingle
              error={error}
              name={'Waist'}
              Open={Open}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.lowerbody?.Waist}
              video={video}
              enterpressed={enterpressed}
              body={body}
              setYouTubeUrl={setYouTubeUrl}
              description={`Place the measuring tape at the ending point of your rib (left or right) and pull the tape around your waist back to the starting point. Make sure it isn’t digging into your skin. Breathe out and note down your measurement. `}
            />
            <AccordionSingle
              error={error}
              name={'Full_length'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.lowerbody?.Full_length}
              Open={Open}
              video={video}
              enterpressed={enterpressed}
              setYouTubeUrl={setYouTubeUrl}
              description={`Place your feet together for an accurate measurement. The hips are situated below the waist. Pick the widest point (including the buttocks) and wrap the measuring tape around.  `}
            />
            <AccordionSingle
              error={error}
              name={'Hip_Round'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.lowerbody?.Hip_Round}
              Open={Open}
              video={video}
              enterpressed={enterpressed}
              setYouTubeUrl={setYouTubeUrl}
              description={`Place your feet together for an accurate measurement. The hips are situated below the waist. Pick the widest point (including the buttocks) and wrap the measuring tape around.  `}
            />
            <AccordionSingle
              error={error}
              name={'InSeam'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.lowerbody?.InSeam}
              Open={Open}
              video={video}
              enterpressed={enterpressed}
              setYouTubeUrl={setYouTubeUrl}
              description={`Stand straight, as tall as you can. From the crotch (above your inner thigh), measure down till your ankle of either leg.  `}
            />
            <AccordionSingle
              error={error}
              name={'Thigh'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.lowerbody?.Thigh}
              Open={Open}
              video={video}
              enterpressed={enterpressed}
              setYouTubeUrl={setYouTubeUrl}
              description={`Stand straight, and place the measuring tape on your inner thigh (right below your crotch.) Wrap the tape around your thigh, back to the starting point. `}
            />
            <AccordionSingle
              error={error}
              name={'Calf'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.lowerbody?.Calf}
              Open={Open}
              video={video}
              enterpressed={enterpressed}
              setYouTubeUrl={setYouTubeUrl}
              description={`Sit on a chair with your leg folded at 90 degrees, so that you can measure the widest point of your calf. Choose the widest point of your calf, which will be 3-5 inches below the knee and wrap the tape around it.  `}
            />
            <AccordionSingle
              error={error}
              name={'Ankle'}
              SetOpen={FocusIt}
              Form={Form}
              value={mes?.lowerbody?.Ankle}
              Open={Open}
              video={video}
              enterpressed={enterpressed}
              setYouTubeUrl={setYouTubeUrl}
              description={`Stand with your legs apart. Wrap the measuring tape around the narrowest part of the ankle so that it’s neither tight nor loose. `}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {AllDone === true ? (
        <Link
          // to={`/viewmeasurement/save/${basicId}`}
          to={`/add-measurement-image/${basicId}`}
          style={{ color: '#fff', display: 'flex', alignItems: 'center' }}
        >
          <Button
            className={styles.submitBtn}
            onClick={AllDone === true ? UploadMeasurement : SetIt}
          >
            Submit
          </Button>
        </Link>
      ) : (
        <Button
          className={styles.submitBtn}
          onClick={AllDone === true ? UploadMeasurement : SetIt}
        >
          {AllDone}
        </Button>
      )}
    </div>
  );
}

export default AccordionS;

// Accordion component

function AccordionSingle({
  name,
  Open,
  SetOpen,
  Form,
  value,
  bt,
  video,
  enterpressed,
  body,
  error,
  setYouTubeUrl,
  description,
}) {
  return (
    <Accordion
      // onChange={() => { handleAccordionChange("neck"); FocusIt('neck') }}
      style={{
        boxShadow: 'none',
        margin: 0,
        borderRadius: '5px 0',
        width: '100%',
        background: '#FBFBFB',
      }}
      expanded={Open === name}
      onChange={() => SetOpen(name)}
    >
      <AccordionSummary
        expandIcon={<MdExpandMore />}
        // IconButtonProps={{ size: 'small' }}
        aria-controls='panel1a-content'
        id='panel1a-header'
        className={styles.accordionSummary}
        style={{
          borderBottom:
            (name === 'Wrist' || name === 'Ankle') && Open !== name
              ? '1px solid lightgray'
              : 'none',
        }}
      >
        <h4 className={styles.accordionHeader}>{name?.replace('_', ' ')}</h4>
      </AccordionSummary>
      <AccordionDetails
        style={{
          background: '#fbfbfb',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        {((name !== 'Neck' && name !== 'Waist') || body === 'men') && (
          <CustomTextField
            // label={name?.replace('_', ' ')}
            size={'small'}
            Form={Form}
            name={name}
            values={value}
            video={video}
            enterpressed={enterpressed}
            error={error}
            description={description}
          />
        )}
        {body !== 'men' && (
          <>
            {name === 'Neck' && (
              <CustomTextField
                label={'Neck Around'}
                size={'small'}
                Form={Form}
                autof={true}
                name={'Neck_Around'}
                enterpressed={enterpressed}
                values={value?.Neck_Around}
                video={video}
                error={error && !value?.Neck_Around}
                setYouTubeUrl={setYouTubeUrl}
                description={`Place the measuring tape at the base of your neck (where the collar would normally rest), and pull it around your neck, meeting at the starting point. Hold one finger under the tape measure to ensure some room for comfort. `}
              />
            )}
            <div
              style={
                {
                  // marginBlock: '2rem',
                }
              }
            ></div>

            {name === 'Neck' && (
              <CustomTextField
                label={'Neck Back'}
                size={'small'}
                Form={Form}
                autof={true}
                enterpressed={enterpressed}
                name={'Neck_Back'}
                values={value?.Neck_Back}
                video={video}
                error={error && !value?.Neck_Back}
                setYouTubeUrl={setYouTubeUrl}
                description={`Place the measuring tape on your trapezius muscle (left or right), and pull it diagonally downwards (towards the spine) depending on the depth of the clothing you desire. `}
              />
            )}

            {name === 'Neck' && (
              <CustomTextField
                label={'Neck Front'}
                size={'small'}
                Form={Form}
                autof={true}
                enterpressed={enterpressed}
                name={'Neck_Front'}
                values={value?.Neck_Front}
                video={video}
                error={error && !value?.Neck_Front}
                setYouTubeUrl={setYouTubeUrl}
                description={`Place the measuring tape on your trapezius muscle (left or right), and pull it diagonally downwards (towards your chest) depending on the depth of the clothing you desire. `}
              />
            )}
          </>
        )}

        {body !== 'men' && (
          <>
            {name === 'Waist' && (
              <CustomTextField
                label={'High Waist'}
                size={'small'}
                Form={Form}
                autof={true}
                enterpressed={enterpressed}
                name={'High_Waist'}
                values={value?.High_Waist}
                video={video}
                error={error && !value?.High_Waist}
                setYouTubeUrl={setYouTubeUrl}
                description={`This is generally the narrowest point between your ribs and hips, right above where your hips stop, above your belly button. To make it simpler, it’s 11-12 inches above your crotch. `}
              />
            )}
            {name === 'Waist' && (
              <CustomTextField
                label={'Mid Waist'}
                size={'small'}
                Form={Form}
                autof={true}
                enterpressed={enterpressed}
                name={'Mid_Waist'}
                values={value?.Mid_Waist}
                video={video}
                error={error && !value?.Mid_Waist}
                setYouTubeUrl={setYouTubeUrl}
                description={`9-10 inches above your crotch, you might have to measure on your belly button, however, it differs from person to person.  `}
              />
            )}
            {name === 'Waist' && (
              <CustomTextField
                label={'Low Waist'}
                size={'small'}
                Form={Form}
                enterpressed={enterpressed}
                autof={true}
                video={video}
                name={'Low_Waist'}
                values={value?.Low_Waist}
                error={error && !value?.Mid_Waist}
                setYouTubeUrl={setYouTubeUrl}
                description={`7-8 inches above your crotch, this is just above the buttocks and below the belly button.`}
              />
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
