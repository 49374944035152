import React from 'react';
import bg from './images/bg.png';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image4 from './images/4.png';
import SwiperComponent from '../swiper';
import { SwiperSlide } from 'swiper/react';
import styles from './new-arrivals.module.scss';
import { useMediaQuery } from '@mui/material';
import { MOBILE_WIDTH } from '../../../constants/breakPoints';
import { Link } from 'react-router-dom';

const images = [
  {
    id: 1,
    name: 'Lehengas',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Flehenga.png?alt=media&token=921ea1b2-d5e3-4fdd-93ca-c8e5028c6949',
    href: '/collection/women/lehengas',
  },
  {
    id: 2,
    name: 'Indo western',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Findo-western.png?alt=media&token=65e98000-4a86-4219-9a96-b7c2e20ab223',
    href: '/collection/women/indo-western',
  },
  {
    id: 3,
    name: 'Ethnic',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fethnic-charm.png?alt=media&token=549974c1-66f8-4f37-bbad-7a60df818ea2',
    href: '/collection/women/ethnic-wear',
  },
  {
    id: 4,
    name: 'Under 5k',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Funder-5k.png?alt=media&token=9dce550a-2a3e-45f2-90bb-bf2f1f6714ca',
    href: '/collection/women/under-5k',
  },
  {
    id: 5,
    name: 'Exclusively on LynkTown',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fexclusive-on-LynkTown.png?alt=media&token=77940b3b-eeb8-441a-be8a-7df12cb0c18e',
    href: '/collection/women/exclusive-on-lynktown',
  },
];

const NewArrivals = () => {
  const isMobile = useMediaQuery(MOBILE_WIDTH);

  return (
    <div className={styles.newArrivals}>
      <div
        className={styles.container}
        style={{
          position: 'relative',
          background: '#F0DED2',
        }}
      >
        <div className={styles.titleSM}>
          <h1>New Arrivals</h1>
          <p>
            Get a chance to wear the fashion worn by your favourite celebrities
            and flaunt it in your style
          </p>
        </div>
        <div className={styles.newArrivals__container}>
          <div className={styles.title}>
            <h1>New Arrivals</h1>
            <p>
              Get a chance to wear the fashion worn by your favourite
              celebrities and flaunt it in your style
            </p>
          </div>
          <SwiperComponent sellAllHref='/products/new-arrivals'>
            {images.map((item, idx) => {
              return (
                <SwiperSlide
                  key={idx}
                  style={{
                    padding: isMobile ? '5px' : '10px',
                    minHeight: isMobile ? 'auto' : '413px',
                  }}
                >
                  <div className={styles.sliderItem}>
                    <Link to={item.href}>
                      <img
                        src={item.image}
                        alt=''
                        style={{
                          maxHeight: isMobile ? '300px' : '413px',
                          height: '100%',
                          minHeight: isMobile ? '300px' : '413px',
                          objectFit: 'cover',
                          objectPosition: 'top center',
                        }}
                      />
                      <p>{item.name}</p>
                    </Link>
                  </div>

                  {/* <ItemCard
                    link={item.lynk ? item.lynk : ''}
                    designerName={
                      item?.businessname || item?.vendordetails?.businessName
                    }
                    totalProducts={item.items.length}
                    measurementRequired={item.measurementRequired}
                    date={moment(item?.createdDate).format('DD-MM-YYYY')}
                    price={item.price ? item.price : ''}
                    id={item.id}
                    product={item.items}
                    to={`/products/${item.businessname}/${item.id}`}
                    products={item.items}
                    vendorInfo={item.vendordetails}
                    item={item}
                  /> */}
                </SwiperSlide>
              );
            })}
          </SwiperComponent>
        </div>
      </div>
    </div>
  );
};

export default NewArrivals;
