import { combineReducers } from 'redux';
import user from './slices/userslice';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import lynks from './slices/lynks';
import requestOrder from './slices/requestOrder';
import ui from './slices/ui';
import orders from './slices/orders';
import mesurements from './slices/mesurement';
import noti from './slices/notification';
import vendor from './slices/vendor';
import category from './slices/category'
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['mesurements'],
};

const rootReducer = history =>
  combineReducers({
    user,
    router: connectRouter(history),
    lynks,
    requestOrder,
    ui,
    orders,
    mesurements,
    noti,
    vendor,
    category
  });

export { rootPersistConfig, rootReducer };
