import Header from '../../../Components/Common/Container/Header/Header';
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import TopCategories from '../../../Components/Home/top-categories';
import TwinningCollection from '../../../Components/Home/twinning-collection';
import TwinningImg from './images/tw1.png';
import carousel1 from './images/carousel/1.png';
import carousel2 from './images/carousel/2.png';
import carousel3 from './images/carousel/3.png';
import carousel3SM from './images/carousel/3sm.jpg';

import CategoryProductCard from '../../../Components/Home/CategoryProductCard';
import product1 from './images/products/p1.png';
import product2 from './images/products/p2.png';
import CategoriesForYou from '../../../Components/Home/Categories-for-you';
import BreadCrumbs from '../../../Components/Common/BreadCrumbs/BreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLynksAccToCategory,
  getLynksAccToOnlyCategory,
} from '../../../redux/slices/category';
import SEO from '../../../Components/Common/SEO';
import firebase from '../../../firebase/firebase';
import { Link } from 'react-router-dom';

const products = [
  {
    id: 1,
    name: 'Varun Bahl Men',
    image: product1,
    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 2,
    name: 'Varun Bahl Men',
    image: product1,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 3,
    name: 'Varun Bahl Men',
    image: product1,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 4,
    name: 'Varun Bahl Men',
    image: product1,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
];
const products2 = [
  {
    id: 1,
    name: 'Varun Bahl Men',
    image: product2,
    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 2,
    name: 'Varun Bahl Men',
    image: product2,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 3,
    name: 'Varun Bahl Men',
    image: product2,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 4,
    name: 'Varun Bahl Men',
    image: product2,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
];

const landingCarousel = [
  // {
  //   id: 1,
  //   href: '/products/new-arrivals',
  //   image: carousel1,
  // },
  // {
  //   id: 2,
  //   href: '/products/custom-made',
  //   image: carousel2,
  // },
  {
    id: 3,
    href:
      '/collection/kids/' + encodeURIComponent('upto-30%-off') + '?value=30',
    image: carousel3,
    imageSM: carousel3SM,
  },
];
const topCategories = [
  {
    id: 1,
    name: '0-2 years',
    href: '/collection/kids/0-2-years',
    value: 'suits',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2F0-2%20years.png?alt=media&token=53c718fc-d617-473b-b344-9212603764bc',
    height: {
      sm: '102px',
      md: '200px',
      lg: '300px',
      xl: '480px',
    },
  },
  {
    id: 2,
    name: '2-5 years',
    href: '/collection/kids/2-5-years',

    value: 'weeding',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2F2-5%20years.png?alt=media&token=33287590-544f-4ea8-845b-a823ff3759f9',
    height: {
      sm: '102px',
      md: '200px',
      lg: '300px',
      xl: '480px',
    },
  },
  {
    id: 3,
    name: '5-12 years',
    value: 'casual',

    href: '/collection/kids/5-12-years',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2F5-12%20years.png?alt=media&token=6cfe1235-b36c-4b0f-a86d-f24e200e2843',
    height: {
      sm: '102px',
      md: '200px',
      lg: '300px',
      xl: '480px',
    },
  },
  {
    id: 4,
    name: '12-15 years',
    value: 'dresses',
    href: '/collection/kids/12-15-years',

    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2F12-15%20years.png?alt=media&token=5f30bca8-e582-42aa-bd25-b6012b889b6a',
    height: {
      sm: '102px',
      md: '200px',
      lg: '300px',
      xl: '480px',
    },
  },
];

const categoryForYou = [
  {
    id: 1,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fbirthday-bash-kids.png?alt=media&token=21dc41b3-fa26-4209-a6ca-6dae85bfad92',
    name: 'Birthday bash',
    href: '/collection/kids/birthday-bash',
  },
  {
    id: 2,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Ftwinning-kids.png?alt=media&token=5080da8c-25b6-4f1e-95c5-da7665e9097c',
    name: 'Twinning',
    href: '/collection/kids/twinning',
  },
  {
    id: 3,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Ffestive-wear-kids.png?alt=media&token=818a7c6f-40fd-4842-bde3-9c309039a742',
    name: 'Festive wear',
    href: '/collection/kids/festive wear',
  },

  {
    id: 5,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fexclusively-on-lt-kids.png?alt=media&token=b6aa8670-9941-4a81-816e-8a4a48462d2b',
    name: 'Exclusively on lynk town',
    href: '/collection/kids/exclusively-on-lt',
  },
];

const KidsCategoryPage = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [forBoys, setForBoys] = useState([]);
  const [forGirls, setForGirls] = useState([]);
  const getData = (sub, action) => {
    firebase
      .firestore()
      .collection('commonlynks')
      .where('category', '==', 'kids')
      .where('isDeleted', '==', 0)
      .where('acceptingOrders', '==', true)
      .where('sub_category', 'array-contains', sub)
      .where('inCompleteProfile', '==', false)
      .get()
      .then(res => {
        const finaldata = res.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        action(finaldata);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData('for-boys', setForBoys);
    getData('for-girls', setForGirls);
  }, []);

  return (
    <>
      <SEO
        title='Buy Designer Outfits for Kids- Shop for kids,Infants and teens'
        description='Shop the latest designer collection  for infants, teen girls and boys. Explore the range of premium kids clothing and shop online with great deals.'
      />

      <Header />

      <main>
        <section className='container'>
          <Swiper
            pagination={{
              clickable: true,
            }}
            // modules={[Pagination]}
            spaceBetween={25}
          >
            {landingCarousel.map(item => {
              const { href, id, image, imageSM } = item;
              return (
                <SwiperSlide key={id}>
                  <Link
                    to={href}
                    style={{
                      width: '100%',

                      background: '#EAEAEA',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={image}
                      alt='Banner'
                      style={{
                        minHeight: '200px',
                        display: isMobile ? 'none' : 'block',
                        borderRadius: '8px',
                      }}
                    />
                    <img
                      alt='Banner'
                      src={imageSM}
                      style={{
                        minHeight: '200px',
                        maxHeight: '500px',
                        display: isMobile ? 'block' : 'none',
                      }}
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <BreadCrumbs
            currentPath={'Kids'}
            paths={[{ name: 'Home', to: '/' }]}
          />
        </section>
        <TopCategories title='Age Groups' topCategories={topCategories} />
        <CategoryProductCard
          title='For Boys'
          products={forBoys}
          showAll='/collection/kids/for-boys'
        />

        <TwinningCollection
          title={
            <>
              Subtleness <br /> by Manish Bhel
            </>
          }
          description='Explore the new collection by famous designer Manish Bhel'
          image={TwinningImg}
        />
        <CategoryProductCard
          title='For Girls'
          products={forGirls}
          showAll='/collection/kids/for-girls'
        />
        <CategoriesForYou products={categoryForYou} />
      </main>
    </>
  );
};

export default KidsCategoryPage;
