import React, { useCallback, useEffect, useState } from 'react';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import designerBG from './images/bg.png';
import designerImage from './images/designer.png';
import requestOrder from './images/request-order.png';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as Instagram } from './images/instagram.svg';
import { ReactComponent as Whatsapp } from './images/whatsapp.svg';
import { ReactComponent as Location } from './images/location.svg';
import {
  HiArrowRight,
  HiOutlineLocationMarker,
  HiOutlineShoppingBag,
  HiShare,
} from 'react-icons/hi';
import firebase from '../../firebase/firebase';
import styles from './designer.module.scss';
import { IconButton } from '@mui/material';
import ItemCard from '../../Components/ItemsCard/itemViewCart';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllVendorLynks,
  getVendorByUniqueID,
  getVendorDetails,
} from '../../redux/slices/vendor';
import { imageurl } from '../../config';
import CategoryCard from '../../Components/Card/CategoryCard';
import SEO from '../../Components/Common/SEO';
import { IoCopyOutline, IoShareOutline } from 'react-icons/io5';
import { showSnackBar } from '../../redux/slices/ui';
import ModalComponent from '../../Components/Modal/ModalComponent';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';

const DesignerDetails = () => {
  const { insta_username } = useParams();
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.ui);
  const [isLoading, setIsLoading] = useState(true);
  const {
    businessName,
    shippingDetails,
    profilePic,
    profileInfo,
    coverPic,
    id,
    ...rest
  } = useSelector(state => {
    return state?.vendor?.selectedvendor || {};
  });

  useEffect(() => {
    if (insta_username) {
      dispatch(getVendorByUniqueID(decodeURIComponent(insta_username)));
    }
  }, [insta_username]);

  useEffect(() => {
    if (id) {
      dispatch(getAllVendorLynks(id));
      setIsLoading(false);
    }
  }, [id]);

  const updateView = useCallback(async () => {
    if (id) {
      firebase
        .firestore()
        .collection('vendors')
        .doc(id)
        .update({
          views: firebase.firestore.FieldValue.increment(1),
        });
    }
  }, [id]);
  useEffect(() => {
    updateView();
  }, [updateView]);

  const vendorLynk = useSelector(state => state.vendor.vendorlynk);

  const shareUrl = `https://app.lynktown.com/designers/${insta_username}`;

  const [showShareModal, setShowShareModal] = useState(false);

  const onCopy = () => {
    navigator.clipboard
      .writeText(`https://app.lynktown.com/designers/${insta_username}`)
      .then(() => {
        dispatch(
          showSnackBar({
            message: 'Lynk copied to clipboard',
          })
        );
      })
      .catch(err => {
        console.error('Unable to copy to clipboard:', err);
      });
  };

  if (loading || isLoading)
    return (
      <>
        <SEO title='Hauteze - Loading...' />
        <p
          style={{
            margin: '2rem 0',
            textAlign: 'center',
          }}
        >
          loading..
        </p>
      </>
    );
  return (
    <div>
      <SEO
        title={`Designer - ${businessName}`}
        description={profileInfo?.bio}
      />

      <BreadCrumbs
        currentPath={businessName}
        paths={[
          { name: 'Home', to: '/' },
          { name: 'Designers', to: '/designers' },
        ]}
      />

      <ModalComponent
        open={showShareModal}
        handleClose={() => setShowShareModal(prev => !prev)}
        height={'280px'}
        closeBtn={true}
      >
        <h1 className={styles.title}>Share</h1>
        <div className={styles.modalContent}>
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={50} round />
          </WhatsappShareButton>
          <EmailShareButton url={shareUrl}>
            <EmailIcon size={50} round />
          </EmailShareButton>
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={50} round />
          </FacebookShareButton>
          <TelegramShareButton url={shareUrl}>
            <TelegramIcon size={50} round />
          </TelegramShareButton>
          <TwitterShareButton url={shareUrl}>
            <XIcon size={50} round />
          </TwitterShareButton>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={50} round />
          </LinkedinShareButton>
        </div>
        <div className={styles.shareUrl}>
          <h1>{shareUrl}</h1>
          <div>
            <IconButton onClick={onCopy}>
              <IoCopyOutline />
            </IconButton>
          </div>
        </div>
      </ModalComponent>
      <div
        className={styles.bg}
        style={{
          background: `url(${coverPic ? imageurl(coverPic) : designerBG})`,
          height: '500px',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
        }}
      >
        <div className={styles.icons}>
          <IconButton
            onClick={() => {
              window.open(`https://wa.me/${profileInfo?.whatsappNumber}`);
            }}
            style={{
              paddingTop: '4px',
            }}
          >
            <Whatsapp />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(
                `https://www.instagram.com/${profileInfo?.insta_username}/`
              );
            }}
          >
            <Instagram />
          </IconButton>
        </div>
        <h1 className={styles.bgText}>LynkTown</h1>

        <div>
          <div className={styles.designerInfo}>
            <div className={styles.utilsBtns}>
              <IconButton onClick={onCopy}>
                <IoCopyOutline />
              </IconButton>
              <IconButton
                onClick={() => {
                  setShowShareModal(prev => !prev);
                }}
              >
                <HiShare />
              </IconButton>
            </div>
            <div>
              <img
                className={styles.designerInfo__profilePic}
                src={imageurl(profilePic) || designerImage}
                alt=''
              />
            </div>
            <div>
              <div>
                <h1 className={styles.designerInfo__name}>{businessName}</h1>
                <p className={styles.designerInfo__lynk} title={shareUrl}>
                  https://app.lynktown.com/designers/{insta_username}
                </p>
              </div>

              <div className={styles.border}></div>
              <div
                className={styles.designerInfo__location}
                title={[
                  // shippingDetails?.plot,
                  // shippingDetails?.address,
                  // shippingDetails?.locality,
                  shippingDetails?.city,
                  shippingDetails?.state,
                  'India',

                  // shippingDetails?.pincode,
                ].join(' ')}
              >
                {' '}
                <HiOutlineLocationMarker />
                <p>
                  {[
                    // shippingDetails?.plot,
                    // shippingDetails?.address,
                    // shippingDetails?.locality,
                    shippingDetails?.city,
                    shippingDetails?.state,
                    'India',

                    // shippingDetails?.pincode,
                  ].join(' ')}
                </p>
              </div>
              <div className={styles.designerInfo__location}>
                {' '}
                <HiOutlineShoppingBag />
                <p>Prducts are readymade & Customise</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: '14rem 0',
        }}
      >
        {' '}
      </div>
      <div
        style={{
          padding: '0 2rem',
        }}
      >
        <div className={styles.requestOrder}>
          <div className={styles.requestOrder__shadow}></div>
          <div className={styles.requestOrder__img}>
            <img src={requestOrder} alt='' />
          </div>
          <div className={styles.requestOrder__content}>
            <div>
              <h1>Request order</h1>
              <p>Buy anything you find interesting</p>
            </div>
            <div>
              <Link
                to={`/${businessName?.replaceAll(
                  ' ',
                  '-'
                )}/request-order/${id}`}
              >
                <IconButton>
                  <HiArrowRight size={30} />
                </IconButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: '8rem 0',
        }}
      >
        {' '}
      </div>
      <SectionNavigation noBack heading='My Collections' />
      <h1 className={styles.gridTitle}>My Collections</h1>
      <div className='gridContainer'>
        {vendorLynk
          ?.filter(i => i.createdDate)
          .sort((a, b) => b?.createdDate - a?.createdDate)
          ?.map(lynk => {
            // return lynk?.items?.map(product => {
            return <CategoryCard product={lynk?.items[0]} lynk={lynk} />;
            // });
          })}
        {/* {vendorLynk
          ?.filter(i => i.createdDate)
          .sort((a, b) => b?.createdDate - a?.createdDate)
          .map(item => {
            return (
              <ItemCard
                maxWidth={'45vw'}
                link={item.lynk ? item.lynk : ''}
                designerName={
                  item?.businessname || item?.vendordetails?.businessName
                }
                totalProducts={item.items.length}
                measurementRequired={item.measurementRequired}
                date={moment(item?.createdDate).format('DD-MM-YYYY')}
                price={item.price ? item.price : ''}
                id={item.id}
                product={item.items}
                to={`/lynk/${item.businessname}/${item.id}`}
                products={item.items}
                vendorInfo={item.vendordetails}
                item={item}
              />
            );
          })} */}
      </div>
    </div>
  );
};

export default DesignerDetails;
