import React, { useEffect, useRef, useState } from 'react';
import styles from './header.module.scss';
import { ReactComponent as Logo } from './images/new-logo.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import { AiOutlineHeart, AiOutlineUser } from 'react-icons/ai';
import { IconButton, useMediaQuery } from '@mui/material';
import { GiHamburgerMenu } from 'react-icons/gi';
import Sidebar from './Components/sidebar/Sidebar';
import MegaMenu from './Components/MegaMenu';
import UserCard from './Components/UserCard/UserCard';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Lynks } from './images/lynks.svg';
import { ReactComponent as ExploreDesigner } from './images/explore-designer.svg';
import { ReactComponent as Orders } from './images/orders.svg';
import { ReactComponent as RequestedOrders } from './images/requested-order.svg';
import useClickOutside from '../../../../hooks/useClickOutside';
import { getCategoryDetails } from '../../../../redux/slices/category';
import { TAB_WIDTH } from '../../../../constants/breakPoints';
import logoGif from './images/logo.gif';
import logo from './images/logo.png';

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const isloading = useSelector(state => state.ui?.loading);

  const requestorder = useSelector(state => state.requestOrder);
  const links = useSelector(state => state.lynks);
  const orders = useSelector(state => state.orders);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleOpen = () => {
    setIsSidebarOpen(true);
  };
  const handleClose = () => {
    setIsSidebarOpen(false);
  };

  const [selectedNavOption, setSelectedNavOption] = useState(null);
  const [selectedNavOptionPosition, setSelectedNavOptionPosition] = useState({
    x: 0,
  });

  const mens = useRef(null);
  const women = useRef(null);
  const twinning = useRef(null);
  const kids = useRef(null);

  const { category } = useSelector(state => state.category);

  useEffect(() => {
    dispatch(getCategoryDetails());
  }, []);

  const isTab = useMediaQuery('(max-width:950px)');

  useEffect(() => {
    if (!isTab && isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  }, [isTab]);

  const navItems = [
    {
      id: 1,
      name: 'Men',
      reference: mens,
      value: 'men',
      style: {
        gridTemplateColumns: `repeat(3, 1fr)`,
      },

      data: [
        {
          title: 'Trending',
          id: 1,
          subCategory: [
            'New arrivals',
            'Indo western',
            'Shirts',
            'Formal wear',
            'Kurtas',
          ],
        },
        {
          title: 'Occasion',
          id: 2,

          subCategory: [
            'Haldi',
            'Mehendi',
            'Sangeet',
            'Wedding',
            'Reception',
            'Festive',
          ],
        },
        {
          title: 'Styles',
          id: 4,

          subCategory: [
            'Kurta Sets',
            'Sherwani',
            'Nehru Jacket',
            'Bandhgala',
            'Jackets and Coats',
            'Ethnic Waistcoats',
            'Bundi Jackets',
            'Suits & Tuxedos',
          ],
        },
      ],
    },
    {
      id: 2,
      name: 'Women',
      reference: women,
      value: 'women',
      style: {
        gridTemplateColumns: `repeat(3, 1fr)`,
      },
      data: [
        {
          title: 'Trending',
          id: 1,
          subCategory: [
            'New arrivals',
            'Festive',
            'Wedding ',
            'Haldi',
            'Mehendi',
            'Sangeet',
            'Maternity Wear',
            'Plus size',
          ],
        },
        {
          title: 'Ethnic Wear',
          id: 2,

          subCategory: [
            'Kurtas',
            'Kurtis and Sets',
            'Sarees',
            'Tunics',
            'Blouses',
            'Anarkalis and Sets',
            'Skirts',
            'Lehengas',
          ],
        },
        {
          title: 'Dresses',
          id: 4,

          subCategory: [
            'Mini',
            'Short',
            'Knee length',
            'Ankle length',
            'Maxi',
            'Gown',
            'Shirt dress',
            'Pleated dress',
          ],
        },
      ],
    },
    {
      id: 3,
      name: 'Kids',
      reference: kids,
      value: 'kids',
      style: {
        gridTemplateColumns: `repeat(2, 1fr)`,
      },
      data: [
        {
          title: 'Trending',
          id: 1,
          subCategory: [
            'New arrivals',
            'Festive Wear',
            'Exclusively on LT',
            'Birthday Bash',
          ],
        },
        {
          title: 'Age Groups',
          id: 2,

          subCategory: ['0-2 Years', '2-5 Years', '5-12 Years', '12-15 Years'],
        },
      ],
    },
    {
      id: 4,
      name: 'Twinning',
      reference: twinning,
      value: 'twinning',
      style: {
        gridTemplateColumns: `repeat(1, 1fr)`,
      },
      data: [
        {
          title: 'Trending',
          id: 1,
          subCategory: [
            'Mom daughter duo',
            'Couples Twinning',
            'Mom Son Duo',
            'Dad Son Duo',
            'Family twinning',
          ],
        },
      ],
    },
  ];

  const topNavSection = [
    { id: 1, title: 'Just In', href: '/' },
    { id: 2, title: 'Sale', href: '/' },
    { id: 3, title: 'Jewellery', href: '/' },
    { id: 4, title: 'Accessories', href: '/' },
    { id: 5, title: 'Collections', href: '/' },
    { id: 6, title: 'Wedding', href: '/' },
    { id: 7, title: 'Navratri', href: '/' },
    { id: 8, title: 'Ships in 24 hours', href: '/' },
  ];
  const topNavSectionMobile = [
    {
      id: 1,
      title: 'Mens',
      href: '/men',
      image:
        'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fparty-wear.png?alt=media&token=b69d9a98-3e34-4dc7-823a-fa5833182e3a',
    },
    {
      id: 2,
      title: 'Women',
      href: '/women',
      image:
        'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fchristmas.png?alt=media&token=b678d390-f15b-4bdf-bc25-76d43e8ab807',
    },
    {
      id: 3,
      title: 'Kids',
      href: '/kids',
      image:
        'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fbirthday-bash-kids.png?alt=media&token=21dc41b3-fa26-4209-a6ca-6dae85bfad92',
    },
  ];

  const onNavOptionHover = navItemId => {
    if (selectedNavOption === navItemId) return;

    let navOptionScreenPosition;

    switch (navItemId) {
      case 'men':
        if (mens === null || mens.current === null) return;
        navOptionScreenPosition = mens.current.getBoundingClientRect();
        break;
      case 'women':
        if (women === null || women.current === null) return;
        navOptionScreenPosition = women.current.getBoundingClientRect();
        break;
      case 'kids':
        if (women === null || women.current === null) return;
        navOptionScreenPosition = women.current.getBoundingClientRect();
        break;
      case 'twinning':
        if (twinning === null || twinning.current === null) return;
        navOptionScreenPosition = twinning.current.getBoundingClientRect();
        break;
      default:
        return;
    }

    setSelectedNavOptionPosition({
      x: navOptionScreenPosition.left,
    });
    setSelectedNavOption(navItemId);
  };

  const onMouseLeave = () => {
    setSelectedNavOption(null);
    setSelectedNavOptionPosition({ x: 0 });
  };

  const onNavOptionClicked = navItemId => {
    selectedNavOption !== null ? onMouseLeave() : onNavOptionHover(navItemId);
  };

  const userCardItem = [
    {
      id: 1,
      title: 'Lynks',
      to: '/lynks',
      icon: <Lynks />,
      itemsLength: links?.alllynks?.filter(
        lynk => !lynk?.isDeleted || !lynk.isDeleted === 1
      ).length,
    },
    {
      id: 2,
      title: 'Explore Designers',
      to: '/designers',
      icon: <ExploreDesigner />,
    },

    {
      id: 3,
      title: 'Orders',
      to: '/orders',
      icon: <Orders />,
      itemsLength: orders?.orders?.filter(
        item => item?.shippmentStatus === 0 || !item?.shippmentStatus
      ).length,
    },
    {
      id: 4,
      title: 'Requested orders',
      to: '/requested-orders',
      icon: <RequestedOrders />,
      itemsLength: requestorder?.AllrequestOrder?.length,
    },
  ];

  const [showUserCard, setShowUserCard] = useState(false);

  const userCardRef = useRef(null);

  const isLap = useMediaQuery('(max-width:920px)');

  useClickOutside(userCardRef, () => {
    setShowUserCard(false);
  });

  // if (isloading) {
  //   return (
  //     <p
  //       style={{
  //         textAlign: 'center',
  //         margin: '2rem 0',
  //       }}
  //     >
  //       Loading...
  //     </p>
  //   );
  // }
  return (
    <>
      <header
        style={{
          borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
        }}
      >
        <div className={styles.header}>
          <div className={styles.left}>
            <IconButton onClick={handleOpen} className={styles.menu}>
              <GiHamburgerMenu size={20} />
            </IconButton>
            <div
              className={styles.logo}
              style={{
                position: 'relative',
              }}
            >
              <Link to='/'>
                {/* <img
                  src={logo}
                  alt=''
                  style={{
                    height: '80px',
                  }}
                /> */}
                <Logo />
              </Link>
            </div>

            <div
              style={{
                height: '100%',
              }}
              onMouseLeave={onMouseLeave}
            >
              <nav className={styles.nav}>
                {navItems.map(item => {
                  const { id, name, reference, value } = item;
                  return (
                    <>
                      {value === 'twinning' ? (
                        <button
                          key={id}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                          onMouseEnter={() => onNavOptionHover(value)}
                          onClick={() => onNavOptionClicked(value)}
                          onFocus={() => onNavOptionHover(value)}
                          onTouchStart={() => onNavOptionClicked(value)}
                        >
                          <span
                            ref={reference}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                              fontWeight:
                                pathname === `/${value}` ? '600' : '400',
                            }}
                          >
                            {name}
                            <MdKeyboardArrowDown
                              style={{
                                fontSize: '20px',
                                color: 'rgba(0, 0, 0, 0.8)',
                              }}
                            />
                          </span>
                        </button>
                      ) : (
                        <Link to={`/${value}`}>
                          <button
                            key={id}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                            onMouseEnter={() => onNavOptionHover(value)}
                            onClick={() => onNavOptionClicked(value)}
                            onFocus={() => onNavOptionHover(value)}
                            onTouchStart={() => onNavOptionClicked(value)}
                          >
                            <span
                              ref={reference}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                fontWeight:
                                  pathname === `/${value}` ? '600' : '400',
                              }}
                            >
                              {name}
                              <MdKeyboardArrowDown
                                style={{
                                  fontSize: '20px',
                                  color: 'rgba(0, 0, 0, 0.8)',
                                }}
                              />
                            </span>
                          </button>
                        </Link>
                      )}
                    </>
                  );
                })}
              </nav>
              <MegaMenu
                selectedNavOption={selectedNavOption}
                selectedNavOptionPosition={selectedNavOptionPosition}
                data={navItems}
                setSelectedNavOption={setSelectedNavOption}
              />
            </div>
            <Link to='/designers'>
              <button className={styles.navLink}>
                <span
                  style={{
                    fontWeight: pathname === `/designers` ? '600' : '400',
                  }}
                >
                  Designers
                </span>
              </button>
            </Link>
          </div>
          <div className={styles.center}>
            <div className={styles.logo}>
              <Link to='/'>
                <Logo />
                {/* <img
                  src={logo}
                  alt=''
                  style={{
                    height: '70px',
                  }}
                /> */}
              </Link>
            </div>
          </div>
          <div className={styles.right}>
            {/* <div className={styles.input}>
              <input placeholder='Search' />
              <FiSearch
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '12px',
                  fontSize: '22px',
                  cursor: 'pointer',
                  color: 'rgba(0, 0, 0, 0.8)',
                }}
              />
            </div> */}
            <div
              ref={userCardRef}
              style={{
                position: 'relative',
                marginLeft: 'auto',
              }}
            >
              <IconButton
                onClick={() => {
                  if (user) {
                    setShowUserCard(prev => !prev);
                  } else {
                    navigate('/login?step=2');
                  }
                }}
              >
                <AiOutlineUser />
              </IconButton>
              {showUserCard && (
                <UserCard
                  navItems={userCardItem}
                  setShowUserCard={setShowUserCard}
                />
              )}
            </div>

            {/* <IconButton>
              <AiOutlineHeart />
            </IconButton>
            <Link to='/cart'>
              <IconButton>
                <svg
                  width='22'
                  height='22'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M16.6466 4.6343H13.2925C13.2925 3.56682 12.8684 2.54307 12.1136 1.78825C11.3588 1.03343 10.335 0.609375 9.26754 0.609375C8.20006 0.609375 7.17631 1.03343 6.42149 1.78825C5.66667 2.54307 5.24262 3.56682 5.24262 4.6343H1.88852C1.53269 4.6343 1.19144 4.77565 0.939832 5.02726C0.688226 5.27886 0.546875 5.62011 0.546875 5.97594V16.0382C0.546875 16.3941 0.688226 16.7353 0.939832 16.9869C1.19144 17.2385 1.53269 17.3799 1.88852 17.3799H16.6466C17.0024 17.3799 17.3436 17.2385 17.5952 16.9869C17.8469 16.7353 17.9882 16.3941 17.9882 16.0382V5.97594C17.9882 5.62011 17.8469 5.27886 17.5952 5.02726C17.3436 4.77565 17.0024 4.6343 16.6466 4.6343ZM9.26754 1.95102C9.97919 1.95102 10.6617 2.23372 11.1649 2.73693C11.6681 3.24014 11.9508 3.92265 11.9508 4.6343H6.58426C6.58426 3.92265 6.86696 3.24014 7.37017 2.73693C7.87339 2.23372 8.55589 1.95102 9.26754 1.95102ZM16.6466 16.0382H1.88852V5.97594H16.6466V16.0382Z'
                    fill='#171717'
                  />
                </svg>
              </IconButton>
            </Link> */}
          </div>
        </div>
        <Sidebar
          isOpen={isSidebarOpen}
          navItems={navItems}
          onClose={handleClose}
        />
        {/* <div className={styles.inputSm}>
          <input placeholder='Search' />
          <FiSearch
            style={{
              position: 'absolute',
              right: '25px',
              top: '12px',
              fontSize: '22px',
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.8)',
            }}
          />
        </div> */}
      </header>
      {pathname === '/' && (
        <nav
          className='container'
          style={{
            paddingBottom: isLap ? '20px' : '32px',
          }}
        >
          {/* <ul className={styles.topNav}>
            {topNavSection.map(nav => {
              const { href, id, title } = nav;
              return (
                <li key={id}>
                  <Link to={href}>{title}</Link>
                </li>
              );
            })}
          </ul> */}
          <ul className={styles.topNavSm}>
            {topNavSectionMobile.map(nav => {
              const { href, id, title, image } = nav;
              return (
                <li
                  key={id}
                  style={{
                    width: '100%',
                  }}
                >
                  <Link to={href}>
                    <img src={image} alt='' />
                    <h3>{title}</h3>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </>
  );
};

export default Header;
