import React from 'react';
import Header from '../../../../Components/Common/Container/Header/Header';
import CategoryProductCard from '../../../../Components/Home/CategoryProductCard';
import product1 from '../images/products/p1.png';

const products = [
  {
    id: 1,
    name: 'Varun Bahl Men',
    image: product1,
    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 2,
    name: 'Varun Bahl Men',
    image: product1,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 3,
    name: 'Varun Bahl Men',
    image: product1,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 4,
    name: 'Varun Bahl Men',
    image: product1,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
];
const ForHim = () => {
  return (
    <>
      <Header />
      <main>
        <section className='container'>
          <CategoryProductCard
            title='For Him'
            products={products}
            style={{
              marginBlock: 0,
            }}
          />
        </section>
      </main>
    </>
  );
};

export default ForHim;
