
import React from 'react';

import styles from './input.module.scss';

const Input = ({ label, inline, type, classes,error,...props }) => {
  
  return (
    <div>
      <>
        <div className={styles.floating_label_group}>
          <input
            className={`${styles.inputField}`}
          
            {...props}
            placeholder={label}
            type={type}
            required={true}
        
          />
          <label className={styles.floating_label}>{label}</label>

          {error && <div style={{fontSize:12,color:'red'}}>{error}</div>}
        </div>
       
      </>
    </div>
  );
};

export default Input;
