import React, { useEffect } from 'react';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import styles from './designer.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getVendor } from '../../redux/slices/vendor';
import DesignerCard from '../../Components/DesignerCard';
import SEO from '../../Components/Common/SEO';
import Footer from '../../Components/Common/Container/Footer/Footer';

const Designers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendor());
  }, []);

  const vendor = useSelector(state => state.vendor.vendorlist);

  return (
    <>
      <SEO
        title='Shop Exclusive Designer Dresses- Buy Designer Indian Wear for Men and Women '
        description='Buy Latest Collection of Clothes from variety of Indian Designers. Shop your designer outfits for festivals, weddings, bridegrooms & more online.'
      />
      <BreadCrumbs
        currentPath='Explore Designers'
        paths={[{ name: 'Home', to: '/' }]}
      />
      <div>
        <SectionNavigation heading='Designers' back='/' />
        <section className={styles.container}>
          {vendor?.map(designer => {
            return <DesignerCard key={designer.id} designer={designer} />;
          })}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Designers;
