import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import LoginButton from '../LoginButton';
import styles from './login.module.scss';
import * as Yup from 'yup';
import ErrorBox from '../../../Common/Form/ErrorBox';
import firebase from '../../../../firebase/firebase';
import { useDispatch } from 'react-redux';
import {
  SigninwithEmail,
  RegisterWithEmail,
  signInWithGoogle,
} from '../../../../redux/slices/userslice';
import Input from '../../../Common/Form/Feilds/Input';
import 'yup-phone-lite';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import SEO from '../../../Common/SEO';

const Login = ({ setCurrentStep, setnumber }) => {
  const dispatch = useDispatch();

  const [register, setRegister] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const handleSubmit = values => {
    if (register) {
      dispatch(RegisterWithEmail(values));
    } else dispatch(SigninwithEmail(values.email, values.password));
  };

  const initialValues = {
    email: '',
    password: '',
    username: '',
    phoneno: '',
    checkTC: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('*Email is required')
      .email('*Email address is not valid'),
    password: register
      ? Yup.string()
          .required('*Password is required')
          .min(8, '*Password must be at least 8 characters')
      : Yup.string().required('*Password is required'),
    username: register ? Yup.string().required('*Username is required') : '',
    phoneno: register
      ? Yup.number()
          .min(1000000000, '*Please enter min 10 digit')
          .max(9999999999, '*Maxmum 10 digits you can enter')
          .required('*Phone number is required')
      : '',
    confirmPassword: register
      ? Yup.string()
          .required('*Confirm Password is required')
          .oneOf([Yup.ref('password'), null], '*Passwords must match')
      : '',

    checkTC: Yup.boolean().oneOf([true], '*Please accept terms and conditions'),
  });

  return (
    <>
      <div className={styles.loginContainer}>
        <div>
          <h1>Welcome to LynkTown!</h1>
          <h6>
            An exclusive community to buy apparel that fits you like a glove
          </h6>
        </div>
        <div className='form-container'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {formik => {
              const {
                values,
                isValid,
                setTouched,
                submitCount,
                handleChange,
                errors,
              } = formik;
              console.log(errors);
              return (
                <Form className={styles.formControl}>
                  {register && (
                    <>
                      <div className={styles.formGroup}>
                        <Input
                          from='login'
                          type='text'
                          id='username'
                          name='username'
                          label={'Username'}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <Input
                          from='login'
                          type='number'
                          id='phoneno'
                          name='phoneno'
                          label={'Phone no.'}
                        />
                      </div>
                    </>
                  )}
                  <div className={styles.formGroup}>
                    <Input
                      from='login'
                      type='email'
                      id='email'
                      name='email'
                      label={'Email address'}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <Input
                      from='login'
                      type='password'
                      id='password'
                      name='password'
                      label={'Password'}
                    />
                  </div>

                  {register && (
                    <div className={styles.formGroup}>
                      <Input
                        from='login'
                        type='password'
                        id='confirmPassword'
                        name='confirmPassword'
                        label={'Confirm Password'}
                      />
                    </div>
                  )}
                  {!register && (
                    <button
                      type='button'
                      onClick={() => setCurrentStep(4)}
                      className={styles.forgotPassword}
                    >
                      Forgot Password?
                    </button>
                  )}
                  <FormGroup
                    sx={{
                      marginBottom: '10px',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.checkTC}
                          onChange={handleChange}
                          name='checkTC'
                          sx={{
                            color: '#6A5B40',
                            '&.Mui-checked': {
                              color: '#6A5B40',
                            },
                            '& .MuiSvgIcon-root': { fontSize: 24 },
                          }}
                        />
                      }
                      label={
                        <>
                          <p className={styles.checkBoxLabel}>
                            I accept to the &nbsp;
                            <Link to='/terms-and-conditions'>
                              Terms and conditions
                            </Link>
                          </p>
                        </>
                      }
                    />

                    <ErrorMessage
                      name='checkTC'
                      render={msg => <ErrorBox>{msg}</ErrorBox>}
                    />
                  </FormGroup>

                  <div>
                    <LoginButton type='submit'>
                      {register ? 'Register' : 'Login'}
                    </LoginButton>
                  </div>
                </Form>
              );
            }}
          </Formik>

          <h6>
            {register ? 'Already have an account ?' : `Don't have an account?`}
            <button
              type='button'
              onClick={() => setRegister(!register)}
              className={styles.registerBtn}
            >
              {register ? 'login' : 'register'}
            </button>
          </h6>

          <div className={styles.googleSignIn}>
            <h6 style={{ color: 'black' }}>or</h6>
            <button
              type='button'
              className={styles.googleBtn}
              onClick={() => dispatch(signInWithGoogle())}
            >
              <img
                src='/images/icons/google-icon.svg'
                className={styles.icon}
                alt=''
              />{' '}
              Sign in with Google
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
