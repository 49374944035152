import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import history from '../history'
import  { rootReducer, rootPersistConfig}  from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import { routerMiddleware } from 'connected-react-router'
// ----------------------------------------------------------------------
 
const store = configureStore({
  reducer:  persistReducer(rootPersistConfig,rootReducer(history)),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(logger)
    .concat(routerMiddleware(history))
    ,
});



const persistor = persistStore(store);
const useSelector = useReduxSelector;

const useDispatch = () => useReduxDispatch();

export { store,persistor, useSelector, useDispatch };
