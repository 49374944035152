/* eslint-disable jsx-a11y/alt-text */
import { Button, IconButton, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import ModalComponent from '../../Components/Modal/ModalComponent';
import ProductCard from '../../Components/ProductCard/ProductCard';
import styles from './requestAlteration.module.scss';
import { ReactComponent as Success } from '../../Components/Modal/images/successLight.svg';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams, useNavigate } from 'react-router';
import { sumbitalteration } from '../../redux/slices/orders';
import { uploadSingleImage } from '../../utils/uploadimage';
import { notify, showSnackBar, startLoading } from '../../redux/slices/ui';
import { ReactComponent as Close } from './img/close.svg';

const RequestAlteration = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const mobile = useMediaQuery(MOBILE_WIDTH);
  const alterationproduct = useSelector(state => state.orders.alteration);

  const dispatch = useDispatch();

  const [error, seterror] = useState({});

  const openModal = (e, r) => {
    setShowModal(true);
  };
  const closeModal = (e, r) => {
    if (r !== 'backdropClick') setShowModal(false);
    navigate('/');
  };

  const viewimage = async file => {
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

    return await toBase64(file);
  };

  const Submitpressed = async () => {
    let isError = false;
    let img = [];
    alterimage.map((i, ind) => {
      if (!i.alteration?.image || i.alteration?.image === '') {
        seterror({ error: 'please upload the image', index: ind });
        setTimeout(() => {
          seterror({});
        }, 3000);
        isError = true;
      } else if (!i.alteration?.text || i.alteration?.text === '') {
        seterror({ error: 'please fill the reason', index: ind });
        setTimeout(() => {
          seterror({});
        }, 3000);
        isError = true;
      }
      if (i?.alteration?.image) {
        img.push(i?.alteration?.image);
      }
    });

    if (!isError) {
      const allimgg = img.map(im => {
        return uploadSingleImage(im, 'alteration');
      });
      const finaldata = _.cloneDeep(alterimage);
      dispatch(startLoading(true));
      await Promise.all(allimgg).then(async resimg => {
        dispatch(startLoading(false));
        finaldata.map((i, index) => {
          finaldata[
            index
          ].alteration.image = `alteration/${finaldata[index].alteration.image.name}`;
          delete finaldata[index].alteration.image64;
        });
        dispatch(startLoading(false));
        const res = dispatch(sumbitalteration(finaldata, id));
        openModal();
      });
    } else {
      dispatch(
        notify({
          type: 'error',
          status: true,
          message: 'Please fill all the Details for all Products',
        })
      );
    }
  };

  useEffect(() => {
    if (!alterationproduct.length) navigate('/');
  }, []);

  const [alterimage, setalterimage] = useState([...alterationproduct]);
  return (
    <div>
      <BreadCrumbs
        currentPath='Request for Alteration'
        paths={[
          { name: 'Home', to: '/' },
          { name: 'Orders', to: '/orders' },
        ]}
      />
      <div className={styles.requestAlter}>
        <div className={styles.requestAlter__header}>
          <h1>Request for Alteration</h1>
        </div>
        <div className={styles.requestAlter__body}>
          {alterationproduct.map((item, index) => {
            return (
              <div className={styles.requestAlter__body__item}>
                <ProductCard data={item} readymadesize={item?.selected_size} />

                <div className={styles.requestAlteration__form}>
                  <div className={styles.requestAlteration__form__control}>
                    <label htmlFor='reason'>Enter reason for alteration*</label>
                    <textarea
                      name='reason'
                      id=''
                      value={alterimage[index]?.alteration?.text}
                      cols='30'
                      rows='10'
                      maxlength='100'
                      placeholder='Start from here'
                      onChange={e => {
                        const local = _.cloneDeep(alterimage[index]);
                        local.alteration = {
                          ...local.alteration,
                          text: e.target.value,
                        };
                        const newalter = [...alterimage];
                        newalter[index] = { ...local };

                        setalterimage(newalter);
                      }}
                    ></textarea>
                    <p>*maximum characters shall be 100</p>
                    {error?.index === index &&
                      error?.error === 'please fill the reason' && (
                        <div style={{ fontSize: '1.2rem', color: 'red' }}>
                          {error?.error}
                        </div>
                      )}
                  </div>
                  <div className={styles.requestAlteration__form__control}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <div>
                        <label htmlFor='reason'>Upload photo</label>
                        {/* <input type='file' /> */}
                        <label
                          htmlFor={`image+${index}`}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <input
                            onChange={async e => {
                              viewimage(e.target.files[0]);
                              const i = [...alterimage];

                              const currobj = _.cloneDeep(i[index]);

                              currobj.alteration = {
                                ...currobj?.alteration,
                                image: e.target.files[0],
                              };
                              currobj.alteration = {
                                ...currobj?.alteration,
                                image64: await viewimage(e.target.files[0]),
                              };
                              currobj.alteration = {
                                ...currobj?.alteration,
                                status: 1,
                              };
                              currobj.status = 3;
                              i[index] = currobj;

                              setalterimage(i);
                            }}
                            style={{ display: 'none' }}
                            accept='image/*'
                            id={`image+${index}`}
                            type='file'
                          />
                          <button className={styles.uploadBtn}>
                            Choose File
                          </button>
                        </label>
                      </div>
                      {alterimage[index]?.alteration?.image64 && (
                        <div className={styles.return__form__control__img}>
                          <div
                            key={index}
                            style={{
                              position: 'relative',
                              width: '50px',
                              height: '50px',
                            }}
                          >
                            <img
                              alt='ing'
                              //   onClick={()=>{imageRemove(index)}}
                              src={alterimage[index]?.alteration?.image64}
                              style={{
                                width: '50px',
                                height: '50px',
                                objectFit: 'cover',
                                objectPosition: 'top',
                              }}
                            />
                            <Close
                              onClick={() => {
                                const local = [...alterimage];
                                let obi = _.cloneDeep(local[index]);
                                obi.alteration = {
                                  ...obi.alteration,
                                  image: null,
                                };
                                obi.alteration = {
                                  ...obi.alteration,
                                  image64: null,
                                };
                                local[index] = obi;
                                setalterimage(local);
                              }}
                              style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-5px',
                                fontSize: '1.5rem',
                                fill: 'var(--danger-dark)',
                                color: 'white',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                          {/* <img
                          style={{ width: '70px', height: '70px' }}
                          src={alterimage[index]?.alteration?.image64}
                          />
                          <button
                          style={{}}
                          onClick={() => {
                            const local = [...alterimage];
                            let obi = _.cloneDeep(local[index]);
                            obi.alteration = { ...obi.alteration, image: null };
                            obi.alteration = {
                              ...obi.alteration,
                              image64: null,
                            };
                            local[index] = obi;
                            setalterimage(local);
                          }}
                          >
                          Delete
                        </button> */}
                        </div>
                      )}
                    </div>
                    {error?.index === index &&
                      error?.error === 'please upload the image' && (
                        <div style={{ fontSize: '1.2rem', color: 'red' }}>
                          {error?.error}
                        </div>
                      )}
                    <h5>
                      You can upload only 1 file not more than 2MB in size and{' '}
                      supports JPG, JPEG or PNG
                    </h5>
                    <Link to='/terms-and-conditions'>Terms and conditions</Link>
                  </div>
                </div>
              </div>
            );
          })}
          <div className={styles.requestAlter__body__btn}>
            <Button onClick={Submitpressed}>Submit</Button>
          </div>
        </div>
      </div>
      <ModalComponent
        closeBtn
        open={showModal}
        handleClose={closeModal}
        width='680px'
        height={mobile ? '228px' : '367px'}
      >
        <div className={styles.modal}>
          <Success
            style={{
              transform: mobile ? 'scale(0.8)' : 'scale(0.85)',
            }}
          />
          {/* <IconButton
            onClick={() => setShowModal(false)}
            style={{
              position: 'absolute',
              top: mobile ? '-10px' : '-30px',
              right: '-10px',
            }}
          >
            <svg
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='12.834' cy='12.834' r='12.834' fill='#DA1E28' />
              <path
                d='M17.1126 8.55859L8.55664 17.1146'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M8.55664 8.55859L17.1126 17.1146'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </IconButton> */}

          <h1>Your alteration request has been sent successfully</h1>

          <Button
            onClick={() => {
              closeModal();
            }}
            className={styles.modal__btn}
          >
            Back to home
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default RequestAlteration;
