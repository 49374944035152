import React from 'react';
import { Route, Routes } from 'react-router';
import {
  Login,
  Lynks,
  LynksDetails,
  OrderDetails,
  Orders,
  RequestedOrderDetails,
  RequestedOrders,
  RequestOrder,
  Profile,
  Notifications,
  AddManMeasurement,
  AddWomenMeasurement,
  EditUploadImage,
  UploadImage,
  ViewMeasurement,
  ViewMeasurementRead,
  Support,
  TermsNConditions,
  RequestAlteration,
  ReturnNRefund,
  Exchange,
} from './Pages';

import PrivateRoute from './priavateroute';
// import PrivateRoute from "./privateroute";
import Container from './Components/Common/Container/Container';
import Createlink from './Pages/createlink';
import UpdatePhone from './Pages/profile/UpdatePhone';
import Designers from './Pages/designers';
import DesignerDetails from './Pages/designersDetails';
import Home from './Pages/home';
import DesignerProducts from './Pages/home/designer-products';
import CustomMadeProducts from './Pages/home/customer-products';
import ReadyToShipProducts from './Pages/home/ready-to-ship';
import NewArrivals from './Pages/home/new-arrival';
import ProductDetail from './Pages/product-detail';
import MensCategoryPage from './Pages/home/men';
import WomenCategoryPage from './Pages/home/women';
import KidsCategoryPage from './Pages/home/kids';
import ForHim from './Pages/home/men/for-him';
import ProductByCategory from './Pages/category/gender';
import Cart from './Pages/cart';
import Footer from './Components/Common/Container/Footer/Footer';

const Routese = () => {
  return (
    <Routes>
      {/* AUTH */}
      <Route path='login' element={<Login />} />
      {/* DEFAULT */}

      <Route path='/' element={<Home />} />
      <Route
        path='/men'
        element={
          <>
            <MensCategoryPage />
            <Footer />
          </>
        }
      />
      <Route
        path='/mens/for-him'
        element={
          <>
            <ForHim />
            <Footer />
          </>
        }
      />
      <Route
        path='/collection/:gender/:categoryId'
        element={
          <>
            <ProductByCategory />
            <Footer />
          </>
        }
      />
      <Route
        path='/women'
        element={
          <>
            <WomenCategoryPage />
            <Footer />
          </>
        }
      />
      <Route
        path='/kids'
        element={
          <>
            <KidsCategoryPage />
            <Footer />
          </>
        }
      />
      <Route path='/products/designers' element={<DesignerProducts />} />
      <Route path='/products/custom-made' element={<CustomMadeProducts />} />
      <Route path='/products/ready-to-ship' element={<ReadyToShipProducts />} />
      <Route path='/products/new-arrivals' element={<NewArrivals />} />
      <Route
        path='/products/:vendendor/:id'
        element={
          <Container back='/' name='Order Details'>
            <ProductDetail />
            <Footer />
          </Container>
        }
      />
      <Route
        path='/cart'
        element={
          <Container back='/' name='Cart'>
            <Cart />
          </Container>
        }
      />

      {/* Explore Designer */}
      <Route
        path='/designers'
        element={
          <Container name={'Explore Designer'} back='/'>
            <Designers />
          </Container>
        }
      />
      <Route
        path='/designers/:insta_username'
        element={
          <>
            <Container back='/'>
              <DesignerDetails />
              <Footer />
            </Container>
          </>
        }
      />

      <Route
        path='/home'
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      {/* LYNKS */}
      <Route
        path='/lynks'
        element={
          <PrivateRoute back='/' name='Lynks'>
            <Lynks />
          </PrivateRoute>
        }
      />
      <Route
        path=':vendendor/:id'
        element={
          <PrivateRoute back='/lynks' name='Order Details'>
            <LynksDetails />
          </PrivateRoute>
        }
      />
      {/* ORDERS */}
      <Route
        path='/orders'
        element={
          <PrivateRoute back='/' name='Orders'>
            <Orders />
          </PrivateRoute>
        }
      />
      <Route
        path='/orders/:id'
        element={
          <PrivateRoute back='/orders' name='Order Details'>
            <OrderDetails />
          </PrivateRoute>
        }
      />
      {/* Requested Orders */}
      <Route
        path='/requested-orders'
        element={
          <PrivateRoute back='/' name='Requested Orders'>
            <RequestedOrders />
          </PrivateRoute>
        }
      />
      <Route
        path='/requested-orders/:id'
        element={
          <PrivateRoute back='/requested-orders' name='Requested Order Details'>
            <RequestedOrderDetails />
          </PrivateRoute>
        }
      />
      {/* Request Order */}

      <Route
        path='/:vendor/request-order/:id'
        element={
          <PrivateRoute back='/' name='Order Request'>
            <RequestOrder />
          </PrivateRoute>
        }
      />
      <Route
        path='/request-alteration/:id'
        element={
          <PrivateRoute back='/orders' name='Request Alteration'>
            <RequestAlteration />
          </PrivateRoute>
        }
      />
      {/* Return N Refund */}
      <Route
        path='/return-refund/:id'
        element={
          <PrivateRoute back='/orders' name='Return and Refund'>
            <ReturnNRefund />
          </PrivateRoute>
        }
      />
      {/* Exchange */}

      <Route
        path='/exchange/:id'
        element={
          <PrivateRoute back='/orders' name='Exchange'>
            <Exchange />
          </PrivateRoute>
        }
      />

      {/* PROFILE */}
      <Route
        path='/profile'
        element={
          <PrivateRoute back='/' name='Profile'>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path='/profile/update-phone'
        element={
          <PrivateRoute back='/' name='Profile'>
            <UpdatePhone />
          </PrivateRoute>
        }
      />
      {/* Notifications */}
      <Route
        path='/notifications'
        element={
          <PrivateRoute back='/' name='Notifications'>
            <Notifications />
          </PrivateRoute>
        }
      />

      {/* MEASURENT */}
      <Route
        path='/add-measurement/He/:size/:fitting/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <AddManMeasurement />
          </PrivateRoute>
        }
      />
      <Route
        path='/add-measurement/She/:size/:fitting/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <AddWomenMeasurement />
          </PrivateRoute>
        }
      />
      <Route
        path='/view-measurement/He/:size/:fitting/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <AddManMeasurement />
          </PrivateRoute>
        }
      />
      <Route
        path='/view-measurement/She/:size/:fitting/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <AddWomenMeasurement />
          </PrivateRoute>
        }
      />
      <Route
        path='/add-measurement/Boy/:size/:fitting/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <AddManMeasurement />
          </PrivateRoute>
        }
      />
      <Route
        path='/add-measurement/Girl/:size/:fitting/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <AddWomenMeasurement />
          </PrivateRoute>
        }
      />
      <Route
        path='/view-measurement/Boy/:size/:fitting/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <AddManMeasurement />
          </PrivateRoute>
        }
      />
      <Route
        path='/view-measurement/Girl/:size/:fitting/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <AddWomenMeasurement />
          </PrivateRoute>
        }
      />
      <Route
        path='/upload-image'
        element={
          <PrivateRoute back='/'>
            <UploadImage />
          </PrivateRoute>
        }
      />
      <Route
        path='/edit-image'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <EditUploadImage />
          </PrivateRoute>
        }
      />
      <Route
        path='/view-measurement'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <ViewMeasurement />
          </PrivateRoute>
        }
      />
      <Route
        path='/view-measurement/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <ViewMeasurementRead />
          </PrivateRoute>
        }
      />
      <Route
        path='/view-measurement/:id'
        element={
          <PrivateRoute back='/' name='Measurment'>
            <ViewMeasurementRead />
          </PrivateRoute>
        }
      />
      <Route
        path='lynk/:vendor/:id'
        element={
          <PrivateRoute back='/'>
            <Createlink />
          </PrivateRoute>
        }
      />

      <Route
        path='/support'
        element={
          <Container name={'Support'} back={'/'}>
            <Support />
          </Container>
        }
      />
      <Route
        path='/terms-and-conditions'
        element={
          <Container name={'Terms and Conditions'} back={'/'}>
            <TermsNConditions />
          </Container>
        }
      />

      {/* <Route
        path='/invoice'
        element={
          // <PrivateRoute back='/' name='Measurment'>
          <Invoice />
          // </PrivateRoute>
        }
      /> */}
    </Routes>
  );
};

export default Routese;
