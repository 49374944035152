import React, { useState } from 'react';
import CustomTextField from './CustomTextField';
import styles from './MeasurementFloating.module.scss';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const measurementDescription = {
  Neck: `Place the measuring tape at the base of your neck (where the collar would normally rest), and pull it around your neck, meeting at the starting point. Hold one finger under the tape measure to ensure some room for comfort. `,
  Neck_Around: `Place the measuring tape at the base of your neck (where the collar would normally rest), and pull it around your neck, meeting at the starting point. Hold one finger under the tape measure to ensure some room for comfort.`,
  Neck_Front: `Place the measuring tape on your trapezius muscle (left or right), and pull it diagonally downwards (towards your chest) depending on the depth of the clothing you desire.`,
  Neck_Back: `Place the measuring tape on your trapezius muscle (left or right), and pull it diagonally downwards (towards the spine) depending on the depth of the clothing you desire.`,
  Shoulder: `Place the measuring tape on your left or right shoulder (the point where you desire the sleeve of your piece of clothing to begin) and pull it all the way across to your other shoulder, from behind your neck. `,
  Chest: `Place the measuring tape 2-inches below your armpit (left or right) and pull it around your chest, back to the starting point. `,
  Arm_Hole: `Raise your (left or right) arm, and extend it so that it’s perpendicular to your body. Wrap the measuring tape from the shoulder, to your armpit, and back to the starting point. The tape should not be tight to restrict the movement of your arm in any way. `,
  Sleeve_length: `Straighten your arm. Place the measuring tape on your left or right shoulder (the point where you desire the sleeve of your piece of clothing to begin) and pull it across the length of your arm, till the wrist.  `,
  Wrist: `Make sure the palm of your hand is facing up. Open up your wrist completely to get the real size of your wrist. Wrap the measuring tape around your wrist, snuggly.`,
  Waist: `Place the measuring tape at the ending point of your rib (left or right) and pull the tape around your waist back to the starting point. Make sure it isn’t digging into your skin. Breathe out and note down your measurement.`,
  High_Waist: `This is generally the narrowest point between your ribs and hips, right above where your hips stop, above your belly button. To make it simpler, it’s 11-12 inches above your crotch.`,
  Mid_Waist: `9-10 inches above your crotch, you might have to measure on your belly button, however, it differs from person to person.`,
  Low_Waist: `7-8 inches above your crotch, this is just above the buttocks and below the belly button.`,
  Full_length: `Place your feet together for an accurate measurement. The hips are situated below the waist. Pick the widest point (including the buttocks) and wrap the measuring tape around.`,
  Hip_Round: `Place your feet together for an accurate measurement. The hips are situated below the waist. Pick the widest point (including the buttocks) and wrap the measuring tape around.`,
  InSeam: `Stand straight, as tall as you can. From the crotch (above your inner thigh), measure down till your ankle of either leg.`,
  Thigh: `Stand straight, and place the measuring tape on your inner thigh (right below your crotch.) Wrap the tape around your thigh, back to the starting point.`,
  Calf: `Sit on a chair with your leg folded at 90 degrees, so that you can measure the widest point of your calf. Choose the widest point of your calf, which will be 3-5 inches below the knee and wrap the tape around it.`,
  Ankle: `Stand with your legs apart. Wrap the measuring tape around the narrowest part of the ankle so that it’s neither tight nor loose.`,
};

function MeasurementFloating({
  name,
  value,
  focused,
  Form,
  enterpressed,
  video,
  body,
  AllDone,
  AllValues,
  UploadMeasurement,
  SetIt,
  error,
  setYouTubeUrl,
}) {
  const [descriptionExpanded, setDescriptionExpanded] = useState('');

  return (
    <div className={`${styles.container} floatingContainer-${body}-${name}`}>
      {name === 'upper' || name === 'lower' ? (
        <></>
      ) : (
        <>
          {((name !== 'Neck' && name !== 'Waist') || body === 'men') && (
            <>
              <CustomTextField
                setYouTubeUrl={setYouTubeUrl}
                label={name?.replace('_', ' ')}
                showName={true}
                size={'small'}
                Form={Form}
                name={name}
                values={value}
                video={video}
                enterpressed={enterpressed}
                AllDone={AllDone}
                AllValues={AllValues}
                UploadMeasurement={UploadMeasurement}
                SetIt={SetIt}
                description={measurementDescription[name]}
                error={error}
              />
            </>
          )}
          {body !== 'men' && (
            <>
              {name === 'Neck' && (
                <div
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  <CustomTextField
                    setYouTubeUrl={setYouTubeUrl}
                    label={'Neck Around'}
                    showName={true}
                    size={'small'}
                    Form={Form}
                    autof={true}
                    name={'Neck_Around'}
                    description={measurementDescription['Neck_Around']}
                    enterpressed={enterpressed}
                    values={value?.Neck_Around}
                    video={video}
                    AllDone={AllDone}
                    AllValues={AllValues}
                    UploadMeasurement={UploadMeasurement}
                    SetIt={SetIt}
                    error={error}
                    descriptionExpanded={descriptionExpanded}
                    setDescriptionExpanded={setDescriptionExpanded}
                  />
                </div>
              )}
              <div
                style={
                  {
                    // marginBlock: '2rem',
                  }
                }
              ></div>

              {name === 'Neck' && (
                <CustomTextField
                  setYouTubeUrl={setYouTubeUrl}
                  label={'Neck Back'}
                  showName={true}
                  size={'small'}
                  Form={Form}
                  autof={true}
                  enterpressed={enterpressed}
                  name={'Neck_Back'}
                  values={value?.Neck_Back}
                  description={measurementDescription['Neck_Back']}
                  video={video}
                  AllDone={AllDone}
                  AllValues={AllValues}
                  UploadMeasurement={UploadMeasurement}
                  SetIt={SetIt}
                  error={error}
                  descriptionExpanded={descriptionExpanded}
                  setDescriptionExpanded={setDescriptionExpanded}
                />
              )}

              {name === 'Neck' && (
                <>
                  <CustomTextField
                    setYouTubeUrl={setYouTubeUrl}
                    label={'Neck Front'}
                    showName={true}
                    size={'small'}
                    Form={Form}
                    autof={true}
                    enterpressed={enterpressed}
                    name={'Neck_Front'}
                    values={value?.Neck_Front}
                    description={measurementDescription['Neck_Front']}
                    video={video}
                    AllDone={AllDone}
                    AllValues={AllValues}
                    UploadMeasurement={UploadMeasurement}
                    SetIt={SetIt}
                    error={error}
                    descriptionExpanded={descriptionExpanded}
                    setDescriptionExpanded={setDescriptionExpanded}
                  />
                  <div>
                    {AllDone === true ? (
                      <Link
                        style={{
                          color: '#fff',
                        }}
                      >
                        <Button
                          className={styles.submitBtn}
                          // sx={{
                          //   display: Open ? 'flex' : 'none',
                          // }}
                          onClick={
                            AllDone === true && AllValues
                              ? UploadMeasurement
                              : SetIt
                          }
                        >
                          {AllDone === true
                            ? 'Save'
                            : 'Please Fill all the fields'}
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        className={styles.submitBtn}
                        onClick={AllDone === true ? UploadMeasurement : SetIt}
                      >
                        {AllDone || 'Next'}
                        {(AllDone === 'Next' || !AllDone) && (
                          <svg
                            width='16'
                            height='17'
                            viewBox='0 0 16 17'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M6 12.6528L10 8.65283L6 4.65283'
                              stroke='white'
                              strokeWidth='1.33333'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        )}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {body !== 'men' && (
            <>
              {name === 'Waist' && (
                <div
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  <CustomTextField
                    setYouTubeUrl={setYouTubeUrl}
                    label={'High Waist'}
                    showName={true}
                    size={'small'}
                    Form={Form}
                    autof={true}
                    enterpressed={enterpressed}
                    name={'High_Waist'}
                    values={value?.High_Waist}
                    video={video}
                    AllDone={AllDone}
                    AllValues={AllValues}
                    description={measurementDescription['High_Waist']}
                    UploadMeasurement={UploadMeasurement}
                    SetIt={SetIt}
                    error={error}
                    descriptionExpanded={descriptionExpanded}
                    setDescriptionExpanded={setDescriptionExpanded}
                  />
                </div>
              )}
              {name === 'Waist' && (
                <CustomTextField
                  setYouTubeUrl={setYouTubeUrl}
                  label={'Mid Waist'}
                  showName={true}
                  size={'small'}
                  Form={Form}
                  autof={true}
                  enterpressed={enterpressed}
                  name={'Mid_Waist'}
                  values={value?.Mid_Waist}
                  description={measurementDescription['Mid_Waist']}
                  video={video}
                  error={error}
                  descriptionExpanded={descriptionExpanded}
                  setDescriptionExpanded={setDescriptionExpanded}
                />
              )}
              {name === 'Waist' && (
                <>
                  <CustomTextField
                    setYouTubeUrl={setYouTubeUrl}
                    label={'Low Waist'}
                    showName={true}
                    size={'small'}
                    Form={Form}
                    enterpressed={enterpressed}
                    autof={true}
                    video={video}
                    name={'Low_Waist'}
                    description={measurementDescription['Low_Waist']}
                    values={value?.Low_Waist}
                    error={error}
                    descriptionExpanded={descriptionExpanded}
                    setDescriptionExpanded={setDescriptionExpanded}
                  />
                  <div>
                    {AllDone === true ? (
                      <Link
                        style={{
                          color: '#fff',
                        }}
                      >
                        <Button
                          className={styles.submitBtn}
                          // sx={{
                          //   display: Open ? 'flex' : 'none',
                          // }}
                          onClick={
                            AllDone === true && AllValues
                              ? UploadMeasurement
                              : SetIt
                          }
                        >
                          {AllDone === true
                            ? 'Save'
                            : 'Please Fill all the fields'}
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        className={styles.submitBtn}
                        onClick={AllDone === true ? UploadMeasurement : SetIt}
                      >
                        {AllDone || 'Next'}
                        {(AllDone === 'Next' || !AllDone) && (
                          <svg
                            width='16'
                            height='17'
                            viewBox='0 0 16 17'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M6 12.6528L10 8.65283L6 4.65283'
                              stroke='white'
                              strokeWidth='1.33333'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        )}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MeasurementFloating;
