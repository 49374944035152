
import moment from 'moment'


export const isAvaliableForReturn = (i, ddate, reShippingRequested) => {
  if (
    parseInt(i.returnDays) > 0 &&
    moment(moment(ddate).add(parseInt(i.returnDays), "days")).diff(
      moment(),
      "seconds"
    ) > 0 &&
    i.canReturn &&
    i.status === 2
  ) {
    return true;
  } else return false;
};

export const isAvaliableForReturnButton = (
  items,
  ddate,
  reShippingRequested
) => {
  let isAvaliable = false;
  items.forEach((i) => {
    if (isAvaliableForReturn(i, ddate)) {
      isAvaliable = true;
    }
  });

  return isAvaliable;
};

export const isAvaliableForExchnage = (i, ddate, reShippingRequested) => {
  if (
    parseInt(i.exchangeDays) > 0 &&
    moment(moment(ddate).add(parseInt(i.exchangeDays), "days")).diff(
      moment(),
      "seconds"
    ) > 0 &&
    i.status !== 4 &&
    i.status !== 5 &&
    !i.exchange
  ) {
    return true;
  } else return false;
};


export const isAvaliableForAlteration = (i, ddate, reShippingRequested) => {
  console.log(parseInt(i.alterationDays) > 0, moment(moment(ddate).add(parseInt(i.alterationDays), 'days')).diff(moment(), 'seconds'), i.status)
  if (parseInt(i.alterationDays) > 0 &&
    moment(moment(ddate).add(parseInt(i.alterationDays), 'days'))
      .diff(moment(), 'seconds') > 0 && i.status !== 3 && !i.alteration) {

    return true
  }
  else return false
}

export const isAvaliableForExchangeButton = (items, ddate, reShippingRequested) => {
  let isAvaliable = false
  items.forEach(i => {
    if (isAvaliableForExchnage(i, ddate)) {
      isAvaliable = true
    }
  })
  return isAvaliable;
}

export const isAvaliableForAlterationButton = (items, ddate, reShippingRequested) => {

  let isAvaliable = false
  items.forEach(i => {
    if (isAvaliableForAlteration(i, ddate)) {
      isAvaliable = true
    }
  })
  return isAvaliable;

}



export const isExchangeDaysAvaliable = (exchangeDays, ddate, isReadyMade, data) => {
  if (exchangeDays && ddate && isReadyMade === "readymade" && data?.status === 2 &&
    !data?.exchange && !data?.return) {
    if (parseInt(exchangeDays) > 0 &&
      moment(moment(ddate).add(parseInt(exchangeDays), 'days')).diff(moment(), 'seconds') > 0) {
      return true
    }
    else return false
  }
}

export const isReturnDaysAvaliable = (returnDays, ddate, isReadyMade, data) => {

  if (returnDays && ddate && isReadyMade === "readymade" && data?.status === 2 && !data?.return) {
    if (parseInt(returnDays) > 0 &&
      moment(moment(ddate).add(parseInt(returnDays), 'days')).diff(moment(), 'seconds') > 0) {
      return true
    }
    else return false
  }
}

export const isAlterationDaysAvaliable = (alterationDay, ddate, isReadyMade, data) => {

  if (alterationDay && ddate && isReadyMade !== "readymade" && data?.status === 2 && !data?.alteration) {
    if (parseInt(alterationDay) > 0 &&
      moment(moment(ddate).add(parseInt(alterationDay), 'days')).diff(moment(), 'seconds') > 0) {
      return true
    }
    else return false
  }
}



