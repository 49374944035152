import { Breadcrumbs, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { MOBILE_WIDTH } from '../../../constants/breakPoints';
import styles from './breadCrumbs.module.scss';

const BreadCrumbs = ({ paths, currentPath }) => {
  const mobile = useMediaQuery(MOBILE_WIDTH);
  return (
    <>
      {!mobile && (
        <div className={styles.breadCrumbs}>
          <Breadcrumbs aria-label='breadcrumb'>
            {paths.map((path, i) => {
              return (
                <Link
                  to={path.to}
                  key={i}
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  {path.name}
                </Link>
              );
            })}

            <Typography
              color='text.primary'
              style={{
                textTransform: 'capitalize',
              }}
            >
              {currentPath}
            </Typography>
          </Breadcrumbs>
        </div>
      )}
    </>
  );
};

export default BreadCrumbs;
