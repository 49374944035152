import React, { useState } from 'react';
import styles from './verify.module.scss';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoginButton from '../LoginButton';
import * as Yup from 'yup';
import Input from '../../../Common/Form/Input';
import {
  resendOtp,
  setuser,
  Signinwithphone,
  verifyotp,
} from '../../../../redux/slices/userslice';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Countdown from 'react-countdown';
import { notify } from '../../../../redux/slices/ui';
import { style } from '@mui/system';
import { IconButton } from '@mui/material';
import { MdOutlineArrowBack } from 'react-icons/md';
import ModalComponent from '../../../Modal/ModalComponent';
import { useEffect } from 'react';
import { useRef } from 'react';

const Verify = ({ number }) => {
  const { otpveryingstate } = useSelector(state => state.user);

  const initialValues = {
    otp: '',
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verifynumber, setverfynum] = useState(true);
  const validationSchema = Yup.object({
    otp: Yup.number().min(6).required('OTP is Required'),
  });
  const [modal, setmodal] = useState(false);
  const handleSubmit = values => {
    dispatch(verifyotp(values.otp.toString()));
  };
  const toggleModal = () => {
    setmodal(false);
  };

  return (
    <div className={styles.verfiyContainer}>
      <div>
        <h1>OTP verification</h1>
        <p>
          Please enter the OTP sent to <span>+91{number}</span>
        </p>
      </div>
      <div>
        {verifynumber && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {formik => {
              const { values, isValid, setTouched, submitCount } = formik;

              return (
                <Form className={styles.formControl}>
                  <div className={styles.formGroup}>
                    <Input
                      type='number'
                      id='otp'
                      name='otp'
                      label={'Enter OTP'}
                    />
                  </div>

                  <div>
                    <LoginButton type='submit' disabled={verifynumber}>
                      Verify
                    </LoginButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}

        {!verifynumber && (
          <div
            style={{
              marginTop: '20px',
            }}
          >
            <LoginButton
              disabled={false}
              onClick={() => {
                dispatch(resendOtp(number));
                setverfynum(true);
              }}
            >
              Resend Otp
            </LoginButton>
          </div>
        )}
        {verifynumber && (
          <div className={styles.verifyFooter}>
            <h4>
              Didn’t recieve code?
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(resendOtp(number));
                }}
              >
                &nbsp; Re-send OTP
              </span>
            </h4>
            <p
              style={{
                textAlign: 'center',
              }}
            >
              Try to fill within
              <span>
                <Countdown
                  date={Date.now() + 60000}
                  renderer={props => {
                    const { minutes, seconds } = props;

                    return (
                      <>
                        &nbsp; 0{minutes} :{' '}
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </>
                    );
                  }}
                  onComplete={() => {
                    dispatch(
                      notify({
                        type: 'error',
                        message: 'Opt Expires Please Resend Otp',
                        status: true,
                      })
                    );
                    setverfynum(false);
                  }}
                />
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Verify;
