import React from 'react';
import bg from './images/bg.png';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image4 from './images/4.png';
import SwiperComponent from '../swiper';
import { SwiperSlide } from 'swiper/react';
import styles from './celebrity-fashion.module.scss';
import { useMediaQuery } from '@mui/material';
import { MOBILE_WIDTH } from '../../../constants/breakPoints';
import { Link } from 'react-router-dom';

const images = [
  {
    id: 1,
    name: 'Bollywood glam',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fbollywood-glam.jpeg?alt=media&token=21f1f486-1e33-4038-8182-6e41d2a31b58',
    href: '/collection/women/bollywood-glam',
  },
  {
    id: 2,
    name: 'Movie inspiration',
    image: image2,
    href: '/collection/women/movie-inspiration',
  },
  {
    id: 3,
    name: 'Bridal glam - celebs looks',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fceleb-bridal-look.webp?alt=media&token=6d90b41a-af9d-4fb9-9147-366ed9a3a6de',
    href: '/collection/women/bridal-glam-celebs-looks',
  },
  {
    id: 4,
    name: 'Celebrity Ethnic',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fethnic.webp?alt=media&token=fbb0c6db-9e76-4051-a549-bc9380ec33ce',
    href: '/collection/women/celebrity-ethnic',
  },
];

const CelebrityFashion = () => {
  const isMobile = useMediaQuery(MOBILE_WIDTH);

  return (
    <div
      className={styles.celebrity}
      style={{
        background: '#F0DED2',
        padding: '20px 1.5rem',
      }}
    >
      <div
        className={styles.container}
        style={{
          position: 'relative',
        }}
      >
        <div className={styles.titleSM}>
          <h1>Celebrity Fashion</h1>
          <p>
            Get a chance to wear the fashion worn by your favourite celebrities
            and flaunt it in your style
          </p>
        </div>
        <div className={styles.celebrity__container}>
          <div className={styles.title}>
            <h1>Celebrity Fashion</h1>
            <p>
              Get a chance to wear the fashion worn by your favourite
              celebrities and flaunt it in your style
            </p>
          </div>
          <SwiperComponent sellAllHref='/products/new-arrivals'>
            {images.map((item, idx) => {
              return (
                <SwiperSlide
                  key={idx}
                  style={{
                    padding: isMobile ? '5px' : '10px',
                    minHeight: isMobile ? 'auto' : '413px',
                  }}
                >
                  <div className={styles.sliderItem}>
                    <Link to={item.href}>
                      <img
                        src={item.image}
                        alt=''
                        style={{
                          maxHeight: isMobile ? '300px' : '413px',
                          height: '100%',
                          minHeight: isMobile ? '300px' : '413px',
                          objectFit: 'cover',
                          objectPosition: 'top center',
                        }}
                      />
                      <p>{item.name}</p>
                    </Link>
                  </div>

                  {/* <ItemCard
                    link={item.lynk ? item.lynk : ''}
                    designerName={
                      item?.businessname || item?.vendordetails?.businessName
                    }
                    totalProducts={item.items.length}
                    measurementRequired={item.measurementRequired}
                    date={moment(item?.createdDate).format('DD-MM-YYYY')}
                    price={item.price ? item.price : ''}
                    id={item.id}
                    product={item.items}
                    to={`/products/${item.businessname}/${item.id}`}
                    products={item.items}
                    vendorInfo={item.vendordetails}
                    item={item}
                  /> */}
                </SwiperSlide>
              );
            })}
          </SwiperComponent>
        </div>
      </div>
    </div>
  );
};

export default CelebrityFashion;
