export const getStatus = (item) => {

    switch (item?.status) {
        case 0:
            return 'Processing'
        case 1:
            return 'Shipped'
        case 2:
            return 'Delivered'
        case 3:
            switch (item?.alteration?.status) {
                case 0:
                    return 'Alteration Requested'
                case 1:
                    return 'Alteration Accepted'
                case 2:
                    return 'Alteration Rejected'
                default:
                    return 'Alteration Completed'
            }
        case 4:
            switch (item?.exchange?.status) {
                case 0:
                    return 'Exchange Requested'
                case 1:
                    return 'Exchange Accepted'
                case 2:
                    return 'Exchange Rejected'
                default:
                    return 'Exchange Completed'
            }
        case 5:
            switch (item?.return?.status) {
                case 0:
                    return 'Return Requested'
                case 1:
                    return 'Return Accepted'
                case 2:
                    return 'Return Rejected'
                default:
                    return 'Return Completed'
            }
        default:
            return 'New'
    }

}



export const lynkStatus = (item) => {

    switch (item?.status) {
        case 0:
            return 'New Lynk'
        case 1:
            return 'Payment Required'
        case 2:
            return 'Measurement Required'
        case 3:
            return 'Completed'
        default:
            return 'Pending'
    }
}


export const getOrderStatus = (item) => {

    switch (item?.status) {
        case 3:
            return 'New Order'
        case 4:
            return 'Delivered'
        case 5:
            return 'Alteration requested'
        case 6:
            return 'Exchange requested'
        case 7:
            return 'Ongoing'
        default:
            return 'Pending'
    }
}

