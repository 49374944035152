import { createSlice } from '@reduxjs/toolkit';
import firebase from '../../firebase/firebase';
import { notify, startLoading, startmodal } from './ui';

const initialState = {
  requestOrderVendor: {},
  AllrequestOrder: [],
  singleorder: {},
  greenlight: false,
};

const slice = createSlice({
  name: 'lynks',
  initialState,
  reducers: {
    requestOrderVendor(state, action) {
      state.requestOrderVendor = action.payload;
    },
    AllrequestedOrder(state, action) {
      state.AllrequestOrder = action.payload;
    },
    singleorder(state, action) {
      state.singleorder = action.payload;
    },
    greenlightr(state, action) {
      state.greenlight = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const setuser = user => {
  return dispatch => {
    dispatch(slice.actions.userdetails(user));
  };
};

export const getRequestOrderVendor = id => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));

    //const uid = state.user.user.uid;
    firebase
      .firestore()
      .collection('vendors')
      .doc(id)
      .get()
      .then(res => {
        const data = res.data();
        dispatch(startLoading(false));
        dispatch(slice.actions.requestOrderVendor(data));
      })
      .catch(err => {
        dispatch(startLoading(false));
      });
  };
};

export const getAllrequestedOrder = () => {
  return async (dispatch, getstate) => {
    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection('requestorder')
        .where('user', '==', uid)
        .where('status', '==', 0)
        .onSnapshot(async querySnapshot => {
          const data = querySnapshot.docs.map(doc => doc.data());
          const ids = querySnapshot.docs.map(doc => doc.id);

          const finalo = ids.map((it, index) => {
            return { ...data[index], id: it };
          });
          const vendordata = data.map(async item => {
            return await item.vendordetails.get();
          });
          await Promise.all(vendordata).then(res => {
            let finalvendor = res.map(item => {
              return item.data();
            });
            let newfinalo = finalo.map((itemo, index) => {
              return { ...itemo, vendordetails: finalvendor[index] };
            });

            const greenlight = sessionStorage.getItem('rordergreen');
            if (!greenlight) {
              sessionStorage.setItem('rordergreen', newfinalo?.length);
            } else if (greenlight === newfinalo?.length) {
              dispatch(slice.actions.greenlightr(false));
            } else if (greenlight < newfinalo?.length) {
              dispatch(slice.actions.greenlightr(true));
            }

            dispatch(slice.actions.AllrequestedOrder(newfinalo));
          });

          dispatch(startLoading(false));
        });
    }
  };
};

export const createOrderRequest = (data, id, userdetail, bussinessName) => {
  return (dispatch, getstate) => {
    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection('requestorder')
        .add({
          items: data,
          user: uid,
          vendor: id,
          userdetail,
          status: 0,
          bussinessName: bussinessName || '',
          vendordetails: firebase.firestore().collection('vendors').doc(id),
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          dispatch(
            startmodal({
              type: 'success',
              message: 'Order request sent!',
              description:
                'You will recieve order lynk once seller accepts your request',
              status: true,
            })
          );
          // setTimeout(() => {
          //   dispatch(startmodal({ status: false }));
          // }, 2000);
          dispatch(startLoading(false));
        })
        .catch(() => {
          dispatch(startLoading(false));
          dispatch(
            notify({
              status: true,
              message: 'something went wrong',
              type: 'error',
            })
          );
        });
    }
  };
};

export const requestedOrderdetail = id => {
  return (dispatch, getstate) => {
    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection('requestorder')
        .doc(id)
        .get()
        .then(querySnapshot => {
          const data = querySnapshot.data();

          dispatch(slice.actions.singleorder(data));
          dispatch(startLoading(false));
        })
        .catch(() => {
          dispatch(startLoading(false));
          dispatch(
            notify({
              status: true,
              message: 'something went wrong',
              type: 'error',
            })
          );
        });
    }
  };
};

export const setrordergreenlight = (ff, count) => {
  return dispatch => {
    dispatch(slice.actions.greenlightr(ff));
    sessionStorage.setItem('rordergreen', count);
  };
};
