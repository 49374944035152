import React from 'react';
import { HeadProvider, Title, Link, Meta } from 'react-head';

export default function SEO({
  title = 'Hauteze - Social buying and selling made easy',
  description = 'Social buying and selling made easy',
  author = 'LynkTown',
  meta,
}) {
  const metaData = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ];

  return (
    <HeadProvider>
      <Title>{title}</Title>
      <Link rel='canonical' href='https://app.lynktown.com/' />
      {metaData.map((data, i) => {
        const { content, name } = data;
        return <Meta key={i} name={name} content={content} />;
      })}
    </HeadProvider>
  );
}
