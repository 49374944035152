import Header from '../../../Components/Common/Container/Header/Header';
import { useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import TwinningCollection from '../../../Components/Home/twinning-collection';
import TwinningImg from './images/tw1.png';

import CategoriesForYou from '../../../Components/Home/Categories-for-you';

import TopDesigners from '../../../Components/Home/top-designers';
import designer1 from './images/designers/d1.png';
import designer2 from './images/designers/d2.png';
import designer3 from './images/designers/d3.png';
import designer4 from './images/designers/d4.png';
import CategoryProductCard from '../../../Components/Home/CategoryProductCard';
import product1 from './images/products/p1.png';
import carousel1 from './images/carousel/1.png';
import carousel2 from './images/carousel/2.png';
import carousel3 from './images/carousel/3.png';
import carousel3SM from './images/carousel/3sm.jpg';

import BreadCrumbs from '../../../Components/Common/BreadCrumbs/BreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLatestLynksAccToCategory,
  getLynkWithMostView,
} from '../../../redux/slices/category';
import SEO from '../../../Components/Common/SEO';
import { Link } from 'react-router-dom';

const products = [
  {
    id: 1,
    name: 'Varun Bahl Men',
    image: product1,
    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 2,
    name: 'Varun Bahl Men',
    image: product1,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 3,
    name: 'Varun Bahl Men',
    image: product1,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 4,
    name: 'Varun Bahl Men',
    image: product1,

    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
];

const landingCarousel = [
  // {
  //   id: 1,
  //   href: '/products/new-arrivals',
  //   image: carousel1,
  // },
  // {
  //   id: 2,
  //   href: '/products/custom-made',
  //   image: carousel2,
  // },
  {
    id: 3,
    href:
      '/collection/women/' + encodeURIComponent('upto-30%-off') + '?value=30',
    image: carousel3,
    imageSM: carousel3SM,
  },
];

const categoryForYou = [
  {
    id: 1,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Ffor-bride.png?alt=media&token=b9d2653d-7467-4c18-b352-8bac1a04e5ec',
    name: 'For Bride',
    href: '/collection/women/for-bride',
  },
  {
    id: 2,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fchristmas.png?alt=media&token=b678d390-f15b-4bdf-bc25-76d43e8ab807',
    name: 'Christmas',
    href: '/collection/women/christmas',
  },
  {
    id: 3,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fsankranti%20(1).png?alt=media&token=8e088551-ca29-4fdb-9f84-3fec48212e7f',
    name: 'Makar Sankranti',
    href: '/collection/women/makar-sankranti',
  },

  {
    id: 5,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fparty%20.png?alt=media&token=1c287258-e31a-4f7b-a40b-2b7996cafbf3',
    name: 'Party',
    href: '/collection/women/party',
  },
  {
    id: 6,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Findo-western.png?alt=media&token=65e98000-4a86-4219-9a96-b7c2e20ab223',
    name: 'Indo western',
    href: '/collection/women/indo-western',
  },
  {
    id: 7,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fco-ord-set.jpg?alt=media&token=f71947ed-e0f3-41a9-b8bc-54f1686a19b2',
    name: 'Co-ord sets',
    href: '/collection/women/co-ord-sets',
  },
  {
    id: 8,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fgown.png?alt=media&token=fd679836-7b7c-4c78-89f8-dc467f74fb6e',
    name: 'Gown',
    href: '/collection/women/gown',
  },
  {
    id: 9,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fsaree.png?alt=media&token=3ce4621e-b031-4810-86a4-f020be6bdbdf',
    name: 'Sarees',
    href: '/collection/women/sarees',
  },
];

const topDesigners = [
  {
    id: 1,

    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_8885f921-ad8d-4e24-bab0-bbde961c5609.jpg?alt=media',
    href: 'Chajed_neha',
    name: 'Neha',
  },
  {
    id: 2,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_7239fb73-aa50-4962-87e5-52f98034383f.jpg?alt=media',
    href: 'akruthi_designer_wear',
    name: 'Akruthi Creations',
  },
  {
    id: 3,

    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_a0226973-b89f-419b-add3-fcf88655cbef.jpg?alt=media',
    href: 'lana_designer_boutique',
    name: 'Lana designer boutique',
  },

  {
    id: 4,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_911cf9bd-4823-44a0-9bbb-5de79c26ea98.jpg?alt=media',
    href: 'blushboutique1589',
    name: `Blush Boutique`,
  },
];

const WomenCategoryPage = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { maxViews, zeroViews } = useSelector(state => state.category);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLynkWithMostView('women'));
    dispatch(getLatestLynksAccToCategory('women'));
  }, [dispatch]);
  return (
    <>
      <SEO
        title='Designer Dresses for Women-Buy Latest Collection of Designer Dresses '
        description='Buy the Latest Designer Dresses for women online. Browse through our extensive collection of dresses, wedding outfits, tops, sarees, Lenghas, Anarkalis, Kurtas, and more.'
      />

      <Header />

      <main>
        <section className='container'>
          <Swiper
            pagination={{
              clickable: true,
            }}
            // modules={[Pagination]}
            spaceBetween={25}
          >
            {landingCarousel.map(item => {
              const { href, id, image, imageSM } = item;
              return (
                <SwiperSlide key={id}>
                  <Link
                    to={href}
                    style={{
                      width: '100%',
                      background: '#EAEAEA',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={image}
                      alt='Banner'
                      style={{
                        minHeight: '200px',
                        display: isMobile ? 'none' : 'block',
                        borderRadius: '8px',
                      }}
                    />
                    <img
                      alt='Banner'
                      src={imageSM}
                      style={{
                        minHeight: '200px',
                        maxHeight: '500px',
                        display: isMobile ? 'block' : 'none',
                      }}
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <BreadCrumbs
            currentPath={'Women'}
            paths={[{ name: 'Home', to: '/' }]}
          />
        </section>
        <CategoryProductCard
          title='Trending Now'
          products={maxViews}
          showAll='/collection/women/trending-now'
        />
        <TopDesigners topDesigners={topDesigners} />
        <TwinningCollection
          title={
            <>
              Subtleness <br /> by Manish Bhel
            </>
          }
          description='Explore the new collection by famous designer Manish Bhel'
          image={TwinningImg}
        />
        <CategoryProductCard
          title={'New Arrivals'}
          products={zeroViews}
          showAll='/collection/women/new-arrivals'
        />

        <CategoriesForYou products={categoryForYou} />
      </main>
    </>
  );
};

export default WomenCategoryPage;
