import { useMediaQuery } from '@mui/material';
import Main from './images/main.svg';
import Back from './images/back.svg';
import Front from './images/front.svg';
import { ReactComponent as Edit } from './images/edit.svg';
import styles from './UploadImage.module.scss';

const EditUploadImage = () => {
  const mobileView = useMediaQuery('(max-width:550px)');

  return (
    <section className={styles.section}>
      <div style={{ padding: mobileView && '0 1rem', marginTop: '2rem' }}></div>
      <div className={styles.main}>
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={Main} alt='' className={styles.mainImg} />
          <h1>Edit Images</h1>
        </div>
        <p>
          Upload your images for better understanding to designer & for a right
          fit product.
        </p>
        <div className={styles.content}>
          <div className={styles.front}>
            <div className={styles.edit}>
              <Edit />
            </div>
            <img src={Front} alt='' />
            <input
              type='file'
              accept='image/*'
              style={{
                display: 'none',
              }}
            />
            <p style={{ textAlign: 'center' }}>Front</p>
          </div>
          <div className={styles.back}>
            <div className={styles.edit}>
              <Edit />
            </div>
            <img src={Back} alt='' />
            <input
              type='file'
              accept='image/*'
              style={{
                display: 'none',
              }}
            />
            <p style={{ textAlign: 'center' }}>Back</p>
          </div>
        </div>
        {<button className={styles.btn}>Add</button>}
      </div>
    </section>
  );
};

export default EditUploadImage;
