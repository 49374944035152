import { ErrorMessage, useField } from 'formik';
import React from 'react';
import ErrorBox from './ErrorBox';
import styles from './input.module.scss';

const Input = ({
  label,
  inline,
  type,
  classes,
  from,
  placeHolder,
  inputRef,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type });
  return (
    <div>
      <>
        <div
          className={styles.floating_label_group}
          style={{
            marginBottom: from === 'login' && '5px',
          }}
        >
          <input
            ref={inputRef}
            className={`${styles.inputField}`}
            {...field}
            {...props}
            placeholder={placeHolder || label}
            type={type}
            style={{
              border: meta.touched && meta.error ? '1px solid red' : '',
            }}
          />
          <label className={styles.floating_label}>{label}</label>

          <ErrorMessage name={field.name} component={ErrorBox} />
        </div>
      </>
    </div>
  );
};

export default Input;
