import { Button } from '@mui/material';
import React from 'react';
import styles from './loginButton.module.scss';

const LoginButton = ({ children, onClick, type = 'button' }) => {
  return (
    <div className={styles.loginButton}>
      <Button className={styles.login__btn} type={type} onClick={onClick}>
        {children}
      </Button>
      <div id='sign-in-button'></div>
    </div>
  );
};

export default LoginButton;
