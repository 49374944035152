import { IconButton, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import ModalComponent from '../Modal/ModalComponent';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Invalid } from './invalid.svg';
import styles from './InvalidLynkModal.module.scss';

const InvalidLynkModal = ({ openmodal }) => {
  const [openModal, setmodal] = useState(openmodal);
  const mobile = useMediaQuery(MOBILE_WIDTH);
  const navigate = useNavigate();
  const toggleModal = () => {
    setmodal(false);
    navigate('/');
  };
  return (
    <ModalComponent
      open={true}
      handleClose={toggleModal}
      height={mobile ? '221px' : '300px'}
      width='400px'
      // top='50%'
    >
      <div className={styles.modalContainer}>
        <IconButton className={styles.close} onClick={toggleModal}>
          <Close />
        </IconButton>

        <Invalid />
        <h1>This Lynk is Invalid </h1>
        <p>Please contact seller to get a valid link </p>
      </div>
    </ModalComponent>
  );
};

export default InvalidLynkModal;
