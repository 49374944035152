import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';
import { HiOutlineMinusSm, HiOutlinePlusSm } from 'react-icons/hi';
import styles from './InfoAccordion.module.scss';

const InfoAccordion = ({ expanded, title, description, handleChange }) => {
  return (
    <Accordion expanded={expanded === title} onChange={handleChange(title)}>
      <AccordionSummary
        expandIcon={
          expanded === title ? (
            <HiOutlineMinusSm size={15} />
          ) : (
            <HiOutlinePlusSm size={15} />
          )
        }
        style={{
          padding: '0 5px',
        }}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
      >
        <Typography
          className={styles.title}
          style={{
            padding: 0,
            margin: '10px 0',
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          className={styles.description}
          style={{
            padding: 0,
            margin: '5px 0',
            color: '#000000c9',
            fontSize: '14px',
          }}
        >
          {description}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default InfoAccordion;
