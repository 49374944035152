import React from 'react';
import styles from './categories.module.scss';
import { Link } from 'react-router-dom';

const CategoriesForYou = ({ products }) => {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>Categories for you</h1>
      <div className={styles.products}>
        {products.map(product => {
          const { id, name, image, href } = product;
          return (
            <div key={id}>
              <Link to={href}>
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <img src={image} alt='' />
                  <div className={styles.shadow}></div>
                  <h1>{name}</h1>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CategoriesForYou;
