import React from 'react';
import Body from './body.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const Details = ({ styles, mea, image, imageback }) => {
  return (
    <div className={styles.details}>
      <div>
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          className={`${styles.details__swiper} viewmeasurement-swiper-mobile`}
        >
          <SwiperSlide>
            <Zoom>
              <img src={image} alt='' />
            </Zoom>
          </SwiperSlide>
          <SwiperSlide>
            <Zoom>
              <img src={imageback} alt='' />
            </Zoom>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={styles.details__info}>
        <div>
          <h1>Name:</h1>
          <h3></h3>
        </div>
        <div>
          <h1>Gender:</h1>
          <h3>{mea.gender}</h3>
        </div>
        <div>
          <h1>Standard Size:</h1>
          <h3>{mea.size}</h3>
        </div>
        <div>
          <h1>Fitting:</h1>
          <h3>{mea.fitting}</h3>
        </div>
      </div>
    </div>
  );
};

export default Details;
