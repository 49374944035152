import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import Input from '../../../../Components/Common/Form/Input';
import { setKey, setLanguage, fromLatLng } from 'react-geocode';
const AddressForm = ({
  handleSubmit,
  initialValues,
  validationSchema,
  styles,
  btntext,
}) => {
  setKey('AIzaSyA_nmZVriBFLHl4ZdmN7d_WVr9PEH2sZa4');
  setLanguage('en');

  const getlocation = formik => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log('Latitude is :', position.coords.latitude);
        console.log('Longitude is :', position.coords.longitude);

        fromLatLng(position.coords.latitude, position.coords.longitude).then(
          response => {
            let address = response.results[0].formatted_address;
            let city, state, country, pincode;
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                switch (response.results[0].address_components[i].types[j]) {
                  case 'sublocality':
                    address =
                      response.results[0].address_components[i].long_name;
                    break;
                  case 'locality':
                    city = response.results[0].address_components[i].long_name;
                    break;
                  case 'administrative_area_level_1':
                    state = response.results[0].address_components[i].long_name;
                    break;
                  case 'country':
                    country =
                      response.results[0].address_components[i].long_name;
                    break;
                  case 'postal_code':
                    pincode =
                      response.results[0].address_components[i].long_name;
                    break;
                  default:
                    break;
                }
              }
            }
            console.log(city, state, country, pincode);
            formik.setFieldValue('city', city);
            formik.setFieldValue('state', state);
            formik.setFieldValue('pincode', pincode);
            formik.setFieldValue('address', address);
            console.log(address);
          },
          error => {
            console.error(error);
          }
        );
      },
      err => {
        console.log(err);
      },
      {
        enableHighAccuracy: true,
      }
    );
  };
  console.log(initialValues);
  return (
    <div className={styles.lynksDetailForm}>
      <h1>Add below details for shipping purpose</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {formik => {
          return (
            <Form>
              <div className={styles.form}>
                <div className={styles.formControl}>
                  <Input label='Enter full name' name='name' id='name' />
                </div>
                <div className={styles.formControl}>
                  <Input label='Enter Email id' name='email' id='email' />
                </div>
                <div className={styles.formControl}>
                  <Input
                    label='Enter Phone Number'
                    name='phoneno'
                    id='phoneno'
                  />
                </div>
              </div>
              <h1>Shipping Address</h1>
              <div className={styles.formLocation}>
                <Button onClick={() => getlocation(formik)}>
                  <HiOutlineLocationMarker size={18} /> &nbsp; Use current
                  location
                </Button>
              </div>
              <div className={styles.form}>
                <div className={styles.formControl}>
                  <Input
                    label='Door/House number/floor'
                    name='doorNo'
                    id='doorNo'
                  />
                </div>
                <div className={styles.formControl}>
                  <Input label='Address' name='address' id='address' />
                </div>
              </div>
              <div className={styles.form}>
                <div className={styles.formControl}>
                  <Input label='City' name='city' id='city' />
                </div>
                <div className={styles.formControl}>
                  <Input label='Pincode' name='pincode' id='pincode' />
                </div>
              </div>
              <div className={styles.form}>
                <div className={styles.formControl}>
                  <Input label='State' name='state' id='state' />
                </div>

                <div className={styles.formControl}>
                  <Input label='Locality' name='locality' id='locality' />
                </div>
              </div>
              <div className={styles.formBtn}>
                <Button type='submit'>{btntext}</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddressForm;
