import React, { useEffect, useState } from 'react';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import ProductCard from '../../Components/ProductCard/ProductCard';
import styles from './orderDetails.module.scss';
import { ReactComponent as Call } from './images/call.svg';
import { ReactComponent as Whatsapp } from './images/whatsapp.svg';
import { Button, IconButton, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import MyImage from '../../Components/Common/ImageLoader';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import Zoom from 'react-medium-image-zoom';

import Pdf from 'react-to-pdf';
import {
  alterationrequest,
  cancelalteration,
  exchangerequest,
  getOrderDetails,
  returnrequest,
} from '../../redux/slices/orders';
import moment from 'moment';
import Invoice from '../../Components/Invoice/Invoice';
import ModalComponent from '../../Components/Modal/ModalComponent';
import Countdown from 'react-countdown';
import { imageurl } from '../../config';
import { getStatus } from '../../utils/statusfinder';
import {
  isAvaliableForAlteration,
  isAvaliableForAlterationButton,
  isAvaliableForExchangeButton,
  isAvaliableForExchnage,
  isAvaliableForReturn,
  isAvaliableForReturnButton,
} from '../../utils/isAvaliableFor';

const OrderDetails = () => {
  const user = useSelector(state => state.user);
  const tab = useMediaQuery('(max-width:760px)');
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    if (user.user) {
      dispatch(getOrderDetails(id));
    }
  }, [user?.user]);

  const order = useSelector(state => state.orders);
  const ref = React.createRef();
  const [p, setp] = useState();
  const [alterationModal, setAlterationModal] = useState(false);
  const [exchangeModal, setexchangeModal] = useState(false);
  const [returnModal, setreturnModal] = useState(false);
  const [alterproduct, setalteredproduct] = useState([]);
  const [exchangeproduct, setexchangeproduct] = useState([]);
  const [returnproduct, setreturnproduct] = useState([]);
  const ddate = order.order?.ddate?.toDate();
  const handleAlternateModal = () => {
    setAlterationModal(!alterationModal);
  };

  const handleExchangeModal = () => {
    setexchangeModal(!exchangeModal);
  };

  const handleReturnModal = () => {
    setreturnModal(!returnModal);
  };

  const navigate = useNavigate();
  console.log(alterproduct);

  const oncancelfunction = itemid => {
    dispatch(cancelalteration(id, itemid));
  };

  return (
    <>
      <div style={{ overflowX: 'hidden' }}>
        <div>
          <BreadCrumbs
            currentPath='Order Details'
            paths={[
              { name: 'Home', to: '/' },
              { name: 'Orders', to: '/orders' },
            ]}
          />
          <div
            style={{
              marginLeft: '2rem',
            }}
          >
            <SectionNavigation back={'-1'} />
          </div>
          <div className={styles.orderDetailsMain}>
            <div className={styles.orderDetails}>
              <div className={styles.orderDetails__header}>
                <h1>Order details</h1>
              </div>
              <div className={styles.orderDetails__body}>
                <div className={styles.orderDetails__body__items}>
                  {order.order?.items?.map((item, index) => {
                    return (
                      <ProductCard
                        key={index}
                        onAlterationCancel={oncancelfunction}
                        readymadesize={
                          order?.order?.selected_size &&
                          order?.order?.selected_size[index]?.id === item?.id
                            ? order?.order?.selected_size[index]?.size
                            : null
                        }
                        productType={
                          order.order.selected_size ? 'readymade' : ''
                        }
                        data={item}
                        status={getStatus(item)}
                        vendor={order?.order?.vendor}
                        from='orderDetails'
                        ddate={order.order?.ddate?.toDate()}
                        isReturnAvailable={item.canReturn}
                        returnDays={item.returnDays}
                        exchangeDays={item.exchangeDays}
                        alterationDays={item.alterationDays}
                      />
                    );
                  })}
                </div>
                <hr />
                <div className={styles.orderDetails__body__details}>
                  <div className={styles.orderDetails__body__details__heading}>
                    <div>
                      <h2>
                        <em> By {order.order?.vendor?.businessName}</em>
                      </h2>
                      <h5>
                        Ordered on{' '}
                        {moment(order?.order?.Orderdate?.toDate()).format(
                          'MMMM Do YYYY'
                        )}
                      </h5>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '30px',
                        alignItems: 'center',
                      }}
                    >
                      <a
                        href={`tel:+91${order?.order?.vendor?.phoneNumber}`}
                        target='_black'
                        rel='noopener noreferrer'
                      >
                        <Call />
                      </a>
                      <a
                        target='_black'
                        rel='noopener noreferrer'
                        href={`https://api.whatsapp.com/send?phone=91${order?.order?.vendor?.phoneNumber}`}
                      >
                        <Whatsapp />
                      </a>
                      {/* {order?.order?.vendor?.phoneNumber && (
                        <div style={{ fontSize: '15px' }}>
                          +91{order?.order?.vendor?.phoneNumber}
                        </div>
                      )} */}
                    </div>
                  </div>
                  {order?.order?.mesurementdetails && (
                    <div
                      className={
                        styles.orderDetails__body__details__measurement
                      }
                    >
                      <Link
                        to={`/view-measurement/${id}`}
                        className={styles.highlightLink}
                      >
                        View Measurement
                        {/* <Tick /> */}
                      </Link>
                    </div>
                  )}
                  {order?.order?.measurementRequired &&
                    !order?.order?.mesurementdetails && (
                      <button
                        className={`${styles.highlightLink} ${styles.invoiceBtn}`}
                        style={{
                          boxShadow: `0px 2px 15px rgba(0, 0, 0, 0.28)`,
                        }}
                      >
                        Add Measurement
                        <svg
                          width='25'
                          height='24'
                          viewBox='0 0 25 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            x='0.146484'
                            width='24'
                            height='24'
                            rx='12'
                            fill='#897552'
                          />
                          <path
                            d='M6.01215 12.6274V10.3134H10.9261V5.71144H13.3181V10.3134H18.2321V12.6274H13.3181V17.2554H10.9261V12.6274H6.01215Z'
                            fill='white'
                          />
                        </svg>
                        {/* Get invoice <Tick /> */}
                      </button>
                    )}
                  <div className={styles.orderDetails__body__details__address}>
                    <h3>Delivery Address</h3>
                    <div className={styles.address_detials}>
                      <h4 style={{ textTransform: 'capitalize' }}>
                        {order.order?.userdetails?.name}
                      </h4>
                      <p>{order.order?.userdetails?.phone}</p>
                      <p>
                        {order?.order?.userdetails?.address},
                        {order.order?.userdetails?.locality},
                        {order.order?.userdetails?.city},
                        {order.order?.userdetails?.state}-
                        {order.order?.userdetails?.pincode}
                      </p>
                    </div>
                  </div>
                  <div className={styles.orderDetails__body__details__invoice}>
                    <Pdf
                      targetRef={ref}
                      filename='invoice.pdf'
                      x={10}
                      y={0}
                      options={{ scale: 1 }}
                    >
                      {({ toPdf }) => (
                        <button
                          className={`${styles.highlightLink} ${styles.invoiceBtn}`}
                          onClick={() => {
                            toPdf();
                          }}
                        >
                          Get Invoice
                          {/* Get invoice <Tick /> */}
                        </button>
                      )}
                    </Pdf>
                  </div>
                </div>
              </div>
              <div className={styles.order_payment}>
                <div>
                  <h3>Paid amount</h3>
                  <p>Includes shipping + taxes</p>
                </div>
                <div>
                  <h2 className={styles.price}>
                    ₹{order?.order?.paymentdetails?.amountpaid}
                  </h2>
                </div>
              </div>
              <div className={styles.footer}>
                {/* {moment(
                  moment(order?.order?.ddate?.toDate()).add(
                    order?.order?.shippingDetails?.modificationDays,
                    'days'
                  )
                ).diff(moment(), 'seconds') > 0 && */}

                {order?.order?.status >= 4 && (
                  <>
                    <div className={styles.return}>
                      {isAvaliableForAlterationButton(
                        order.order.items,
                        order.order?.ddate?.toDate(),
                        order?.order?.reShippingRequested
                      ) && (
                        <Button onClick={handleAlternateModal}>
                          Request for alteration
                        </Button>
                      )}
                    </div>

                    <div className={styles.return}>
                      {isAvaliableForReturnButton(
                        order.order.items,
                        order.order?.ddate?.toDate(),
                        order?.order?.reShippingRequested
                      ) && <Button onClick={handleReturnModal}>Return</Button>}

                      {isAvaliableForExchangeButton(
                        order.order.items,
                        order.order?.ddate?.toDate(),
                        order?.order?.reShippingRequested
                      ) && (
                        <Button onClick={handleExchangeModal}>Exchange</Button>
                      )}
                    </div>
                  </>
                )}

                {/* {!order?.order?.reShippingRequested && order?.order?.ddate && (
                  <div
                    style={{
                      maxWidth: '410px',
                      width: '100%',
                      height: '70px',
                      backgroundColor: '#EEECE7',
                    }}
                  >
                    {order?.order?.status >= 4 && (
                      <div className={styles.timefooter}>
                        <h3 style={{ width: '100%' }}>Note*</h3>
                        <div style={{ display: 'flex' }}>
                          <div>
                            {moment(
                              moment(order?.order?.ddate?.toDate()).add(
                                order?.order?.shippingDetails?.modificationDays,
                                'days'
                              )
                            ).diff(moment(), 'seconds') > 0 ? (
                              <h5>
                                You can{' '}
                                {!order?.order?.measurementRequired
                                  ? ' Exchange'
                                  : 'Alteration'}{' '}
                                the product only once within
                              </h5>
                            ) : (
                              <h5>
                                Time Expired for{' '}
                                {!order?.order?.measurementRequired
                                  ? ' Exchange'
                                  : 'Alteration'}{' '}
                              </h5>
                            )}
                          </div>

                          <div className={styles.time}>
                            {order?.order?.ddate?.toDate() &&
                              moment(
                                moment(order?.order?.ddate?.toDate()).add(
                                  order?.order?.shippingDetails?.modificationDays,
                                  'days'
                                )
                              ).diff(moment(), 'days') > 0 ? (
                              <h5>
                                &nbsp;{' '}
                                {moment(
                                  moment(order?.order?.ddate?.toDate()).add(
                                    order?.order?.shippingDetails
                                      ?.modificationDays,
                                    'days'
                                  )
                                ).diff(moment(), 'days')}{' '}
                                Days
                              </h5>
                            ) : (
                              <Countdown
                                date={moment(order?.order?.ddate?.toDate()).add(
                                  order?.order?.shippingDetails
                                    ?.modificationDays,
                                  'days'
                                )}
                                renderer={props => {
                                  const { hours, minutes, seconds } = props;

                                  return (
                                    <h5>
                                      &nbsp; {hours}:{minutes} :{seconds} hr
                                    </h5>
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )} */}
                <div>
                  <Link to='/terms-and-conditions?return=true'>
                    Terms and conditions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalComponent
          closeBtn
          open={returnModal}
          handleClose={handleReturnModal}
          height={tab ? 'fit-content' : 'fit-content'}
          width={'401.78px'}
        >
          <div className={styles.alterationModal}>
            <h1>Select product for Return</h1>
            <hr />
            <div className={styles.alterationProduct}>
              {order.order?.items
                ?.filter(i =>
                  isAvaliableForReturn(
                    i,
                    order?.order?.ddate?.toDate(),
                    order?.order?.reShippingRequested
                  )
                )
                .map((item, index) => {
                  return (
                    <ProductCard
                      key={index}
                      data={item}
                      from='alterModal'
                      setselectedproduct={setreturnproduct}
                      selectedproduct={returnproduct}
                      ini={index}
                    />
                  );
                })}
            </div>
            <div className={styles.alterationBtn}>
              <Button
                disabled={returnproduct.length < 1}
                onClick={() => {
                  dispatch(returnrequest(returnproduct));
                  navigate(`/return-refund/${id}`);
                }}
              >
                Proceed
              </Button>
            </div>
          </div>
        </ModalComponent>
        <ModalComponent
          closeBtn
          open={exchangeModal}
          handleClose={handleExchangeModal}
          height={tab ? 'fit-content' : 'fit-content'}
          width={'401.78px'}
        >
          <div className={styles.alterationModal}>
            <h1>Select product for Exchange</h1>
            <hr />
            <div className={styles.alterationProduct}>
              {order.order?.items
                ?.filter(i =>
                  isAvaliableForExchnage(
                    i,
                    order?.order?.ddate?.toDate(),
                    order?.order?.reShippingRequested
                  )
                )
                .map((item, index) => {
                  return (
                    <ProductCard
                      key={index}
                      data={item}
                      vendor={order.order.vendor}
                      from='alterModal'
                      setselectedproduct={setexchangeproduct}
                      selectedproduct={exchangeproduct}
                      ini={index}
                    />
                  );
                })}
            </div>
            <div className={styles.alterationBtn}>
              <Button
                disabled={exchangeproduct.length < 1}
                onClick={() => {
                  dispatch(exchangerequest(exchangeproduct));
                  navigate(`/exchange/${id}`);
                }}
              >
                Proceed
              </Button>
            </div>
          </div>
        </ModalComponent>
        <ModalComponent
          closeBtn
          open={alterationModal}
          handleClose={handleAlternateModal}
          height={tab ? 'fit-content' : 'fit-content'}
          width={'401.78px'}
        >
          <div className={styles.alterationModal}>
            <h1>Select product for alteration</h1>
            <hr />
            <div className={styles.alterationProduct}>
              {order.order?.items
                ?.filter(i =>
                  isAvaliableForAlteration(
                    i,
                    order.order?.ddate?.toDate(),
                    order?.order?.reShippingRequested
                  )
                )
                .map((item, index) => {
                  return (
                    <ProductCard
                      key={index}
                      data={item}
                      vendor={order.order.vendor}
                      from='alterModal'
                      setselectedproduct={setalteredproduct}
                      selectedproduct={alterproduct}
                      ini={index}
                    />
                  );
                })}
            </div>
            <div className={styles.alterationBtn}>
              <Button
                disabled={alterproduct.length < 1}
                onClick={() => {
                  dispatch(alterationrequest(alterproduct));
                  navigate(`/request-alteration/${id}`);
                }}
              >
                Proceed
              </Button>
            </div>
          </div>
        </ModalComponent>

        <div
          style={{
            opacity: 0,
            zIndex: -100,
            position: 'fixed',
            top: 0,
            backgroundColor: 'red',
            maxWidth: '100%',
            overflowX: p ? 'scroll' : 'hidden',
          }}
        >
          <div
            ref={ref}
            style={{
              maxWidth: '800px',
              display: 'flex',
              justifyContent: 'center',
              width: '700px',
              height: '1500px',
            }}
          >
            <Invoice data={order.order} id={id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
