import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import styles from './ItemCard.module.scss';
import cardImg from './images/cardImage.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import { imageurl } from '../../config';
import MyImage from '../Common/ImageLoader';
import { useMediaQuery } from '@mui/material';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import firebase from '../../firebase/firebase';
const ItemCard = ({
  status,
  link,
  designerName,
  totalProducts,
  date,
  price,
  measurementRequired,
  id,
  to,
  products,
  vendorInfo,
  maxWidth,
  item,
}) => {
  const [imagesss, setimage] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    let imag = [];
    products?.map(item => {
      imag = [...imag, ...item?.images?.map(it => imageurl(it))];
    });
    setimage(imag);
  }, [products]);

  const isMobile = useMediaQuery(MOBILE_WIDTH);

  const updateClicks = async () => {
    if (id) {
      firebase
        .firestore()
        .collection('commonlynks')
        .doc(id)
        .update({
          clicks: firebase.firestore.FieldValue.increment(1),
        });
    }
  };

  return (
    <div
      className={styles.card}
      // style={{
      //   background:
      //     status === 'completed'
      //       ? 'var(--primary-medium)'
      //       : 'var(--primary-dark)',
      //   margin: '5px',
      // }}
    >
      <div className={styles.cardImg} style={{ width: '100%' }}>
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          style={{ maxWidth: maxWidth || '100%', width: '100%' }}
        >
          {imagesss.length === 0 ? (
            <SwiperSlide
              style={{
                minWidth: '100%',
                height: '250px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <div
                style={{
                  height: '250px',
                  minWidth: '300px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <Zoom closeText='Close'> */}
                <MyImage
                  height={'280px'}
                  src={'http://placehold.jp/300x250.png'}
                  alt=''
                />
                {/* </Zoom> */}
              </div>
            </SwiperSlide>
          ) : (
            imagesss?.map((item, index) => {
              return (
                <SwiperSlide key={index} style={{ minWidth: '100%' }}>
                  <div
                    style={{
                      height: isMobile && maxWidth ? '220px' : '300px',
                      width: '100%',
                    }}
                  >
                    <MyImage
                      src={item}
                      alt=''
                      images={imagesss}
                      index={index}
                    />
                    {/* </Zoom> */}
                  </div>
                </SwiperSlide>
              );
            })
          )}
        </Swiper>
      </div>
      <div className={styles.cardDetails}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '4px',
          }}
        >
          {measurementRequired ? (
            <div
              className={styles.customCard}
              style={{
                marginRight: '8px',
              }}
            >
              <h3>Custom made</h3>
            </div>
          ) : (
            <div
              className={styles.customCard}
              style={{
                marginRight: '8px',
              }}
            >
              <h3>Ready to ship</h3>
            </div>
          )}
          {item?.shippingDetails &&
            !item?.shippingDetails?.shippingChargesExtra && (
              <div
                className={styles.customCard}
                style={{
                  color: '#ffffff',
                  background: '#a1f492',
                }}
              >
                <h3>Free Shipping</h3>
              </div>
            )}
          {/* {true && (
            <div className={styles.soldOut}>
              <h4>Sold out</h4>
            </div>
          )} */}

          {status && (
            <div
              className={`${
                status === 'new'
                  ? styles.cardNew
                  : status === 'Measurement pending'
                  ? styles.cardPending
                  : styles.cardCompleted
              } ${status.toLowerCase()}`}
            >
              <h4>{status}</h4>
            </div>
          )}
        </div>
        <div className={styles.cardPrice}>
          {/* <Link to={`${to}`}>{link?.substring(0, 10)}</Link> */}
          {price && (
            <h4>
              {price && '₹'} {parseFloat(price).toFixed(2)}
            </h4>
          )}
        </div>
        <div className={styles.cardDesigner}>
          <h1
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
            }}
            title={designerName}
          >
            By <span>{designerName}</span>
          </h1>
        </div>
        {totalProducts && (
          <div className={styles.cardTotalProducts}>
            <p>Total no of products ({totalProducts})</p>
          </div>
        )}
        <div className={styles.cardDate}>
          <h4>
            {measurementRequired && item.items[0].productDispatchTimeLine && (
              <>Dispatch In : {item.items[0].productDispatchTimeLine} days</>
            )}
          </h4>
          {/* <h4>Date : {date}</h4> */}
          <button
            onClick={() => {
              //updateClicks();
              navigate(to, {
                state: { vendorInfo, item },
              });
            }}
            style={{
              border: 0,
              padding: 0,
              background: 'none',
            }}
          >
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
