import React, { useEffect, useState } from 'react';

import styles from '../../requestOrder.module.scss';

import Input from '../../../../Components/Common/Form/normalinput';
import { Button, IconButton, Typography } from '@mui/material';
import { RiCloseCircleFill } from 'react-icons/ri';
import { ReactComponent as Close } from './images/close.svg';

const Formelement = props => {
  return (
    <div className={styles.requestOrderDetails}>
      <div className={styles.requestOrderDetails__header}>
        <h2>Product details {props.index + 1}</h2>
        {props.index !== 0 && (
          <Button
            onClick={() => {
              props.delete(props.index);
            }}
          >
            Delete
          </Button>
        )}
      </div>
      <div className={styles.requestOrderDetailsInput}>
        <Input
          label='Add product/post link here'
          name='productLink'
          id='productLink'
          value={props.product[props.index].lynk}
          onChange={e => props.onLinkChange(props.index, e.target.value)}
        />
       { props.proerror?.index===props.index && <div style={{color:'red'}} >{props.proerror?.error}</div>}
        
      </div>
      <div className={styles.requestOrderDetailsBtn}>
        <label htmlFor={`input-${props.index}`}>
          <input
            onChange={e => {
              console.log(props);
              props.fileupload(e);
            }}
            style={{ display: 'none' }}
            accept='image/*'
            id={`input-${props.index}`}
            multiple
            type='file'
          />
          <Button variant='contained' component='span'>
            <Typography variant={'h6'}> Upload image</Typography>
          </Button>
          { props.imgerror?.index===props.index && <div style={{color:'red'}} >{props.imgerror?.error}</div>}
        </label>
        <div style={{ display: 'flex', gap: '2.2rem', flexWrap: 'wrap' }}>
          {props.product[props.index].localimage?.map((image, index) => {
            return (
              <div key={index} style={{ position: 'relative' }}>
                <img
                  alt='ing'
                  //   onClick={()=>{imageRemove(index)}}
                  src={image}
                  style={{ width: '50px', height: '50px' }}
                />
                <Close
                  onClick={() => {
                    props.imageRemove(props.index, index);
                  }}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '0',
                    fontSize: '1.5rem',
                    fill: 'var(--danger-dark)',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Formelement;
