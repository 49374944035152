import { Modal } from '@mui/base';
import {
  Backdrop,
  Fade,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper';
import { MOBILE_WIDTH } from '../../../constants/breakPoints';
import { AiFillCloseCircle } from 'react-icons/ai';
// import Zoom from 'react-img-zoom';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import {
  MdArrowForwardIos,
  MdClose,
  MdOutlineArrowBackIos,
} from 'react-icons/md';

const MyImage = ({
  src,
  alt,
  width,
  height,
  onClick,
  rounded,
  images,
  index,
  style,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <>
      {openModal && (
        <ImageModal
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
          }}
          image={image}
          activeSlide={activeSlide}
          images={images}
        />
      )}

      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'zoom-in',
        }}
        onClick={() => {
          setOpenModal(true);
          setImage(src);
          setActiveSlide(index);
        }}
      >
        <img
          alt={alt}
          effect='blur'
          src={src}
          style={{
            borderRadius: rounded ? '50%' : '10px',
            objectFit: 'cover',
            objectPosition: 'top center',
            width: '100%',
            height: height || '100%',
            ...style,
          }}
        />
      </div>
    </>
  );
};

export default MyImage;

export const ImageModal = ({ open, handleClose, images, activeSlide }) => {
  const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '700px',
    background: 'transparent',
    width: '100%',
    zIndex: 10,
  };
  const mobile = useMediaQuery(MOBILE_WIDTH);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        style={{
          position: 'relative',
          zIndex: '10000000000000000000000000000000000000',
        }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <>
          <div
            style={{
              position: 'fixed',
              height: '100vh',
              width: '100vw',
              background: ` rgba(255,255,255,0.5)`,
              backdropFilter: ` blur(2px)`,
              top: 0,
              zIndex: 10,
            }}
            onClick={handleClose}
          ></div>
          <Box sx={style}>
            <Swiper
              initialSlide={activeSlide || 0}
              pagination={{
                clickable: true,
              }}
              zoom={true}
              modules={[Zoom, Pagination, Navigation]}
              centeredSlides={true}
              navigation={{
                nextEl: '.swiper-btn-next',
                prevEl: '.swiper-btn-prev',
                disabledClass: 'disabled',
              }}
              // loop={true}
            >
              {images.length > 1 && (
                <Fragment>
                  <div className='z-10 top-[40%] translate-y-[-50%] absolute left-0 sm:left-10       disabled:pointer-events-none disabled:opacity-60  swiper-btn-prev '>
                    <IconButton>
                      <MdOutlineArrowBackIos className='text-3xl' />
                    </IconButton>
                  </div>
                  <div className=' z-10 top-[40%] translate-y-[-50%]  absolute right-0 sm:right-10     disabled:pointer-events-none  disabled:opacity-60 swiper-btn-next'>
                    <IconButton>
                      <MdArrowForwardIos className='text-3xl' />
                    </IconButton>
                  </div>
                </Fragment>
              )}
              {images?.map((image, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    {/* <Zoom img={image} zoomScale={3} width={600} height={600} /> */}
                    {/* <PrismaZoom> */}
                    <div className='swiper-zoom-container'>
                      <img
                        src={image}
                        alt='asd'
                        // style={{
                        //   height: mobile ? '70vh' : '90vh',
                        //   maxWidth: '600px',
                        //   width: '100%',
                        //   margin: '0 auto',
                        // }}
                      />
                    </div>
                    {/* </PrismaZoom> */}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
          {mobile ? (
            <IconButton
              onClick={handleClose}
              style={{
                position: 'fixed',
                zIndex: 20,
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
                background: 'white',
              }}
            >
              <MdClose size={40} color='red' />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleClose}
              style={{
                position: 'fixed',
                zIndex: 20,
                top: '30px',
                right: '30px',
                background: 'white',
              }}
            >
              <MdClose size={40} color='red' />
            </IconButton>
          )}
        </>
      </Modal>
    </>
  );
};
