import React from 'react';
import { Navigation } from 'swiper';
import { Swiper } from 'swiper/react';
import styles from './swiper.module.scss';
import { IconButton, useMediaQuery } from '@mui/material';
import { MdArrowForwardIos, MdOutlineArrowBackIos } from 'react-icons/md';
import { MOBILE_WIDTH } from '../../../constants/breakPoints';

const SwiperComponent = ({
  children,
  progressColor,
  sellAllHref,
  spaceBetween,
  sm,
}) => {
  const isMobile = useMediaQuery(MOBILE_WIDTH);
  return (
    <Swiper
      slidesPerView={sm ? 1.5 : 1.5}
      watchSlidesProgress={true}
      modules={[Navigation]}
      breakpoints={{
        500: {
          slidesPerView: 1.8,
          spaceBetween: spaceBetween || 0,
        },
        700: {
          slidesPerView: 2.5,
          spaceBetween: spaceBetween || 0,
        },
        900: {
          slidesPerView: 2.3,
          spaceBetween: spaceBetween || 0,
        },
        1200: {
          slidesPerView: 2.5,
        },
        1400: {
          slidesPerView: 3,
        },
      }}
      style={{
        paddingBottom: isMobile ? '20px' : '110px',
        position: 'relative',
      }}
      navigation={{
        nextEl: '.btn-next',
        prevEl: '.btn-prev',
      }}
    >
      {!isMobile && (
        <div className={styles.swiperBtns}>
          <div>
            <IconButton className='btn-prev'>
              <MdOutlineArrowBackIos
                style={{
                  fontSize: '30px',
                }}
              />
            </IconButton>
          </div>
          <div>
            <IconButton className='btn-next'>
              <MdArrowForwardIos
                style={{
                  fontSize: '30px',
                }}
              />
            </IconButton>
          </div>
        </div>
      )}
      {children}
    </Swiper>
  );
};

export default SwiperComponent;
