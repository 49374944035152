import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  Input,
  InputBase,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { ReactComponent as Close } from './img/close.svg';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { IoCloseCircle, IoCloseCircleOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import ProductCard from '../../Components/ProductCard/ProductCard';
import { uploadSingleImage } from '../../utils/uploadimage';
import styles from './return.module.scss';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { sumbitreturn } from '../../redux/slices/orders';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import ModalComponent from '../../Components/Modal/ModalComponent';
import { ReactComponent as Success } from '../../Components/Modal/images/successLight.svg';
import { useNavigate } from 'react-router-dom';
import { startLoading } from '../../redux/slices/ui';
const ReturnNRefund = () => {
  const [showModal, setShowModal] = React.useState(false);
  const mobile = useMediaQuery(MOBILE_WIDTH);

  const { id } = useParams();
  const returnproduct = useSelector(state => state.orders.return);
  const dispatch = useDispatch();

  const [error, seterror] = useState({});

  const navigate = useNavigate();
  const handleModal = (e, r) => {
    if (r !== 'backdropClick') setShowModal(!showModal);
  };

  const viewimage = async file => {
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

    return await toBase64(file);
  };

  const Submitpressed = async () => {
    let isError = false;
    let img = [];
    alterimage.map((i, ind) => {
      if (!i.return?.image || i.return?.image === '') {
        seterror({ error: 'please upload the image', index: ind });
        setTimeout(() => {
          seterror({});
        }, 3000);
        isError = true;
      } else if (i.return?.reason.length < 1) {
        seterror({ error: 'please fill the reason', index: ind });
        setTimeout(() => {
          seterror({});
        }, 3000);
        isError = true;
      } else if (!i.return.text && i.return.text === '') {
        seterror({ error: 'please fill description', index: ind });
        setTimeout(() => {
          seterror({});
        }, 3000);
        isError = true;
      }

      if (i?.return?.image) {
        img.push(i?.return?.image);
      }
    });

    if (!isError) {
      const allimgg = img.map(im => {
        return uploadSingleImage(im, 'return');
      });
      const finaldata = _.cloneDeep(alterimage);
      dispatch(startLoading(false));
      await Promise.all(allimgg)
        .then(async resimg => {
          dispatch(startLoading(false));
          finaldata.map((i, index) => {
            finaldata[
              index
            ].return.image = `return/${finaldata[index].return.image.name}`;
            delete finaldata[index].return.image64;
          });
          dispatch(startLoading(false));
          const res = await dispatch(sumbitreturn(finaldata, id));
          handleModal();
        })
        .catch(() => {
          dispatch(startLoading(false));
        });
    }
  };
  const [alterimage, setalterimage] = useState([...returnproduct]);

  const [returnReason, setReturnReason] = React.useState([]);

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    setReturnReason(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const reasons = [
    'Defective product',
    'Qualit Issues',
    'Size issue',
    'Wrong Product',
    'Colour Issue',
  ];

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {},
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: '1.6rem',
      borderRadius: '5px',
      padding: '12px 12px',
      // transition: theme.transitions.create([
      //   'border-color',
      //   'background-color',
      //   'box-shadow',
      // ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'DM Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      },
    },
  }));
  return (
    <div>
      <BreadCrumbs
        currentPath='Return & Refund'
        paths={[
          { name: 'Home', to: '/' },
          { name: 'Orders', to: '/orders' },
        ]}
      />
      <div className={styles.return}>
        <div className={styles.return__header}>
          <h1>Return and Refund</h1>
        </div>
        <div className={styles.return__body}>
          {alterimage.map((item, index) => {
            return (
              <div className={styles.return__body__item}>
                <ProductCard data={item} />

                <div className={styles.return__form}>
                  <div
                    className={styles.return__form__control}
                    style={{
                      position: 'relative',
                    }}
                  >
                    {!alterimage[index]?.return?.reason ||
                      (alterimage[index]?.return?.reason?.length === 0 && (
                        <p
                          style={{
                            position: 'absolute',
                            top: '40px',
                            left: '18px',
                            zIndex: '1',
                            fontSize: '1.3rem',
                            pointerEvents: 'none',
                          }}
                        >
                          Select...
                        </p>
                      ))}
                    <label htmlFor='reason'>Enter reason for return*</label>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        multiple
                        value={alterimage[index]?.return?.reason || []}
                        onChange={e => {
                          const local = _.cloneDeep(alterimage[index]);
                          local.return = {
                            ...local.return,
                            reason:
                              typeof value === 'string'
                                ? e.target.value.split(',')
                                : e.target.value,
                          };
                          const newalter = [...alterimage];
                          newalter[index] = { ...local };

                          setalterimage(newalter);
                        }}
                        input={
                          <BootstrapInput className={styles.selectInput} />
                        }
                        fullWidth
                        sx={{
                          backgroundColor: 'white',
                          width: '100%',
                        }}
                        renderValue={selected => {
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                                alignItems: 'center',
                              }}
                            >
                              {!selected || selected.length < 0 ? (
                                <h1>Enter reason for return*</h1>
                              ) : (
                                <>
                                  {selected.map(value => (
                                    <div className={styles.chip}>
                                      <div>
                                        <h4>{value}</h4>
                                      </div>
                                      {/* <div>
                                  <button style={{ cursor: 'pointer' }}>
                                    <IoCloseCircle
                                      size='1.8rem'
                                      color='rgba(0, 0, 0, 0.6)'
                                    />
                                  </button>
                                </div> */}
                                    </div>
                                  ))}
                                </>
                              )}
                            </Box>
                          );
                        }}
                      >
                        {reasons.map(name => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                alterimage[index]?.return?.reason?.indexOf(
                                  name
                                ) > -1
                              }
                              sx={{
                                color: '#6A5B40',
                                '&.Mui-checked': {
                                  color: '#6A5B40',
                                },
                                '& .MuiSvgIcon-root': { fontSize: 20 },
                              }}
                            />
                            <ListItemText
                              primary={name}
                              className={styles.checkboxList}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={styles.return__form__control}>
                    <label htmlFor='description'>Description*</label>
                    <textarea
                      name='description'
                      id=''
                      cols='30'
                      rows='10'
                      placeholder='Start from here'
                      value={alterimage[index].return?.text}
                      onChange={e => {
                        const local = _.cloneDeep(alterimage[index]);
                        local.return = {
                          ...local.return,
                          text: e.target.value,
                        };
                        const newalter = [...alterimage];
                        newalter[index] = { ...local };

                        setalterimage(newalter);
                      }}
                    ></textarea>
                    <p>*maximum characters shall be 100</p>
                  </div>
                  <div className={styles.return__form__control}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <div>
                        <label
                          htmlFor='reason'
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          Upload photo
                        </label>
                        {/* <input type='file' /> */}

                        <label htmlFor='image'>
                          <input
                            onChange={async e => {
                              viewimage(e.target.files[0]);
                              const i = [...alterimage];

                              const currobj = _.cloneDeep(i[index]);

                              currobj.return = {
                                ...currobj?.return,
                                image: e.target.files[0],
                              };
                              currobj.return = {
                                ...currobj?.return,
                                image64: await viewimage(e.target.files[0]),
                              };
                              currobj.return = {
                                ...currobj?.return,
                                status: 1,
                              };
                              currobj.status = 5;
                              i[index] = currobj;

                              setalterimage(i);
                            }}
                            style={{ display: 'none' }}
                            accept='image/*'
                            id='image'
                            type='file'
                          />
                          <Button className={styles.uploadBtn}>
                            Choose File
                          </Button>
                        </label>
                      </div>
                      {alterimage[index]?.return?.image64 && (
                        <div className={styles.return__form__control__img}>
                          <div
                            key={index}
                            style={{
                              position: 'relative',
                              width: '50px',
                              height: '50px',
                            }}
                          >
                            <img
                              alt='ing'
                              //   onClick={()=>{imageRemove(index)}}
                              src={alterimage[index]?.return?.image64}
                              style={{
                                width: '50px',
                                height: '50px',
                                objectFit: 'cover',
                                objectPosition: 'top',
                              }}
                            />
                            <Close
                              onClick={() => {
                                const local = [...alterimage];
                                let obi = _.cloneDeep(local[index]);
                                obi.return = { ...obi.return, image: null };
                                obi.return = { ...obi.return, image64: null };
                                local[index] = obi;
                                setalterimage(local);
                              }}
                              style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-5px',
                                fontSize: '1.5rem',
                                fill: 'var(--danger-dark)',
                                color: 'white',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {error?.index === index && (
                      <div style={{ fontSize: '20px', color: 'red' }}>
                        {error?.error}
                      </div>
                    )}
                    <h5>
                      You can upload only 1 file not more than 2MB in size and
                      supports JPG, JPEG or PNG
                    </h5>
                    <Link to='/terms-and-conditions'>Terms and conditions</Link>
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className={styles.order_payment}>
            <div>
              <h3>Paid amount</h3>
              <p>Includes shipping</p>
            </div>
            <div>
              <h2 className={styles.price}>₹2000.00</h2>
            </div>
          </div> */}
          <div className={styles.return__body__btn}>
            <Button onClick={Submitpressed}>Submit</Button>
          </div>
        </div>
      </div>
      <ModalComponent
        open={showModal}
        handleClose={handleModal}
        width='680px'
        height={mobile ? '228px' : '367px'}
      >
        <div className={styles.modal}>
          <Success
            style={{
              transform: mobile ? 'scale(0.8)' : 'scale(1.8)',
            }}
          />
          {/* <IconButton
            onClick={() => setShowModal(false)}
            style={{
              position: 'absolute',
              top: mobile ? '5px' : '10px',
              right: '10px',
            }}
          >
            <svg
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='12.834' cy='12.834' r='12.834' fill='#DA1E28' />
              <path
                d='M17.1126 8.55859L8.55664 17.1146'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M8.55664 8.55859L17.1126 17.1146'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </IconButton> */}
          <h1>Your request for Return sent successfully</h1>

          <p>
            Note: The product should be in original condition as received with
            tags and bill.
          </p>

          <Button
            onClick={() => {
              navigate('/');
              handleModal();
            }}
            className={styles.modal__btn}
          >
            Back to home
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ReturnNRefund;
