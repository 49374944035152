import React from 'react';
import styles from './itemTag.module.scss';

const ItemTag = ({ status, lynk, style }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '4px',
        ...style,
      }}
    >
      {lynk.measurementRequired ? (
        <div
          className={styles.customCard}
          style={{
            marginRight: '8px',
          }}
        >
          <h3>Custom made</h3>
        </div>
      ) : (
        <div
          className={styles.customCard}
          style={{
            marginRight: '8px',
          }}
        >
          <h3>Ready to ship</h3>
        </div>
      )}
      {lynk?.shippingDetails &&
        !lynk?.shippingDetails?.shippingChargesExtra && (
          <div
            className={styles.customCard}
            style={{
              color: '#ffffff',
              background: '#a1f492',
            }}
          >
            <h3>Free Shipping</h3>
          </div>
        )}
      {/* {true && (
            <div className={styles.soldOut}>
              <h4>Sold out</h4>
            </div>
          )} */}

      {status && (
        <div
          className={`${
            status === 'new'
              ? styles.cardNew
              : status === 'Measurement pending'
              ? styles.cardPending
              : styles.cardCompleted
          } ${status.toLowerCase()}`}
        >
          <h4>{status}</h4>
        </div>
      )}
    </div>
  );
};

export default ItemTag;
