import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import Input from '../../Common/Form/Input';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { ChangePhoneNumber, verifynewphone, verifyotp } from '../../../redux/slices/userslice';
import { useNavigate } from 'react-router';

const VerifyPhone = ({ styles, currentPhone }) => {
  const dispatch = useDispatch();

  const initialValues = {
    otp: '',
  };
  const validationSchema = Yup.object({
    otp: Yup.number().min(6).required('OTP is Required'),
  });
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
   const pc=await dispatch(verifynewphone(values.otp.toString()));
   navigate('/profile');
   
  };
  return (
    <>
      <h1>OTP verification</h1>
      <p>Please enter the OTP sent to +91{currentPhone}</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {formik => {
          const { dirty, values, isValid } = formik;

          return (
            <Form
              style={{
                width: '100%',
              }}
            >
              <div className={styles.formContainer__form}>
                <Input
                  placeholder='X X X X'
                  label='Enter OTP'
                  name='otp'
                  id='otp'
                />
              </div>

              <div className={styles.formContainer__submitButton}>
                <Button disabled={!dirty || !isValid} type='submit'>
                  Continue
                </Button>
                <div id='recaptcha-container'></div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default VerifyPhone;
