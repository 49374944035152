import firebase from '../firebase/firebase';
export const uploadSingleImage = async (file, bucket) => {

  const task = firebase.storage().ref(`/${bucket}/${file.name}`).put(file);


  return await task
    .then(() => {})
    .catch(err => {
      console.log(err);
    });
};
