import React, { useEffect, useState } from 'react';
import Header from '../../../Components/Common/Container/Header/Header';
import { useParams } from 'react-router';
import styles from './gender.module.scss';
import 'swiper/css';
import CategoryCard from '../../../Components/Card/CategoryCard';
import BreadCrumbs from '../../../Components/Common/BreadCrumbs/BreadCrumbs';
import SEO from '../../../Components/Common/SEO';
import { categoryMetaData } from '../../../constants/metadata';
import { capitalizeText, replaceSecondHyphen } from '../../../utils/helper';
import firebase from '../../../firebase/firebase';
import { useSearchParams } from 'react-router-dom';

const ProductByCategory = () => {
  const { categoryId, gender } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  let [searchParams] = useSearchParams();
  const discountValue = searchParams.get('value');
  const getData = (gender, categoryId) => {
    if (categoryId === 'trending-now') {
      // alert(categoryId)
      firebase
        .firestore()
        .collection('commonlynks')
        .where('isDeleted', '==', 0)
        .where('category', '==', gender)
        .where('acceptingOrders', '==', true)
        .where('inCompleteProfile', '==', false)
        .get()
        .then(res => {
          const finaldata = res.docs
            .map(doc => ({ ...doc.data(), id: doc.id }))
            .sort((a, b) => b?.views - a.views);
          setProducts(finaldata);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
      return;
    }
    if (categoryId === 'new-arrivals') {
      // alert(categoryId)
      firebase
        .firestore()
        .collection('commonlynks')
        .where('isDeleted', '==', 0)
        .where('category', '==', gender)
        .where('acceptingOrders', '==', true)
        .where('inCompleteProfile', '==', false)
        .orderBy('createdDate', 'desc')
        .get()
        .then(res => {
          const finaldata = res.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));
          setProducts(finaldata);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
      return;
    }
    if (categoryId?.includes('upto') && discountValue) {
      firebase
        .firestore()
        .collection('commonlynks')
        .where('isDeleted', '==', 0)
        .where('category', '==', gender)
        .where('acceptingOrders', '==', true)
        .where('inCompleteProfile', '==', false)
        .where('totalDiscountPercent', '<=', parseInt(discountValue))
        .get()
        .then(res => {
          const finaldata = res.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));
          setProducts(finaldata);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
      return;
    }
    // alert(categoryId)
    firebase
      .firestore()
      .collection('commonlynks')
      .where('category', '==', gender)
      .where('isDeleted', '==', 0)
      .where('acceptingOrders', '==', true)
      .where('sub_category', 'array-contains', categoryId)
      .where('inCompleteProfile', '==', false)
      .get()
      .then(res => {
        const finaldata = res.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProducts(finaldata);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  useEffect(() => getData(gender, categoryId), [gender, categoryId]);

  return (
    <>
      <SEO
        title={
          `${categoryMetaData[gender][categoryId]?.title}` ||
          `${capitalizeText(
            categoryId.replaceAll('-', ' ').replace('lt', 'LynkTown')
          )}`
        }
        description={categoryMetaData[gender][categoryId]?.description}
      />
      <Header />
      <main
        style={{
          margin: '20px 0',
        }}
      >
        <section className='container'>
          <BreadCrumbs
            currentPath={
              !Number.isNaN(+categoryId[0])
                ? replaceSecondHyphen(categoryId)
                : categoryId.replaceAll('-', ' ').replace('lt', 'LynkTown')
            }
            paths={[
              { name: 'Home', to: '/' },
              { name: gender, to: `/${gender}` },
            ]}
          />
          <div className={styles.section}>
            <h1 className={styles.title}>
              {!Number.isNaN(+categoryId[0])
                ? replaceSecondHyphen(categoryId)
                : categoryId.replaceAll('-', ' ').replace('lt', 'LynkTown')}
            </h1>

            {loading ? (
              <p
                style={{
                  margin: '30px 0',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
              >
                Loading....
              </p>
            ) : (
              <>
                {!products.length && (
                  <p className={styles.noProduct}>No Products Yet!</p>
                )}

                <div className='gridContainer'>
                  {products?.map(lynk => {
                    return <CategoryCard product={lynk.items[0]} lynk={lynk} />;
                    // return lynk?.items?.map(product => {
                    // });
                  })}
                </div>
              </>
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default ProductByCategory;
