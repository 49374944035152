import Header from '../../../Components/Common/Container/Header/Header';
import { Button, useMediaQuery } from '@mui/material';
import React, { useEffect, useReducer } from 'react';
import TopCategories from '../../../Components/Home/top-categories';
import TwinningCollection from '../../../Components/Home/twinning-collection';
import TwinningImg from './images/tw1.png';
import CategoriesForYou from '../../../Components/Home/Categories-for-you';
import TopDesigners from '../../../Components/Home/top-designers';
import designer1 from './images/designers/d1.png';
import designer2 from './images/designers/d2.png';
import designer3 from './images/designers/d3.png';
import designer4 from './images/designers/d4.png';
import CategoryProductCard from '../../../Components/Home/CategoryProductCard';
import product1 from './images/products/p1.png';
import carousel1 from './images/carousel/1.png';
import carousel2 from './images/carousel/2.png';
import carousel3 from './images/carousel/3.png';
import carousel3SM from './images/carousel/3sm.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import BreadCrumbs from '../../../Components/Common/BreadCrumbs/BreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLatestLynksAccToCategory,
  getLynkWithMostView,
} from '../../../redux/slices/category';
import SEO from '../../../Components/Common/SEO';
import { Link } from 'react-router-dom';

const products = [
  {
    id: 1,
    name: 'Varun Bahl Men',
    image: product1,
    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 2,
    name: 'Varun Bahl Men',
    image: product1,
    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 3,
    name: 'Varun Bahl Men',
    image: product1,
    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
  {
    id: 4,
    name: 'Varun Bahl Men',
    image: product1,
    price: 'Rs.17,000',
    description: 'Black Embroidered Tuxedo Set',
  },
];

const landingCarousel = [
  // {
  //   id: 1,
  //   href: '/products/new-arrivals',
  //   image: carousel1,
  // },
  // {
  //   id: 2,
  //   href: '/products/custom-made',
  //   image: carousel2,
  // },
  {
    id: 3,
    href: '/collection/men/' + encodeURIComponent('upto-30%-off') + '?value=30',
    image: carousel3,
    imageSM: carousel3SM,
  },
];

const topCategories = [
  {
    id: 1,
    name: 'Suits & Tuxedos',
    href: '/collection/men/suits-tuxedos',
    value: 'suits',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fsuits-and-tuxedos.png?alt=media&token=6bec3405-4f35-48a5-b7e5-06bff1193699',
    height: {
      sm: '102px',
      md: '200px',
      lg: '300px',
      xl: '480px',
    },
  },
  {
    id: 2,
    name: 'Kurtas',
    href: '/collection/men/kurtas',

    value: 'weeding',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fkurta-men.png?alt=media&token=8bf16e58-b53e-4a4a-8af7-e8a6d410fccf',
    height: {
      sm: '102px',
      md: '200px',
      lg: '300px',
      xl: '480px',
    },
  },
  {
    id: 3,
    name: 'Formal Wear',
    href: '/collection/men/formal-wear',

    value: 'casual',

    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fformal-wear-men.png?alt=media&token=cd3df8f5-759f-4011-afe8-07627f7b214e',
    height: {
      sm: '102px',
      md: '340px',
      lg: '480px',
      xl: '542px',
    },
  },
  {
    id: 4,
    name: 'Bottomwear',
    value: 'dresses',
    href: '/collection/men/bottom-wear',

    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fbottom-wear-men.png?alt=media&token=6e53b800-0aaa-4e0d-8771-9e4782eb599f',
    height: {
      sm: '102px',
      md: '340px',
      lg: '480px',
      xl: '542px',
    },
  },
];

const categoryForYou = [
  {
    id: 1,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Ffor-groom.png?alt=media&token=d6457576-9a73-4bcb-aca7-018bfc68aecc',
    name: 'For Groom',
    href: '/collection/men/for-groom',
  },
  {
    id: 2,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fchritsmas.png?alt=media&token=0fbede70-c379-42e0-8d50-21c89acfa57c',
    name: 'Christmas',
    href: '/collection/men/christmas',
  },
  {
    id: 3,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fmakar-sankranti.png?alt=media&token=e56d172e-7b21-455b-bf4b-6739a6b5d64f',
    name: 'Makar Sankranti',
    href: '/collection/men/makar-sankranti',
  },

  {
    id: 5,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fparty-wear.png?alt=media&token=89f56075-46f1-4ee5-84fe-1055cbd8c7ce',
    name: 'Party Wear',
    href: '/collection/men/party-wear',
  },
  {
    id: 6,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fbandhgala.png?alt=media&token=9b82c319-e9db-445a-9a20-71c757098975',
    name: 'Bandhgala',
    href: '/collection/men/bandhgala',
  },
  {
    id: 7,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fsuits-and-tuxedos.png?alt=media&token=6bec3405-4f35-48a5-b7e5-06bff1193699',
    name: 'Suits & tuxedos',
    href: '/collection/men/suits-tuxedos',
  },
  {
    id: 8,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fsherwani.png?alt=media&token=820b76d8-c3e9-49c1-ba5e-3b7844e711cf',
    name: 'Sherwani',
    href: '/collection/men/sherwani',
  },
  {
    id: 9,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fshirts.png?alt=media&token=fa857e2b-00b3-4ef5-997f-ad714eb851ff',
    name: 'Shirts',
    href: '/collection/men/shirts',
  },
];

const designers = [
  {
    id: 1,
    img: designer1,
  },
  {
    id: 2,
    img: designer2,
  },
  {
    id: 3,
    img: designer3,
  },
  {
    id: 4,
    img: designer4,
  },
];

const topDesigners = [
  {
    id: 1,

    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_1644c6eb-8943-4977-95e8-9fca3b297480.jpg?alt=media',
    href: 'lana_designer_boutique',
    name: 'Lana designer boutique',
  },
  {
    id: 2,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_21052935-9c30-447b-8236-724ef2b8ae7e.webp?alt=media',
    href: 'akruthi_designer_wear',
    name: 'Akruthi Creations',
  },
  {
    id: 3,

    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_f8437c2c-5e70-4b6e-908a-a17a2f0160fc.jpg?alt=media',
    href: 'Chajed_neha',
    name: 'Neha',
  },
  {
    id: 4,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/lynks%2Frn_image_picker_lib_temp_a21c8b17-70d1-4091-9a02-26284f95125a.jpg?alt=media',
    href: 'bratt_hew',
    name: `Bratt hew`,
  },
];

const MensCategoryPage = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { maxViews, zeroViews } = useSelector(state => state.category);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLynkWithMostView('men'));
    dispatch(getLatestLynksAccToCategory('men'));
  }, [dispatch]);
  return (
    <>
      <SEO
        title="Shop Latest Designer Men's Wear Collection Online"
        description="Shop Latest Designer Men's Wear Collection Online. Browse through extensive collection of Kurtas, Sherwani, Blazers, Jackets, Indowestern, Bandhgalas & more."
      />

      <Header />
      <main>
        <section className='container'>
          <Swiper
            pagination={{
              clickable: true,
            }}
            // modules={[Pagination]}
            spaceBetween={25}
          >
            {landingCarousel.map(item => {
              const { href, id, image, imageSM } = item;
              return (
                <SwiperSlide key={id}>
                  <Link
                    to={href}
                    style={{
                      width: '100%',
                      background: '#EAEAEA',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={image}
                      alt='Banner'
                      style={{
                        minHeight: '200px',
                        display: isMobile ? 'none' : 'block',
                        borderRadius: '8px',
                      }}
                    />
                    <img
                      alt='Banner'
                      src={imageSM}
                      style={{
                        minHeight: '200px',
                        maxHeight: '500px',
                        display: isMobile ? 'block' : 'none',
                      }}
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <BreadCrumbs
            currentPath={'Mens'}
            paths={[{ name: 'Home', to: '/' }]}
          />
          {/* <div
            style={{
              position: 'relative',
              width: '100%',
              height: isMobile ? '573px' : '750px',
            }}
          >
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'top center',
              }}
              src='https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fkurta-men.png?alt=media&token=8bf16e58-b53e-4a4a-8af7-e8a6d410fccf'
              alt='kurta'
            />
            <div
              style={{
                position: 'absolute',
                bottom: '100px',
                left: '50%',
                zIndex: 3,
                textAlign: 'center',

                transform: 'translateX(-50%)',
              }}
            >
              <h1
                style={{
                  fontSize: '32px',
                  fontWeight: '500',
                  color: '#fff',
                }}
              >
                Kurtas
              </h1>
              <Button
                style={{
                  fontSize: '18px',
                  fontWeight: '400',
                  color: '#fff',
                  border: '1px solid white',
                  marginTop: '8px',
                  padding: '8px 20px',
                  width: '100%',
                }}
              >
                <Link to={'/collection/men/kurtas'}>Shop Now</Link>
              </Button>
            </div>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                height: '40%',
                zIndex: 2,
                width: '100%',

                background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.76) 100%)`,
              }}
            ></div>
          </div> */}
        </section>
        <TopCategories
          showTitle={false}
          topCategories={topCategories}
          style={{
            paddingBlock: '10px',
          }}
        />
        <CategoryProductCard
          title='Trending Now'
          products={maxViews?.slice(0, 4)}
          showAll='/collection/men/trending-now'
        />
        <TopDesigners topDesigners={topDesigners} />
        <TwinningCollection
          title={
            <>
              Subtleness <br /> by Manish Bhel
            </>
          }
          description='Explore the new collection by famous designer Manish Bhel'
          image={TwinningImg}
        />
        {zeroViews.length > 0 && (
          <CategoryProductCard
            products={zeroViews?.slice(0, 4)}
            title={'New Arrivals'}
            showAll='/collection/men/new-arrivals'
          />
        )}

        <CategoriesForYou products={categoryForYou} />
      </main>
    </>
  );
};

export default MensCategoryPage;
