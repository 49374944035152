import React from "react";
import styles from "./DetailsPageHeader.module.scss";
import user from "./images/user.svg";
import verified3 from "./images/verfied2.png";
import bg from "./images/bg.png";
import { Button, IconButton, useMediaQuery } from "@mui/material";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { imageurl } from "../../config";
import MyImage from "../Common/ImageLoader";
import { MOBILE_WIDTH } from "../../constants/breakPoints";
import { ReactComponent as Whatsapp } from "./images/whatsapp.svg";
import { Link } from "react-router-dom";

const DetailsPageHeader = ({
  image,
  name,
  coverpic,
  location,
  requestPage,
  phone,
  verified,
  insta_username,
  id,
}) => {
  const mobile = useMediaQuery(MOBILE_WIDTH);

  return (
    <div
      className={styles.lynksDetailContent__header}
      style={{
        backgroundImage: coverpic
          ? `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,url(${imageurl(
              coverpic
            )})`
          : `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,url(${imageurl(
              bg
            )})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className={styles.headerImg}>
        {/* <MyImage src={imageurl(image)} alt='' width='150px' height='150px' /> */}

        <img
          src={imageurl(image) || "http://placehold.jp/150x150.png"}
          rounded
          alt=""
          // width='150px'
          // height='150px'
        />
      </div>
      <div className={styles.headerInfo}>
        <div className={styles.headerInfo__name}>
          <Link
            to={`/designers/${encodeURIComponent(insta_username)}`}
            style={{
              color: "#fff",
            }}
          >
            <h3>{name || "BussinessName"}</h3>
          </Link>
          {verified && (
            <div
              style={{
                marginLeft: 10,
                marginTop: 5,
              }}
            >
              <svg
                width={mobile ? "15" : "20"}
                height={mobile ? "15" : "20"}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.319336"
                  y="0.446777"
                  width="15"
                  height="15"
                  rx="7.5"
                  fill="#3AC267"
                />
                <path
                  d="M3.69434 7.94678L6.31934 10.5718L11.7568 5.13428"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
            </div>
          )}
        </div>
        {requestPage && (
          <div style={{ fontSize: "15px" }}>
            <HiOutlineLocationMarker /> {location}
          </div>
        )}
      </div>
      {!requestPage && (
        <div className={styles.headerCall}>
          <IconButton>
            <a href={`tel:+91${phone}`}>
              <FiPhoneCall />
            </a>
          </IconButton>
          <IconButton>
            <a
              target="_black"
              rel="noopener noreferrer"
              href={`https://api.whatsapp.com/send?phone=91${phone}`}
            >
              <Whatsapp />
            </a>
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default DetailsPageHeader;
