import React from 'react';
import styles from './login.module.scss';
import { ReactComponent as Logo } from './image/new-logo.svg';
import { Button, IconButton, useMediaQuery } from '@mui/material';
import Landing from './components/Landing';
import Login from './components/Login/Login';
import Verify from './components/Verify/Verify';
import bgImg from './image/Signup.jpg';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router';
import ForgotPassword from './components/ForgotPassword';

const LoginComponent = ({ currentStep, setCurrentStep, number, setnumber }) => {
  let Component = null;

  const mobile = useMediaQuery(MOBILE_WIDTH);
  const TAB = useMediaQuery('(max-width: 700px)');

  switch (currentStep) {
    case 1: {
      Component = (
        <Landing
          setCurrentStep={setCurrentStep}
          mobile={mobile}
          currentStep={currentStep}
          TAB={TAB}
        />
      );

      break;
    }
    case 2: {
      Component = (
        <Login setCurrentStep={setCurrentStep} setnumber={setnumber} />
      );
      break;
    }
    case 3: {
      Component = <Verify setCurrentStep={setCurrentStep} number={number} />;
      break;
    }
    case 4: {
      Component = (
        <ForgotPassword setCurrentStep={setCurrentStep} number={number} />
      );
      break;
    }
    default:
      Component = null;
  }

  const size = { width: '653px', height: '624px' };

  const navigate = useNavigate();

  return (
    <div className={styles.LoginComponent} style={size}>
      <div
        className={styles.main}
        style={{
          justifyContent: mobile && currentStep === 1 ? 'end' : 'center',
        }}
      >
        {currentStep === 3 && (
          <div className={styles.backBtn}>
            <IconButton onClick={() => navigate(-1)}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M16.4994 7.49996H5.10964L10.0617 2.56902C10.6501 1.98132 10.6501 1.02847 10.0617 0.440774C9.47327 -0.146925 8.51924 -0.146925 7.93082 0.440774L0.427698 7.9346C0.29108 8.07714 0.183988 8.24522 0.112567 8.42919C-0.0375224 8.79408 -0.0375224 9.20336 0.112567 9.56825C0.183988 9.75223 0.29108 9.92031 0.427698 10.0628L7.93082 17.5567C8.21258 17.8404 8.59614 18 8.99626 18C9.39638 18 9.77994 17.8404 10.0617 17.5567C10.3458 17.2753 10.5056 16.8922 10.5056 16.4925C10.5056 16.0929 10.3458 15.7098 10.0617 15.4284L5.10964 10.4975H16.4994C17.3281 10.4975 18 9.82647 18 8.99872C18 8.17098 17.3281 7.49996 16.4994 7.49996Z'
                  fill='#0A0A0A'
                />
              </svg>
            </IconButton>
          </div>
        )}

        {currentStep === 2 && (
          <div>
            <Logo />
          </div>
        )}
        {Component}
      </div>
    </div>
  );
};

export default LoginComponent;
