import React from 'react';
import styles from './CategoryProductCard.module.scss';
import { Link } from 'react-router-dom';
import { imageurl } from '../../../config';
import CategoryCard from '../../Card/CategoryCard';
import { Button } from '@mui/material';

const CategoryProductCard = ({ products, title, style, showAll }) => {
  return (
    <div className={styles.section} style={style}>
      <h1 className={styles.title}>{title}</h1>
      <div className='gridContainer'>
        {products?.map(lynk => {
          if (lynk.items?.length > 0) {
            return (
              <CategoryCard key={lynk.id} product={lynk.items[0]} lynk={lynk} />
            );
          }
        })}
      </div>
      {showAll && (
        <Link to={showAll}>
          <Button className={styles.btn}>Show all</Button>
        </Link>
      )}
    </div>
  );
};

export default CategoryProductCard;
