import React, { useEffect } from 'react';
import {
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, startmodal } from '../../redux/slices/ui';
import { useNavigate } from 'react-router';
import styles from './uimo.module.scss';
import { ReactComponent as Error } from './images/error.svg';
import { ReactComponent as Success } from './images/success.svg';
import { MOBILE_WIDTH } from '../../constants/breakPoints';

const ModalComponent = () => {
  const mobile = useMediaQuery(MOBILE_WIDTH);
  const state = useSelector(state => state.ui);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: state?.modal?.width || '467px',
    width: '100%',
    height: state?.modal?.height || '300px',
    backgroundColor: `var(--white)`,
    padding: '4rem 3rem',
    boxShadow: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    borderRadius: '20px',
    outline: 'none',
  };
  const styleMobile = {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: state?.modal?.width || '500px',
    width: '100%',
    height: state?.modal?.height || '300px',
    backgroundColor: `var(--white)`,
    padding: '1rem',
    boxShadow: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    borderRadius: '20px 20px 0px 0px',
    outline: 'none',
  };

  const handleClose = (e, r) => {
    if (r !== 'backdropClick') {
      dispatch(closeModal());
      if (state?.modal?.onClose) {
        state?.modal?.onClose();
      }
      navigate(state.modal.btn === 'View order details' ? '/orders' : '/');
      if (state?.modal.navigate) {
        navigate(state?.modal.navigate);
      }
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={state?.modal?.status}
        closeAfterTransition
        onClose={handleClose}
        BackdropComponent={Backdrop}

        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
        <Fade in={state?.modal?.status}>
          <div style={mobile ? styleMobile : style}>
            <div
              className={styles.modalContainer}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {state.modal.type === 'error' ? (
                <Error />
              ) : (
                state.modal.type === 'success' && <Success />
              )}
              <div className={styles.content}>
                {/* <h1>
                  {state.modal.type === 'error'
                    ? 'error'
                    : state.modal.type === 'success' && 'success'}
                </h1> */}
                <h3 style={{ fontWeight: 'bold' }}>{state.modal.message}</h3>
                <p
                  style={{
                    maxWidth: '377px',
                  }}
                >
                  {state.modal.description}
                </p>
                <Button
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: 'white',
                    borderRadius: '5px',
                    backgroundColor: '#6A5B40',
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    marginTop: '20px',
                  }}
                  onClick={() => {
                    handleClose();
                    navigate(
                      state.modal.btn === 'View order details' ? '/orders' : '/'
                    );
                  }}
                >
                  {state.modal.btn || 'Back to home'}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalComponent;
