import { Button, IconButton, useMediaQuery } from '@mui/material';
import React from 'react';
import styles from './SectionNavigation.module.scss';
import { ReactComponent as ArrowBack } from './image/arrow.svg';
import { useNavigate, useLocation } from 'react-router';

import { IoArrowBack } from 'react-icons/io5';
import { MOBILE_WIDTH } from '../../../constants/breakPoints';

const SectionNavigation = ({ heading, customBg, back, noLine, noBack }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // MEDIA QUERY
  const mobile = useMediaQuery(MOBILE_WIDTH);
  return (
    <>
      {!mobile && (
        <div className={styles.SectionNavigation}>
          {!noBack && (
            <IconButton
              onClick={() => {
                if (location?.state?.from === 'createlink') {
                  navigate('/', { state: {} });
                } else {
                  location.key !== 'default' ? navigate(-1) : navigate('/');
                }
              }}
            >
              <IoArrowBack size={mobile ? 20 : 30} color='black' />
            </IconButton>
          )}
          {heading && (
            <>
              <div className={styles.navigationHeading}>
                <h1>{heading}</h1>
              </div>
              {!noLine && (
                <div
                  style={{
                    height: '1px',
                    background: `${customBg || 'var(--primary-dark)'}`,
                    flex: 1,
                  }}
                ></div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SectionNavigation;
