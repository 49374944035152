import { Button } from '@mui/material';
import React from 'react';
import styles from './for-occasions.module.scss';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image4 from './images/4.png';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ForOccasions = () => {
  const { category } = useSelector(state => state.category);

  const images = [
    {
      id: 1,
      name: 'Wedding',
      image:
        'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fwedding.png?alt=media&token=0d9400bc-40c7-4deb-8534-6d621e86784a',
      category: 'women',
      value: 'wedding',
      data: category
        ?.find(i => i.value === 'mens')
        ?.sub_categories?.find(j => j.id === 'wedding'),
    },
    {
      id: 2,
      name: 'Christmas',
      image:
        'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fchristmas.png?alt=media&token=b678d390-f15b-4bdf-bc25-76d43e8ab807',
      category: 'women',
      value: 'christmas',
      data: category
        ?.find(i => i.value === 'mens')
        ?.sub_categories?.find(j => j.id === 'diwali'),
    },
    {
      id: 3,
      name: 'Makar Sankranti',
      image:
        'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fsankranti%20(1).png?alt=media&token=8e088551-ca29-4fdb-9f84-3fec48212e7f',
      category: 'women',
      value: 'makar-sankranti',
      data: category
        ?.find(i => i.value === 'women')
        ?.sub_categories?.find(j => j.id === 'onam'),
    },
    {
      id: 4,
      name: 'Party',
      image:
        'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fparty%20.png?alt=media&token=1c287258-e31a-4f7b-a40b-2b7996cafbf3',
      category: 'women',
      value: 'party',
      data: category
        ?.find(i => i.value === 'mens')
        ?.sub_categories?.find(j => j.id === 'party'),
    },
  ];
  return (
    <div className='container'>
      <section className={styles.section}>
        <div>
          <h1 className={styles.title}>
            You are magnificent, dress your best for the occasion
          </h1>
          <Button className={styles.btn}>Show all</Button>
        </div>
        <div className={styles.products}>
          <div className={styles.prod}>
            {images.map(item => {
              return (
                <Link to={`/collection/${item.category}/${item.value}`}>
                  <div key={item.key}>
                    <img src={item?.image} alt='' />
                    <p>{item.name}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForOccasions;
