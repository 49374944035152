import React from 'react';
import bg from './images/bg.png';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image4 from './images/4.png';
import SwiperComponent from '../swiper';
import { SwiperSlide } from 'swiper/react';
import styles from './different-materials.module.scss';
import { useMediaQuery } from '@mui/material';
import { MOBILE_WIDTH } from '../../../constants/breakPoints';
import { Link } from 'react-router-dom';

const images = [
  {
    id: 1,
    name: 'Rich Silk - Silk Splendor',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Frich-silk.png?alt=media&token=43136452-7586-4cb3-b5ad-9aa7210c38b6',
    href: '/collection/women/rich-Silk-silk-Splendor',
  },
  {
    id: 2,
    name: 'Cotton comfort',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fcotton.png?alt=media&token=2e2f8569-357f-4049-9e60-76c1c1f60420',
    href: '/collection/women/cotton',
  },
  {
    id: 3,
    name: 'Velvet vibes',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fvelvet.jpg?alt=media&token=342db400-fb59-4750-8ff7-f2fa9f01f673',
    href: '/collection/women/velvet-vibes',
  },
  {
    id: 4,
    name: 'Chiffon elegance',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fchiffon.png?alt=media&token=8906ba92-bb82-4f4b-bd11-b035439edc56',
    href: '/collection/women/chiffon-elegance',
  },
  {
    id: 5,
    name: 'Satin chic',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fsatin.png?alt=media&token=ea2c6b7d-28f2-40a0-bb09-87027dbd4e82',
    href: '/collection/women/satin-chic',
  },
  {
    id: 6,
    name: 'Organza opulence',
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Forganza.png?alt=media&token=c2d7cd75-4535-4f61-9fb9-a6c8c3e2ce77',
    href: '/collection/women/organza-opulence',
  },
];

const DifferentMaterials = () => {
  const isMobile = useMediaQuery(MOBILE_WIDTH);

  return (
    <div
      className={styles.different}
      style={{
        background: `url(${bg})`,
        height: isMobile ? '' : '719px',
        padding: '20px 0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className={styles.container}
        style={{
          position: 'relative',
          zIndex: 10,
        }}
      >
        <div className={styles.titleSM}>
          <h1>Different Materials</h1>
          <p>
            Immerse yourself in luxury with our curated fabric collection. From
            the silky allure of cashmere to the breezy elegance of linen,
            explore a world of textures redefining style and comfort. Elevate
            your fashion story. Start exploring.{' '}
          </p>
        </div>

        <div className={styles.different__container}>
          <div className={styles.title}>
            <h1>Different Materials</h1>
            <p>
              Immerse yourself in luxury with our curated fabric collection.
              From the silky allure of cashmere to the breezy elegance of linen,
              explore a world of textures redefining style and comfort. Elevate
              your fashion story. Start exploring.{' '}
            </p>
          </div>
          <SwiperComponent sellAllHref='/products/new-arrivals'>
            {images.map((item, idx) => {
              return (
                <SwiperSlide
                  key={idx}
                  style={{
                    padding: isMobile ? '5px' : '10px',
                    minHeight: isMobile ? 'auto' : '413px',
                  }}
                >
                  <div className={styles.sliderItem}>
                    <Link to={item.href}>
                      <img
                        src={item.image}
                        alt=''
                        style={{
                          maxHeight: isMobile ? '300px' : '413px',
                          height: '100%',
                          minHeight: isMobile ? '300px' : '413px',
                          objectFit: 'cover',
                          objectPosition: 'top center',
                        }}
                      />
                      <p>{item.name}</p>
                      <div className={styles.shadow}></div>
                    </Link>
                  </div>

                  {/* <ItemCard
                    link={item.lynk ? item.lynk : ''}
                    designerName={
                      item?.businessname || item?.vendordetails?.businessName
                    }
                    totalProducts={item.items.length}
                    measurementRequired={item.measurementRequired}
                    date={moment(item?.createdDate).format('DD-MM-YYYY')}
                    price={item.price ? item.price : ''}
                    id={item.id}
                    product={item.items}
                    to={`/products/${item.businessname}/${item.id}`}
                    products={item.items}
                    vendorInfo={item.vendordetails}
                    item={item}
                  /> */}
                </SwiperSlide>
              );
            })}
          </SwiperComponent>
        </div>
      </div>
    </div>
  );
};

export default DifferentMaterials;
