import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../redux/slices/ui';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {
  const ui=useSelector(state => state.ui)
  const dispatch= useDispatch();
  const [open, setOpen] = React.useState(false);



  const handleClose = (event, reason) => {
    dispatch(notify({type:ui.notification?.type,status:false,message:""}))
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
    
      <Snackbar open={ui.notification?.status} 
        anchorOrigin={{   vertical: 'top',
        horizontal: 'right',}}
      autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={ui.notification?.type} sx={{ width: '100%' }}>
         <div style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}>
         <p style={{fontSize:'16px'}}>{ui.notification?.message}</p>
         </div>
        </Alert>
      </Snackbar>
     
    </Stack>
  );
}