import { Box, Button, IconButton, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styles from './ViewMeasurementMobile.module.scss';
import Details from './components/Details';
import UpperBody from './components/UpperBody';
import LowerBody from './components/LowerBody';
import ModalComponent from '../../../Components/Modal/ModalComponent';
import { ReactComponent as Tick } from './tick.svg';
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router';
const ViewMeasurementMobile = ({ mea, image, savemesurement, view, to }) => {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = (dd, d) => {
    if (d !== 'backdropClick') setOpenModal(prev => !prev);
  };
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: '#CFDAE8' }}>
            <TabList
              onChange={handleChange}
              variant='fullWidth'
              className={styles.tablist}
            >
              <Tab
                sx={{
                  '&.Mui-selected': {
                    color: '#6a5b40',
                  },
                }}
                label='Upper body'
                value={+1}
              />
              <Tab
                sx={{
                  '&.Mui-selected': {
                    color: '#6a5b40',
                  },
                }}
                label='Lower body'
                value={+2}
              />
            </TabList>
          </Box>
          <TabPanel
            value={+1}
            sx={{
              padding: '20px 16px',
              marginBottom: '8rem',
              position: 'relative',
            }}
          >
            {!view && (
              <IconButton
                className={styles.editBtn}
                onClick={() => navigate(to)}
              >
                <FiEdit />
              </IconButton>
            )}
            <Details
              styles={styles}
              mea={mea}
              image={image?.front}
              imageback={image?.back}
            />
            <UpperBody styles={styles} mea={mea} />
            {!view ? (
              <Button
                className={styles.sumbitBtn}
                onClick={async () => {
                await savemesurement(false,toggleModal);
                  
                }}
              >
                Submit
              </Button>
            ) : (
              <Button
                className={styles.sumbitBtn}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            )}
          </TabPanel>
          <TabPanel
            value={+2}
            sx={{
              padding: '20px 16px',
              marginBottom: '8rem',
              position: 'relative',
            }}
          >
            {!view && (
              <IconButton
                className={styles.editBtn}
                onClick={() => navigate(to)}
              >
                <FiEdit />
              </IconButton>
            )}
            <Details
              styles={styles}
              mea={mea}
              image={image?.back}
              imageback={image?.front}
            />
            <LowerBody styles={styles} mea={mea} />
            {!view ? (
              <Button
                className={styles.sumbitBtn}
                onClick={() => {
                  toggleModal();
                  savemesurement();
                }}
              >
                Submit
              </Button>
            ) : (
              <Button
                className={styles.sumbitBtn}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            )}
          </TabPanel>
        </TabContext>
      </Box>
      <ModalComponent open={openModal} handleClose={toggleModal} height='300px'>
        <div className={styles.modalContent}>
          <Tick />
          <h1>Order Placed!</h1>
          <p>
          Thankyou for your order, check your order details in the order section
          </p>

          <div>
            <Button
              onClick={() => {
                toggleModal();
                navigate('/');
              }}
            >
              Back to Home
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default ViewMeasurementMobile;
