import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';
import { MdExpandMore } from 'react-icons/md';
import AccordionComp from './AccordionComp/AccordionComp';

const measurementData = [
  {
    heading: 'Neck',
    id: '1',
    items: [
      {
        name: 'Neck',
        value: '1.5',
        id: '1',
      },

      {
        name: 'Neck front',
        value: '1.5',
        id: '2',
      },
      {
        name: 'Neck back',
        value: '1.5',
        id: '3',
      },
    ],
  },
  {
    heading: 'Shoulder',
    id: '2',
    items: [
      {
        name: 'Shoulder',
        value: '1.5',
        id: '1',
      },
    ],
  },
  {
    heading: 'Chest',
    id: '3',
    items: [
      {
        name: 'AroundChest',
        value: '1.5',
        id: '1',
      },
    ],
  },
  {
    heading: 'Arms',
    id: '5',
    items: [
      {
        name: 'Arm Hole',
        value: '1.5',
        id: '1',
      },
    ],
  },
  {
    heading: 'Wrist',
    id: '6',
    items: [
      {
        name: 'Wrist',
        value: '1.5',
        id: '1',
      },
    ],
  },
  {
    heading: 'Ankle',
    id: '8',
    items: [
      {
        name: 'Ankle',
        value: '1.5',
        id: '1',
      },
    ],
  },
  {
    heading: 'Sleeves',
    id: '7',
    items: [
      {
        name: 'Sleeve Length',
        value: '1.5',
        id: '1',
      },
    ],
  },
];

const UpperBody = ({ styles,mea }) => {
  const l= mea?.mesurement?.upperbody
  const gender=mea?.gender
  const measurementData = [
    {
      heading: 'Neck',
      id: '1',
      items: gender==="He" || gender==="Boy" ?
      [
        {
          name: 'Neck',
          value: l?.Neck,
          id: '1',
        },
      ]
      :
      [
        {
          name: 'Neck Around',
          value: l?.Neck?.Neck_Around,
          id: '1',
        },
  
        {
          name: 'Neck front',
          value:l?.Neck?.Neck_Front,
          id: '2',
        },
        {
          name: 'Neck back',
          value: l?.Neck?.Neck_Back,
          id: '3',
        },
      ]
    },
    {
      heading: 'Shoulder',
      id: '2',
      items: [
        {
          name: 'Shoulder',
          value:l?.Shoulder,
          id: '1',
        },
      ],
    },
    {
      heading: 'Chest',
      id: '3',
      items: [
        {
          name: 'AroundChest',
          value:l?.Chest,
          id: '1',
        },
      ],
    },
    {
      heading: 'Arms',
      id: '5',
      items: [
        {
          name: 'Arm Hole',
          value: l?.Arm_Hole,
          id: '1',
        },
      ],
    },
    {
      heading: 'Wrist',
      id: '6',
      items: [
        {
          name: 'Wrist',
          value: l?.Wrist,
          id: '1',
        },
      ],
    },
    
    {
      heading: 'Sleeves',
      id: '7',
      items: [
        {
          name: 'Sleeve Length',
          value: l?.Sleeve_length,
          id: '1',
        },
      ],
    },
  ];
  return (
    <div>
      {measurementData.map(measurement => {
        const { heading, items, id } = measurement;

        return <AccordionComp key={id} heading={heading} items={items} />;
      })}
    </div>
  );
};

export default UpperBody;
