export const categoryMetaData = {
  men: {
    'new-arrivals': {
      title: `New Arrival in Men's Designer Clothing Collection`,
      description: `Shop new arrivals in men designer clothing at LynkTown. Explore our wide selection of men's clothes. Buy the latest men's fashion now.`,
    },
    'indo-western': {
      title: `Shop Designer Indo Western Dress for Men Online in India`,
      description: `	Shop online for the latest designer Indo Western dress for men in India. Find stylish kurta, jacket, sherwani, and more to create the perfect fusion look.`,
    },
    shirts: {
      title: `Shop Men's Shirts Online at LynkTown - Best Fashion Store`,
      description: `Shop casual shirts, formal shirts, printed shirts with patterns & more men's shirts online at LynkTown.`,
    },
    'formal-wear': {
      title: `Formal Clothes for Men - Buy Formal Wear for Men Online `,
      description: `Formal Clothes for Men - Shop for Mens Formal Wear shirts & trousers Online. Get Formal Wear for Men in various patterns & colours by top designers `,
    },
    kurtas: {
      title: `Designer Kurtas for Men - Shop Ethnic Kurta Set for Men Online`,
      description: `Shop designer kurtas for men online. Find the latest ethnic kurta sets and traditional Indian wear for men. Look stylish in designer kurtas.`,
    },
    haldi: {
      title: `Buy Haldi Outfit for Men Online | Buy Latest Ocassion wear`,
      description: `Looking for haldi dress for men online in India? Shop for the best haldi dress for men from our collection of exclusive, customized & handmade products.`,
    },
    mehendi: {
      title: `Shop Mehendi Outfits for Men - Unique & Custom Designs`,
      description: `Shop for unique custom mens mehendi outfits and dresses for your wedding events. Handmade pieces for grooms and guests.`,
    },
    sangeet: {
      title: `Sangeet Outfits for Groom online at best prices`,
      description: `Buy Indian sangeet dresses for men online. Choose from a wide range of Sherwanis, Kurta Sets, Achkan & more at the best price.`,
    },
    cocktail: {
      title: `Buy Men's Cocktail Party Wear Online At LynkTown`,
      description: `LynkTown offers an Exclusive Collection of Cocktail Party Dress for Men. Shop the latest collection of Cocktail Party Wear for Men online at best prices.`,
    },
    wedding: {
      title: `Men's Wedding Dresses - Buy Indian Wedding Dresses for Men Online`,
      description: `Wedding Dress for Men - Explore our wide collection of Wedding Suits for men. Buy wedding Sherwani, Kurtas, Pajamas, Indo Western ,Bandhgala etc.`,
    },
    reception: {
      title: `Shop Men's Wedding Reception Outfit Online in India`,
      description: `Looking for mens wedding reception outfit online in India? Shop for the best men's wedding reception outfit from our collection.`,
    },
    festive: {
      title: `Buy Festive Men's wear Collection Online`,
      description: `Shop for stylish men's festive wear including ethnic outfits and occasion wear. Find kurta sets, sherwanis, indo-western styles and more.`,
    },
    'ethnic-waistcoats': {
      title: `Shop Men's Designer Ethnic Waistcoats Online in India`,
      description: `Shop stylish Men's Ethnic Waistcoats Online on LynkTown. Choose from a massive collection of Designer Indian Ethnic Waistcoats for Men in India.`,
    },
    'kurta-sets': {
      title: `Buy Designer Kurta Set for Men Online`,
      description: `Explore our wide range of Kurta Sets for Men at best price. Shop from our trendy Designer Kurta Collection in various fabric and pattern`,
    },
    sherwani: {
      title: `Shop Designer Sherwani for men online at best price`,
      description: `Explore our wide range of Men sherwani online. Buy finest Sherwanis for groom for weddings and other special occasions from various designers.`,
    },
    'nehru-jacket': {
      title: `Buy Bundi & Nehru jacket for Men Online `,
      description: `Buy designer nehru jackets for men online. Shop now for traditional, ethnic, floral, and printed nehru jackets for a royal wedding.`,
    },
    bandhgala: {
      title: `Bandhgala for Men- Buy Jodhpuri Jacket for Men`,
      description: `LynkTown offers the latest range of designer Jodhpuri/Bandhgala Suits for men at the best prices. Get the best deals and variety online in India.`,
    },
    'bundi-jackets': {
      title: `Buy Kurta Bundi Set for Men Online `,
      description: `Shop Designer Nehru Jackets For Men, Bundi Jackets, Embroidered Jacket with Kurta Set, Pant Set, Jackets, Bandhgala, & more`,
    },
    'jackets-and-coats': {
      title: `Latest Men's Jacket and Coats Collection`,
      description: `Buy Mens jackets online from a huge collection of jackets & coats available at low prices. check out the huge collection of men's outerwear .`,
    },
    'suits-tuxedos': {
      title: `Shop Latest Designer Tuxedo Suits Online for Men in India `,
      description: `Shop Designer Tuxedo Suit For Men, Blazer Set, Tuxedo Set, Printed Tuxedo Set, Embroidered Tuxedo Set, & more`,
    },
    tuxedo: {
      title: `Shop Designer Tuxedo Suits for Men Online in India `,
      description: `Shop for Tuxedo Suits Online for Men in India. Checkout the wide range of Tuxedo suits from various designers in India`,
    },
    'bottom-wear': {
      title: `Shop the Latest Men's Bottom Wear Collection Online `,
      description: `Explore and buy the latest trends in men's bottom wear online. Choose from a diverse range of jeans, trousers, chinos, shorts, and more.`,
    },
    'for-groom': {
      title: `Buy Latest Designer Groom Wear Online`,
      description: `Shop for Indian groom wedding dress online. Explore the latest designer collections of grooms outfits like sherwanis, kurta sets, jodhpuri, Nehru jacket & more`,
    },
    christmas: {
      title: `Men's Christmas Clothing - Festive Outfits `,
      description: `Shop Christmas party wear for men. Browse for men's slim fit shirts, smart trousers, casual jackets, brogues and ties.`,
    },
    christmas: {
      title: `Men's Christmas Clothing - Festive Outfits `,
      description: `Shop Christmas party wear for men. Browse for men's slim fit shirts, smart trousers, casual jackets, brogues and ties.`,
    },
    christmas: {
      title: `Men's Christmas Clothing - Festive Outfits `,
      description: `Shop Christmas party wear for men. Browse for men's slim fit shirts, smart trousers, casual jackets, brogues and ties.`,
    },
    'makar-sankranti': {
      title: `Makar Sankranti Ethnic Collection- Ethnic wear for Men`,
      description: `Elevate your Makar Sankranti style! Embrace outfits that blend tradition and trend. Discover stylish designer ensembles at best price.`,
    },
    'party-wear': {
      title: `Buy Party Wear Dresses for Men Online in India`,
      description: `Shop for Party Wear Dresses For Men in India. Buy the latest Party Wear Dresses For Men from casual hangouts to formal affairs, find your perfect fit for any occasion.`,
    },
    shirts: {
      title: `Buy Shirt for Men- Best Selling Formal and Casual Shirts`,
      description: `Explore the best collection of casual & formal shirts, a variety of premium quality cotton, linen, and polyester shirts perfect for any occasion.`,
    },
    'ethnic-wear': {
      title: `Buy Designer Ethnic Wear for Men- Ethnic Wear Online`,
      description: `Men Ethnic Wear: Latest Indian Traditional Dresses for Men Online. Browse a wide collection of Kurtas, Ethnic Jacket, Sherwanis & more.`,
    },
    'ethnic-blazers': {
      title: `Buy Men Suits Blazer Ethnic Wear Online- Designer Ethnic Blazers`,
      description: `Men Suits Blazers Ethnic Wear Online. Shop for Men Suits Blazers Ethnic Wear in India, specially curated to fit all your occasions.`,
    },
    'blazers-and-coats': {
      title: `Shop Designer Blazers and Waistcoats for Men `,
      description: `Buy online casual, formal and designer party wear blazers for men. Explore our wide range of stylish tuxedos and colourful printed blazers with a variety of fabrics.`,
    },
    'formal-pants': {
      title: `Buy Latest Men's Formal Pants/Trousers Online in India`,
      description: `Buy from our wide range of men's formal trousers online in India. Pick from the latest styles in various fabrics.`,
    },
  },
  women: {
    'new-arrivals': {
      title: `Shop Designer New Arrivals for Women Online at Best.`,
      description: `Buy a variety of designer clothing for women online. Fashions, including lehengas, kurta sets, sarees, dresses, jumpsuits, tops, and more`,
    },
    festive: {
      title: `Buy Designer Festive Wear for Women`,
      description: `Indian festive wear for women online. Shop from a wide range of latest designer anarkali, salwar suits, sarees & more at best price`,
    },
    wedding: {
      title: `Shop Designer Wedding Collection for Women - Indian Wedding Dresses`,
      description: `Bridal Dresses Online Shopping Online. Shop designer outfit for every occasion which is haldi, mehendi,sangeet & more.`,
    },
    haldi: {
      title: `Buy Designer Haldi Dresses for Women Online- Occasion Wear `,
      description: `Buy designer haldi collection outfits featuring lehengas, kurtas, anarkalis, shararas, and much more`,
    },
    mehendi: {
      title: `Mehndi Dress for Brides- Buy Mehndi Outfit Online`,
      description: `Shop Mehendi Dresses for bride, gowns, anarkalis, yellow kurtis, jewellery, sarees, tunics, sharara suits from India's designers.`,
    },
    sangeet: {
      title: `Buy Designer Women's Dresses for Sangeet - Sangeet Outfits `,
      description: `Shop sangeet dresses for the bride at best prices. Buy designer gowns, lehenga, sharara, suits online in your size and colour.`,
    },
    'maternity-wear': {
      title: `Buy Designer Maternity Wear for Women- Baby Shower Dress`,
      description: `Shop maternity ethnic clothes online in soothing colours & prints. Attractive baby shower wear for the special moment of your life.`,
    },
    'plus-size': {
      title: `Plus size outfits - Buy Designer Plus Size Dresses for Women`,
      description: `Find custom-fit, elegant plus size dresses for women designed exclusively for your body. Look fabulous for any occasion.`,
    },
    sarees: {
      title: `Bridal & Wedding Sarees- Buy Designer Sarees for Women`,
      description: `Shop for designer sarees online. Choose from the latest designer sarees & blouse sets for weddings, parties, and other occasions`,
    },
    kurtas: {
      title: `Indian Designer Kurtis- Buy Indian Ethnic Wear`,
      description: `Shop for Kurtas Online - Buy the latest and designer Kurtas for women online. Check out latest range of Dresses & Jumpsuits, Kurtas & more.`,
    },
    'kurtis-and-sets': {
      title: `Kurta sets- Buy Designer Kurtas for Women Online in India`,
      description: `Wide Range Of Indian Designer Kurta Sets, Anarkalis, Long, Short & Dailywear Kurtis. Buy gorgeous Chanderi silk, muslin, chikankari and georgette kurta sets & more`,
    },
    tunics: {
      title: `Buy Indian Designer Kurtas & Tunic Online `,
      description: `Tunics and Tunic Sets. Explore our latest collection of designer tops and tunics for women, featuring a captivating blend of Indian styles and fusion wear designs.`,
    },
    blouses: {
      title: `Shop Latest Custom Design Blouse and Sarees`,
      description: `Buy Designer Blouses online, Custom Design Blouses, Ready Made Blouses, Saree Blouse patterns for any Occasion. Boat Neck Blouse, Princess Cut, Sleeveless Blouses and more.`,
    },
    'anarkalis-and-sets': {
      title: `Buy Designer Anarkali Suits Collection Online for Women In India`,
      description: `Shop designer anarkali suits for women online in India. Select from a wide range of anarkali kurtas & kurtis at attractive prices and latest designs.`,
    },
    skirts: {
      title: `Skirts- Buy Short Skirt, Mini & Long Skirts Online for Women`,
      description: `From delightful handblock printed long skirts to the new age chevron printed lehenga skirt like palazzos, we have all that you need right here with us!`,
    },
    lehengas: {
      title: `Lengha Choli- Buy Latest Designer Lengha for Women Online `,
      description: `Shop for latest designer lehenga online for women. Checkout exclusive range of Indian lehenga, trendy lehenga choli for any occasion`,
    },
    'knee-length': {
      title: `Designer Knee Length Dresses - Knee Length Dresses for Parties.`,
      description: `Find beautiful custom-fit knee length dresses perfect for parties, weddings, and any special occasion. Look spectacular in our occasion wear.`,
    },
    'ankle-length': {
      title: `Buy Ankle length Dresses for Women Online in India `,
      description: `Make a lasting impression at any formal event with our exquisite range of formal dresses. From elegant floor-length gowns to chic cocktail dresses`,
    },
    maxi: {
      title: `Shop Maxi Dresses for Women - Party Wear Dresses `,
      description: `Explore our collection of full sleeve and full-length maxi dresses, where elegance and sophistication meet.`,
    },
    gown: {
      title: `Buy Latest Designer Gowns for Women Online - Bridal Gowns/Wedding Gown`,
      description: `Buy the latest party wear designer gowns for women and girls online. Wide range of designer gowns for Indian wedding & reception parties.`,
    },
    'shirt-dress': {
      title: `Shirt Dresses for Women Online at Best price `,
      description: `Explore a collection of trendy Dresses for Women Online. Buy Womens Dresses like A-Line, Midi, Bodycon & more`,
    },
    'pleated-dress': {
      title: `Women's Pleated Dresses - Women's Dresses /Jumpsuits `,
      description: `Women's Pleated Dresses -Buy pleated dresses for any occasion whether it's formal or wedding function.`,
    },
    mini: {
      title: `Buy Mini Skirts for Girls Online `,
      description: ` Mini Skirt For Women & Ladies at Affordable Prices. Shop the latest corduroy A-line styles, distressed denim cuts, figure-hugging bodycon silhouettes & more.`,
    },
    short: {
      title: `Shop for Latest Short Dresses Designs Online`,
      description: `Shop for Latest Short Dresses Designs Online`,
    },
    'for-bride': {
      title: `Shop Latest Designer Bridal Dresses for Women Online in India`,
      description: `Buy custom-fit designer bridal dresses online for your wedding events. Shop Designer dresses for haldi, mehendi, sangeet outfits and wedding dresses designed just for you.`,
    },
    christmas: {
      title: `Buy Womens Christmas Party Dresses -Women's Holiday Wear`,
      description: `Looking for christmas dress online in India? Shop for the best christmas dress from our collection of exclusive, customized & handmade products.`,
    },
    'makar-sankranti': {
      title: `Makar Sankranti Ethnic Collection-Sarees & Kurtas for Women `,
      description: `Discover the perfect Makar Sankranti saree look with our exclusive collection. Shop for Sankranti and find the ideal ethnic wear. `,
    },
    party: {
      title: `Women Party Wear Dresses- Buy cocktail and party dresses online`,
      description: `Party Wear Dress Of Women Online. Shop for Party Wear Dress Of Women in India. From elegant black styles, off the shoulder numbers and dazzling sequin dresses.`,
    },
    'indo-western': {
      title: ` Designer Indo western Dresses - Buy Western Wear for Women`,
      description: `Designer Indo Western Dresses for Women - Buy indo western wear for girls and ladies online in India. Shop latest collection of women's Indo western dresses for every occasion `,
    },
    'co-ord-sets': {
      title: `Buy Co-Ord Sets for Women - Formal, Lounge and Party wear sets`,
      description: `Custom-Fit Co-Ord Sets for Any Occasion. Find stylish and custom-fit co-ord sets for women, including formal, party, and lounge wear. `,
    },
    'ethnic-wear': {
      title: `Buy Latest Designer collection for Women's Ethnic Wear `,
      description: `Ethnic wear: Shop for ethnic wear online at best prices in India. Choose from a wide range of kurtis, sarees, salwar suits, lehenga cholis, dress & more.`,
    },
    'top-wear': {
      title: `Women's Tops- Buy Women's Tops Online in India`,
      description: `Tops - Shop from latest collection of Top Online in India. Get tube, maxi, kaftan, tank, peplum, wrap & more types of women & girls Tops`,
    },
    'bottom-wear': {
      title: `Buy Latest Collection of Bottoms Ethnic Indian Wear`,
      description: `Shop for Bottom Wear Online -Discover a range of stylish options, including pants, trousers, palazzos, salwars, leggings, jeggings, and more`,
    },
    jumpsuits: {
      title: `Jumpsuits -Shop Designer Jumpsuit for Women Online`,
      description: `Designer Indian Jumpsuits. Explore exquisite collection of Fusion and Contemporary Designer Jumpsuits and Rompers from Indian Fashion Designers. `,
    },
    'wrap-dress': {
      title: `Buy Wrap Dresses for Women Online in India at Best Price `,
      description: `Wrap Dresses for Every Occasion. From midi wrap dresses perfect for work to elegant satin occasion dresses, get the look you love with our edit.`,
    },
    bodycon: {
      title: `Buy Latest Collection of Bodycon Dresses for Women `,
      description: `Full Range of Bodycon Dresses: Ribbed Bodycon Dresses, Short - Long Dress & More. Explore an exquisite collection of bodycon dresses`,
    },
    'tube-dress': {
      title: `Buy Stylish Tube Dress Collection at Best Price `,
      description: `Buy Tube Dress from top rated designers. Shop from widest range of vibrant colours & patterns to avail hassle free returns `,
    },
    'a-line': {
      title: `A Line Dress - Buy A-line Dress for Women Online `,
      description: `A- line Dress Dresses for Women - Shop A- line Dress Dresses for Women Online at Low Prices in India. Wide range of A- line Dress Dresses.`,
    },
    reception: {
      title: `Reception Wear Dresses for Women - Gowns, Lengha,Saree`,
      description: `FReception Dress For Bride - Shop designer gown, lehenga & saree for reception online. Get your perfect Reception Dress in unique designs at great prices`,
    },
    'modern-classics': {
      title: `Buy Classic Modern Women Dress Online  `,
      description: `Shop wide collection of modern classics from A-line dresses, shirt dresses, wrap dresses, shift dress, pencil dress, shirtwaist dresses, sheath dresse & more.`,
    },
    'bollywood-glam': {
      title: `Bollywood Glam- Buy Bollywood Ethnic Dress for Women `,
      description: `Buy Bollywood Fashion clothes and other Bollywood replica dresses from our collection of exclusive, customized & handmade products.`,
    },
    'bridal-glam-celebs-looks': {
      title: `Shop Bridal Dresses, Designer gowns, Lenghas Online`,
      description: `Shop bridal dresses, gowns, lehengas, anarkalis, gowns, sarees, kurta, and wedding outfits from top fashion designers`,
    },
    'exclusive-on-lynktown': {
      title: `Buy Top Designer Collection Exclusive on LynkTown `,
      description: `Shop our exclusive collection of custom-fit designer lenghas, wedding gowns, partywear, and occasion wear for any celebration. `,
    },
    'under-5k': {
      title: `Buy Top Designer Collection Exclusive on LynkTown `,
      description: `Shop our exclusive collection of custom-fit designer lenghas, wedding gowns, partywear, and occasion wear for any celebration. `,
    },
    'celebrity-ethnic': {
      title: `Shop Celebrity Inspired Dresses for Women Online `,
      description: `Discover stunning celebrity Indian dresses, clothing, and outfits online. Wide range of celebrity wear at best prices.`,
    },
    'shirt-dress ': {
      title: `Shirt Dresses for Women Online at Best price `,
      description: `Explore a collection of trendy Dresses for Women Online. Buy Womens Dresses like A-Line, Midi, Bodycon & more`,
    },
    'pleated-dress ': {
      title: `Women's Pleated Dresses - Women's Dresses /Jumpsuits `,
      description: `Women's Pleated Dresses -Buy pleated dresses for any occasion whether it's formal or wedding function.`,
    },
    'mini ': {
      title: `Buy Mini Skirts for Girls Online `,
      description: ` Mini Skirt For Women & Ladies at Affordable Prices. Shop the latest corduroy A-line styles, distressed denim cuts, figure-hugging bodycon silhouettes & more.`,
    },
    'short ': {
      title: `Shop for Latest Short Dresses Designs Online`,
      description: `Shop for Latest Short Dresses Designs Online`,
    },
    'for-bride ': {
      title: `Shop Latest Designer Bridal Dresses for Women Online in India`,
      description: `Buy custom-fit designer bridal dresses online for your wedding events. Shop Designer dresses for haldi, mehendi, sangeet outfits and wedding dresses designed just for you.`,
    },
  },
  kids: {
    'new-arrivals': {
      title: `New Arrivals - Buy Latest Collection of Kids Wear Online`,
      description: `Exploring best quality latest designer collection for your kids? Check our range of beautiful kids new arrival collection online.`,
    },
    'festive-wear': {
      title: `Shop Kids Festive Wear - Kids Ethnic Wear`,
      description: `Kids' festive wear for little boys and girls is available in ethnic, contemporary, and indo-western styles. Discover the latest styles of designer outfits for kids`,
    },
    'exclusively-on-lt': {
      title: `Custom-Fit Kids' Fashion for Every Occasion, Exclusively on LynkTown`,
      description: `Find custom-fit kids' fashion for every occasion - from practical playwear to designer party styles for boys and girls. Shop our exclusive selection on LynkTown!`,
    },
    'birthday-bash': {
      title: `Shop Stylish Party Wear Dresses for Kids- Birthday Party Dress`,
      description: `Choose from an exclusive designer collection of shirts and trousers, white shirts, dresses, suit sets & more from top fashion designers.`,
    },
    '0-2-years': {
      title: `Dresses for New Borns (0-2 Years)-Boys & Girls`,
      description: `Shop our adorable collection of rompers, onesies, bibs, suits, sets, night dresses, and more baby clothes for newborns 0-2 years.`,
    },
    '2-5-years': {
      title: `Kids Wear Exclusively for 2-5 Years - Shorts, Shirts & More `,
      description: `Shop our selection of kids wear including shorts, trousers, shirts, dresses, and sets exclusively designed for ages 2-5 years. `,
    },
    '5-12-years': {
      title: `Kids Wear 5-12 Years - Buy Children Clothes Online `,
      description: `Buy casual, festive and party kidswear online from India's leading designers. Discover latest styles for girls and boys.`,
    },
    '12-15-years': {
      title: `12-15 Years Kids Wear Online- Latest Kids Collection `,
      description: `Explore trendy 12-15 years kids' wear online. Discover the latest kids' collection featuring stylish and comfortable clothing for every occasion`,
    },
  },
  twinning: {
    'mom-daughter-duo': {
      title: `Mother-Daughter Twinning Dresses - Twinning in Style`,
      description: `Find the perfect mother-daughter twinning dresses to match styles with your mini-me. Look beautiful while twinning with your daughter in trendy dresses.`,
    },
    'couples-twinning': {
      title: `Buy Matching Couple Twinning Outfits Online in India `,
      description: `Discover the perfect harmony of style and connection with our exquisite collection of couple twinning dresses`,
    },
    'mom-son-duo': {
      title: `Shop Mother Son Matching Dress Online in India `,
      description: `Find the perfect twinning dresses online in India for you and your son. Match in style with trendy mother son matching outfits and make memories.`,
    },
    'dad-son-duo': {
      title: `Father - Son Matching Outfits - Kurta Pajama, Jackets , Sherwani & more`,
      description: `Shop Online Latest Father and Son Matching outfits, Kurta Pajamas, Jackets, Sherwin's, Dhoti Kurta, Jacket Kurta Pajama, Pathani & more`,
    },
    'family-twinning': {
      title: `Shop for Family Twinning Outfits Online in India `,
      description: `Shop Matching Outfits for all. Stylish Family Party Dresses for Family Photoshoot, Indian Wedding Matching Ethnic Wear and Family Combo Dresses & more`,
    },
  },
};
