import React from 'react';
import MyImage from '../../../Components/Common/ImageLoader';
import { imageurl } from '../../../config';
import styles from './detailsCard.module.scss';
import example from './images/example.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';

const DetailsCard = ({ title, data }) => {
  return (
    <div className={styles.detailsCard}>
      <div className={styles.detailsCard__header}>
        <h1>{title || 'Product details  '}</h1>
      </div>
      <div className={styles.detailsCard__body} style={{ flexWrap: 'wrap' }}>
        <div className={styles.detailsCard__body__img}>
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            style={{ width: '100%' }}
          >
            {data.images?.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{ minWidth: '100%', height: '250px' }}
                >
                  <div style={{ height: '250px' }}>
                    <Zoom>
                      <img
                        src={imageurl(item)}
                        alt=''
                        style={{
                          minWidth: 100,
                          height: '120px',
                          objectPosition: 'top center',
                        }}
                      />
                      {/* <MyImage src={item} alt='' width={200} height={200} /> */}
                    </Zoom>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* <img
            src={imageurl(data?.images[0])}
            alt=''
            style={{ minWidth: 100 }}
          /> */}
        </div>
        <div
          className={styles.detailsCard__body__content}
          style={{ maxWidth: '100%', display: 'flex' }}
        >
          <a
            href={data?.lynk}
            style={{ maxWidth: '100%' }}
            target='_blank'
            rel='noopener noreferrer'
          >
            {data?.lynk?.substring(0, 20) + '...'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default DetailsCard;
