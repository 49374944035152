import { createSlice } from "@reduxjs/toolkit";
import firebase from "../../firebase/firebase";
import { startLoading, startmodal } from "./ui";
import moment from "moment";
import { setlocalmeasure } from "./mesurement";
const initialState = {
  lynksDetail: {},
  alllynks: [],
  greenlight: false,
  showMeasurementModal: false,
  measurementInfo: null,
  customProducts: [],
  readyMadeProducts: [],
  allCommonlynks: [],
};

const slice = createSlice({
  name: "lynks",
  initialState,
  reducers: {
    lynksdetail(state, action) {
      state.lynksDetail = action.payload;
    },
    getAlllinks(state, action) {
      state.alllynks = action.payload;
    },
    greenlight(state, action) {
      state.greenlight = action.payload;
    },
    measurementModal(state, action) {
      state.showMeasurementModal = action.payload;
    },
    measurementInfo(state, action) {
      state.measurementInfo = action.payload;
    },
    getAllCustomProductlinks(state, action) {
      state.customProducts = action.payload;
    },
    getAllReadyProductlinks(state, action) {
      state.readyMadeProducts = action.payload;
    },
    getAllProductlinks(state, action) {
      state.allCommonlynks = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const closeMeasurementModal = () => {
  return (dispatch, state) => {
    dispatch(slice.actions.measurementModal(false));
    dispatch(slice.actions.measurementInfo(null));
    sessionStorage.setItem("showMeasurementModal", false);
  };
};

export const setuser = (user) => {
  return (dispatch) => {
    dispatch(slice.actions.userdetails(user));
  };
};

export const getLynksDetails = (id) => {
  return (dispatch, getstate) => {
    dispatch(slice.actions.lynksdetail({}));

    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection("lynks")
        .doc(id)
        .get()
        .then((res) => {
          const data = res.data();
          if (!data.user) {
            firebase
              .firestore()
              .collection("lynks")
              .doc(id)
              .update({ user: uid })
              .then(async (res2) => {
                await firebase
                  .firestore()
                  .collection("vendors")
                  .doc(res.data().vendor)
                  .get()
                  .then(async (vendor) => {
                    dispatch(
                      slice.actions.lynksdetail({
                        ...res.data(),
                        vendor: vendor.data(),
                      })
                    );
                    dispatch(startLoading(false));
                  })
                  .catch(() => {
                    dispatch(startLoading(false));
                  });
              })
              .catch((err) => {
                dispatch(startLoading(false));
              });
          } else {
            if (data.user === uid) {
              firebase
                .firestore()
                .collection("vendors")
                .doc(res.data().vendor)
                .get()
                .then((vendor) => {
                  dispatch(startLoading(false));
                  dispatch(
                    slice.actions.lynksdetail({
                      ...res.data(),
                      vendor: vendor.data(),
                    })
                  );
                })
                .catch(() => {
                  dispatch(startLoading(false));
                });
            } else dispatch(slice.actions.lynksdetail(null));
          }
        })
        .catch((err) => {});
    }
  };
};

export const getAlllinks = () => {
  return (dispatch, getstate) => {
    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection("lynks")
        .where("user", "==", uid)
        .where("status", "<", 3)
        .onSnapshot((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data());
          const ids = querySnapshot.docs.map((doc) => doc.id);
          const final = data
            .map((item, index) => {
              return { ...item, id: ids[index] };
            })
            .sort((a, b) => moment(b.createdDate) - moment(a.createdDate));

          dispatch(slice.actions.getAlllinks(final));
          const greenlight = sessionStorage.getItem("lynkgreen");
          if (!greenlight) {
            sessionStorage.setItem("lynkgreen", final?.length);
            dispatch(slice.actions.greenlight(false));
          } else if (greenlight === final?.length) {
            dispatch(slice.actions.greenlight(false));
          } else if (greenlight < final?.length) {
            dispatch(slice.actions.greenlight(true));
          }

          const showMeasurementModal = sessionStorage.getItem(
            "showMeasurementModal"
          );

          let measurementInfo;
          final.forEach((item) => {
            if (
              item.paymentRequired &&
              item.paymentdetails &&
              item.measurementRequired &&
              !item?.measurementDetails
            ) {
              measurementInfo = {
                id: item.id,
                businessName: item.businessname,
              };
              return;
            } else {
              return false;
            }
          });

          if (showMeasurementModal === null && measurementInfo) {
            sessionStorage.setItem("showMeasurementModal", true);
            dispatch(slice.actions.measurementModal(true));
            dispatch(slice.actions.measurementInfo(measurementInfo));
          } else {
            dispatch(
              slice.actions.measurementModal(JSON.parse(showMeasurementModal))
            );
            if (measurementInfo) {
              dispatch(slice.actions.measurementInfo(measurementInfo));
            } else {
              dispatch(slice.actions.measurementInfo(null));
            }
          }
          //  else {
          //   dispatch(slice.actions.measurementModal(true));
          //   dispatch(slice.actions.measurementInfo(measurementInfo));
          // }

          dispatch(startLoading(false));
        });
    }
  };
};

export const saveaddress = (id, data2, status, size, isshipiing) => {
  return async (dispatch, getstate) => {
    let newItems = [];
    const dd = { status };
    if (isshipiing) dd["userdetails"] = data2;
    if (size.length > 0) {
      dd["selected_size"] = size;
      await firebase
        .firestore()
        .collection("lynks")
        .doc(id)
        .get()
        .then((res) => {
          const data = res.data();
          const items = [...data?.items];
          newItems = [...items];
          size.map((i, index) => {
            let inx = newItems.findIndex((ii) => ii.id === i.id);
            newItems[inx].selected_size = i.size;
          });
        });
      dd["items"] = [...newItems];
    }
    if (status === "3" || status === 3) {
      dd["Orderdate"] = firebase.firestore.FieldValue.serverTimestamp();
    }
    firebase
      .firestore()
      .collection("lynks")
      .doc(id)
      .update({ ...dd })
      .then(() => {
        if (status === "3" || status === 3) {
          firebase
            .firestore()
            .collection("orders")
            .add({
              lynks: firebase.firestore().collection("lynks").doc(id),
              status: 0,
            });
          dispatch(startLoading(false));
          dispatch(
            startmodal({
              type: "success",
              message: "Order Placed!",
              description:
                "Thankyou for your order, check your order details in the order section",
              btn: "View order details",
              status: "true",
              width: "645px",
              height: "341px",
              closeBtn: true,
            })
          );
        }
      });
  };
};

export const submitmesurement = (id, data, navi) => {
  return (dispatch, getstate) => {
    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection("lynks")
        .doc(id)
        .update({
          mesurementdetails: data,
          status: 3,
          Orderdate: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          firebase
            .firestore()
            .collection("orders")
            .add({
              lynks: firebase.firestore().collection("lynks").doc(id),
              status: 0,
              user: uid,
              Orderdate: firebase.firestore.FieldValue.serverTimestamp(),
            });
          dispatch(startLoading(false));
          // dispatch(
          //   startmodal({
          //     type: 'success',
          //     message: 'Your Measurement added Successfully',
          //     status: true,
          //   })
          // );
          navi &&
            dispatch(
              startmodal({
                type: "success",
                message: "Order Placed!",
                description:
                  "Thankyou for your order, check your order details in the order section",
                btn: "View order details",
                status: "true",
                width: "645px",
                height: "341px",
                navigate: "/",
                onClose: () => {
                  dispatch(setlocalmeasure({}));
                },
              })
            );
        })
        .catch(() => {
          dispatch(startLoading(false));
        });
    }
  };
};

export const savepaymnet = (id, data, status, lynksDetail) => {
  return (dispatch, getstate) => {
    const uid = firebase.auth().currentUser?.uid;
    const parentLynk = lynksDetail.parentLynk;
    firebase
      .firestore()
      .collection("commonlynks")
      .doc(parentLynk)
      .get()
      .then((res) => {
        const parentData = res.data();
        let finalItems = structuredClone(parentData.items);
        if (!parentData.measurementRequired) {
          const items = structuredClone(parentData.items);
          finalItems = items.map((item) => {
            return {
              ...item,
              stocks: parseInt(item.stocks) - 1,
            };
          });
        }
        firebase
          .firestore()
          .collection("commonlynks")
          .doc(parentLynk)
          .update({ items: finalItems })
          .then(() => {
            console.log("updated");
          });
      });

    if (uid) {
      const o = { paymentdetails: data, status };
      if (status === "3" || status === 3) {
        o["Orderdate"] = firebase.firestore.FieldValue.serverTimestamp();
      }
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection("lynks")
        .doc(id)
        .update(o)
        .then(() => {
          dispatch(startLoading(false));
          if (status === "3" || status === 3) {
            dispatch(
              startmodal({
                type: "success",
                message: "Order Placed!",
                description:
                  "Thankyou for your order, check your order details in the order section",
                btn: "View order details",
                status: "true",
                width: "645px",
                height: "341px",
              })
            );
            firebase
              .firestore()
              .collection("orders")
              .add({
                lynks: firebase.firestore().collection("lynks").doc(id),
                status: 0,
                user: uid,
                Orderdate: firebase.firestore.FieldValue.serverTimestamp(),
              });

            dispatch(startLoading(false));
          }
        })
        .catch(() => {
          dispatch(startLoading(false));
        });
    }
  };
};

export const setgreenlight = (ff, count) => {
  return (dispatch) => {
    dispatch(slice.actions.greenlight(ff));
    sessionStorage.setItem("lynkgreen", count);
  };
};

export const getAllCustomProductlinks = () => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));
    firebase
      .firestore()
      .collection("commonlynks")
      .where("measurementRequired", "==", true)
      .where("inCompleteProfile", "==", false)
      .where("acceptingOrders", "==", true)
      .where("isDeleted", "==", 0)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        const ids = querySnapshot.docs.map((doc) => doc.id);
        const final = data
          .map((item, index) => {
            return { ...item, id: ids[index] };
          })
          .sort((a, b) => moment(b.createdDate) - moment(a.createdDate));
        dispatch(slice.actions.getAllCustomProductlinks(final));
        dispatch(startLoading(false));
      });
  };
};

export const getAllReadyMadeProductlinks = () => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));
    firebase
      .firestore()
      .collection("commonlynks")
      .where("measurementRequired", "==", false)
      .where("inCompleteProfile", "==", false)
      .where("acceptingOrders", "==", true)
      .where("isDeleted", "==", 0)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        const ids = querySnapshot.docs.map((doc) => doc.id);
        const final = data
          .map((item, index) => {
            return { ...item, id: ids[index] };
          })
          .sort((a, b) => moment(b.createdDate) - moment(a.createdDate));

        dispatch(slice.actions.getAllReadyProductlinks(final));
        dispatch(startLoading(false));
      });
  };
};

const getVendorData = async (data) => {
  let arr = data;
  for (let i in arr) {
    const vendorData = await firebase
      .firestore()
      .collection("vendors")
      .doc(data[i].vendor)
      .get();
    arr[i].vendorData = vendorData;
  }
  return arr;
};

export const getAllroductlinks = () => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));
    firebase
      .firestore()
      .collection("commonlynks")
      .where("inCompleteProfile", "==", false)
      .where("acceptingOrders", "==", true)
      .where("isDeleted", "==", 0)
      .onSnapshot(async (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        const ids = querySnapshot.docs.map((doc) => doc.id);
        const final = data
          .map((item, index) => {
            return { ...item, id: ids[index] };
          })
          .sort((a, b) => moment(b.createdDate) - moment(a.createdDate));
        dispatch(slice.actions.getAllProductlinks(final));
        dispatch(startLoading(false));
      });
  };
};
