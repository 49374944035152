import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import React from 'react';
import ErrorBox from '../ErrorBox';
import styles from './input.module.scss';
import { MdRemoveRedEye } from 'react-icons/md';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
const Input = ({ label, inline, type, classes, radio, ...props }) => {
  const [field, meta] = useField({ ...props, type });

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <div
      className={styles.inputContainer}
      style={{
        position: 'relative',
      }}
    >
      <TextField
        label={label}
        variant='outlined'
        {...field}
        {...props}
        type={type === 'password' && showPassword ? 'text' : type}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        className={styles.inputField}
        onPaste={e => {
          if (type === 'password') {
            e.preventDefault();
            return false;
          }
        }}
        onCopy={e => {
          if (type === 'password') {
            e.preventDefault();
            return false;
          }
        }}
        sx={{
          '.MuiFormHelperText-root': {
            textAlign: 'left',
            fontSize: '1.4rem',
          },
        }}
      />

      {type === 'password' && (
        <IconButton
          type='button'
          aria-label='toggle password visibility'
          sx={{
            position: 'absolute',
            right: '0',
            top: '5px',
          }}
          onClick={handleClickShowPassword}
        >
          {showPassword ? <IoMdEye size={20} /> : <IoMdEyeOff size={20} />}
          {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
        </IconButton>
      )}

      {/* <ErrorMessage name={field.name} component={ErrorBox} /> */}
    </div>
  );
};

export default Input;
