import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';

import ItemCard from '../../Components/ItemsCard/ItemCard';

import { setgreenlight } from '../../redux/slices/lynks';
import moment from 'moment';
import EmptyData from '../../Components/EmptyData/EmptyData';
import { lynkStatus } from '../../utils/statusfinder';
const Lynks = () => {
  const dispatch = useDispatch();
  const links = useSelector(state => state.lynks);
  const { loading } = useSelector(state => state.ui);
  useEffect(() => {
    if (links?.alllynks?.length > 0) {
      dispatch(setgreenlight(false, links?.alllynks?.length));
    }
  }, [links]);

  return (
    <div>
      <BreadCrumbs currentPath='Lynks' paths={[{ name: 'Home', to: '/' }]} />
      <div>
        <SectionNavigation heading='Lynks' back='/' />
      </div>
      {!loading && !links?.alllynks?.length && (
        <EmptyData
          label='Lynks'
          description='Contact fashion designers to get Lynks.'
          svg={
            <svg
              width='101'
              height='101'
              viewBox='0 0 101 101'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M92.943 7.66273C97.6902 12.4267 100.356 18.878 100.356 25.6035C100.356 32.329 97.6902 38.7803 92.943 43.5443L83.9432 52.4852C77.3551 59.0733 68.0612 61.1321 59.5908 58.8969L75.0022 43.5443L79.4727 39.015L83.9432 34.5445C88.8842 29.6034 88.8842 21.6036 83.9432 16.6625C82.7769 15.4745 81.3855 14.5308 79.8504 13.8865C78.3152 13.2423 76.6671 12.9105 75.0022 12.9105C73.3374 12.9105 71.6892 13.2423 70.1541 13.8865C68.6189 14.5308 67.2275 15.4745 66.0612 16.6625L61.5319 21.133L41.65 41.0149C39.4736 32.5445 41.5324 23.2506 48.1205 16.6625L57.0614 7.66273C61.8255 2.91554 68.2768 0.25 75.0022 0.25C81.7277 0.25 88.179 2.91554 92.943 7.66273ZM39.1207 70.426L70.5317 39.015C73.0023 36.5444 73.0023 32.5445 70.5317 30.074C67.9436 27.5446 63.8848 27.7799 61.5319 30.074L30.1797 61.485C27.7092 63.9556 27.7092 67.9555 30.1797 70.426C32.7679 72.9554 36.8266 72.7201 39.1207 70.426ZM34.6502 83.8963L59.0026 59.4851C61.2378 67.9555 59.179 77.2494 52.591 83.8375L43.65 92.8373C38.886 97.5845 32.4347 100.25 25.7092 100.25C18.9838 100.25 12.5325 97.5845 7.76844 92.8373C3.02125 88.0732 0.355713 81.6219 0.355713 74.8965C0.355713 68.171 3.02125 61.7197 7.76844 56.9557L16.7682 48.0148C23.3563 41.4267 32.6502 39.3679 41.1206 41.5443L16.7682 65.9555C11.7684 70.8966 11.7684 78.8964 16.7682 83.8963C21.7093 88.8374 29.7091 88.8374 34.6502 83.8963Z'
                fill='#E6E3DC'
                fillOpacity='0.5'
              />
            </svg>
          }
        />
      )}
      <div className='items--common__container'>
        {links?.alllynks?.map(item => {
          if (!item?.isDeleted) {
            return (
              <ItemCard
                key={item.id}
                status={lynkStatus(item)}
                link={`/${item?.businessname?.replaceAll(' ', '-')}/${item.id}`}
                designerName={item?.businessname}
                totalProducts={item?.items?.length}
                date={moment(item?.createdDate).format('DD/MM/YYYY')}
                price={item.total}
                measurementRequired={item.measurementRequired}
                paymentRequired={item.paymentRequired}
                shippingRequired={item.shippingRequired}
                id={item.id}
                to={`/${item?.businessname?.replaceAll(' ', '-')}/${item.id}`}
                products={item.items}
                item={item}
              />
            );
          } else return <></>;
        })}
      </div>
    </div>
  );
};

export default Lynks;
