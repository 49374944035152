import React from 'react';
import {
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { MOBILE_WIDTH } from '../../constants/breakPoints';

const ModalComponent = ({
  open,
  handleClose,
  width,
  height,
  children,
  top,
  padding,
  closeBtn,
  maxHeight,
}) => {
  const mobile = useMediaQuery(MOBILE_WIDTH);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: width || '567px',
    width: '100%',
    height: height || '450px',
    backgroundColor: `var(--white)`,
    padding: padding || '4rem 3rem',
    boxShadow: 24,
    textAlign: 'center',
    zIndex: 10000000000000,
    borderRadius: '10px',
    outline: 'none',
    overflowY: 'auto',
    maxHeight: maxHeight,
  };

  const styleMobile = {
    position: 'absolute',
    bottom: top || '20px',
    left: '50%',
    marginTop: '0 2rem',
    maxWidth: width || '95vw',
    transform: 'translateX(-50%)',
    width: '95vw',
    height: height || '400px',
    backgroundColor: `var(--white)`,
    padding: '2.2rem 1rem',
    boxShadow: 24,
    textAlign: 'center',
    zIndex: 10000000000000,
    borderRadius: '10px',
    outline: 'none',
    maxHeight: maxHeight,
    overflowY: 'auto',
  };
  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableBackdropClick={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={mobile ? styleMobile : style}>
            {closeBtn && (
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  zIndex: 10,
                }}
              >
                <svg
                  width='26'
                  height='27'
                  viewBox='0 0 26 27'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle cx='12.998' cy='13.3027' r='12.834' fill='#DA1E28' />
                  <path
                    d='M17.2747 9.02539L8.71875 17.5814'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M8.71875 9.02539L17.2747 17.5814'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </IconButton>
            )}

            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalComponent;
