import React from 'react';
import styles from './for-men.module.scss';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image4 from './images/4.png';
import { Button, useMediaQuery } from '@mui/material';
import { MOBILE_WIDTH, TAB_WIDTH } from '../../../constants/breakPoints';
import { Link } from 'react-router-dom';

const ForMens = () => {
  const isMobile = useMediaQuery(MOBILE_WIDTH);
  const isTab = useMediaQuery(TAB_WIDTH);
  return (
    <section className={styles.section}>
      <div className='container'>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <h1>Be the perfect man for every occasion</h1>
        </div>
        <div className={styles.items}>
          <div className={styles.item}>
            <div
              className={styles.img}
              style={{
                height: isMobile ? '222px' : isTab ? '400px' : '631.89px',
              }}
            >
              <Link to='/collection/men/kurtas'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fkurta-men.png?alt=media&token=a7ed2d1e-a2eb-4f6a-8a60-61c012415bc8'
                  alt=''
                />
                <p>Kurtas</p>
                <div></div>
              </Link>
            </div>
            <div
              className={styles.img}
              style={{
                height: isMobile ? '222px' : isTab ? '420px' : '684.2px',
              }}
            >
              <Link to='/collection/men/suits'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fsuits.png?alt=media&token=3f4a34f7-8453-42d9-9699-bf1bd50319cd'
                  alt=''
                />
                <p>Suits</p>
                <div></div>
              </Link>
            </div>
          </div>
          <div
            className={styles.item}
            style={{
              marginTop: isMobile ? '30px' : isTab ? '50px' : '120px',
            }}
          >
            <div
              className={styles.img}
              style={{
                height: isMobile ? '232px' : isTab ? '450px' : '757.17px',
              }}
            >
              <Link to='/collection/men/indo-western'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Findo-western-men.png?alt=media&token=5872786c-6533-4d16-b28f-88d61f954f50'
                  alt=''
                />
                <p>Indo western</p>
                <div></div>
              </Link>
            </div>
            <div
              className={styles.img}
              style={{
                maxWidth: '395px',
                height: isMobile ? '222px' : isTab ? '350px' : '543px',

                marginTop: isMobile ? '10px' : isTab ? '40px' : '80px',
              }}
            >
              <Link to='/collection/men/formal-wear'>
                <img
                  src='https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fformal-wear.png?alt=media&token=3d9b7b5e-eca0-4415-a21d-837ffa5d9753'
                  alt=''
                />
                <p>Formal wear</p>
                <div></div>
              </Link>
            </div>
          </div>
        </div>
        <Link to='/men'>
          <Button className={styles.btn}>Show all</Button>
        </Link>
      </div>
    </section>
  );
};

export default ForMens;
