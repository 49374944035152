import React from "react";
import styles from "./forgot.module.scss";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Input from "../../../Common/Form/Feilds/Input";
import LoginButton from "../LoginButton";
import firebase from "../../../../firebase/firebase";
const ForgotPassword = ({ setCurrentStep, setnumber }) => {
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("*Enter a valid email")
      .required("*Email is required"),
  });

  const handleSubmit = (values) => {
    console.log("🚀 ~ file: index.js:18 ~ handleSubmit ~ values:", values);
    firebase
      .auth()
      .sendPasswordResetEmail(values.email)
      .then(() => {
        alert("An email has been sent to your registered email id");
      })
      .catch((error) => {
        console.log(error);
        alert("Invalid email, user not found");
      });
  };
  return (
    <div className={styles.verfiyContainer}>
      <div>
        <h1>Forgot password</h1>
        <p>Please Enter your Register email</p>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            const { values, isValid, setTouched, submitCount, errors } = formik;
            console.log(
              "🚀 ~ file: index.js:34 ~ ForgotPassword ~ errors:",
              errors
            );

            return (
              <Form className={styles.formControl}>
                <div className={styles.formGroup}>
                  <Input
                    // type='email'
                    id="email"
                    name="email"
                    label={"Enter Register Email"}
                  />
                </div>

                <button
                  type="button"
                  onClick={() => setCurrentStep(2)}
                  className={styles.forgotPassword}
                >
                  Back to Login
                </button>

                <div>
                  <LoginButton type="submit">Submit</LoginButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
