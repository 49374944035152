import cx from 'classnames';
import styles from './measurement.module.scss';
//image
import img from './body.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { Pagination } from 'swiper';
import { Navigation } from 'swiper';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { submitmesurement } from '../../redux/slices/lynks';
import { useEffect } from 'react';
import {
  setlocalmeasure,
  getMesurementDetails,
} from '../../redux/slices/mesurement';
import firebase from '../../firebase/firebase';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import ViewMeasurementMobile from '../ViewMeasurement/ViewMeasurementMobile/ViewMeasurementMobile';
export default function ViewMeasurement() {
  const tabView = useMediaQuery('(max-width:768px)');
  const mobileView = useMediaQuery(MOBILE_WIDTH);
  const dispatch = useDispatch();
  const measaure = useSelector(state => state.mesurements);
  const upper = {};
  const lower = {};
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getMesurementDetails(id));
  }, []);

  return (
    <>
      {mobileView ? (
        <ViewMeasurementMobile mea={measaure?.localmesure} image={measaure?.localimages} view={true} />
      ) : (
        <section className={styles.section}>
          <BreadCrumbs
            currentPath='Measurements'
            paths={[{ name: 'Home', to: '/' }]}
          />
          <Grid
            container
            className={cx(styles.gridContainer, styles.mainGridContainer)}
          >
            <Grid item xs={12} sm={12} md={5}>
              <div
                className={`${styles.modelImgContainer} view-measurement__carousel`}
              >
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination, Navigation]}
                  style={{ width: '100%' }}
                >
                  <SwiperSlide>
                    <img
                      src={measaure.localimages?.front || img}
                      alt='all body details'
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={measaure.localimages?.back || img}
                      alt='all body details'
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </Grid>
            {!tabView && !mobileView && (
              <Grid item xs={0} sm={0} md={1} style={{ height: '100%' }}></Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ height: '100%' }}
              className={styles.detailsDiv}
            >
              <Grid
                container
                spacing={2}
                className={cx(
                  styles.upperBodyGridContainer,
                  styles.gridContainer
                )}
              >
                <div className={styles.OtherInfoDiv}>
                  {/* <span>
                <h1>Name:</h1>
                <h3></h3>
              </span> */}
                  <span>
                    <h1>Gender:</h1>
                    <h3>{measaure?.localmesure?.gender}</h3>
                  </span>
                  <span>
                    <h1>Standard size:</h1>
                    <h3>{measaure?.localmesure?.size}</h3>
                  </span>
                  <span>
                    <h1>Fitting:</h1>
                    <h3>{measaure?.localmesure?.fitting}</h3>
                  </span>
                </div>
                <Grid item xs={12}>
                  <div className={styles.detailsHeader}>Upper Body</div>
                </Grid>
                <Grid item xs={12}></Grid>
                { (measaure?.localmesure?.gender==="He" || measaure?.localmesure?.gender==="Boy") && Object.keys(measaure?.localmesure?.mesurement?.upperbody).map((item,index)=>{
                    return   <Grid item xs={12} sm={12} md={6} className={styles.gridItem}>
                    <span>{index+1}. {item.replace("_"," ")}</span>
                    <div>
                      {/* <span style={{ fontSize: '10px' }}>Neck Around</span> */}
                      {measaure?.localmesure?.mesurement?.upperbody[item] || 0}
                    </div>
                  </Grid>
                })   
              }        
             { (measaure?.localmesure?.gender!=="He" && measaure?.localmesure?.gender!=="Boy") &&
                <>
                <Grid item xs={12} sm={12} md={6} className={styles.gridItem}>
                  <span>2. Neck round</span>
                  <div>
                    {/* <span style={{ fontSize: '10px' }}>Neck Around</span> */}
                    {measaure?.localmesure?.mesurement?.upperbody?.Neck
                      ?.Neck_Around || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={styles.gridItem}>
                  <span>3. Back Neck</span>
                  <div>
                    {/* <span style={{ fontSize: '10px' }}>Neck Around</span> */}
                    {measaure?.localmesure?.mesurement?.upperbody?.Neck
                      ?.Neck_Around || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={styles.gridItem}>
                  <span>4. Front Neck</span>
                  <div>
                    {/* <span style={{ fontSize: '10px' }}>Neck Around</span> */}
                    {measaure?.localmesure?.mesurement?.upperbody?.Neck
                      ?.Neck_Around || 0}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>5. Shoulder</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.upperbody?.Shoulder ||
                      0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>6. Chest</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.upperbody?.Chest || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>7. Arm Hole</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.upperbody?.Arm_Hole ||
                      0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>8. Sleeve Length</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.upperbody
                      ?.Sleeve_length || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>9. Wrist</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.upperbody?.Wrist || 0}
                  </div>
                </Grid>
                </>
}
              </Grid>
              <Grid
                container
                spacing={2}
                className={cx(
                  styles.lowerBodyGridContainer,
                  styles.gridContainer
                )}
              >
                <Grid item xs={12}>
                  <span className={styles.detailsHeader}>Lower Body</span>
                </Grid>
                <Grid item xs={12}></Grid>
                { (measaure?.localmesure?.gender==="He" || measaure?.localmesure?.gender==="Boy") && Object.keys(measaure?.localmesure?.mesurement?.lowerbody).map((item,index)=>{
                    return   <Grid item xs={12} sm={12} md={6} className={styles.gridItem}>
                    <span>{index+1}. {item.replace("_"," ")}</span>
                    <div>
                      {/* <span style={{ fontSize: '10px' }}>Neck Around</span> */}
                      {measaure?.localmesure?.mesurement?.lowerbody[item] || 0}
                    </div>
                  </Grid>
                })   
              }
{(measaure?.localmesure?.gender!=="He" && measaure?.localmesure?.gender!=="Boy") &&
                  <>
                <Grid item xs={12} sm={12} md={6} className={styles.gridItem}>
                  <span>1. High Waist</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.lowerbody?.Waist
                      ?.High_Waist || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={styles.gridItem}>
                  <span>2. Low Waist</span>
                  <div>
                    {/* <span style={{ fontSize: '10px' }}></span> */}
                    {measaure?.localmesure?.mesurement?.lowerbody?.Waist
                      ?.Low_Waist || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={styles.gridItem}>
                  <span>3. Mid Waist</span>
                  <div>
                    {/* <span style={{ fontSize: '10px' }}>t</span> */}
                    {measaure?.localmesure?.mesurement?.lowerbody?.Waist
                      ?.Mid_Waist || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>4. Full Length</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.lowerbody
                      ?.Full_length || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>5. Hip Round</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.lowerbody?.Hip_Round ||
                      0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>6. InSeam</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.lowerbody?.InSeam || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>7. Thigh</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.lowerbody?.Thigh || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>8. Calf</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.lowerbody?.Calf || 0}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.gridItem}>
                  <span>9. Ankle</span>
                  <div>
                    {measaure?.localmesure?.mesurement?.lowerbody?.Ankle || 0}
                  </div>
                </Grid>
                </>}
              </Grid>
              <Grid
                container
                className={cx(styles.gridContainer, styles.buttonGridContainer)}
                spacing={3}
              >
              <Grid item xs={6} sm={6} md={6}>
                  <Button
                    className={cx(styles.button, styles.backBtn)}
                    startIcon={<MdOutlineArrowBack />}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              
              </Grid>
            </Grid>
          </Grid>
        </section>
      )}
    </>
  );
}
