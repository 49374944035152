import { createSlice } from '@reduxjs/toolkit';
import { imageurl } from '../../config';
import firebase from '../../firebase/firebase';
import { startLoading } from './ui';

const initialState = {
  mesurements: {},
  localmesure: {},
  localimages: [],
  localimagesfiles: [],
};

const slice = createSlice({
  name: 'lynks',
  initialState,
  reducers: {
    mesurements(state, action) {
      state.mesurements = action.payload;
    },
    localmesure(state, action) {
      state.localmesure = action.payload;
    },
    localimages(state, action) {
      state.localimages = action.payload;
    },
    localimagesfile(state, action) {
      state.localimagesfiles = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const getMesurementDetails = id => {
  return (dispatch, getstate) => {
    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection('lynks')
        .doc(id)
        .get()
        .then(res => {
          dispatch(startLoading(false));
          dispatch(
            slice.actions.localmesure({ ...res.data()?.mesurementdetails })
          );
          dispatch(
            slice.actions.localimages({
              front: imageurl(res.data()?.mesurementdetails?.images?.front),
              back: imageurl(res.data()?.mesurementdetails?.images?.back),
            })
          );
        })
        .catch(err => {});
    }
  };
};

export const setlocalmeasure = data => {
  return dispatch => {
    dispatch(slice.actions.localmesure(data));
  };
};

export const localimages = data => {
  return dispatch => {
    dispatch(slice.actions.localimages(data));
  };
};
export const localimagesfile = data => {
  return dispatch => {
    dispatch(slice.actions.localimagesfile(data));
  };
};
