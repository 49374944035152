import React, { useEffect, useState } from 'react';
import styles from './exchange.module.scss';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputBase,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import { IoCloseCircle, IoCloseCircleOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import ProductCard from '../../Components/ProductCard/ProductCard';
import ModalComponent from '../../Components/Modal/ModalComponent';
import { ReactComponent as Close } from '../../Components/Modal/images/close.svg';
import { ReactComponent as Success } from '../../Components/Modal/images/successLight.svg';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import { useDispatch, useSelector } from 'react-redux';
import { uploadSingleImage } from '../../utils/uploadimage';
import _ from 'lodash';
import { sumbitexchange } from '../../redux/slices/orders';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { startLoading, notify } from '../../redux/slices/ui';
const Exchange = () => {
  const [showModal, setShowModal] = React.useState(false);
  const mobile = useMediaQuery(MOBILE_WIDTH);

  const { id } = useParams();
  const exchangeproduct = useSelector(state => state.orders.exchange);
  const [alterimage, setalterimage] = useState([...exchangeproduct]);

  const dispatch = useDispatch();

  const [error, seterror] = useState({});

  useEffect(() => {
    seterror({});
  }, [alterimage]);

  const openModal = (e, r) => {
    setShowModal(true);
  };
  const closeModal = (e, r) => {
    if (r !== 'backdropClick') setShowModal(false);
    navigate('/');
  };
  const viewimage = async file => {
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

    return await toBase64(file);
  };

  const Submitpressed = async () => {
    let errori = {};

    let isError = false;
    let img = [];
    alterimage.map((i, ind) => {
      if (!i.exchange?.image || i.exchange?.image === '') {
        errori = { ...errori, image: 'please upload the image', index: ind };
        isError = true;
      }
      if (i.exchange?.reason?.length < 1 || !i.exchange?.reason?.length) {
        errori = { ...errori, reason: 'please fill the reason', index: ind };
        isError = true;
      }
      if (!i.exchange?.text || i.exchange?.text === '') {
        errori = { ...errori, des: 'please fill description', index: ind };
        isError = true;
      }

      if (i?.exchange?.image) {
        img.push(i?.exchange?.image);
      }
      seterror(errori);
      return null;
    });
    if (!isError) {
      dispatch(startLoading(true));
      const allimgg = img.map(im => {
        return uploadSingleImage(im, 'exchange');
      });
      const finaldata = _.cloneDeep(alterimage);
      await Promise.all(allimgg)
        .then(async resimg => {
          finaldata.map((i, index) => {
            finaldata[
              index
            ].exchange.image = `exchange/${finaldata[index].exchange.image.name}`;
            delete finaldata[index].exchange.image64;
          });
          dispatch(startLoading(false));
          const res = await dispatch(sumbitexchange(finaldata, id));

          openModal();
        })
        .catch(() => {
          dispatch(startLoading(false));
        });
    } else {
      dispatch(
        notify({
          type: 'error',
          status: true,
          message: 'Please fill all the Details for all the Products',
        })
      );
    }
  };

  const [returnReason, setReturnReason] = React.useState([]);

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    setReturnReason(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const reasons = [
    'Defective product',
    'Quality Issues',
    'Size issue',
    'Wrong Product',
    'Colour Issue',
  ];

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {},
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: '1.6rem',
      borderRadius: '5px',
      padding: '12px 12px',
      // transition: theme.transitions.create([
      //   'border-color',
      //   'background-color',
      //   'box-shadow',
      // ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'DM Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      },
    },
  }));

  const navigate = useNavigate();
  return (
    <div>
      <BreadCrumbs
        currentPath='Exchange'
        paths={[
          { name: 'Home', to: '/' },
          { name: 'Orders', to: '/orders' },
        ]}
      />
      <div className={styles.exchange}>
        <div className={styles.exchange__header}>
          <h1>Exchange</h1>
        </div>
        <div className={styles.exchange__body}>
          {alterimage.map((item, index) => {
            return (
              <div className={styles.exchange__body__item}>
                <ProductCard
                  data={item}
                  productType='readymade'
                  readymadesize={item.selected_size}
                />

                <div className={styles.exchange__form}>
                  <div
                    className={styles.return__form__control}
                    style={{
                      position: 'relative',
                    }}
                  >
                    {!alterimage[index]?.exchange?.reason ||
                      (alterimage[index]?.exchange?.reason?.length === 0 && (
                        <p
                          style={{
                            position: 'absolute',
                            top: '40px',
                            left: '18px',
                            zIndex: '1',
                            fontSize: '1.3rem',
                            pointerEvents: 'none',
                          }}
                        >
                          Select...
                        </p>
                      ))}
                    <label htmlFor='reason'>Enter reason for exchange*</label>
                    <FormControl
                      sx={{ width: '100%' }}
                      error={
                        error?.index === index &&
                        error?.error === 'please fill the reason'
                      }
                    >
                      <Select
                        multiple
                        value={alterimage[index]?.exchange?.reason || []}
                        onChange={e => {
                          const local = _.cloneDeep(alterimage[index]);
                          local.exchange = {
                            ...local.exchange,
                            reason:
                              typeof value === 'string'
                                ? e.target.value.split(',')
                                : e.target.value,
                          };
                          const newalter = [...alterimage];
                          newalter[index] = { ...local };

                          setalterimage(newalter);
                        }}
                        // renderValue={selected => selected.join(', ')}
                        // MenuProps={MenuProps}
                        input={
                          <BootstrapInput className={styles.selectInput} />
                        }
                        fullWidth
                        sx={{
                          backgroundColor: 'white',
                          width: '100%',
                        }}
                        renderValue={selected => {
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                                alignItems: 'center',
                              }}
                            >
                              {!selected || selected.length < 0 ? (
                                <h1>Enter reason for return*</h1>
                              ) : (
                                <>
                                  {selected.map(value => (
                                    <div className={styles.chip}>
                                      <div>
                                        <h4>{value}</h4>
                                      </div>
                                      {/* <div>
                                  <button style={{ cursor: 'pointer' }}>
                                    <IoCloseCircle
                                      size='1.8rem'
                                      color='rgba(0, 0, 0, 0.6)'
                                    />
                                  </button>
                                </div> */}
                                    </div>
                                  ))}
                                </>
                              )}
                            </Box>
                          );
                        }}
                      >
                        {reasons.map(name => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                alterimage[index]?.exchange?.reason?.indexOf(
                                  name
                                ) > -1
                              }
                              sx={{
                                color: '#6A5B40',
                                '&.Mui-checked': {
                                  color: '#6A5B40',
                                },
                                '& .MuiSvgIcon-root': { fontSize: 20 },
                              }}
                            />
                            <ListItemText
                              primary={name}
                              className={styles.checkboxList}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {error?.index === index &&
                      error?.reason === 'please fill the reason' && (
                        <div style={{ fontSize: '1.2rem', color: 'red' }}>
                          <FormHelperText> {error?.reason}</FormHelperText>
                        </div>
                      )}
                  </div>
                  <div className={styles.return__form__control}>
                    <label htmlFor='description'>Description*</label>
                    <textarea
                      name='description'
                      id=''
                      cols='30'
                      rows='10'
                      placeholder='Start from here'
                      value={alterimage[index].exchange?.text}
                      onChange={e => {
                        const local = _.cloneDeep(alterimage[index]);
                        local.exchange = {
                          ...local.exchange,
                          text: e.target.value,
                        };
                        const newalter = [...alterimage];
                        newalter[index] = { ...local };

                        setalterimage(newalter);
                      }}
                    ></textarea>
                    <p>*maximum characters shall be 100</p>
                  </div>

                  {error?.index === index &&
                    error?.des === 'please fill description' && (
                      <div style={{ fontSize: '1.2rem', color: 'red' }}>
                        {error?.des}
                      </div>
                    )}
                  <div className={styles.return__form__control}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <div>
                        <label
                          htmlFor={`image${index}`}
                          style={{
                            cursor: 'pointer',
                            width: '110px',
                          }}
                        >
                          Upload photo
                          <input
                            onChange={async e => {
                              viewimage(e.target.files[0]);
                              const i = [...alterimage];

                              const currobj = _.cloneDeep(i[index]);

                              currobj.exchange = {
                                ...currobj?.exchange,
                                image: e.target.files[0],
                              };
                              currobj.exchange = {
                                ...currobj?.exchange,
                                image64: await viewimage(e.target.files[0]),
                              };
                              currobj.exchange = {
                                ...currobj?.exchange,
                                status: 1,
                              };
                              currobj.status = 4;
                              i[index] = currobj;

                              setalterimage(i);
                            }}
                            style={{ display: 'none' }}
                            accept='image/*'
                            id={`image${index}`}
                            type='file'
                          />
                          <Button
                            style={{
                              marginTop: '5px',
                            }}
                            className={styles.uploadBtn}
                          >
                            <h6>Choose File</h6>
                          </Button>
                        </label>
                      </div>
                      {alterimage[index]?.exchange?.image64 && (
                        <div className={styles.return__form__control__img}>
                          <div
                            key={index}
                            style={{
                              position: 'relative',
                              width: '50px',
                              height: '50px',
                            }}
                          >
                            <img
                              alt='ing'
                              //   onClick={()=>{imageRemove(index)}}
                              src={alterimage[index]?.exchange?.image64}
                              style={{
                                width: '50px',
                                height: '50px',
                                objectFit: 'cover',
                                objectPosition: 'top',
                              }}
                            />
                            <Close
                              onClick={() => {
                                const local = [...alterimage];
                                let obi = _.cloneDeep(local[index]);
                                obi.exchange = {
                                  ...obi.exchange,
                                  image: null,
                                };
                                obi.exchange = {
                                  ...obi.exchange,
                                  image64: null,
                                };
                                local[index] = obi;
                                setalterimage(local);
                              }}
                              style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-5px',
                                fontSize: '1.5rem',
                                fill: 'var(--danger-dark)',
                                color: 'white',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {error?.index === index &&
                      error?.error === 'please upload the image' && (
                        <div
                          style={{
                            fontSize: '1.2rem',
                            color: 'red',
                            fontFamily: 'DM Sans',
                          }}
                        >
                          {error?.image}
                        </div>
                      )}

                    <h5>
                      You can upload only 1 file not more than 2MB in size and{' '}
                      supports JPG, JPEG or PNG
                    </h5>
                    <Link to='/terms-and-conditions'>Terms and conditions</Link>
                  </div>
                  <div
                    style={{
                      fontFamily: 'DM Sans',
                      marginTop: '10px',
                    }}
                  >
                    <h2
                      style={{
                        color: '#DA1E28',
                        fontSize: '1.6rem',
                        marginBlock: '4px',
                      }}
                    >
                      Note*
                    </h2>
                    <h4
                      style={{
                        color: 'rgba(218, 30, 40, 0.7)',
                        fontSize: '1.4rem',
                      }}
                    >
                      The product should be in original condition as received
                      with tags and bill.
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div className={styles.order_payment}>
            <div>
              <h3>Paid amount</h3>
              <p>Includes shipping</p>
            </div>
            <div>
              <h2 className={styles.price}>₹2000.00</h2>
            </div>
          </div> */}
          <div className={styles.exchange__body__btn}>
            <Button onClick={Submitpressed}>Submit</Button>
          </div>
        </div>
      </div>
      <ModalComponent
        height={mobile ? '228px' : '367px'}
        width='500px'
        open={showModal}
        handleClose={closeModal}
        closeBtn
      >
        <div className={styles.modalContainer}>
          <Success
            style={{
              transform: mobile ? 'scale(0.8)' : 'scale(0.85)',
            }}
          />
          {/* <IconButton
            onClick={openModal}
            style={{
              position: 'absolute',
              top: mobile ? '-10px' : '-30px',
              right: '-10px',
            }}
          >
            <svg
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='12.834' cy='12.834' r='12.834' fill='#DA1E28' />
              <path
                d='M17.1126 8.55859L8.55664 17.1146'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M8.55664 8.55859L17.1126 17.1146'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </IconButton> */}

          <h4>Exchange request sent successfully!</h4>
          <p>
            Note: The product should be in original condition as received with
            tags and bill.
          </p>

          <Button
            onClick={() => {
              closeModal();
            }}
            className={styles.modal__btn}
          >
            Back to home
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default Exchange;
