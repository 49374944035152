import React, { Fragment } from 'react';
import image1 from './images/1.png';
import styles from './twinning-collection.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { IconButton } from '@mui/material';
import { MdArrowForwardIos, MdOutlineArrowBackIos } from 'react-icons/md';
import { Link } from 'react-router-dom';

const items = [
  {
    id: 1,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fmom-daughter-duo.png?alt=media&token=2b1e4358-d579-449c-8104-76a5430a83ec',
    href: '/collection/twinning/mom-daughter-duo',
  },

  {
    id: 3,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fcouple-twinning.png?alt=media&token=a5fb6216-300c-4b14-b30b-7624cda628d4',
    href: '/collection/twinning/couples-twinning',
  },
  {
    id: 4,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fmom-son-twinning.png?alt=media&token=5c8d6444-6d8c-4491-b639-1f5c0ad61493',
    href: '/collection/twinning/mom-son-twinning',
  },
  {
    id: 5,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fdad-son-twinning.png?alt=media&token=f66c3edd-769c-4e36-b7fa-989b6befc0df',
    href: '/collection/twinning/dad-son-twinning',
  },
  {
    id: 6,
    image:
      'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Ffamily-twinning.png?alt=media&token=c77e4b09-76c8-4205-8cf4-c3d29bb50959',
    href: '/collection/twinning/family-twinning',
  },
];

const TwinningCollection = ({
  title = (
    <>
      Twinning <br /> Collection
    </>
  ),
  description = 'Now wear stunning couple clothes and match the best with your bette half',
  images = [
    'https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/banners%2Fmom-daughter-duo.png?alt=media&token=2b1e4358-d579-449c-8104-76a5430a83ec',
    image1,
  ],
}) => {
  return (
    <section className={styles.container}>
      <div className={styles.left}>
        <h1>
          {' '}
          Twinning <br /> Collection
        </h1>
        <p>
          Discover a world of style with our Twinning Collection! Match outfits,
          create unforgettable moments. Express unique styles while twinning
          effortlessly.
        </p>
      </div>
      <div className={styles.right}>
        <Swiper
          pagination={{
            dynamicBullets: false,
            clickable: true,
          }}
          spaceBetween={20}
          style={{
            paddingBottom: '50px',
            width: '100%',
          }}
          navigation={{
            nextEl: '.btn-next',
            prevEl: '.btn-prev',
            disabledClass: 'disabled',
          }}
          modules={[Zoom, Pagination, Navigation]}
        >
          {/* {images.length > 1 && ( */}
          <Fragment>
            <div
              style={{
                position: 'absolute',
                bottom: '-0px',
                left: '30%',
                zIndex: 10,
              }}
              className='btn-prev'
            >
              <IconButton>
                <MdOutlineArrowBackIos size={20} />
              </IconButton>
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: '-0px',
                right: '30%',
                zIndex: 10,
              }}
              className=' btn-next'
            >
              <IconButton>
                <MdArrowForwardIos size={20} />
              </IconButton>
            </div>
          </Fragment>
          {items.map(item => {
            const { href, id, image } = item;
            return (
              <SwiperSlide
                key={id}
                style={{
                  maxWidth: '100%',
                  width: '100%',
                  minWidth: '100%',
                }}
              >
                <Link to={href}>
                  <img src={image} alt='Twinning Collection' />
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default TwinningCollection;
