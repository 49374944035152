import React, { useEffect } from 'react';
import Header from '../../../Components/Common/Container/Header/Header';
import SectionNavigation from '../../../Components/Common/SectionNavigation/SectionNavigation';
import ItemCard from '../../../Components/ItemsCard/ItemCard';
import { products } from '../../../constants/dummyData';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomProductlinks } from '../../../redux/slices/lynks';
import BreadCrumbs from '../../../Components/Common/BreadCrumbs/BreadCrumbs';
import ProductFilter from '../../../Components/filter/ProductFilter';
import CategoryCard from '../../../Components/Card/CategoryCard';

const CustomMadeProducts = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCustomProductlinks());
  }, []);
  const { customProducts } = useSelector(state => state.lynks);

  return (
    <>
      <Header name={'Custom made products '} back={'/products'} />
      <div className='container'>
        <BreadCrumbs
          currentPath='Custom made products'
          paths={[{ name: 'Home', to: '/' }]}
        />
        <div
          style={{
            display: 'flex',

            alignItems: 'center',

            justifyContent: 'space-between',
          }}
        >
          <SectionNavigation
            noLine={true}
            heading={
              <>
                <p
                  style={{
                    color: '#0A0A0A',
                  }}
                >
                  <span
                    style={{
                      color: '#6A5B40',
                      fontStyle: 'italic',
                    }}
                  >
                    Custom made
                  </span>{' '}
                  Products{' '}
                </p>
              </>
            }
            back='/'
          />
          {/* <ProductFilter /> */}
        </div>

        <div className='gridContainer'>
          {customProducts
            ?.filter(i => i.createdDate)
            .sort((a, b) => b?.createdDate - a?.createdDate)
            ?.map(lynk => {
              // return lynk?.items?.map(product => {
                return <CategoryCard product={lynk?.items[0]} lynk={lynk} />;
              // });
            })}
        </div>
      </div>
    </>
  );
};

export default CustomMadeProducts;
