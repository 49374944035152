export const convertToSlug = text => {
  if (!text) return;
  return text
    .toLowerCase()
    .replace(/[\W_]+/g, '-') // Replace non-word characters with hyphens
    .replace(/^-+|-+$/g, ''); // Trim leading/trailing hyphens
};


  export function replaceSecondHyphen(str) {
    let firstHyphenIndex = str.indexOf('-');
    if (firstHyphenIndex !== -1) {
      let secondHyphenIndex = str.indexOf('-', firstHyphenIndex + 1);
      if (secondHyphenIndex !== -1) {
        let charsBeforeSecondHyphen = str.substring(0, secondHyphenIndex);
        let charsAfterSecondHyphen = str.substring(secondHyphenIndex + 1);
        str = charsBeforeSecondHyphen + ' ' + charsAfterSecondHyphen;
      }
    }
    return str;
  }
  export function capitalizeText(sentence) {
    return sentence.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }