export const imageurl = url => {
  if (!url) return null;
  let newurl;
  if (url?.includes('/')) newurl = encodeURIComponent(url);
  else newurl = url;

  const encoded = `https://firebasestorage.googleapis.com/v0/b/u2-lynk.appspot.com/o/${newurl}?alt=media`;

  return encoded;
};

//how to get the stock data of Tesla in javascript?
