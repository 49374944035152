import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router';
import LoginComponent from '../../Components/Login/LoginContainer';
import styles from './login.module.scss';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import SEO from '../../Components/Common/SEO';
const Login = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const step = +searchParams.get('step');

  const [currentStep, setCurrentStep] = React.useState(step || 1);
  const user = useSelector(state => state.user);
  const [number, setnumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (user.user) {
      navigate(-1);
    }
  }, [user.user]);

  return (
    <>
      <SEO title='LynkTown Sign in' />

      <div className={styles.container}>
        <LoginComponent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          number={number}
          setnumber={setnumber}
        />
      </div>
    </>
  );
};

export default Login;
