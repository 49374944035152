import { createSlice } from '@reduxjs/toolkit';
import firebase from '../../firebase/firebase';


const initialState = {
noti:[], 
unreadnoti:0
}

const slice = createSlice({
  name: 'lynks',
  initialState,
  reducers: {
    notification(state, action) {
      state.noti = action.payload;
    },
    unreadnoti(state, action) {
        state.unreadnoti = action.payload;
      },
   
  },
});

// Reducer
export default slice.reducer;

export const getnotification = () => {
  return dispatch => {
    let notilen=0
    const user=  firebase.auth().currentUser;
  if(user){
    firebase.firestore().collection('notifications')
    .where('user',"==",`${user.uid}`).where('type',"==","user")
    .onSnapshot((snap)=>{
      const da=snap.docs.map((doc)=>doc.data())
   
      da.map((item)=>{
          if(!item?.isRead ){
            notilen=notilen+1
          }
      })
      dispatch(slice.actions.notification(da));
      dispatch(slice.actions.unreadnoti(notilen));
    })
  }
   
   
  };
};


export const updateread = () => {
    return async dispatch => {
      const user=  firebase.auth().currentUser;
    if(user){
        const usersQuerySnapshot = await  firebase.firestore().collection('notifications').where('user', '==', user.uid).where('type',"==","user").get();
const batch = firebase.firestore().batch();
usersQuerySnapshot.forEach(documentSnapshot => {
    batch.update(documentSnapshot.ref, { isRead: true });
});

return batch.commit().then(()=>{
    dispatch(slice.actions.unreadnoti(0));
});

}

    }
      }



