import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import { requestedOrderdetail } from '../../redux/slices/requestOrder';
import DetailsCard from './components/DetailsCard';
import { ReactComponent as Whatsapp } from './components/images/whatsapp.svg';
import { IconButton } from '@mui/material';
import { FiPhoneCall } from 'react-icons/fi';
import styles from './components/detailsCard.module.scss';

const RequestOrderDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const vendorInfo = location.state;

  const data = useSelector(state => state.requestOrder);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    dispatch(requestedOrderdetail(id));
  }, []);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <BreadCrumbs
          currentPath='Requested Orders Details'
          paths={[
            { name: 'Home', to: '/' },
            { name: 'Requested orders', to: '/requested-orders' },
          ]}
        />
        <div className={styles.headerCall}>
          <IconButton>
            <a
              target='_black'
              rel='noopener noreferrer'
              href={`tel:+91${vendorInfo?.phoneNumber}`}
            >
              <FiPhoneCall />
            </a>
          </IconButton>
          <IconButton>
            <a
              target='_black'
              rel='noopener noreferrer'
              href={`https://api.whatsapp.com/send?phone=91${vendorInfo?.phoneNumber}`}
            >
              <Whatsapp />
            </a>
          </IconButton>
        </div>
      </div>

      <SectionNavigation heading='Orders details' back={-1} />

      <div className='items--request-details__container'>
        {data?.singleorder?.items?.map((item, index) => {
          return <DetailsCard key={index} data={item} />;
        })}
      </div>
    </div>
  );
};

export default RequestOrderDetails;
