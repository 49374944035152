import React from 'react';
import styles from './works.module.scss';
import { ReactComponent as Click } from './images/click.svg';
import { ReactComponent as LinkChain } from './images/link.svg';
import { ReactComponent as Camera } from './images/camera.svg';
import { ReactComponent as Submit } from './images/submit.svg';

const Works = () => {
  return (
    <div className={styles.requestOrderWorks}>
      <h2>How it works</h2>
      <div className={styles.worksContent}>
        <div className={styles.workSteps}>
          <div className={styles.worksStepImg}>
            <Click />
          </div>
          <h3>Select any product from instagram profile of a vendor</h3>
        </div>
        <div className={styles.workSteps}>
          <div className={styles.worksStepImg}>
            <Camera />
          </div>
          <h3>Take screenshot of a product image</h3>
        </div>
        <div className={styles.workSteps}>
          <div className={styles.worksStepImg}>
            <LinkChain />
          </div>
          <h3>Copy product link</h3>
        </div>
        <div className={styles.workSteps}>
          <div className={styles.worksStepImg}>
            <Submit />
          </div>
          <h3>Send request to vendor</h3>
        </div>
      </div>
    </div>
  );
};

export default Works;
