import React from 'react';

const ErrorBox = ({ children }) => {
  return (
    <p
      style={{
        color: 'red',
        textAlign: 'left',
        marginTop: '0.3rem',
        fontFamily: 'DM Sans',
        fontSize: '1.4rem',
      }}
    >
      {children}
    </p>
  );
};

export default ErrorBox;
