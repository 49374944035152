import { createSlice } from '@reduxjs/toolkit';
import firebase from '../../firebase/firebase';
import { showSnackBar, startLoading, startmodal } from './ui';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
const initialState = {
  orders: [],
  order: {},
  greenlight: false,
  alteration: [],
  exchange: [],
  return: [],
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    orders(state, action) {
      state.orders = action.payload;
    },
    order(state, action) {
      state.order = action.payload;
    },
    greenlighto(state, action) {
      state.greenlight = action.payload;
    },

    alteration(state, action) {
      state.alteration = action.payload;
    },
    exchange(state, action) {
      state.exchange = action.payload;
    },
    return(state, action) {
      state.return = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const getAllOrders = () => {
  return (dispatch, getstate) => {
    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection('lynks')
        .where('user', '==', uid)
        .where('status', '>', 2)
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => doc.data());
          const ids = querySnapshot.docs.map(doc => doc.id);
          const final = data
            .map((item, index) => {
              return { ...item, id: ids[index] };
            })
            .sort(
              (a, b) =>
                moment(b.Orderdate?.toDate()) - moment(a.Orderdate?.toDate())
            );

          const greenlight = sessionStorage.getItem('ordergreen');
          if (!greenlight) {
            sessionStorage.setItem('ordergreen', final?.length);
          } else if (greenlight === final?.length) {
            dispatch(slice.actions.greenlighto(false));
          } else if (greenlight < final?.length) {
            dispatch(slice.actions.greenlighto(true));
          }

          dispatch(slice.actions.orders(final));
          dispatch(startLoading(false));
        });
    }
  };
};

export const getOrderDetails = id => {
  return (dispatch, getstate) => {
    dispatch(slice.actions.order({}));
    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection('lynks')
        .doc(id)
        .get()
        .then(res => {
          if (res.data().user === uid) {
            firebase
              .firestore()
              .collection('vendors')
              .doc(res.data().vendor)
              .get()
              .then(vendor => {
                dispatch(
                  slice.actions.order({ ...res.data(), vendor: vendor.data() })
                );
              });
          } else {
            dispatch(
              startmodal({
                message: 'This Order does Not belongs to you ',
                type: 'error',
                status: true,
              })
            );
          }
          dispatch(startLoading(false));
        })
        .catch(err => { });
    }
  };
};

export const setordergreenlight = (ff, count) => {
  return dispatch => {
    dispatch(slice.actions.greenlighto(ff));
    sessionStorage.setItem('ordergreen', count);
  };
};

export const alterationrequest = productdtata => {
  return dispatch => {
    dispatch(slice.actions.alteration(productdtata));
  };
};

export const returnrequest = productdtata => {
  return dispatch => {
    dispatch(slice.actions.return(productdtata));
  };
};

export const exchangerequest = productdtata => {
  return dispatch => {
    dispatch(slice.actions.exchange(productdtata));
  };
};

export const sumbitalteration = (data, id) => {
  return async dispatch => {
    const allitem = await firebase
      .firestore()
      .collection('lynks')
      .doc(id)
      .get()
      .then(res => res.data().items);
    const local = [...allitem];
    const localid = data.map(item => item.id);
    const finaldata = allitem.map((item, ind) => {
      if (localid.includes(item.id)) {
        local[ind] = {
          ...data[localid.indexOf(item.id)],
          alteration: {
            ...data[localid.indexOf(item.id)].alteration,
            date: new Date(),
          },
        };
      }
    });

    firebase
      .firestore()
      .collection('lynks')
      .doc(id)
      .update({
        items: local,
        reShippingRequested: true,
        status: 5,
      })
      .then(res => {
        dispatch(slice.actions.alteration([]));
        return 1;
      })
      .catch(() => {
        return 0;
      });
  };
};

export const cancelalteration = (docid, itemid) => {
  return async (dispatch, state) => {
    dispatch(startLoading(true));
    const alldata = await firebase
      .firestore()
      .collection('lynks')
      .doc(docid)
      .get()
      .then(res => res.data());
    const allitem = alldata.items;
    const local = [...allitem];
    const inde = local.findIndex(i => i.id === itemid);

    // delete local[inde].alteration;
    local[inde].alteration.status = 3;
    local[inde].status = 3;
    let isAllalterationcalnceled = true;
    local.map(i => {
      if (i.status > 2) isAllalterationcalnceled = false;
      return;
    });
    firebase
      .firestore()
      .collection('lynks')
      .doc(docid)
      .update({
        items: local,
        reShippingRequested: !isAllalterationcalnceled,
        status: isAllalterationcalnceled ? 4 : 5,
      })
      .then(res => {
        firebase.firestore().collection('notifications').add({
          user: alldata.vendor,
          message: 'Alteration request cancelled',
          timestamp: firebase.firestore().FieldValue.serverTimestamp(),
          Lynkid: docid,
          status: 1,
          type: 'vendor',
          bussinessName: alldata.bussinessname,
        });
        dispatch(startLoading(false));
        dispatch(slice.actions.alteration([]));
        dispatch(getOrderDetails(docid));
        dispatch(
          showSnackBar({
            message: 'Alteration request cancelled successfully!',
          })
        );
        return 1;
      })
      .catch(() => {
        dispatch(startLoading(false));
        return 0;
      });
  };
};

export const sumbitexchange = (data, id) => {
  return async dispatch => {
    dispatch(startLoading(true));
    const allitem = await firebase
      .firestore()
      .collection('lynks')
      .doc(id)
      .get()
      .then(res => res.data().items);
    const local = [...allitem];
    const localid = data.map(item => item.id);
    allitem.map((item, ind) => {
      if (localid.includes(item.id)) {
        local[ind] = {
          ...data[localid.indexOf(item.id)],
          exchange: {
            ...data[localid.indexOf(item.id)].exchange,
            date: new Date(),
          },
        };
      }
    });

    firebase
      .firestore()
      .collection('lynks')
      .doc(id)
      .update({
        items: local,
        reShippingRequested: true,
        status: 7,
      })
      .then(async res => {
        await dispatch(ShippingExchange(data, id));
        dispatch(slice.actions.exchange([]));
        dispatch(startLoading(false));
        return 1;
      })
      .catch(() => {
        dispatch(startLoading(false));
        return 0;
      });
  };
};

export const sumbitreturn = (data, id) => {
  return async dispatch => {
    const allitem = await firebase
      .firestore()
      .collection('lynks')
      .doc(id)
      .get()
      .then(res => res.data().items);
    const local = [...allitem];
    const localid = data.map(item => item.id);
    allitem.forEach((item, ind) => {
      if (localid.includes(item.id)) {
        local[ind] = { ...data[localid.indexOf(item.id)] };
      }
    });

    firebase
      .firestore()
      .collection('lynks')
      .doc(id)
      .update({
        items: local,
        status: 7,
      })
      .then(async res => {
        dispatch(slice.actions.return([]));
        await dispatch(ShippingReturn(data, id));
        return 1;
      })
      .catch(() => {
        return 0;
      });
  };
};

export const ShippingExchange = (data, id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await firebase
      .functions()
      .httpsCallable('shipingtoken')()
      .then(response => response?.data?.data?.token);

    const orderDetails = state?.orders?.order;
    const shipItems = data;
    const orderId = uuidv4();

    const orderData = {
      order_id: orderId,
      order_date: moment().format('YYYY-MM-DD'),
      pickup_location: orderDetails?.vendor?.shippingDetails?.id,
      pickup_address: orderDetails?.userdetails?.address,
      pickup_customer_name: orderDetails?.userdetails?.name,
      pickup_last_name: orderDetails?.userdetails?.name,
      pickup_city: orderDetails?.userdetails?.city,
      pickup_pincode: orderDetails?.userdetails?.pincode,
      pickup_state: orderDetails?.userdetails?.state,
      pickup_country: 'India',
      channel_id: '2379996',
      pickup_email: orderDetails?.userdetails?.email,
      pickup_phone: orderDetails?.userdetails?.phone.replace('+91', ''),
      shipping_customer_name: orderDetails?.vendor?.businessName,
      shipping_address: orderDetails?.vendor?.shippingDetails?.address,
      shipping_city: orderDetails?.vendor?.shippingDetails?.city,
      shipping_country: 'India',
      shipping_pincode: orderDetails?.vendor?.shippingDetails?.pincode,
      shipping_state: orderDetails?.vendor?.shippingDetails?.state,
      shipping_email: orderDetails?.vendor?.profileInfo?.email,

      shipping_phone: orderDetails?.vendor?.phoneNumber,
      shipping_is_billing: 1,
      comment: id,
      order_items: shipItems.map(sitm => {
        return {
          name: sitm.name,
          sku: sitm.id,
          units: 1,
          selling_price: sitm.price,
        };
      }),
      payment_method: 'Prepaid',
      sub_total: orderDetails?.paymentdetails?.amountpaid,
      length: orderDetails?.shippingDimensions?.length,
      breadth: orderDetails?.shippingDimensions?.breadth,
      height: orderDetails?.shippingDimensions?.height,
      weight: orderDetails?.shippingDimensions?.weight,
    };
    // alert(JSON.stringify(orderData))

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: orderData,
      redirect: 'follow',
    };

    axios({
      url: 'https://apiv2.shiprocket.in/v1/external/orders/create/return',
      ...requestOptions,
    }).then(res => {
      const local = [...shipItems];
      const localid = data.map(item => item.id);
      const finaldata = shipItems.map((item, ind) => {
        if (localid.includes(item.id)) {
          local[ind] = {
            ...data[localid.indexOf(item.id)],
            orderId,
            exchange: { ...data[localid.indexOf(item.id)].exchange, status: 1 },
          };
        }
      });

      firebase.firestore().collection('lynks').doc(id).update({
        items: finaldata,
        modificaitonOrderId: orderId,
      });
      console.log(res);
    });
  };
};

export const ShippingReturn = (data, id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await firebase
      .functions()
      .httpsCallable('shipingtoken')()
      .then(response => response?.data?.data?.token);

    const orderDetails = state?.orders?.order;
    const shipItems = data;
    const orderId = uuidv4();

    const orderData = {
      order_id: orderId,
      order_date: moment().format('YYYY-MM-DD'),
      pickup_location: orderDetails?.vendor?.shippingDetails?.id,
      pickup_address: orderDetails?.userdetails?.address,
      pickup_customer_name: orderDetails?.userdetails?.name,
      pickup_last_name: orderDetails?.userdetails?.name,
      pickup_city: orderDetails?.userdetails?.city,
      pickup_pincode: orderDetails?.userdetails?.pincode,
      pickup_state: orderDetails?.userdetails?.state,
      pickup_country: 'India',
      channel_id: '2379996',
      pickup_email: orderDetails?.userdetails?.email,
      pickup_phone: orderDetails?.userdetails?.phone.replace('+91', ''),
      shipping_customer_name: orderDetails?.vendor?.businessName,
      shipping_address: orderDetails?.vendor?.shippingDetails?.address,
      shipping_city: orderDetails?.vendor?.shippingDetails?.city,
      shipping_country: 'India',
      shipping_pincode: orderDetails?.vendor?.shippingDetails?.pincode,
      shipping_state: orderDetails?.vendor?.shippingDetails?.state,
      shipping_email: orderDetails?.vendor?.profileInfo?.email,
      tag: 'return',
      shipping_phone: orderDetails?.vendor?.phoneNumber,
      shipping_is_billing: 1,
      comment: id,
      order_items: shipItems.map(sitm => {
        return {
          name: sitm.name,
          sku: sitm.id,
          units: 1,
          selling_price: sitm.price,
        };
      }),
      payment_method: 'Prepaid',
      sub_total: orderDetails?.paymentdetails?.amountpaid,
      length: orderDetails?.shippingDimensions?.length,
      breadth: orderDetails?.shippingDimensions?.breadth,
      height: orderDetails?.shippingDimensions?.height,
      weight: orderDetails?.shippingDimensions?.weight,
    };
    // alert(JSON.stringify(orderData))

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: orderData,
      redirect: 'follow',
    };

    axios({
      url: 'https://apiv2.shiprocket.in/v1/external/orders/create/return',
      ...requestOptions,
    }).then(res => {
      const local = [...shipItems];
      const localid = data.map(item => item.id);
      const finaldata = shipItems.map((item, ind) => {
        if (localid.includes(item.id)) {
          local[ind] = {
            ...data[localid.indexOf(item.id)],
            orderId,
            return: { ...data[localid.indexOf(item.id)].return, status: 1 },
          };
        }
      });

      firebase.firestore().collection('lynks').doc(id).update({
        items: local,
        modificaitonOrderId: orderId,
      });
      console.log(res);
    });
  };
};
