import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import Input from '../../Common/Form/Input';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { ChangePhoneNumber } from '../../../redux/slices/userslice';

const ChangePhone = ({ styles, setCurrentPhone, setCurrentStep }) => {
  const dispatch = useDispatch();
  const initialValues = {
    phone: '',
  };
  const validationSchema = Yup.object({
    phone: Yup.string()
      .phone('IN', 'Please enter a valid phone number')
      .required('Phone is required'),
  });

  const handleSubmit = async (values) => {
    const ssi=dispatch(ChangePhoneNumber(values.phone));
    
    setCurrentPhone(values.phone);
    if(ssi)
    setCurrentStep(2);
  };
  return (
    <>
      <h1>Update your mobile Number</h1>
      <p>Will be verified in the next step</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {formik => {
          const { dirty, values, isValid } = formik;

          return (
            <Form
              style={{
                width: '100%',
              }}
            >
              <div className={styles.formContainer__form}>
                <Input label='Phone Number' name='phone' id='phone' />
              </div>

              <div className={styles.formContainer__submitButton}>
                <Button disabled={!dirty || !isValid} type='submit'>
                  Continue
                </Button>
                <div id='recaptcha-container'></div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ChangePhone;
