import React, { useState, useEffect } from 'react';
import styles from './CustomTextField.module.scss';

import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  useMediaQuery,
  IconButton,
} from '@mui/material';

import './common.scss';
import { MdExpandMore } from 'react-icons/md';
import { MOBILE_WIDTH } from '../../../constants/breakPoints';
import { Link } from 'react-router-dom';
export default function CustomTextField({
  label,
  helperText,
  focused,
  Form,
  name,
  values,
  bt,
  video,
  enterpressed,
  autof,
  AllDone,
  AllValues,
  UploadMeasurement,
  SetIt,
  title,
  showName,
  error,
  setYouTubeUrl,
  description,
  descriptionExpanded,
  setDescriptionExpanded,
}) {
  const multiInputName = [
    'Neck_Around',
    'Neck_Back',
    'Neck_Front',
    'High_Waist',
    'Mid_Waist',
    'Low_Waist',
  ];
  const [Open, SetOpen] = useState(false);
  const Tab = useMediaQuery('(max-width:786px');
  const mobile = useMediaQuery(MOBILE_WIDTH);
  const [localstate, setlocalstate] = useState('');
  const BringValue = value => {
    // console.log('value', input.target.value)
    Form(value, name, bt);
    setlocalstate(value);
  };

  const keyPress = e => {
    if (e.keyCode === 13) {
      console.log('value', e.target.value);
      enterpressed();
    }
  };

  const [inputFirst, setInputFirst] = useState(
    values.toString()?.includes('.')
      ? values.toString()?.split('.')[0]
      : values || 0
  );
  const [inputSecond, setInputSecond] = useState(
    values.toString()?.includes('.') ? values.toString()?.split('.')[1] : 0 || 0
  );

  useEffect(() => {
    setInputFirst(
      values.toString()?.includes('.')
        ? values.toString()?.split('.')[0]
        : values || 0
    );

    setInputSecond(
      values.toString()?.includes('.')
        ? values.toString()?.split('.')[1]
        : 0 || 0
    );
  }, [label]);

  const changeValue = (method, type) => {
    if (method === 'add') {
      if (type === 'first') setInputFirst(prev => +prev + 1);
      if (type === 'second') setInputSecond(prev => +prev + 1);
    }
    if (method === 'sub') {
      if (type === 'first')
        setInputFirst(prev => {
          if (prev <= 0) {
            return 0;
          } else {
            return +prev - 1;
          }
        });
      if (type === 'second')
        setInputSecond(prev => {
          if (prev <= 0) {
            return 0;
          } else {
            return +prev - 1;
          }
        });
    }

    BringValue(Number(+inputFirst + '.' + (+inputSecond + 1)));
  };

  useEffect(() => {
    BringValue(Number(+inputFirst + '.' + +inputSecond) || null);
  }, [inputFirst, inputSecond]);

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.container}>
        {!Tab ? (
          <Accordion
            // onChange={() => { handleAccordionChange("neck"); FocusIt('neck') }}
            style={{
              boxShadow: 'none',
              marginTop: Tab ? 0 : -20,
              borderRadius: '5px 0',
              width: '100%',
              backgroundColor: '#fff',
              padding: Tab ? '0 5px' : '0 10px',
            }}
            expanded={Open}
            // onChange={() => SetOpen(!Open)}
          >
            <AccordionSummary
              // expandIcon={<MdExpandMore  size={20} onClick={() => SetOpen(!Open)} />}
              // IconButtonProps={{ size: 'small' }}
              aria-controls='panel1a-content'
              id='panel1a-header'
              className={styles.accordionSummary}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: 0,
                gap: 0,
              }}
              sx={{
                margin: 0,
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  margin: '0.5rem',
                  color: '#6C6C6C',
                  fontSize: '1.2rem',
                }}
              >
                {label}
              </p>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                }}
              >
                <TextField
                  error={error}
                  // color='red'
                  // className={styles.textField}

                  type='number'
                  InputLabelProps={{
                    style: {
                      color: 'grey',
                      fontFamily: 'DM Sans',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '16px',
                      letterSpacing: '0.3199999928474426px',
                      color: '#0a0a0a',
                    },
                  }}
                  variant='standard'
                  fullWidth
                  onKeyDown={keyPress}
                  // helperText={helperText}
                  // label={helperText}
                  sx={{
                    width: '200px',

                    fontSize: '55px',
                    fontWeight: 'bold',
                    '& input': {
                      fontFamily: 'DM Sans',
                      fontSize: '16px',
                      fontWeight: 700,
                      borderColor: 'red',
                    },
                  }}
                  inputRef={input => {
                    if (!autof) return input && input.focus();
                  }}
                  inputProps={{ autoFocus: true }}
                  value={values || ''}
                  onChange={e => {
                    BringValue(e.target.value);
                  }}
                />
                <p
                  style={{
                    position: 'absolute',
                    top: Tab ? '0px' : '5px',

                    fontSize: '1.6rem ',
                    left: Tab ? '10px' : `${120}px`,
                    fontWeight: '700',
                    fontFamily: 'DM Sans',
                    pointerEvents: 'none',
                    color: error ? 'red' : '#0A0A0A',
                  }}
                >
                  inches
                </p>
                {error && (
                  <p
                    style={{
                      color: '#ff0000',
                      padding: '0.2rem 0',
                    }}
                  >
                    Please fill the input*
                  </p>
                )}
                <div>
                  <p
                    className={styles.accInfo}
                    style={{ marginTop: Tab ? '0' : '5px' }}
                  >
                    {description}
                    <span
                      onClick={() => {
                        if (setYouTubeUrl) {
                          if (name === 'Neck_Around') {
                            setYouTubeUrl('https://youtu.be/hMvmiRWOh-o');
                          }
                          if (name === 'Neck_Back') {
                            setYouTubeUrl('https://youtu.be/6VMvIfmVGZs');
                          }
                          if (name === 'Neck_Front') {
                            setYouTubeUrl('https://youtu.be/5iIYOvjloOY');
                          }
                          if (name === 'High_Waist') {
                            setYouTubeUrl('https://youtu.be/y8wcSF7XRiY');
                          }
                          if (name === 'Mid_Waist') {
                            setYouTubeUrl('https://youtu.be/oFNMYLQwEdc');
                          }
                          if (name === 'Low_Waist') {
                            setYouTubeUrl('https://youtu.be/z3LsmbvRMO4');
                          }
                        }
                        video(true);
                      }}
                    >
                      Video Link{' '}
                    </span>
                  </p>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              style={{
                background: '#fff',
                display: 'flex',
                flexDirection: 'column',
                marginTop: Tab ? '-0.8em' : '0',
                padding: 0,
              }}
            ></AccordionDetails>
          </Accordion>
        ) : (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <h2
              className={styles.label}
              style={{
                color: error === name && 'red',
              }}
            >
              {label} (Inches)
            </h2>
            {multiInputName.includes(name) ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextField
                    error={error === name}
                    // color='red'
                    // className={styles.textField}

                    type='number'
                    InputLabelProps={{
                      style: {
                        color: 'grey',
                        fontFamily: 'DM Sans',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '16px',
                        letterSpacing: '0.3199999928474426px',
                        color: '#0a0a0a',
                      },
                    }}
                    variant='standard'
                    fullWidth
                    onKeyDown={keyPress}
                    // helperText={helperText}
                    // label={helperText}
                    sx={{
                      width: '200px',

                      fontSize: '55px',
                      fontWeight: 'bold',
                      '& input': {
                        fontFamily: 'DM Sans',
                        fontSize: '16px',
                        fontWeight: 700,
                        borderColor: 'red',
                      },
                    }}
                    inputRef={input => {
                      if (!autof) return input && input.focus();
                    }}
                    inputProps={{ autoFocus: true }}
                    value={values || ''}
                    onChange={e => {
                      BringValue(e.target.value);
                    }}
                  />

                  <IconButton
                    onClick={() => {
                      if (descriptionExpanded === name) {
                        setDescriptionExpanded('');
                      } else {
                        setDescriptionExpanded(name);
                      }
                    }}
                  >
                    <MdExpandMore size={20} />
                  </IconButton>
                </div>
              </>
            ) : (
              <div>
                <div className={styles.inputContainer}>
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    <input
                      style={{
                        color: inputFirst > 0 && '#4983d9',
                      }}
                      min={1}
                      type='number'
                      value={inputFirst}
                      // value={values || ''}
                      // onChange={BringValue}

                      onChange={e => {
                        setInputFirst(e.target.value);
                      }}
                    />
                    <IconButton
                      style={{
                        borderRadius: '50%',
                        position: 'absolute',
                        right: '5px',
                        top: '5px',
                      }}
                      onClick={() => {
                        changeValue('add', 'first');
                      }}
                    >
                      <svg
                        width='12'
                        height='7'
                        viewBox='0 0 12 7'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M11.5813 6.78223L6.28718 0.600408L0.993065 6.78223L11.5813 6.78223Z'
                          fill='black'
                          fillOpacity='0.5'
                        />
                      </svg>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        changeValue('sub', 'first');
                      }}
                      style={{
                        borderRadius: '50%',
                        position: 'absolute',
                        right: '5px',
                        bottom: '5px',
                      }}
                    >
                      <svg
                        width='12'
                        height='7'
                        viewBox='0 0 12 7'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M0.993164 0.191406L6.28728 6.37323L11.5814 0.191406H0.993164Z'
                          fill='black'
                          fillOpacity='0.5'
                        />
                      </svg>
                    </IconButton>
                  </div>
                  <div
                    style={{
                      fontSize: '4rem',
                      alignSelf: 'end',
                    }}
                  >
                    .
                  </div>
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    <input
                      type='number'
                      style={{
                        color: inputSecond > 0 && '#4983d9',
                      }}
                      onKeyDown={keyPress}
                      value={inputSecond}
                      // value={values || ''}
                      onChange={e => {
                        setInputSecond(e.target.value);
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        changeValue('add', 'second');
                      }}
                      style={{
                        borderRadius: '50%',
                        position: 'absolute',
                        right: '5px',
                        top: '5px',
                      }}
                    >
                      <svg
                        width='12'
                        height='7'
                        viewBox='0 0 12 7'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M11.5813 6.78223L6.28718 0.600408L0.993065 6.78223L11.5813 6.78223Z'
                          fill='black'
                          fillOpacity='0.5'
                        />
                      </svg>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        changeValue('sub', 'second');
                      }}
                      style={{
                        borderRadius: '50%',
                        position: 'absolute',
                        right: '5px',
                        bottom: '5px',
                      }}
                    >
                      <svg
                        width='12'
                        height='7'
                        viewBox='0 0 12 7'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M0.993164 0.191406L6.28728 6.37323L11.5814 0.191406H0.993164Z'
                          fill='black'
                          fillOpacity='0.5'
                        />
                      </svg>
                    </IconButton>
                  </div>
                  <div>
                    {AllDone === true ? (
                      <Link
                        style={{
                          color: '#fff',
                        }}
                      >
                        <Button
                          className={styles.submitBtn}
                          sx={{
                            display: Open ? 'flex' : 'none',
                          }}
                          onClick={
                            AllDone === true && AllValues
                              ? UploadMeasurement
                              : SetIt
                          }
                        >
                          {AllDone === true
                            ? 'Save'
                            : 'Please Fill all the fields'}
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        className={styles.submitBtn}
                        onClick={AllDone === true ? UploadMeasurement : SetIt}
                      >
                        {AllDone || 'Next'}
                        {(AllDone === 'Next' || !AllDone) && (
                          <svg
                            width='16'
                            height='17'
                            viewBox='0 0 16 17'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M6 12.6528L10 8.65283L6 4.65283'
                              stroke='white'
                              strokeWidth='1.33333'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {error === name && (
              <p
                style={{
                  padding: `10px 0 0 0`,
                  textAlign: 'left',
                  color: 'red',
                }}
              >
                Field is required*
              </p>
            )}
            {descriptionExpanded === name && (
              <p
                style={{
                  textAlign: 'left',
                  fontSize: '10px',
                }}
                className={styles.accInfo}
              >
                {description}
                <span
                  onClick={() => {
                    if (setYouTubeUrl) {
                     
                      if (name === 'Neck_Around') {
                        setYouTubeUrl('https://youtu.be/hMvmiRWOh-o');
                      }
                      if (name === 'Neck_Back') {
                        setYouTubeUrl('https://youtu.be/6VMvIfmVGZs');
                      }
                      if (name === 'Neck_Front') {
                        setYouTubeUrl('https://youtu.be/5iIYOvjloOY');
                      }
                      if (name === 'High_Waist') {
                        setYouTubeUrl('https://youtu.be/y8wcSF7XRiY');
                      }
                      if (name === 'Mid_Waist') {
                        setYouTubeUrl('https://youtu.be/oFNMYLQwEdc');
                      }
                      if (name === 'Low_Waist') {
                        setYouTubeUrl('https://youtu.be/z3LsmbvRMO4');
                      }
                    }
                    video(true);
                  }}
                >
                  Video Link{' '}
                </span>
              </p>
            )}
            {!multiInputName.includes(name) && (
              <p
                style={{
                  textAlign: 'left',
                  fontSize: '10px',
                }}
                className={styles.accInfo}
              >
                {description}
                <span
                  onClick={() => {
                    console.log('testeadfadf');
                    if (setYouTubeUrl) {
                      if (name === 'Neck_Around') {
                        setYouTubeUrl('https://youtu.be/hMvmiRWOh-o');
                      }
                      if (name === 'Neck_Back') {
                        setYouTubeUrl('https://youtu.be/6VMvIfmVGZs');
                      }
                      if (name === 'Neck_Front') {
                        setYouTubeUrl('https://youtu.be/5iIYOvjloOY');
                      }
                      if (name === 'High_Waist') {
                        setYouTubeUrl('https://youtu.be/y8wcSF7XRiY');
                      }
                      if (name === 'Mid_Waist') {
                        setYouTubeUrl('https://youtu.be/oFNMYLQwEdc');
                      }
                      if (name === 'Low_Waist') {
                        setYouTubeUrl('https://youtu.be/z3LsmbvRMO4');
                      }
                    }
                    video(true);
                  }}
                >
                  Video Link{' '}
                </span>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
