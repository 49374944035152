import styles from './lynksdetails.module.scss';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  getLynksDetails,
  saveaddress,
  savepaymnet,
} from '../../redux/slices/lynks';
// import Stepper from 'react-stepper-horizontal';
import example from './images/example.svg';
import DetailsPageHeader from '../../Components/DetailsPageHeader/DetailsPageHeader';
import { Form, Formik } from 'formik';
import Input from '../../Components/Common/Form/Input';
// import { Box, Step, StepLabel, Stepper } from '@mui/material';
import * as Yup from 'yup';
import { Button, Step, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import AddressForm from './Components/AddressForm/AddressForm';
import ModalComponent from '../../Components/Modal/ModalComponent';
import { ReactComponent as Tick } from './images/tick.svg';
import ProductCard from '../../Components/ProductCard/ProductCardNew';
import MeasurementForm from './Components/Measurement/MeasurementForm';
import firebase from '../../firebase/firebase';
import { AiFillCloseCircle } from 'react-icons/ai';

// const steps = ['Order Summary', 'Payment', 'Measurement'];

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import { notify } from '../../redux/slices/ui';
import { startLoading, startmodal } from '../../redux/slices/ui';
import {
  setlocalmeasure,
  localimages,
  localimagesfile,
} from '../../redux/slices/mesurement';
import InvalidLynkModal from '../../Components/InvalidLynkModal/InvalidLynkModal';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import moment from 'moment';
import Loading from '../../Components/Common/loading';

function loadScript(src) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.getElementById('root').appendChild(script);
  });
}

const LynksDetails = () => {
  const [total, settotal] = useState(0);
  const location = useLocation();
  const user = useSelector(state => state.user);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [invalid, setinvalid] = useState(false);
  const lynks = useSelector(state => state.lynks);
  const navigate = useNavigate();
  const [username, setusername] = useState('');
  const [emailinfo, setemailinfo] = useState('');
  const [touched, settouched] = useState(false);
  const mobile = useMediaQuery(MOBILE_WIDTH);

  const userInfo = user.profiledetials;
  useEffect(() => {
    dispatch(setlocalmeasure({}));
    dispatch(localimages({}));
    dispatch(localimagesfile({}));
  }, []);

  const isloading = useSelector(state => state.ui?.loading);

  async function displayRazorpay() {
    setIsLoading(true);

    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      setIsLoading(false);

      return;
    }

    const createorder = firebase.functions().httpsCallable('createorder');
    dispatch(startLoading(true));

    const { data } = await createorder({
      lynk: id,
      total: parseFloat(total).toFixed(2),
    }).then(result => result);

    dispatch(startLoading(false));

    const options = {
      //key: "rzp_test_FPWKEFU9QLsSAj",
      key: 'rzp_live_PXWgpoiHOWnM2w',
      currency: data.currency,
      amount: data.amount,
      order_id: data.id,
      name: 'LynkTown',
      description: 'Thank you for Shoping With Us',
      handler: function (response) {
        dispatch(
          savepaymnet(
            id,
            {
              paymentid: response.razorpay_payment_id,
              orderid: response.razorpay_order_id,
              amountpaid: data.amount / 100,
              shipping: shipingrate,
              type: 'Prepaid',
            },
            lynks?.lynksDetail?.measurementRequired ? 2 : 3,
            lynks?.lynksDetail
          )
        );
        if (lynks?.lynksDetail?.measurementRequired) toggleModal();

        // lynks?.lynksDetail?.measurementRequired && setActiveStep(2)
      },
      prefill: {
        name: username,
        email: emailinfo,
        phone_number: user.user.phoneNumber,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setIsLoading(false);
  }

  useEffect(() => {
    const fetchdata = async () => {
      await dispatch(getLynksDetails(id));
    };
    if (user.user) {
      fetchdata();
    }
  }, [user.user]);

  const [size, setsize] = useState([]);
  useEffect(() => {
    if (lynks.lynksDetail === null) {
      setinvalid(true);
    }
    if (lynks.lynksDetail?.status >= 3) {
      if (location?.state?.from === 'createlink')
        navigate(`/orders/${id}`, {
          replace: true,
          state: { from: 'createlink' },
        });
      else navigate(`/orders/${id}`, { replace: true, state: {} });
    }
  }, [lynks]);

  const [shipingrate, setshipingrate] = useState(0);
  const [sizeerror, setsizeerror] = useState([]);

  useEffect(() => {
    if (touched) {
      const papi = [];
      size.map((i, ind) => {
        if (i === null || !i) {
          papi.push(ind);
        }
      });
      setsizeerror(papi);
    }
  }, [size]);

  const handleSubmit = async values => {
    settouched(true);
    if (!lynks?.lynksDetail?.measurementRequired) {
      const papi = [];

      size.map((i, ind) => {
        if (i === null || !i) {
          papi.push(ind);
        }
      });

      if (papi.length > 0) {
        dispatch(
          notify({
            type: 'error',
            message: 'Please Select the Size',
            status: true,
          })
        );
        if (mobile) window.scrollTo(0, 0);
        setsizeerror(papi);
        return;
      }
    }

    if (
      values.pincode !== '' &&
      lynks?.lynksDetail?.shippingDetails?.shippingChargesExtra
    ) {
      dispatch(startLoading(true));
      const shipingg = await firebase.functions().httpsCallable('shipingrates');
      await shipingg({
        to: values.pincode,
        from: lynks?.lynksDetail?.vendor?.shippingDetails?.pincode,
        weight: lynks?.lynksDetail?.shippingDetails?.weight,
      })
        .then(res => {
          setshipingrate(res?.data?.rate);
          settotal(
            parseFloat(lynks?.lynksDetail?.total) +
              parseFloat(res?.data?.rate) -
              parseFloat(lynks?.lynksDetail?.totalDiscount)
          );
          dispatch(startLoading(false));
        })
        .catch(() => {
          dispatch(startLoading(false));
        });
    }

    setusername(values.name);
    setemailinfo(values.email);

    const v = { ...values, phone: user.user.phoneNumber };

    const sizedata = !lynks?.lynksDetail?.measurementRequired
      ? lynks?.lynksDetail?.items?.map((item, index) => {
          return { id: item.id, size: size[index] };
        })
      : [];

    dispatch(
      saveaddress(id, v, 1, sizedata, lynks?.lynksDetail?.shippingRequired)
    );
    setActiveStep(1);
  };
  console.log(userInfo);
  const initialValues = lynks?.lynksDetail?.userdetails
    ? { ...lynks?.lynksDetail?.userdetails }
    : {
        name: userInfo?.username || '',
        email: userInfo?.email || '',
        phoneno: userInfo?.phoneno || '',
        address: '',
        doorNo: '',
        city: '',
        state: '',
        pincode: '',
        locality: '',
      };

  const [isLoading, setIsLoading] = useState(false);

  const Addpayment = () => {
    if (lynks?.lynksDetail?.paymentRequired) displayRazorpay();
    else {
      setIsLoading(true);

      dispatch(
        savepaymnet(
          id,
          {
            amountpaid: lynks?.lynksDetail?.total + shipingrate,
            shipping: shipingrate,
            type: 'COD',
          },
          lynks?.lynksDetail?.measurementRequired ? 2 : 3,
          lynks?.lynksDetail
        )
      );
      if (lynks?.lynksDetail?.measurementRequired) toggleModal();
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    phoneno: Yup.string()
      .phone('IN', 'Please enter a valid phone number')
      .required('Phone number is required'),
    doorNo: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    pincode: Yup.string()
      .matches(/^[0-9]{6}$/, 'Must be exactly 6 digits')
      .required('Required'),
    locality: Yup.string().required('Required'),
  });

  const [activeStep, setActiveStep] = useState(0);
  const [steplenght, setsteplenght] = useState(0);

  const [steps, setsteps] = useState([
    {
      title: 'Order Detail',
    },
  ]);

  useEffect(() => {
    let local = [];
    if (
      lynks?.lynksDetail?.shippingRequired ||
      lynks?.lynksDetail?.paymentRequired
    ) {
      local[local.length] = {
        title: 'Order Detail',
      };

      settotal(lynks?.lynksDetail?.total - lynks?.lynksDetail?.totalDiscount);
    }
    if (
      lynks?.lynksDetail?.paymentRequired ||
      lynks?.lynksDetail?.shippingRequired ||
      lynks?.lynksDetail?.measurementRequired
    ) {
      local[local.length] = {
        title: 'Payment',
      };
      settotal(lynks?.lynksDetail?.total - lynks?.lynksDetail?.totalDiscount);
    }
    if (lynks?.lynksDetail?.measurementRequired) {
      if (lynks?.lynksDetail?.paymentRequired) {
        local[local.length] = {
          title: 'Measurement',
        };
      } else
        local[1] = {
          title: 'Measurement',
        };
    }

    setsteps([...local]);
    setsteplenght(local.length);

    if (lynks?.lynksDetail?.status === 2) {
      if (lynks?.lynksDetail?.measurementRequired) setActiveStep(2);
    } else if (
      lynks?.lynksDetail?.shippingRequired ||
      lynks?.lynksDetail?.paymentRequired
    )
      setActiveStep(0);
    else setActiveStep(1);
  }, [lynks?.lynksDetail]);
  console.log('🚀 ~ file: index.js:345 ~ LynksDetails ~ lynks:', lynks);

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = (dd, d) => {
    if (d !== 'backdropClick') setOpenModal(prev => !prev);
  };

  const stepsMui = lynks?.lynksDetail?.measurementRequired
    ? ['Order Details', 'Payment', 'Measurement']
    : ['Order Details', 'Payment'];
  const ddate = lynks?.lynksDetail?.ddate?.toDate();
  console.log(lynks);
  return (
    !isloading && (
      <>
        {invalid !== null && invalid ? (
          <div
            style={{
              fontSize: '50px',
              display: 'flex',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <InvalidLynkModal openmodal={invalid !== null && invalid} />
            {/* <AiFillCloseCircle style={{ frontSize: '50px', color: 'red' }} />
      Invalid Lynk */}
          </div>
        ) : (
          <>
            {lynks.lynksDetail?.isDeleted &&
            parseInt(lynks.lynksDetail?.isDeleted) === 1 ? (
              <div
                style={{
                  fontSize: '50px',
                  display: 'flex',
                  height: '70vh',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <InvalidLynkModal
                  openmodal={
                    lynks.lynksDetail?.isDeleted &&
                    parseInt(lynks.lynksDetail?.isDeleted) === 1
                  }
                />
                {/* <AiFillCloseCircle style={{ frontSize: '50px', color: 'red' }} />
          this Lynk is invalid */}
              </div>
            ) : (
              <div className={styles.lynksDetailsPage}>
                <Loading isLoading={isLoading} />
                <div>
                  <BreadCrumbs
                    currentPath='Order Summary'
                    paths={[
                      { name: 'Home', to: '/' },
                      { name: 'Lynks', to: '/lynks' },
                    ]}
                  />
                </div>
                <div className={styles.lynksDetailNav}>
                  <SectionNavigation back='/lynks' />
                </div>
                {
                  <div className={styles.lynksDetailContent}>
                    <DetailsPageHeader
                      id={lynks?.lynksDetail?.vendor?.id}
                      name={lynks?.lynksDetail?.vendor?.businessName}
                      image={lynks?.lynksDetail?.vendor?.profilePic}
                      phone={lynks?.lynksDetail?.vendor?.phoneNumber}
                      verified={lynks?.lynksDetail?.vendor?.isVerified}
                      coverpic={lynks?.lynksDetail?.vendor?.coverPic}
                      insta_username={
                        lynks?.lynksDetail?.vendor?.profileInfo?.insta_username
                      }
                      location={`${lynks?.lynksDetail?.vendor?.shippingDetails?.address} , ${lynks?.lynksDetail?.vendor?.shippingDetails?.locality},${lynks?.lynksDetail?.vendor?.shippingDetails?.city},${lynks?.lynksDetail?.vendor?.shippingDetails?.pincode}, ${lynks?.lynksDetail?.vendor?.shippingDetails?.state}`}
                    />

                    <div className={styles.stepper}>
                      {/* <Stepper
                  completeColor='#3AC267'
                  activeColor='#6A5B40'
                  defaultTitleColor='#606060'
                  titleFontSize={14}
                  lineMarginOffset={0}
                  activeBorderColor='green'
                  completeBarColor='#6A5B40'
                  size={30}
                  steps={steps}
                  activeStep={activeStep}
                /> */}

                      <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        sx={{
                          '& .MuiStepLabel-iconContainer': {
                            '& .MuiStepIcon-root': {
                              border: '1px solid #6A5B40',
                              color: 'transparent',
                              height: '3rem',
                              width: '3rem',
                              borderRadius: '50%',
                              text: {
                                fill: '#606060',
                              },
                            },
                            '& .MuiStepIcon-root.Mui-active': {
                              border: 'none',

                              color: '#6A5B40',
                              text: {
                                fill: 'white',
                              },
                            },
                            '& .MuiStepIcon-root.Mui-completed': {
                              border: 'none',
                              color: '#3AC267',
                              text: {
                                fill: 'white',
                              },
                            },
                          },
                          '& .MuiStepLabel-labelContainer': {
                            lineHeight: 1.5,
                            marginTop: '0.5rem',

                            '& .MuiStepLabel-label': {
                              lineHeight: 1.2,
                            },
                          },
                        }}
                      >
                        {stepsMui.map(label => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>

                    {(activeStep === 0 || activeStep === 1) && (
                      <div className={styles.items}>
                        {lynks?.lynksDetail?.items?.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <ProductCard
                                lynksDetail={lynks?.lynksDetail}
                                key={index}
                                item={item}
                                data={item}
                                index={index}
                                sizedata={size}
                                ddate={lynks?.lynksDetail?.ddate?.toDate()}
                                returnDays={item.returnDays}
                                exchangeDays={item.exchangeDays}
                                alterationDays={item.alterationDays}
                                renderplace='lynk'
                                from='lynkdetails'
                                vendor={lynks?.lynksDetail?.vendor}
                                productType={
                                  lynks?.lynksDetail?.measurementRequired
                                    ? 'customized'
                                    : 'readymade'
                                }
                                isReturnAvailable={item.isReturnAvailable}
                                setsize={(e, ind) => {
                                  const temp = [...size];
                                  temp[ind] = e;
                                  setsize([...temp]);
                                }}
                                readymadesize={size?.[index]}
                                sizeerror={sizeerror}
                                steps={activeStep}
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                    <div
                      style={{
                        padding: '0 8px',
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          maxWidth: '606px',
                          background: '#EEECE7',
                          margin: '30px auto',
                          padding: '10px',
                          borderRadius: '7px',
                        }}
                      >
                        <h2>
                          <span>Note* </span> -{' '}
                          <span
                            style={{
                              fontWeight: 400,
                            }}
                          >
                            Kindly record clear unboxing video for seamless
                            return, exchange & alteration of products{' '}
                          </span>
                        </h2>
                      </div>
                    </div>

                    {activeStep === 0 ? (
                      <>
                        {lynks?.lynksDetail?.shippingRequired ? (
                          <AddressForm
                            styles={styles}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            handleSubmit={handleSubmit}
                            btntext={'Next'}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: '10px 0px',
                              width: '100%',
                            }}
                          >
                            <Button
                              style={{
                                background: '#6a5b40',
                                padding: '8px 65px',
                                fontSize: '1.5rem',
                                color: 'white',
                                borderRadius: '50px',
                              }}
                              onClick={handleSubmit}
                            >
                              Next
                            </Button>
                          </div>
                        )}
                      </>
                    ) : activeStep === 1 ? (
                      <>
                        {
                          <ModalComponent
                            open={openModal}
                            handleClose={toggleModal}
                          >
                            <div className={styles.modalContent}>
                              <Tick />
                              <h1>Payment is successful</h1>

                              {lynks?.lynksDetail?.measurementRequired ? (
                                <>
                                  <p>
                                    You can add your measurement now or later
                                  </p>
                                  <div className={styles.modalBtns}>
                                    <Button
                                      onClick={() => {
                                        toggleModal();
                                        setActiveStep(2);
                                      }}
                                    >
                                      Add measurement
                                    </Button>

                                    <Button
                                      className={styles.outlineBtn}
                                      onClick={() => navigate('/lynks')}
                                    >
                                      I’ll do it later
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <div className={styles.modalBtns}>
                                  <Button onClick={() => navigate('/orders')}>
                                    Check details in the orders list
                                  </Button>

                                  <Button
                                    className={styles.outlineBtn}
                                    onClick={() => navigate('/')}
                                  >
                                    Back To Home
                                  </Button>
                                </div>
                              )}
                            </div>
                          </ModalComponent>
                        }
                        <div className={styles.totalSection}>
                          <div className={styles.total}>
                            <div
                              style={{
                                maxWidth: '350px',
                                margin: '0 auto',
                                width: '100%',
                              }}
                            >
                              <h3>Price Details</h3>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div>
                                  <p>Product Price </p>
                                  {lynks?.lynksDetail?.totalDiscount > 0 && (
                                    <p>Product Discount </p>
                                  )}
                                  <p>Shipping Charges </p>
                                </div>
                                <div>
                                  <p>
                                    ₹
                                    {parseFloat(
                                      lynks?.lynksDetail?.total
                                    ).toFixed(2)}
                                  </p>
                                  {lynks?.lynksDetail?.totalDiscount > 0 && (
                                    <p>
                                      -&nbsp;₹
                                      {parseFloat(
                                        lynks?.lynksDetail?.totalDiscount
                                      ).toFixed(2)}
                                    </p>
                                  )}

                                  <p style={{ color: 'green' }}>
                                    {shipingrate > 0
                                      ? `+₹${shipingrate}`
                                      : 'Free'}
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: '100%',
                                  borderTop: '1px solid lightgray',
                                  marginTop: '1rem',
                                }}
                              ></div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: '1rem',
                                }}
                              >
                                <div>
                                  <h3>Total Amount</h3>
                                </div>
                                <div>
                                  <h3>₹{parseFloat(total).toFixed(2)}</h3>
                                </div>
                              </div>

                              <div
                                style={{
                                  width: '100%',
                                  borderTop: '1px solid lightgray',
                                  marginTop: '1rem',
                                }}
                              ></div>
                            </div>
                          </div>

                          <div
                            className={styles.formBtn}
                            style={{
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            {/* {!lynks?.lynksDetail?.paymentRequired && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  fontSize: '1.6rem',
                                  alignItems: 'center',
                                  marginBottom: '20px',
                                }}
                              >
                                <span>Cash on Delivery</span>
                              </div>
                            )} */}

                            <Button onClick={Addpayment}>
                              {lynks?.lynksDetail?.paymentRequired
                                ? 'Pay'
                                : ' Cash on Delivery'}
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className={styles.addMeasurement}>
                        <h2>Add measurement</h2>
                        <MeasurementForm
                          id={id}
                          vendorInfo={lynks?.lynksDetail?.vendor}
                        />
                      </div>
                    )}
                  </div>
                }
              </div>
            )}
          </>
        )}
      </>
    )
  );
};

export default LynksDetails;
