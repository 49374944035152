import React from 'react';
import styles from './FloatingTag.module.scss';

import { Button } from '@mui/material';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';

function FloatingTag({
  position,
  FocusIt,
  IsFilled,
  NECK,
  CHEST,
  WRIST,
  SHOULDER,
  ARMHOLE,
  SLEEVE,
  WAIST,
  HIPROUND,
  INSEAM,
  FULLLENGTH,
  THIGH,
  CALF,
  ANKLE,
  body,
}) {
  return (
    <div className={styles.container}>
      {position === 'upper' ? (
        <>
          <Button
            onClick={() => FocusIt('Neck')}
            className={
              body === 'women'
                ? NECK.Neck_Around === '' &&
                  NECK.Neck_Back === '' &&
                  NECK.Neck_Front === ''
                  ? styles.neck
                  : styles.neckG
                : NECK === ''
                ? styles.neck
                : styles.neckG
            }
          >
            Neck <MdChevronRight style={{ fontSize: 15 }} />
          </Button>
          <Button
            onClick={() => FocusIt('Shoulder')}
            className={SHOULDER === '' ? styles.shoulder : styles.shoulderG}
          >
            <MdChevronLeft style={{ fontSize: 15 }} /> Shoulder
          </Button>
          <Button
            onClick={() => FocusIt('Chest')}
            className={CHEST === '' ? styles.chest : styles.chestG}
          >
            Chest <MdChevronRight style={{ fontSize: 15 }} />
          </Button>
          <Button
            onClick={() => FocusIt('Arm_Hole')}
            className={ARMHOLE === '' ? styles.armHole : styles.armHoleG}
          >
            <MdChevronLeft style={{ fontSize: 15 }} /> Arm Hole
          </Button>
          <Button
            onClick={() => FocusIt('Sleeve_length')}
            className={SLEEVE === '' ? styles.sleeve : styles.sleeveG}
          >
            <MdChevronLeft style={{ fontSize: 15 }} /> Sleeve
          </Button>
          <Button
            onClick={() => FocusIt('Wrist')}
            className={WRIST === '' ? styles.wrist : styles.wristG}
          >
            Wrist <MdChevronRight style={{ fontSize: 15 }} />
          </Button>
        </>
      ) : (
        <>
          {/* Bottom Tags here */}

          <Button
            onClick={() => FocusIt('Waist')}
            className={
              body === 'women'
                ? WAIST.High_Waist === '' &&
                  WAIST.Low_Waist === '' &&
                  WAIST.Mid_Waist === ''
                  ? styles.waist
                  : styles.waistG
                : WAIST === ''
                ? styles.waist
                : styles.waistG
            }
          >
            <MdChevronLeft style={{ fontSize: 15 }} /> Waist
          </Button>
          <Button
            onClick={() => FocusIt('Hip_Round')}
            className={HIPROUND === '' ? styles.hip : styles.hipG}
          >
            Hip Round <MdChevronRight style={{ fontSize: 15 }} />
          </Button>
          <Button
            onClick={() => FocusIt('Full_length')}
            className={
              FULLLENGTH === '' ? styles.fullLength : styles.fullLengthG
            }
          >
            <MdChevronLeft style={{ fontSize: 15 }} /> Full Length
          </Button>
          <Button
            onClick={() => FocusIt('Thigh')}
            className={THIGH === '' ? styles.thigh : styles.thighG}
          >
            <MdChevronLeft style={{ fontSize: 15 }} /> Thigh
          </Button>
          {console.log(INSEAM)}
          <Button
            onClick={() => FocusIt('InSeam')}
            className={INSEAM === '' ? styles.inseam : styles.inseamG}
          >
            Inseam
            <MdChevronRight style={{ fontSize: 15 }} />
          </Button>
          <Button
            onClick={() => FocusIt('Calf')}
            className={CALF === '' ? styles.calf : styles.calfG}
          >
            Calf <MdChevronRight style={{ fontSize: 15 }} />
          </Button>
          <Button
            onClick={() => FocusIt('Ankle')}
            className={ANKLE === '' ? styles.ankle : styles.ankleG}
          >
            <MdChevronLeft style={{ fontSize: 15 }} /> Ankle
          </Button>
        </>
      )}
    </div>
  );
}

export default FloatingTag;
