import React from 'react';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import { TermNConditionsData, TermNConditionsReturnData } from './data';
import styles from './termNcondition.module.scss';
import { useLocation, useParams } from 'react-router';

const TermsNConditions = () => {
  const { search } = useLocation();
  return (
    <div>
      <BreadCrumbs
        currentPath='Terms & conditions'
        paths={[{ name: 'Home', to: '/' }]}
      />
      <SectionNavigation heading='Terms & Conditions' back={-1} noLine />
      <div className={styles.container}>
        <div className={styles.termsMain}>
          <h2>
            Please read these terms and conditions ("Terms") carefully before
            signing up for our app. These Terms outline the legal agreement
            between you ("User," "You," or "Your") and LynkTown ("We," "Us," or
            "Our") regarding your use of our mobile application (the
            "LynkTown"). By signing up and using the App, you acknowledge and
            agree to be bound by these Terms. If you do not agree with any part
            of these Terms, please refrain from signing up or using the App.
          </h2>
        </div>
        <div className={styles.TermNConditionsList}>
          <h1>Following are the terms and conditions you should know:</h1>
          <ul>
            {search.includes('return') ? (
              <>
                {' '}
                {TermNConditionsReturnData?.map((data, index) => {
                  return (
                    <li key={data.id}>
                      <h1>
                        {data.id}-{data.title}
                      </h1>
                      <p>{data.term}</p>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {TermNConditionsData?.map((data, index) => {
                  return (
                    <li key={data.id}>
                      <h1>
                        {data.id}-{data.title}
                      </h1>
                      <p>{data.term}</p>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsNConditions;
