import { createSlice } from "@reduxjs/toolkit";
import firebase from "../../firebase/firebase";
import { notify, startLoading, startmodal } from "./ui";
import moment from "moment/moment";

const initialState = {
  vendorlist: [],
  vendorlynk: [],
  selectedvendor: {},
};

const slice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    getAllVendors: (state, action) => {
      state.vendorlist = action.payload;
    },
    getVendorLynk: (state, action) => {
      state.vendorlynk = action.payload;
    },
    getVedorDetails: (state, action) => {
      state.selectedvendor = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const getVendor = (limit) => {
  return (dispatch, getstate) => {
    dispatch(startLoading(true));

    //const uid = state.user.user.uid;
    firebase
      .firestore()
      .collection("vendors")
      .where("totalLynks", ">", 0)
      .where("isVerified", "==", true)
      .orderBy("totalLynks", "desc")
      .orderBy("latestLynkCreated", "desc")
      .limit(limit ? limit : 100)
      .get()
      .then((res) => {
        const data = res.docs.map((item) => ({ ...item.data(), id: item.id }));
        dispatch(startLoading(false));
        dispatch(slice.actions.getAllVendors(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(startLoading(false));
      });
  };
};

export const getAllVendorLynks = (id) => {
  return async (dispatch, getstate) => {
    if (id) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection("commonlynks")
        .where("acceptingOrders", "==", true)
        .where("inCompleteProfile", "==", false)
        .where("isDeleted", "==", 0)
        .where("vendor", "==", id)
        .onSnapshot(async (querySnapshot) => {
          const ids = querySnapshot.docs.map((doc) => doc.id);
          const data = querySnapshot.docs.map((doc, inx) => ({
            ...doc.data(),
            id: ids[inx],
          }));
          dispatch(slice.actions.getVendorLynk(data));
          dispatch(startLoading(false));
        });
    }
  };
};

export const getVendorByUniqueID = (id) => {
  return async (dispatch, getstate) => {
    if (id) {
      console.log(id);
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection("vendors")
        .where("profileInfo.insta_username", "==", id)
        .get()
        .then(async (res) => {
          const data = res.docs.map((doc) => doc.data());
          dispatch(slice.actions.getVedorDetails(data?.[0]));
          dispatch(startLoading(false));
        });
    }
  };
};

export const getVendorDetails = (id) => {
  return async (dispatch, getstate) => {
    if (id) {
      dispatch(startLoading(true));
      firebase
        .firestore()
        .collection("vendors")
        .doc(id)
        .get()
        .then(async (res) => {
          const data = res.data();
          dispatch(slice.actions.getVedorDetails(data));
          dispatch(startLoading(false));
        });
    }
  };
};
