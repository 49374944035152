import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import ProductCardNew from '../../Components/ProductCard/ProductCardNew';
import styles from './productDetails.module.scss';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVendorLynks, getVendorDetails } from '../../redux/slices/vendor';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import ItemCard from '../../Components/ItemsCard/ItemCard';
import moment from 'moment';
import { HiOutlineMinusSm, HiOutlinePlusSm } from 'react-icons/hi';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';
import image1 from './image/1.png';
import image2 from './image/2.png';
import image3 from './image/3.png';
import { MOBILE_WIDTH, TAB_WIDTH } from '../../constants/breakPoints';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
import firebase from '../../firebase/firebase';
import { startLoading } from '../../redux/slices/ui';
import { imageurl } from '../../config';
import { Link } from 'react-router-dom';
import { ImageModal } from '../../Components/Common/ImageLoader';
import CategoryCard from '../../Components/Card/CategoryCard';
import {
  isAlterationDaysAvaliable,
  isExchangeDaysAvaliable,
  isReturnDaysAvaliable,
} from '../../utils/isAvaliableFor';
import InfoAccordion from './components/InfoAccordion';
import SEO from '../../Components/Common/SEO';

const ProductDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isloading = useSelector(state => state.ui?.loading);
  const [expanded, setExpanded] = useState(false);

  const vendorInfo = useSelector(state => state.vendor.selectedvendor);
  console.log(
    '🚀 ~ file: index.js:50 ~ ProductDetail ~ vendorInfo:',
    vendorInfo
  );

  const productInfo = [
    {
      id: 1,
      title: 'About the Designer',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, esse?',
    },
    {
      id: 2,
      title: 'Fabric',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, esse?',
    },
    {
      id: 3,
      title: 'Care',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, esse?',
    },
    {
      id: 4,
      title: 'Disclaimer',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, esse?',
    },
    {
      id: 5,
      title: 'Shipping information',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, esse?',
    },
  ];

  const vendorsProducts = useSelector(state => state.vendor.vendorlynk);

  const [state, setState] = useState(location?.state);
  const [size, setsize] = useState([]);

  const [productInfos, setProductInfos] = useState(productInfo);

  const [sizeerror, setsizeerror] = useState([]);
  const updateView = async () => {
    firebase
      .firestore()
      .collection('commonlynks')
      .doc(id)
      .update({
        views: firebase.firestore.FieldValue.increment(1),
      });
  };

  useEffect(() => {
    dispatch(startLoading(true));

    firebase
      .firestore()
      .collection('commonlynks')
      .doc(id)
      .get()
      .then(res => {
        const data = res.data();

        setState({ item: data });
        updateView();
        dispatch(getVendorDetails(data.vendor));
        dispatch(getAllVendorLynks(data.vendor));
        dispatch(startLoading(false));
      });
  }, [id]);

  const lynks = state?.item;

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isTab = useMediaQuery('(max-width:1024px)');
  const isTabSm = useMediaQuery(TAB_WIDTH);

  const [activeSlide, setActiveSlide] = useState(0);
  const [imagesList, setImagesList] = useState([]);
  const [isImageModelOpen, setIsImageModelOpen] = useState(false);

  const handleClose = () => {
    setIsImageModelOpen(false);
  };

  const isMobile = useMediaQuery(MOBILE_WIDTH);

  // if (!location?.state) {
  //   return <Navigate to={'/'} replace={true} />;
  // }

  if (isloading)
    return (
      <>
        <SEO title='Hauteze - Loading...' />
        <p
          style={{
            margin: '2rem 0',
            textAlign: 'center',
          }}
        >
          loading..
        </p>
      </>
    );

  return (
    <>
      <SEO
        title={`${lynks?.items?.slice(0, 1)?.map(prod => prod.name)} - by ${
          lynks?.businessname
        }`}
      />

      <section>
        <ImageModal
          activeSlide={activeSlide}
          handleClose={handleClose}
          open={isImageModelOpen}
          images={imagesList}
        />
        <BreadCrumbs
          currentPath='Product Details'
          paths={[
            { name: 'Home', to: '/' },
            {
              name: lynks?.businessname,
              to: `/designers/${vendorInfo?.profileInfo?.insta_username}`,
            },
          ]}
        />

        <div className='container'>
          {lynks?.items?.slice(0, 1)?.map(prod => {
            console.log(
              '🚀 ~ file: index.js:181 ~ {lynks?.items?.slice ~ prod:',
              prod
            );
            const {
              canReturn,
              exchangeDays,
              images,
              isModification,
              lynk,
              name,
              price,
              sizes,
              stocks,
              productDiscount,
              returnDays,
              ddate,
              alterationDays,
            } = prod;
            const productType = lynks?.measurementRequired
              ? 'customized'
              : 'readymade';

            const allImage = lynks.items.map(item => item.images).flat();

            const priceInfo = lynks.items.reduce((acc, item) => {
              return +item.price + acc;
            }, 0);
            const discountInfo = lynks.items.reduce((acc, item) => {
              return +item.productDiscount + acc;
            }, 0);
            const productpriceData =
              parseFloat(priceInfo) - parseFloat(discountInfo);
            return (
              <div className={styles.grid}>
                {isTab ? (
                  <>
                    <Swiper
                      pagination={{
                        dynamicBullets: false,
                        clickable: true,
                      }}
                      spaceBetween={20}
                      style={{
                        width: '100%',

                        maxWidth: '100%',
                      }}
                      modules={[Pagination]}
                    >
                      {allImage.map((img, idx) => {
                        return (
                          <SwiperSlide
                            key={img}
                            style={{
                              width: '100%',

                              maxWidth: '100%',
                            }}
                          >
                            <img
                              src={imageurl(img)}
                              onClick={() => {
                                setActiveSlide(idx);
                                setImagesList(
                                  allImage.map(image => imageurl(image))
                                );
                                setIsImageModelOpen(true);
                              }}
                              alt=''
                              style={{
                                width: '100%',
                                maxHeight: isTabSm ? '520.09px' : '700px',
                                objectPosition: 'top center',
                                objectFit: 'cover',
                                cursor: 'zoom-in',
                              }}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    {allImage.map((img, idx) => {
                      return (
                        <img
                          src={imageurl(img)}
                          alt=''
                          onClick={() => {
                            setActiveSlide(idx);
                            setImagesList(
                              allImage.map(image => imageurl(image))
                            );
                            setIsImageModelOpen(true);
                          }}
                          style={{
                            width: '100%',
                            maxHeight: '880px',
                            objectFit: 'cover',
                            cursor: 'zoom-in',
                          }}
                        />
                      );
                    })}
                  </div>
                )}

                <div className={styles.productInfo}>
                  <h1> {lynks.items.map(item => item.name).join(' + ')}</h1>

                  <h3>
                    by
                    <Link
                      to={`/designers/${vendorInfo?.profileInfo?.insta_username}`}
                    >
                      {' '}
                      {lynks.businessname}
                    </Link>
                  </h3>
                  <span
                    style={{
                      padding: '1rem 0',
                      display: 'inline-block',
                    }}
                  >
                    {lynks?.items?.length > 1 && <>Total no of products (2)</>}
                  </span>

                  <div className={styles.price}>
                    <div className={styles.itemPrice}>
                      {discountInfo ? (
                        <>
                          <h2>₹{productpriceData.toFixed(2)}</h2>
                          <p className={styles.lineThrough}>
                            ₹{parseFloat(priceInfo).toFixed(2)}
                          </p>
                          <span>
                            {parseFloat(
                              (parseFloat(discountInfo) /
                                parseFloat(priceInfo).toFixed(2)) *
                                100
                            ).toFixed(2)}
                            % OFF
                          </span>
                        </>
                      ) : (
                        <>
                          <h2>₹{parseFloat(priceInfo).toFixed(2)}</h2>
                        </>
                      )}
                    </div>
                    <div className={styles.sizesCard}>
                      <div>
                        {productType === 'customized' && <h2>Made to Order</h2>}
                        <div className={styles.sizes}>
                          {sizes?.map(size => {
                            return <div className={styles.size}>{size}</div>;
                          })}
                        </div>
                      </div>
                      <div className={styles.btns}>
                        <Link
                          to={`/lynk/${vendorInfo?.profileInfo?.insta_username}/${id}`}
                          style={{
                            width: '100%',
                          }}
                        >
                          <Button className={styles.fill}>Purchase</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <InfoAccordion
                    title={'About the designer'}
                    handleChange={handleChange}
                    expanded={expanded}
                    description={vendorInfo?.profileInfo?.bio}
                  />
                  {/* <InfoAccordion
                  title={'Fabric'}
                  handleChange={handleChange}
                  expanded={expanded}
                  description={
                    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, esse?'
                  }
                /> */}
                  <InfoAccordion
                    title={'Care'}
                    handleChange={handleChange}
                    expanded={expanded}
                    description={
                      'For optimal care and longest lifespan, wash this item in cold water on the delicate cycle and avoid bleach and trichloroethylene-based dry cleaning solvents. Allow the item to air dry fully by laying it flat without heat. Following these cleaning and care instructions will help maintain the quality and appearance of this product over time.'
                    }
                  />
                  <InfoAccordion
                    title={'Disclaimer'}
                    handleChange={handleChange}
                    expanded={expanded}
                    description={
                      <>
                        The outfits on LynkTown are sourced directly from
                        designers. Variations may occur in colour, texture or
                        design due to handmade nature or photography. Review
                        size charts/measurements for custom outfits and other
                        instructions before purchasing. <br /> Design
                        availability and prices may change at designer's
                        discretion. We facilitate transactions between customers
                        and designers. Concerns regarding purchased items should
                        be directed to the respective designer. We do not own or
                        manufacture the products listed. We aim for transparency
                        and trust while prioritising customer satisfaction.
                        Thank you for choosing our platform to explore and
                        support the creativity of talented fashion designers.
                      </>
                    }
                  />
                  <InfoAccordion
                    title={'Shipping information'}
                    handleChange={handleChange}
                    expanded={expanded}
                    description={
                      <>
                        {prod?.productDispatchTimeLine && (
                          <span
                            style={{
                              fontSize: isMobile ? '12px' : '14px',
                              fontWeight: isMobile ? '500px' : '600',
                              color: '#ababab',
                            }}
                          >
                            Dispatch In : {prod?.productDispatchTimeLine} days
                          </span>
                        )}
                        {prod.status > 1 ? (
                          <div
                            style={{
                              margin: '10px 0',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                              textAlign: 'start',
                              columnGap: '20px',
                              fontSize: '12px',
                              fontWeight: '700',
                              color: '#3B3B3dB',
                              padding: '0 10px',
                            }}
                          >
                            {productType === 'readymade' ? (
                              <p>
                                <span>
                                  {isExchangeDaysAvaliable(
                                    exchangeDays,
                                    ddate,
                                    productType,
                                    prod
                                  ) ? (
                                    <>
                                      <span> Exchange within</span>&nbsp;
                                      <span
                                        style={{
                                          color: '#ababab',
                                        }}
                                      >
                                        {moment(
                                          moment(ddate).add(
                                            parseInt(exchangeDays),
                                            'd'
                                          )
                                        ).diff(moment(), 'day')}{' '}
                                        days
                                      </span>
                                    </>
                                  ) : !prod.exchange ? (
                                    <span
                                      style={{
                                        color: '#ababab',
                                      }}
                                    >
                                      Exchange Not available
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                            {productType !== 'readymade' ? (
                              <p>
                                <span>
                                  {isAlterationDaysAvaliable(
                                    alterationDays,
                                    ddate,
                                    productType,
                                    prod
                                  ) ? (
                                    <>
                                      <span> Alteration within</span>&nbsp;
                                      <span
                                        style={{
                                          color: '#ababab',
                                        }}
                                      >
                                        {moment(
                                          moment(ddate).add(
                                            parseInt(alterationDays),
                                            'd'
                                          )
                                        ).diff(moment(), 'day')}{' '}
                                        days
                                      </span>
                                    </>
                                  ) : !prod.alteration ? (
                                    <span
                                      style={{
                                        color: '#ababab',
                                      }}
                                    >
                                      Alteration Not available
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                            {productType === 'readymade' ? (
                              <p>
                                <span>
                                  {isReturnDaysAvaliable(
                                    returnDays,
                                    ddate,
                                    productType,
                                    prod
                                  ) ? (
                                    <>
                                      <span> Return within </span>&nbsp;
                                      <span
                                        style={{
                                          color: '#ababab',
                                        }}
                                      >
                                        {moment(
                                          moment(ddate).add(
                                            parseInt(returnDays),
                                            'd'
                                          )
                                        ).diff(moment(), 'day')}{' '}
                                        days
                                      </span>
                                    </>
                                  ) : !prod.return && !prod.exchange ? (
                                    <span
                                      style={{
                                        color: '#ababab',
                                      }}
                                    >
                                      Return Not available
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <div
                            style={{
                              margin: '10px 0',
                              width: '100%',
                              display: 'flex',
                              flexWrap: 'wrap',
                              rowGap: '5px',
                              alignItems: 'center',
                              justifyContent: 'start',
                              textAlign: 'start',
                              columnGap: '20px',
                              fontSize: isMobile ? '12px' : '14px',
                              fontWeight: isMobile ? '500px' : '600',
                              color: '#3B3B3dB',
                            }}
                          >
                            {productType === 'readymade' ? (
                              <p>
                                <span>
                                  {parseInt(exchangeDays) > 0 &&
                                  !prod.exchange ? (
                                    <>
                                      <span> Exchange within</span>&nbsp;
                                      <span
                                        style={{
                                          color: '#ababab',
                                        }}
                                      >
                                        {parseInt(exchangeDays)} days
                                      </span>
                                    </>
                                  ) : !prod.exchange ? (
                                    <span
                                      style={{
                                        color: '#ababab',
                                      }}
                                    >
                                      Exchange Not available
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                            {productType !== 'readymade' ? (
                              <p>
                                <span
                                  style={{
                                    color: '#ababab',
                                  }}
                                >
                                  {parseInt(alterationDays) > 0 &&
                                  !prod.alteration ? (
                                    <>
                                      <span> Alteration within</span>&nbsp;
                                      {parseInt(alterationDays)} days
                                    </>
                                  ) : !prod.alteration ? (
                                    <>Alteration Not available</>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                            {productType === 'readymade' ? (
                              <p>
                                <span>
                                  {parseInt(returnDays) > 0 && !prod.return ? (
                                    <>
                                      <span> Return within </span>{' '}
                                      <span
                                        style={{
                                          color: '#DA1E28',
                                        }}
                                      >
                                        {parseInt(returnDays)} days
                                      </span>
                                    </>
                                  ) : !prod.return && !prod.exchange ? (
                                    <span
                                      style={{
                                        color: '#DA1E28',
                                      }}
                                    >
                                      Return Not available
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </>
                    }
                  />
                  {/* {productInfos.map(info => {
                  return (
                    <Accordion
                      expanded={expanded === info.title}
                      onChange={handleChange(info.title)}
                      key={info.id}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === info.title ? (
                            <HiOutlineMinusSm size={15} />
                          ) : (
                            <HiOutlinePlusSm size={15} />
                          )
                        }
                        aria-controls={`${info.title}-content`}
                        id={`${info.title}-header`}
                      >
                        <Typography
                          style={{
                            padding: 0,
                            margin: '10px 0',
                          }}
                        >
                          {info.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          style={{
                            padding: 0,
                            margin: '10px 0',
                          }}
                        >
                          {info.description}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })} */}
                </div>
              </div>
            );
          })}
        </div>

        {/* {vendorsProducts.filter(prod => prod.id !== id).length > 1 && ( */}
        <>
          <div className={styles.moreProducts}>
            <h1 className={styles.title}>More from this designer</h1>
            <div className={styles.gridContainer}>
              {/* {vendorsProducts
              .filter(prod => prod.id !== id)
              .slice(0, 5)
              ?.map((item, idx) => {
               
                return (
                  <></>
                  // <ItemCard
                  //   maxWidth={'45vw'}
                  //   key={idx}
                  //   link={item.lynk ? item.lynk : ''}
                  //   designerName={
                  //     item?.businessname || item?.vendordetails?.businessName
                  //   }
                  //   totalProducts={item.items.length}
                  //   measurementRequired={item.measurementRequired}
                  //   date={moment(item?.createdDate).format('DD-MM-YYYY')}
                  //   price={item.price ? item.price : ''}
                  //   id={item.id}
                  //   product={item.items}
                  //   to={`/products/${item.businessname}/${item.id}`}
                  //   products={item.items}
                  //   vendorInfo={item.vendordetails}
                  //   item={item}
                  // />
                  // <CategoryCard product={item} />
                );
              })} */}
              {vendorsProducts
                .filter(prod => prod.id !== id)
                .slice(0, 5)
                .sort((a, b) => b?.createdDate - a?.createdDate)
                ?.map(lynk => {
                  // return lynk?.items?.map(product => {
                  return <CategoryCard product={lynk?.items[0]} lynk={lynk} />;
                  // });
                })}
            </div>
            {vendorsProducts.filter(prod => prod.id !== id).length > 5 && (
              <Button
                className={styles.btn2}
                onClick={() => {
                  navigate(
                    `/designers/${vendorInfo?.profileInfo?.insta_username}`
                  );
                }}
              >
                View all
              </Button>
            )}
          </div>
        </>
        {/* // )} */}
      </section>
    </>
  );
};

export default ProductDetail;
