import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import './Styles/main.scss';
import { useEffect } from 'react';

import firebase from './firebase/firebase';
import { message } from './firebase/firebase';
import { getProfiledata, iftoken, setuser } from './redux/slices/userslice';

import { store } from './redux/store';
import Loadingcomp from './Components/Common/loading';
import { startLoading } from './redux/slices/ui';
import ModalComponent from './Components/Modal/uitatemodel';
import 'react-notifications/lib/notifications.css';
import CustomizedSnackbars from './Components/Common/notification';
import { getAllOrders } from './redux/slices/orders';
import { getAlllinks } from './redux/slices/lynks';
import { getAllrequestedOrder } from './redux/slices/requestOrder';
import { getnotification } from './redux/slices/notification';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import OtpModal from './Components/Modal/otpverifier';
import SnackBar from './Components/Modal/snackbar/SnackBar';
import { TextField } from '@mui/material';
import ScrollToTop from './hooks/useScrollToTop';
import SEO from './Components/Common/SEO';
import { GoogleTagManagerNoScript, GoogleTagManagerScript } from './GoogleTag';

function App() {
  useEffect(() => {
    store.dispatch(startLoading(true));
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        store.dispatch(getAllOrders());
        store.dispatch(getAlllinks());
        store.dispatch(getAllrequestedOrder());
        store.dispatch(getnotification());
        store.dispatch(getProfiledata());
        store.dispatch(iftoken());
        store.dispatch(setuser(user));
      } else {
        store.dispatch(setuser(null));
      }

      store.dispatch(startLoading(false));
    });
  }, []);

  useEffect(() => {
    message?.onMessage(function (payload) {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      };
      // console.log(notificationTitle,notificationOptions)

      Notification.requestPermission().then(function (result) {
        console.log(result);
      });
      // If it's okay let's create a notification
      var notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      notification.onclick = function (event) {
        event.preventDefault();
        window.open(payload.notification.link, '_blank');
        notification.close();
      };
    });
  }, [message]);

  // useEffect(() => {
  //   store.dispatch(
  //     startmodal({
  //       type: 'error',
  //       message:
  //         'Your Order Request is Submitted Successfully Lorem lorem Ipsum',
  //       status: true,
  //     })
  //   );
  // }, []);

  return (
    <div className='App'>
      {/* <SEO /> */}
      {/* <DeviceOrientation lockOrientation={'portrait'}> */}
      {/* Will only be in DOM in landscape */}
      {/* <Orientation orientation='portrait' > */}
      <Router>
        <ModalComponent />
        <OtpModal />
        <SnackBar />

        <CustomizedSnackbars />
        <Loadingcomp />
        <ScrollToTop />
        <Routes />
      </Router>
      {/* </Orientation> */}
      {/* Will stay in DOM, but is only visible in portrait */}
      {/* <Orientation orientation='landscape'>
          <div>
            <p>Please rotate your device</p>
          </div>
        </Orientation>
      </DeviceOrientation> */}
    </div>
  );
}

export default App;
