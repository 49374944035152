import React from 'react';
import { ReactComponent as Empty } from './emptyBig.svg';
import { ReactComponent as EmptySmall } from './emptySmall.svg';
import styles from './EmptyData.module.scss';
import { MOBILE_WIDTH } from '../../constants/breakPoints';
import { useMediaQuery } from '@mui/material';

const EmptyData = ({ label, description, svg }) => {
  // MEDIA QUERY
  const mobile = useMediaQuery(MOBILE_WIDTH);
  return (
    <div className={styles.emptyContainer}>
      <>{svg}</>

      <div className={styles.content}>
        <h1>No {label} available! </h1>
        {description && <p>{description}</p>}
      </div>
    </div>
  );
};

export default EmptyData;
