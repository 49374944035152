import moment from 'moment';
import React from 'react';
import styles from './invoice.module.scss';
import { ReactComponent as Logo } from './logo.svg';
const Invoice = ({ data, ref, id }) => {
  const items = [
    {
      name: 'Celebrity style',
      type: 'Men red kurtha',
      price: '₹999.00',
      quantity: 4,
    },
    {
      name: 'Blue men kurtae',
      type: 'Men red kurtha',
      price: '₹1999.00',
      quantity: 5,
    },
  ];

  return (
    <div className={styles.invoice} ref={ref}>
      <div className={styles.invoice__header}>
        <div className={styles.invoice__header__logo}>
          <Logo />
        </div>
        <div className={styles.invoice__header__info}>
          <h1>Invoice</h1>
        </div>
      </div>
      <div className={styles.invoice__body}>
        <div className={styles.invoice__body__info}>
          <h4>To</h4>
          <h1>{data?.userdetails?.name}</h1>
          <div className={styles.invoice__body__info__address}>
            <div>
              <h6 style={{ fontSize: 15 }}>
                {data?.userdetails?.address} {data?.userdetails?.locality},
                {data?.userdetails?.city},{data?.userdetails?.state}-
                {data?.userdetails?.pincode}
              </h6>
              <h3>
                Email id: <span>{data?.userdetails?.email}</span>
              </h3>
            </div>

            <div>
              <p>
                Invoice <span>{`#${id.substring(0, 5)}`}</span>
              </p>
              <p>
                Date{' '}
                <span>
                  {moment(data?.Orderdate?.toDate()).format('YYYY-MM-DD')}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.invoice__body__info}>
          <h4>Billing & Shipping from,</h4>
          <h1>
            {data?.vendor?.profileInfo?.name || data?.vendor?.businessName}
          </h1>
          <div className={styles.invoice__body__info__address}>
            <div>
              <h6 style={{ fontSize: 15 }}>
                {data?.vendor?.shippingDetails?.address}{' '}
                {data?.vendor?.shippingDetails?.locality},
                {data?.vendor?.shippingDetails?.city},
                {data?.vendor?.shippingDetails?.state}-
                {data?.vendor?.shippingDetails?.pincode}
              </h6>
              <h3>
                Email id: <span>{data?.vendor?.profileInfo?.email}</span>
              </h3>
            </div>
          </div>
        </div>
        <div className={styles.invoice__body__items}>
          <div className={styles.invoice__body__items__header}>
            <h3>Item Description</h3>

            <h3>Quantity</h3>
            <h3>Price</h3>

            <h3>Total</h3>
          </div>
          <div className={styles.invoice__body__items__body}>
            {data?.items?.map(item => {
              const { name, price } = item;
              return (
                <div
                  key={name}
                  className={styles.invoice__body__items__body__item}
                >
                  <div>
                    <h4>{name}</h4>
                    {/* // <p>{type}</p> */}
                  </div>
                  <div>
                    <h4>{1}</h4>
                  </div>
                  <div>
                    <h4>₹{price}</h4>
                  </div>
                  <div>
                    <h2>₹{price}</h2>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.invoice__body__price}>
          <div>
            <h3>MRP</h3>
            <h4>₹{data?.total}</h4>
          </div>
          <div>
            <h3>Shipping Charge</h3>
            <h4>
              ₹
              {data?.paymentdetails?.shipping > 0
                ? data?.paymentdetails?.shipping
                : '0.00'}
            </h4>
          </div>

          <div>
            <h3>Convenience Fee</h3>
            <h4>₹0.00</h4>
          </div>
          <div
            style={{
              width: '100%',
              height: '1.5px',
              background: '#253D34',
              marginBlock: '10px',
              opacity: 0.2,
            }}
          ></div>
          <div>
            <h3>Total</h3>
            <h4 className={styles.total}>
              ₹{data?.paymentdetails?.amountpaid}
            </h4>
          </div>
        </div>
        <div className={styles.invoice__body__paymethod}>
          <h3>Payment Mode</h3>
          <h3>
            {data.paymentRequired
              ? 'Prepaid (Online payment)'
              : 'Cash On Delivery'}
          </h3>
        </div>
        <div className={styles.invoice__body__footer}>
          <h3>DECLARATION </h3>
          <p>
            The goods sold are intended for end-user consumption and are not for
            retail sale.
          </p>
          <h2>
            If you have any questions, feel free to call customer care at +91
            9900649137 or use Contact Us section in our App, or log on to &nbsp;
            <a
              href='https://lynktown.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.lynktown.com
            </a>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
