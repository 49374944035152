import { Form, Formik } from 'formik';
import React, { useRef, useEffect } from 'react';
import Input from '../../Components/Common/Form/Input';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import styles from './updatePhone.module.scss';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import 'yup-phone-lite';
import { ChangePhoneNumber } from '../../redux/slices/userslice';
import { useDispatch } from 'react-redux';
import firebase from '../../firebase/firebase';
import { useState } from 'react';
import ChangePhone from '../../Components/Profile/Components/ChangePhone';
import VerifyPhone from '../../Components/Profile/Components/VerifyPhone';

const UpdatePhone = () => {
  const [currentPhone, setCurrentPhone] = useState();

  const [currentStep, setCurrentStep] = useState(1);

  return (
    <div className={styles.updatePage}>
      <SectionNavigation back='/profile' />
      <div className={styles.updateContent}>
        <div className={styles.formContainer}>
          {currentStep === 1 && (
            <ChangePhone
              styles={styles}
              setCurrentPhone={setCurrentPhone}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 2 && (
            <VerifyPhone styles={styles} currentPhone={currentPhone} />
          )}
        </div>
      </div>
      <div id='sign-in-button'></div>
    </div>
  );
};

export default UpdatePhone;
