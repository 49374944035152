import React from 'react';
import { ReactComponent as Tick } from '../../images/tick.svg';
import styles from './advantages.module.scss';

const Advantages = () => {
  return (
    <div className={styles.requestOrderAdvantages}>
      <h2>Advantages</h2>
      <div className={styles.requestOrderAdvantage}>
        <Tick />
        <p>Quick order processing</p>
      </div>
      <div className={styles.requestOrderAdvantage}>
        <Tick />
        <p>Secured payments</p>
      </div>
      <div className={styles.requestOrderAdvantage}>
        <Tick />
        <p>Receive customise products</p>
      </div>
    </div>
  );
};

export default Advantages;
