import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../Components/Common/BreadCrumbs/BreadCrumbs';
import SectionNavigation from '../../Components/Common/SectionNavigation/SectionNavigation';
import ItemCard from '../../Components/ItemsCard/ItemCard';
import { setrordergreenlight } from '../../redux/slices/requestOrder';
import moment from 'moment';
import EmptyData from '../../Components/EmptyData/EmptyData';
const RequestedOrders = () => {
  const dispatch = useDispatch();

  const data = useSelector(state => state.requestOrder);
  useEffect(() => {
    if (data?.AllrequestOrder?.length > 0) {
      dispatch(setrordergreenlight(false, data?.AllrequestOrder?.length));
    }
  }, [data]);

  return (
    <div>
      <BreadCrumbs
        currentPath='Requested Orders'
        paths={[{ name: 'Home', to: '/' }]}
      />
      <SectionNavigation heading='Requested Orders' back={-1} />

      {!data?.AllrequestOrder.length && (
        <EmptyData
          svg={
            <svg
              width='101'
              height='81'
              viewBox='0 0 101 81'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M32.9218 67.3213H85.8521C87.1628 67.3213 88.3126 66.3352 88.6673 64.9029L100.387 18.2357C100.639 17.2297 100.466 16.149 99.9115 15.313C99.3561 14.4797 98.4924 13.9875 97.5708 13.9875H26.1583L24.0641 3.26539C23.7665 1.73849 22.5764 0.654297 21.2031 0.654297H3.42968C1.81073 0.654297 0.5 2.1456 0.5 3.9876C0.5 5.83047 1.81073 7.32091 3.42968 7.32091H18.8517L29.4313 61.4871C26.3186 63.027 24.1327 66.5522 24.1327 70.6546C24.1327 76.1684 28.0756 80.6545 32.9218 80.6545H85.8521C87.4718 80.6545 88.7818 79.1641 88.7818 77.3212C88.7818 75.4792 87.4718 73.9879 85.8521 73.9879H32.9218C31.3082 73.9879 29.9921 72.494 29.9921 70.6546C29.9921 68.8152 31.3082 67.3213 32.9218 67.3213Z'
                fill='#E6E3DC'
              />
            </svg>
          }
          label='requested orders'
        />
      )}
      <div className='items--common__container'>
        {data?.AllrequestOrder?.slice()
          .sort((a, b) => b.timestamp?.toDate() - a.timestamp?.toDate())
          ?.map(item => {
            return (
              <ItemCard
                key={item.id}
                status={item.status === 0 ? 'pending' : 'Approved'}
                link={item.lynk ? item.lynk : ''}
                designerName={
                  item?.businessname || item?.vendordetails?.businessName
                }
                totalProducts={item.items.length}
                date={moment(item?.timestamp?.toDate()).format('DD-MM-YYYY')}
                price={item.price ? item.price : ''}
                id={item.id}
                product={item.items}
                to={`/requested-orders/${item.id}`}
                products={item.items}
                vendorInfo={item.vendordetails}
                item={item}
              />
            );
          })}
      </div>
    </div>
  );
};

export default RequestedOrders;
