import { useEffect } from "react"
import { Link, useNavigate, useParams } from 'react-router-dom';
import firebase from '../firebase/firebase'
const Createlink = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        firebase.firestore().collection('commonlynks').doc(id).get()
            .then(async (res) => {
                const userid = await firebase.auth()?.currentUser?.uid
                if (!userid) {
                    navigate('/')
                }
                if (res.data()) {
                    let lynkdata = null
                    if (res.data()?.users?.length > 0) {
                        lynkdata = res.data().users.find((i) => i.user === userid)
                    }

                    if (lynkdata)
                        navigate(`/${res.data().businessname}/${lynkdata.lynk}`, { replace: true, state: { from: "createlink" } })
                    else {
                        let allusers = res.data().users || []
                        let alldata = { ...res.data() }
                        delete alldata.users
                        firebase.firestore().collection('lynks').add({
                            ...alldata, parentLynk: id
                        }).then((res2) => {

                            firebase.firestore().collection('commonlynks').doc(id).update({ users: [...allusers, { user: userid, lynk: res2.id }] })
                            navigate(`/${res.data().businessname}/${res2.id}`, { replace: true, state: { from: "createlink" } })
                        })
                    }


                }
            })

    }, [])
    return (
        <>
        </>
    )
}
export default Createlink
