import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyA4mKgqOVQ-9A9V2tpb8d6OcYju84w76kY',
  authDomain: 'u2-lynk.firebaseapp.com',
  databaseURL: 'https://u2-lynk-default-rtdb.firebaseio.com',
  projectId: 'u2-lynk',
  storageBucket: 'u2-lynk.appspot.com',
  messagingSenderId: '351885442572',
  appId: '1:351885442572:web:bb803a7f090408791c3829',
  measurementId: 'G-CWNQY9G21F',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const message = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
export default firebase;
